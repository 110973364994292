import React, { useMemo } from 'react'
import { convertToKstDateTime, convertToKstMiniGameDateTime } from '../../utils/dateTime'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  PowerBallTypeEnums,
} from '../../utils/enums/MiniGame/PowerBallEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'

const MiniGameBettingLogData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 20,
    },
    {
      Header: '게임명',
      accessor: 'miniGameType',
      disableGlobalFilter: true,
      width: 40,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{MiniGameTypeEnums[original.miniGameType]}</>
      },
    },
    {
      Header: '배팅종목',
      accessor: 'miniGameBettingType',
      disableGlobalFilter: true,
      width: 40,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{PowerBallTypeEnums[original.miniGameBettingType]}</>
      },
    },
    {
      Header: '회차',
      accessor: 'gameRound',
      disableGlobalFilter: true,
      width: 40,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.gameRound?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '배당',
      accessor: 'bettingPer',
      disableGlobalFilter: true,
      width: 40,
    },
    {
      Header: '배팅액',
      accessor: 'bettingAmount',
      disableGlobalFilter: true,
      width: 40,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '당첨액',
      accessor: 'winningAmount',
      disableGlobalFilter: true,
      width: 40,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.winningAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '배팅결과',
      accessor: 'bettingResult',
      disableGlobalFilter: true,
      width: 30,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.bettingResult) {
          case 'ING':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '20px',
                  background: 'green',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingTypeEnums[original.bettingResult]}
              </button>
            )
          case 'WIN':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '20px',
                  background: 'blue',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingTypeEnums[original.bettingResult]}
              </button>
            )
          case 'LOSE':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '20px',
                  background: 'red',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingTypeEnums[original.bettingResult]}
              </button>
            )
          case 'CANCEL':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '20px',
                  background: 'orange',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingTypeEnums[original.bettingResult]}
              </button>
            )
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>-</span>
        }
      },
    },
    {
      Header: '배팅시간',
      accessor: 'bettingAt',
      disableGlobalFilter: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{convertToKstMiniGameDateTime(original.bettingAt)}</>
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MiniGameBettingLogData
