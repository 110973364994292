import React, { Fragment, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import CustomLoading from '../CustomLoading'
import {
  deleteSportsBettingHistory,
  cancelSportsBettingHistory,
  searchSportsBettingHistory,
} from '../../api/game/sportsGameApi'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import { SportTypeEnums, SportsResultStatusEnums } from '../../utils/enums/SportsGame/SportsGameEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import BettingHistoryRadio from './BettingHistoryRadio'
import { ListButton, MoneyFormModifyButtomInfo } from '../../shared/components/MoneyCustomElement'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../utils/palette'
import { CustomButtonInfos } from '../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../mobile/mobileCasinoGame/MobileLiveCasino'

const SportsBettingHistory = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [sportsBettingHistoryContent, setSportsBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchSportsBettingHistory = async params => {
    await searchSportsBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setSportsBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchSportsBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          // <img src={`${process.env.PUBLIC_URL}/img/sports/icon_h.png`} alt="" style={{ width: '7px', height: '7px' }} />
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const getOddNameKrComponent = (marketName, oddNameKr, pointName) => {
    switch (true) {
      case oddNameKr === '홀':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr === '짝':
        return <span>[{oddNameKr}] &nbsp; </span>
      case oddNameKr === '예':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr === '아니오':
        return <span>[{oddNameKr}] &nbsp; </span>
      case oddNameKr.includes('/언더'):
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr.includes('/오버'):
        return <span>[{oddNameKr}] &nbsp; </span>
      case marketName.includes('/양팀모두득점') && oddNameKr.includes('예'):
        return <span>&nbsp; [{oddNameKr}]</span>
      case marketName.includes('/양팀모두득점') && oddNameKr.includes('아니오'):
        return <span>[{oddNameKr}]&nbsp;</span>
      case marketName.includes('언더오버 3-Way') && oddNameKr.includes('언더'):
        return <span>&nbsp;[{pointName}]</span>
      case marketName.includes('언더오버 3-Way') && oddNameKr.includes('오버'):
        return <span>[{pointName}]&nbsp;</span>
      case marketName.includes('핸디캡 3-Way') && oddNameKr.includes('홈'):
        return <span>&nbsp;[{pointName}]</span>
      case marketName.includes('핸디캡 3-Way') && oddNameKr.includes('원정'):
        return <span>[{pointName}]&nbsp;</span>
      default:
    }
  }

  const onClickBettingHistoryCancelHandler = item => {
    // 현재시간
    const currentTime = moment().tz('Asia/Seoul')
    // 취소 가능 시간

    const availableCancelTime = moment(item.availableCancelTime).tz('Asia/Seoul')

    if (currentTime >= availableCancelTime) {
      alert('해당 배팅의 취소가능 시간이 지나서 배팅 취소가 불가능합니다.')
      return
    }

    // 경기가 시작된 것이 하나라도 있는 지
    const findIdx = item.detailBettingLogs.findIndex(log => moment(log.startAt).tz('Asia/Seoul') < currentTime)

    if (findIdx !== -1) {
      // 이미 시작된 경기가 존재하는 상황
      alert('이미 시작된 경기가 있어, 배팅 취소가 불가능합니다.')
      return
    }

    setLoading(true)
    setLoadingInfo('배팅 취소 처리중입니다.')

    cancelSportsBettingHistory({
      sportsBettingLogKey: item.sportsBettingLogKey,
      bettingAmount: item.bettingAmount,
    })
      .then(res => {
        alert('해당 배팅건에 대해 취소 처리 되었습니다.')
        fetchSportsBettingHistory(sportsBettingHistoryParams)
        dispatch(fetchMemberInfoAction())
        setLoading(false)
        setLoadingInfo('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'SPORTS-1017':
            alert(`${message}`)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('해당 배팅건에 대해 취소 처리 실패하였습니다.')
        }

        setLoading(false)
        setLoadingInfo('')
      })
  }
  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteSportsBettingHistory({
        sportsBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  return (
    <BettingHistoryTableWrap className="sports-container" ref={sportsBettingHistoryWrapRef}>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo} />
        </SportsGamePageLoadingOverlay>
      )}
      {sportsBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          스포츠 배팅내역이 없습니다.
          {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
        </NoContentDiv>
      ) : (
        <>
          {sportsBettingHistoryContent.map((item, index) => (
            <BettingHistoryTable key={item.sportsBettingLogKey}>
              <BettingHistoryTableHead>
                <BettingHistoryTableTr>
                  <th style={{ width: '8%' }}>경기일시</th>
                  <th style={{ width: '20%' }}>리그</th>
                  <th style={{ width: '12%' }}>마켓</th>
                  <th style={{ width: '23%' }}>홈팀 (Home)</th>
                  <th style={{ width: '6%' }}>무</th>
                  <th style={{ width: '23%' }}>원정팀 (Away)</th>
                  <th style={{ width: '5%' }}>점수</th>
                  <th style={{ width: '5%' }}>결과</th>
                </BettingHistoryTableTr>
              </BettingHistoryTableHead>
              <BettingHistoryTableBody>
                {/* 폴더 수만큼 생겨야 함 */}
                {item.detailBettingLogs.map(detail => (
                  <>
                    {detail.awayOddName ? (
                      <BettingHistoryTableTr key={detail.sportsDetailBettingLogKey}>
                        <BettingHistoryContentBox>
                          <BettingHistoryContentItemBox width="8">
                            {convertToKstByFormat(detail.startAt, 'MM-DD HH:mm')}
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="20">
                            {detail.sportsType !== 'ETC' && (
                              <img
                                src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${detail.sportsType}.png`}
                                alt=""
                              />
                            )}
                            {detail.leagueImage && <img src={detail.leagueImage} alt="" />}
                            {detail.leagueName || '-'}
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="12">{detail.marketName}</BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox
                            width="23"
                            active={detail.homeOddName === detail.selectedOddName}
                          >
                            <BettingHistoryContentItemDetailInfo1 textAlign="left">
                              <span>{detail.homeName}</span>
                              {getOddNameKrComponent(detail.marketName, detail.homeOddNameKr, detail.pointName)}
                            </BettingHistoryContentItemDetailInfo1>
                            <BettingHistoryContentItemDetailInfo2 textAlign="right">
                              {getMarketIconComponent(detail.marketName, detail.homeOddNameKr)}
                              <span style={{ marginLeft: '5px' }}>{detail.homeOddValue}</span>
                            </BettingHistoryContentItemDetailInfo2>
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="6" active={detail.selectedOddName === 'Draw'}>
                            <span>{(detail.drawOddName && detail.drawOddValue) || detail.pointName || 'VS'}</span>
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox
                            width="23"
                            active={detail.awayOddName === detail.selectedOddName}
                          >
                            <BettingHistoryContentItemDetailInfo2 textAlign="left">
                              <span style={{ marginRight: '5px' }}>{detail.awayOddValue}</span>
                              {getMarketIconComponent(detail.marketName, detail.awayOddNameKr)}
                            </BettingHistoryContentItemDetailInfo2>
                            <BettingHistoryContentItemDetailInfo1 textAlign="right">
                              {getOddNameKrComponent(detail.marketName, detail.awayOddNameKr, detail.pointName)}
                              <span>{detail.awayName}</span>
                            </BettingHistoryContentItemDetailInfo1>
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="5">
                            {detail.resultScore || '-'}
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="5">
                            <BettingHistoryContentBoxFooterInfo bettingResult={detail.sportsResultStatus}>
                              {SportsResultStatusEnums[detail.sportsResultStatus]}
                            </BettingHistoryContentBoxFooterInfo>
                          </BettingHistoryContentItemBox>
                        </BettingHistoryContentBox>
                      </BettingHistoryTableTr>
                    ) : (
                      <BettingHistoryTableTr key={detail.sportsDetailBettingLogKey}>
                        <BettingHistoryContentBox>
                          <BettingHistoryContentItemBox width="8">
                            {convertToKstByFormat(detail.startAt, 'MM-DD HH:mm')}
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="20">
                            {detail.sportsType !== 'ETC' && (
                              <img
                                src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${detail.sportsType}.png`}
                                alt=""
                              />
                            )}

                            {detail.leagueImage && <img src={detail.leagueImage} alt="" />}
                            {detail.leagueName || '-'}
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="12">
                            {detail?.marketName === '보너스 배당' && (
                              <SportsEventTitleTypeImg
                                src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                                style={{ width: '20px', height: '20px' }}
                                alt=""
                              />
                            )}
                            {detail.marketName}
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox
                            width="52"
                            active={detail.homeOddName === detail.selectedOddName}
                          >
                            <BettingHistoryContentItemDetailInfo1 textAlign="left">
                              {detail.homeName && (
                                <span style={{ marginRight: '10px' }}>
                                  {detail.homeName} VS {detail.awayName}
                                </span>
                              )}

                              {detail.selectedOddNameKr && !detail.marketName.includes('정확한') && (
                                <span style={{ marginRight: '10px' }}> [{detail.selectedOddNameKr}]</span>
                              )}
                              {detail.pointName && <span style={{ marginRight: '10px' }}> [{detail.pointName}]</span>}
                              <span>{detail.homeOddValue}</span>
                            </BettingHistoryContentItemDetailInfo1>
                            <BettingHistoryContentItemDetailInfo2 />
                          </BettingHistoryContentItemBox>

                          <BettingHistoryContentItemBox width="5">
                            {detail.resultScore || '-'}
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="5">
                            <BettingHistoryContentBoxFooterInfo bettingResult={detail.sportsResultStatus}>
                              {SportsResultStatusEnums[detail.sportsResultStatus]}
                            </BettingHistoryContentBoxFooterInfo>
                          </BettingHistoryContentItemBox>
                        </BettingHistoryContentBox>
                      </BettingHistoryTableTr>
                    )}
                  </>
                ))}
                <BettingHistoryTableTr>
                  <BettingHistoryContentBox>
                    <BettingHistoryRadio
                      sportsBettingLogKey={item.sportsBettingLogKey}
                      sportsResultStatus={item.sportsResultStatus}
                      checkRadio={checkRadio}
                      setCheckRadio={setCheckRadio}
                    />
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 시간 :</span>{' '}
                      {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 금액 : </span>
                      {item.bettingAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배당 : </span>
                      {item.totalOddValue}
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>예상 적중금액 : </span>
                      {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>적중 금액 : </span>
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo bettingResult={item.sportsResultStatus}>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>결과 : </span>
                      {SportsResultStatusEnums[item.sportsResultStatus]}
                    </BettingHistoryContentBoxFooterInfo>
                  </BettingHistoryContentBox>
                </BettingHistoryTableTr>
              </BettingHistoryTableBody>
              {moment(item.availableCancelTime).tz('Asia/Seoul') > moment().tz('Asia/Seoul') && (
                <BettingCancelBox>
                  <BettingCancelButton
                    onClick={() => {
                      onClickBettingHistoryCancelHandler(item)
                    }}
                  >
                    배팅 취소
                  </BettingCancelButton>
                </BettingCancelBox>
              )}
            </BettingHistoryTable>
          ))}
        </>
      )}

      {totalElements > 0 && (
        <>
          <ListWrap>
            <CustomDeleteButton
              onClick={() => {
                setCheckRadio(
                  sportsBettingHistoryContent
                    .filter(value => value.sportsResultStatus !== 'ING')
                    .map(value => value.sportsBettingLogKey),
                )
              }}
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton>
          </ListWrap>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </>
      )}
    </BettingHistoryTableWrap>
  )
}

export default SportsBettingHistory

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 120px;
    height: auto;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #525252;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead`
  th {
    background: none;
  }
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 600;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;

  div {
    font-weight: 500;
  }
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
  // &:hover {
  //   border: none;
  //   cursor: pointer;
  // }
  font-weight: 700;
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid #525252;
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: ${HermesMainSelect};
    color: ${HermesMainText};
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 10%;
  position: relative;
  color: red;
  margin-top: 2px;
  margin-left: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 70%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 5px;
  float: left;
  font-weight: 600;
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 29%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;
`
