import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FaLock } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { bettingForMiniGame } from '../../api/game/miniGameApi'
import CustomLoading from '../../containers/CustomLoading'
import BettingCartFormField from '../../containers/Layout/BettingCartFormField'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { setCloseHandler, setMiniGameCompleteFlag, setResetData } from '../../redux/miniGameBettingSlice'
import { MiniGameTypeEnums, PowerBallTypeEnums } from '../../utils/enums/MiniGame/PowerBallEnums'
import { HermesMainBorder, HermesMainHover, HermesMainHover2, HermesMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'

const MobileMiniGameBettingCart = () => {
  // redux 사용
  const dispatch = useDispatch()

  const {
    holdingMoney,
    miniGameMaxBetting,
    miniGameMinBetting,
    miniGameMaxWinning,
    memberTotalGameMoney,
    userId,
    memberLevel,
    noteRedisSize,
    oneToOneRedisSize,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      miniGameMaxBetting: memberInfo.miniGameBettingInfo.miniGameMaxBetting,
      miniGameMinBetting: memberInfo.miniGameBettingInfo.miniGameMinBetting,
      miniGameMaxWinning: memberInfo.miniGameBettingInfo.miniGameMaxWinning,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const {
    miniGameType,
    miniGameRound,
    bettingCloseFlag,
    bettingCompleteFlag,
    bettingMoney,
    miniGameBettingPer,
    miniGameBettingType,
    bettingCartNum,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameRound: miniGameBetting.miniGameRound,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
      bettingMoney: miniGameBetting.bettingMoney,
      miniGameBettingPer: miniGameBetting.miniGameBettingPer,
      miniGameBettingType: miniGameBetting.miniGameBettingType,
      bettingCartNum: miniGameBetting.miniGameBettingPer && 1,
    }
  })
  const [inputBettingMoney, setInputBettingMoney] = useState(Number(bettingMoney) || 0)
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  const onClickCloseHandler = event => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(setCloseHandler())
  }

  // 배팅 완료 및 초기화 시
  useEffect(() => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }, [miniGameBettingPer])

  useEffect(() => {
    dispatch(setResetData())
    setExpectMoney(0)
    setInputBettingMoney(0)
  }, [miniGameType])

  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)
    if (!miniGameBettingPer || miniGameBettingPer === 0 || inputBettingMoney === 0) {
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(bettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(miniGameMinBetting)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney) > Number(miniGameMaxBetting)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(miniGameMaxWinning)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        miniGameType,
        miniGameBettingType,
        bettingAmount: inputBettingMoney?.toString().replaceAll(',', ''),
        expectMoney: expectMoney?.toString().replaceAll(',', ''),
        bettingPer: miniGameBettingPer,
        bettingAt: moment().tz('Asia/Seoul').format(),
        gameRound: miniGameRound,
      }

      bettingForMiniGame(body)
        .then(res => {
          alert('배팅이 정상적으로 이루어졌습니다.')
          dispatch(setMiniGameCompleteFlag(!bettingCompleteFlag))
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          switch (errorCode) {
            case 'SYSTEM-1017':
              alert(`${message}`)
              break
            case 'SYSTEM-1018':
              alert(`${message}`)
              break
            case 'MINIGAME-1003':
              alert(`${message}`)
              break
            case 'MINIGAME-1008':
              alert(`${message}`)
              break
            case 'MINIGAME-1009':
              alert(`${message}`)
              break
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('배팅이 실패했습니다.')
          }
        })
        .finally(() => {
          setApiFlag(false)
          onClickCloseHandler()
          dispatch(setResetData())
          dispatch(fetchMemberInfoAction())
        })
    } else {
      setApiFlag(false)
    }
  }

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니
    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)

    if (inputRealMoney > miniGameMaxBetting) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = miniGameMaxBetting
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > miniGameMaxWinning) {
        resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
      }
    }

    if (resultExpectMoney > miniGameMaxWinning) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))

    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, miniGameBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  const onClickResetMoneyHandler = event => {
    setInputBettingMoney(0)
  }

  useEffect(() => {
    if (miniGameRound && miniGameBettingPer && bettingCloseFlag) {
      dispatch(setResetData())
    }
  }, [bettingCloseFlag, bettingCompleteFlag, miniGameRound, miniGameBettingPer, miniGameType])

  const getLevelIconUrl = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return `${process.env.PUBLIC_URL}/img/level/level1.png`
      case memberLevel === 'TWO':
        return `${process.env.PUBLIC_URL}/img/level/level2.png`
      case memberLevel === 'THREE':
        return `${process.env.PUBLIC_URL}/img/level/level3.png`
      case memberLevel === 'FOUR':
        return `${process.env.PUBLIC_URL}/img/level/level4.png`
      case memberLevel === 'FIVE':
        return `${process.env.PUBLIC_URL}/img/level/level5.png`
      case memberLevel === 'VIP1':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP1.png`
      case memberLevel === 'VIP2':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP2.png`
      case memberLevel === 'VIP3':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP3.png`
      default:
        return ''
    }
  }

  const [hideIconCart, setHideIconCart] = useState(false)
  const toggleIconCart = () => {
    setHideIconCart(prevShowBettingCart => !prevShowBettingCart)
  }
  const mobileMenuRef = useRef()
  const clickMenuOutside = event => {
    if (hideIconCart && !mobileMenuRef.current.contains(event.target)) {
      toggleIconCart()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickMenuOutside)

    return () => {
      document.removeEventListener('mousedown', clickMenuOutside)
    }
  })
  return (
    <>
      {apiFlag && <CustomLoading />}
      {!hideIconCart && (
        <MiniGameBettingCartWrap onClick={toggleIconCart}>
          {bettingCartNum > 0 && (
            <>
              <img src={`${process.env.PUBLIC_URL}/img/main/bettingCart3_num.png`} alt="" />
              <span>{bettingCartNum} </span>
            </>
          )}
        </MiniGameBettingCartWrap>
      )}
      <SidbarWrap showBettingCart={hideIconCart} ref={mobileMenuRef}>
        <SlimScrollDiv>
          <SidbarMenuWrap className="sidebar-container">
            <BettingCartBox>
              <BettingCartHeaderBox>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong style={{ marginTop: '5px', fontWeight: '700', fontSize: '16px' }}>BET</strong>
                      </td>
                      <td style={{ width: '16.66666%', padding: '7px' }} />
                      <td style={{ fontSize: '14px' }}>
                        <BettingDeleteAll onClick={onClickCloseHandler}>Clear all</BettingDeleteAll>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </BettingCartHeaderBox>

              <BettingListWrap>
                {miniGameBettingType ? (
                  <BettingListInnerWrap>
                    <BettingListItemLeagueBox>{MiniGameTypeEnums[miniGameType]}</BettingListItemLeagueBox>
                    <BettingListItemTeamBox>
                      {MiniGameTypeEnums[miniGameType]} ({miniGameRound}) 회차
                    </BettingListItemTeamBox>
                    <BettingListItemMarketBox>
                      <span>{PowerBallTypeEnums[miniGameBettingType]}</span>
                    </BettingListItemMarketBox>
                    <BettingListItemDeleteBox
                      onClick={() => {
                        onClickCloseHandler()
                      }}
                    >
                      <CustomIoClose />
                    </BettingListItemDeleteBox>
                    <BettingListItemPointBox>
                      <div>
                        <div>{miniGameBettingPer}</div>
                      </div>
                    </BettingListItemPointBox>
                  </BettingListInnerWrap>
                ) : (
                  <BettingListInnerWrap>
                    <BettingListBox>
                      <BettingListEmptyBox>
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/img/sidebar/side_bets_empty.png`}
                            alt=""
                            style={{ marginBottom: '16px' }}
                          />
                        </div>
                        선택된 배팅내역이 없습니다.
                      </BettingListEmptyBox>
                    </BettingListBox>
                  </BettingListInnerWrap>
                )}
              </BettingListWrap>

              <Form
                // ref={formRef}
                onSubmit={onSubmit}
                initialValues={{
                  miniGameBettingPer,
                  inputBettingMoney,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <BettingAssetFormWrap onSubmit={handleSubmit}>
                    <BettingInputWrap>
                      <BettingInputInlineBlockWrap>
                        <BettingInputBox>
                          <BettingInputBoxLeftBox>
                            <BettingInputBoxLeftItem>배팅</BettingInputBoxLeftItem>
                            <BettingInputBoxLeftItemRefresh onClick={onClickResetMoneyHandler}>
                              <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-refresh.png`} alt="" />
                            </BettingInputBoxLeftItemRefresh>
                          </BettingInputBoxLeftBox>
                          <BettingInputBoxRightBox>
                            <Field
                              name="inputBettingMoney"
                              component={BettingCartFormField}
                              isAboveError={false}
                              wrapperClassName="betting-amount-input"
                            >
                              {({ input }) => (
                                <input
                                  type="text"
                                  name="inputBettingMoney"
                                  value={inputBettingMoney}
                                  placeholder={inputBettingMoney}
                                  onChange={e => {
                                    const value = e.target.value.trim()
                                    const numbersOnly = value.match(/\d+/g)?.join('')

                                    if (!numbersOnly) {
                                      setInputBettingMoney('0')
                                    } else {
                                      setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                                    }

                                    input.onChange(e)
                                  }}
                                />
                              )}
                            </Field>
                          </BettingInputBoxRightBox>
                        </BettingInputBox>
                      </BettingInputInlineBlockWrap>
                    </BettingInputWrap>

                    <BettingButtonWrap>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ padding: '4px', paddingLeft: '0', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('5000')}>
                                오천
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('10000')}>
                                일만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('50000')}>
                                오만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('100000')}>
                                십만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('')}>
                                전액
                              </BettingButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </BettingButtonWrap>

                    <BettingTotalInfoWrap>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>총 배당:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameBettingPer || 0} 배</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>총 배당금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{expectMoney?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>최소 배팅금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameMinBetting?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>최대 배팅금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameMaxBetting?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>최대 당첨금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameMaxWinning?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                    </BettingTotalInfoWrap>

                    <BettingBetButtonWrap>
                      <BettingBetButton type="submit">배팅하기</BettingBetButton>
                    </BettingBetButtonWrap>
                  </BettingAssetFormWrap>
                )}
              </Form>
            </BettingCartBox>

            <BettingBottomBox>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://타이슨.com')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/forever2.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://t.me/tysonsol')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/forever1.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://t.me/tysonsol')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/cart_logo.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
            </BettingBottomBox>
          </SidbarMenuWrap>
        </SlimScrollDiv>
      </SidbarWrap>
    </>
  )
}

export default MobileMiniGameBettingCart

const MiniGameBettingCartIcon = styled.img`
  width: 75px;
  height: 75px;
`

const MiniGameBettingCartNumWrap = styled.div`
  position: absolute;
  top: -15px;
  right: -10px;
  width: 36px;
  height: 36px;
  border-color: ${HermesMainHover2};
  background-color: ${HermesMainHover};
  border-radius: 50%;
`

const MiniGameBettingCartNum = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  line-height: 20px;
  color: #fff;
`

const MiniGameBettingCartNumIcon = styled.img`
  width: 35px;
  height: 35px;
`

const MiniGameBettingCartWrap = styled.div`
  display: block;
  position: fixed;
  z-index: 1000000;
  right: 3%;
  margin-left: 0;
  opacity: 0.85;
  bottom: 10px;
  left: calc(50% - 50px);
  width: 100px;
  box-sizing: border-box;

  img {
    cursor: pointer;
    vertical-align: middle;
    border: 0;
    box-sizing: border-box;
    width: 100px;
    overflow-clip-margin: content-box;
    overflow: clip;
  }

  span {
    color: #fff;
    position: absolute;
    font-size: 16px;
    font-weight: 700;
    right: 22px;
    bottom: 9px;
    box-sizing: border-box;
  }
`

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%); /* 시작 지점을 오른쪽 끝으로 설정 */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* 끝 지점을 오른쪽으로부터 이동 없음으로 설정 */
    opacity: 1;
  }
`

const SidbarWrap = styled.div`
  transition: all 0.5s ease-out 0s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 1010;
  -webkit-transform: translateZ(0);
  margin-top: 67px;
  width: 299px;
  z-index: 1010;
  background-color: black;
  display: ${props => (props.showBettingCart ? 'block' : 'none')};
  animation: ${slideInFromRight} 0.5s ease-out;
`

// 스크롤 용도
const SlimScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`

const SidbarMenuWrap = styled.div`
  height: 100%;
  overflow: auto;
  margin-bottom: 30px;
`

// 배팅 카트
const BettingCartBox = styled.div`
  background: #1a1c1f;
  border-radius: 6px;
  margin-top: 8px;
  padding: 0;
  margin-bottom: 9px;
`

const BettingCartHeaderBox = styled.div`
  padding: 12px;
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    display: table;
    border-color: gray;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;

    tbody tr td {
      vertical-align: middle !important;
      color: #fff;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
`

// 폴더 수
const BettingFolderNum = styled.span`
  font-family: arial;
  border-radius: 50%;

  background: ${HermesMainBorder};
  color: #000;
  opacity: 0.9;
  cursor: pointer;
  width: 22px;
  height: 22px;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  min-width: 10px;
  padding-top: 4px;
  margin-right: 4px;
`

const BettingDeleteAll = styled.div`
  float: right;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.1s ease-out 0s;

  &:hover {
    color: ${HermesMainHover};
  }
`

const BettingListWrap = styled.div`
  box-sizing: border-box;
  padding: 0 8px;
`

const BettingListInnerWrap = styled.div`
  background-size: 100% 100%;
  background-image: url(${process.env.PUBLIC_URL}/img/sidebar/side-betlist.png);
  background-repeat: no-repeat;
  clear: both;
  text-align: left;
  margin: 1px 0 3px 1px;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-border-radius: 3px;
  width: 100%;
  height: 115px;
  position: relative;
`

const BettingListItemLeagueBox = styled.div`
  width: 210px;
  min-width: 210px;
  color: #848c99;
  padding: 16px;
  overflow: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
`

const BettingListItemTeamBox = styled.div`
  width: 210px;
  min-width: 210px;
  color: #fff;
  padding: 16px;
  overflow: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
`
const BettingListItemMarketBox = styled.div`
  width: 190px;
  min-width: 190px;
  color: #fff;
  padding: 0;
  margin: 16px 0 0 16px;
  overflow-x: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
  font-weight: 700;
`

const CustomIoClose = styled(IoClose)``

const BettingListItemDeleteBox = styled.div`
  color: #fff;
  font-size: 22px;
  padding: 2px 5px 5px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 6px;
  float: right;
  font-family: 'Malgun Gothic', dotum;
  line-height: 17px;
  z-index: 333;
`

const BettingListItemPointBox = styled.div`
  float: right;
  font-family: 'Malgun Gothic', dotum;
  line-height: 17px;
  color: ${HermesMainHover2};
  font-weight: 700;
  height: 17px;
  border-radius: 3px;
  margin: 10px 6px 0 0;

  div {
    border-radius: 4px;
    display: inline-block;
    padding: 5px 6px;

    div {
      display: block;
      float: left;
    }
  }
`

const BettingListBox = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  clear: both;
  width: 100%;
  height: 115px;
  text-align: left;
  margin: 1px 0 3px 1px;
  overflow-x: hidden;
  overflow-y: hidden;
`

const BettingListEmptyBox = styled.div`
  width: auto;
  color: #fff;
  display: block;
  text-align: center;
  margin: 0;
`

const BettingInputWrap = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
`

const BettingInputInlineBlockWrap = styled.div`
  display: inline-block;
  width: 100%;
`

const BettingInputBox = styled.div`
  margin-top: 16px;
  background: rgb(31, 33, 36);
  display: flex;
  padding: 16px 16px 12px 16px;
`

const BettingInputBoxLeftBox = styled.div`
  background: rgb(37, 40, 43);
  border-radius: 6px;
  color: ${HermesMainText} !important;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  padding: 2px;
`

const BettingInputBoxLeftItem = styled.div`
  padding: 0 12px;
`
const BettingInputBoxLeftItemRefresh = styled.div`
  padding: 6px;
  background: #2e3236;
  border-radius: 6px;
  cursor: pointer;

  img {
    cursor: pointer;
  }
`

const BettingInputBoxRightBox = styled.div`
  flex: 1 1 0%;
  padding-left: 12px;

  input {
    font-weight: 700;
    color: #fff;
    width: 100%;
    height: 40px;
    background: #181b1c;
    border: 1px solid #738087;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: center;
    border-radius: 8px;
  }
`

const BettingButtonWrap = styled.div`
  background: rgb(31, 33, 36);
  padding: 0 16px;

  table {
    background: #1f2124 !important;
    padding: 0;
    margin: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tbody tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
    }
  }
`

const BettingButton = styled.button`
  color: #fff;
  background: #25282b;

  border: none;
  box-shadow: none;
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;

  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin-right: 2px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background: #34383e;
  }
`

const BettingTotalInfoWrap = styled.div`
  background: #1f2124;
  padding: 0 16px;

  table {
    background: #1f2124;
    width: 100%;
    padding: 0;
    margin: 0;
  }
`

const BettingTotalInfoFirstTd = styled.td`
  width: 50%;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  padding: 8px;
  margin: 0;

  span {
    color: #fff;
    float: left;
  }
`

const BettingTotalInfoSecondTd = styled.td`
  width: 50%;
  text-align: center;
  padding: 0;
  margin: 0;

  span {
    color: ${HermesMainText};
    float: right;
  }
`

const BettingBetButtonWrap = styled.div`
  border-radius: 0px 0px 8px 8px;
  background: #1f2124;
  width: 100%;
  padding-top: 8px;
  padding: 16px;
  margin: 0;
`

const BettingBetButton = styled.button`
  background: ${HermesMainHover} !important;
  border: 1px solid ${HermesMainHover} !important;
  transition: background-color 0.1s ease-out 0s, border 0.1s ease-out 0s;

  width: 100%;
  height: 45px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 3px;
  font-weight: 500;
  color: #000;
  font-size: 13px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  display: block;
`

// 배팅 카드 밑 이미지
const BettingBottomBox = styled.div`
  background: #1a1c1f;
  border-radius: 6px;
  margin-top: 8px;

  padding: 0;
  margin-bottom: 9px;
`

const BettingBottomItem = styled.div`
  margin-bottom: 5px;
`

const BettingAssetFormWrap = styled.form``

const BettingWhatWrapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
`

const SportsBettingCartIcon = styled.img`
  width: 75px;
  height: 75px;
`

const SportsBettingCartNumWrap = styled.div`
  position: absolute;
  top: -15px;
  right: -10px;
  width: 36px;
  height: 36px;
`

const SportsBettingCartNum = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  line-height: 20px;
  color: #000;
`

const SportsBettingCartNumIcon = styled.img`
  width: 35px;
  height: 35px;
`

const SporteBettingCartWrap = styled.div`
  width: 75px;
  height: 75px;
  display: flex;
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 999;
`

const SolutionWrap = styled.div`
  width: 100%;
  margin-top: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
  font-family: dotum, gulim, Arial, sans-serif !important;
`
