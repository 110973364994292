import styled from 'styled-components'
import { HermesMainText, colorMainText } from '../../utils/palette'

// Header Wrap
export const MyPageHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // border-bottom: 1px solid #edae07;
`

// header info
export const MyPageHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: ${HermesMainText};
`

// content Wrap
export const MyPageContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

export const MyPageSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`
