import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { FixtureSortType, FixtureType, SportMarketType } from '../utils/enums/SportsGame/SportsGameEnums'

export const fetchOverseasFixtureListAction = createAction('FETCH_OVERSEAS_FIXTURE_LIST')
export const fetchOverseasFixtureMarketAction = createAction('FETCH_OVERSEAS_FIXTURE_MARKET')

const sportsOverseasInfoSlice = createSlice({
  name: 'sportsOverseasInfo',
  initialState: {
    overseasLoading: false,
    totalElements: 0,
    selectedSportId: -1,
    overseasParams: {
      key: '',
      type: SportMarketType.OVERSEAS,
      sportId: -1,
      locationId: -1,
      leagueId: -1,
      page: 0,
      size: 100,
      sortType: FixtureSortType.TODAY,
      fixturePopularId: null,
      leaguePopularId: null,
    },
    overseasMarketParams: {
      fixtureId: -1,
      type: SportMarketType.OVERSEAS,
      // eslint-disable-next-line no-useless-escape
      host: window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1'),
    },
    overseasCategory: [
      {
        sportId: -1,
        sportCount: 0,
        sportName: '모든 종목',
        sportSortNum: -1,
        sportImageUrl: '',
        locations: [],
      },
    ],
    overseasPopularFixtureCategory: [],
    overseasPopularLeagueCategory: [],
    overseasFixtures: {},
  },
  reducers: {
    // 사이드 바에서 스포츠 선택 했을때
    selectSideSportMenu: (state, action) => {
      const { sportId, locationId, leagueId } = action.payload

      state.selectedSportId = sportId

      state.overseasParams = {
        key: '',
        type: SportMarketType.OVERSEAS,
        sportId,
        locationId,
        leagueId,
        page: 0,
        size: 100,
        sortType: FixtureSortType.TODAY,
        fixturePopularId: null,
        leaguePopularId: null,
      }
    },
    setOverseasParamsByPopularFixtureId: (state, action) => {
      const { sportId, fixtureId } = action.payload
      state.selectedSportId = sportId
      state.overseasParams = {
        key: '',
        type: SportMarketType.OVERSEAS,
        sportId,
        locationId: -1,
        leagueId: -1,
        page: 0,
        size: 100,
        sortType: FixtureSortType.TODAY,
        fixturePopularId: fixtureId,
        leaguePopularId: null,
      }
    },
    setOverseasParamsByPopularLeagueId: (state, action) => {
      const { sportId, leagueId } = action.payload
      state.selectedSportId = sportId
      state.overseasParams = {
        key: '',
        type: SportMarketType.OVERSEAS,
        sportId,
        locationId: -1,
        leagueId: -1,
        page: 0,
        size: 100,
        sortType: FixtureSortType.TODAY,
        fixturePopularId: null,
        leaguePopularId: leagueId,
      }
    },
    setSelectedSportId: (state, action) => {
      const sportId = action.payload

      state.selectedSportId = sportId

      state.overseasParams = {
        key: '',
        type: SportMarketType.OVERSEAS,
        sportId,
        locationId: -1,
        leagueId: -1,
        page: 0,
        size: 100,
        sortType: FixtureSortType.TODAY,
        fixturePopularId: null,
        leaguePopularId: null,
      }
    },
    setSortType: (state, action) => {
      const sortType = action.payload

      state.overseasParams = {
        ...state.overseasParams,
        locationId: -1,
        leagueId: -1,
        page: 0,
        size: 100,
        sortType,
        fixturePopularId: null,
        leaguePopularId: null,
      }
    },
    setSearchKeywordParam: (state, action) => {
      const searchKeyword = action.payload

      state.overseasParams = {
        ...state.overseasParams,
        locationId: -1,
        leagueId: -1,
        page: 0,
        size: 100,
        key: searchKeyword,
        fixturePopularId: null,
        leaguePopularId: null,
      }
    },

    setPageNum: (state, action) => {
      const pageNum = action.payload

      state.overseasParams = {
        ...state.overseasParams,
        page: pageNum,
        fixturePopularId: null,
        leaguePopularId: null,
      }
    },

    setFixtureMarketParam: (state, action) => {
      const fixtureId = action.payload

      state.overseasMarketParams = {
        ...state.overseasMarketParams,
        fixtureId,
      }
    },
    //  경기 리그 Collapse 처리
    setFixtureCollapse: (state, action) => {
      const prev = state.overseasFixtures[action.payload]

      if (prev) {
        prev.fixtureLeagueCollapse = !prev.fixtureLeagueCollapse
        state.overseasFixtures[action.payload] = prev
      }
    },
    //  경기 추가 배팅 옵션
    setFixtureMarketAdditionalOption: (state, action) => {
      const { key, fixtureId } = action.payload
      const prevFixtures = state.overseasFixtures[key]?.fixtures || []
      const prevFixtureIndex = prevFixtures?.findIndex(fixture => fixture.fixtureId === fixtureId)

      if (prevFixtureIndex !== -1) {
        const prev = state.overseasFixtures[key]?.fixtures[prevFixtureIndex]?.additionalOption

        const updatedMarketAdditionalOption = !prev

        state.overseasFixtures[key].fixtures[prevFixtureIndex].additionalOption = updatedMarketAdditionalOption
      }
    },

    // 선택된 경기의 마켓 Collapse
    setSelectedFixtureMarketCollapse: (state, action) => {
      const { selectedFixture, marketId } = action.payload

      const eventKey = `${selectedFixture.fixture.leagueId}-${selectedFixture.fixture.startDate}`

      const event = state.overseasFixtures[eventKey]

      if (!event) return

      const fixture = event.fixtures.find(fixtureItem => fixtureItem.fixtureId === selectedFixture.fixture.fixtureId)

      if (!fixture) return

      const market = fixture.markets.find(marketItem => marketItem.marketId === marketId)

      if (!market) return

      market.marketCollapse = !market.marketCollapse
    },
    setOverseasLoading: (state, action) => {
      state.overseasLoading = action.payload
    },
    // 국내형 카테고리
    setOverseasPreMatchCategory: (state, action) => {
      const { sports, popularLeagues, popularFixtures } = action.payload

      const updatedSportsTypeInfo = [...state.overseasCategory]
      let totalCount = 0
      sports.forEach(item => {
        const index = updatedSportsTypeInfo.findIndex(info => info.sportId === item.sportId)
        if (index !== -1) {
          totalCount += item.sportCount
          updatedSportsTypeInfo[index] = item
        } else {
          totalCount += item.sportCount
          updatedSportsTypeInfo.push(item)
        }
      })

      const index = updatedSportsTypeInfo.findIndex(info => info.sportId === -1)

      if (index !== -1) {
        updatedSportsTypeInfo[index].sportCount = totalCount
      }

      // 인기리그
      const updatedPopularLeagueInfo = [...state.overseasPopularLeagueCategory]
      popularLeagues.forEach(item => {
        updatedPopularLeagueInfo.push(item)
      })
      // 인기경기
      const updatedPopularFixtureInfo = [...state.overseasPopularFixtureCategory]
      popularFixtures.forEach(item => {
        updatedPopularFixtureInfo.push(item)
      })

      state.overseasPopularLeagueCategory = updatedPopularLeagueInfo
      state.overseasPopularFixtureCategory = updatedPopularFixtureInfo
      state.overseasCategory = updatedSportsTypeInfo
    },

    // 프리매치 경기 정보
    setOverseasSports: (state, action) => {
      const { sportEvents, totalElements } = action.payload

      if (!sportEvents || sportEvents.length === 0) {
        state.overseasFixtures = {}
        state.overseasLoading = false
        return
      }

      state.totalElements = totalElements

      // 리그별 그룹화 항시 적용
      state.overseasFixtures = sportEvents.reduce((grouped, sportEvent) => {
        const key = `${sportEvent.fixture.leagueId}-${sportEvent.fixture.startDate}`

        if (!grouped[key]) {
          grouped[key] = {
            sportId: sportEvent.fixture.sportId,
            sportName: sportEvent.fixture.sportName,
            sportImageUrl: sportEvent.fixture.sportImageUrl,
            locationId: sportEvent.fixture.locationId,
            locationName: sportEvent.fixture.locationName,
            locationImageUrl: sportEvent.fixture.locationImageUrl,
            leagueId: sportEvent.fixture.leagueId,
            leagueName: sportEvent.fixture.leagueName,
            leagueImageUrl: sportEvent.fixture.leagueImageUrl,
            startDate: sportEvent.fixture.startDate,
            fixtureLeagueCollapse: true,
            fixtures: [],
          }
        }

        grouped[key].fixtures.push({
          ...sportEvent,
          additionalOption: false,
          mainMarkets: sportEvent.mainMarkets.map(mainMarkets => {
            return {
              ...mainMarkets,
              betLines: mainMarkets?.betLines.map(betLine => {
                return {
                  ...betLine,
                  bets: betLine?.bets.map(bet => {
                    return {
                      ...bet,
                      isActive: false,
                    }
                  }),
                }
              }),
            }
          }),
        })

        return grouped
      }, {})
    },

    // 경기 마켓 정보
    setOverseasFixtureMarkets: (state, action) => {
      const { sportEvent, marketTab, bettingCartItems } = action.payload

      const key = `${sportEvent.fixture.leagueId}-${sportEvent.fixture.startDate}`

      // key -> fixture -> markets 에 넣어야 함
      const fixtureIndex = state.overseasFixtures[key]?.fixtures.findIndex(
        item => item.fixtureId === sportEvent.fixtureId,
      )

      if (fixtureIndex === -1) return

      // 모든 bettingCartItems에서 betId 수집
      const activeBetIds = new Set(bettingCartItems.map(item => item.selectedBetInfo?.betId))

      const fixture = state.overseasFixtures[key].fixtures[fixtureIndex]

      // 라이브 트래커 URL
      fixture.fixture.liveTrackerUrl = sportEvent.fixture.liveTrackerUrl

      fixture.mainMarkets = sportEvent.mainMarkets.map(market => ({
        ...market,
        betLines: market.betLines.map(betLine => ({
          ...betLine,
          bets: betLine.bets.map(bet => ({
            ...bet,
            isActive: activeBetIds.has(bet.betId),
          })),
        })),
      }))

      fixture.markets = sportEvent.markets.map(market => ({
        ...market,
        betLines: market.betLines.map(betLine => ({
          ...betLine,
          bets: betLine.bets.map(bet => ({
            ...bet,
            isActive: activeBetIds.has(bet.betId),
          })),
        })),
      }))
    },

    // 선택한 마켓 Bet Active 처리
    setOverseasActiveBetInfo: (state, action) => {
      const bettingCartItems = action.payload // 배열로 가정

      // 모든 bettingCartItems에서 betId 수집
      const activeBetIds = new Set(bettingCartItems.map(item => item.selectedBetInfo?.betId))

      // Update overseasFixtures
      Object.keys(state.overseasFixtures).forEach(key => {
        const fixtures = state.overseasFixtures[key]?.fixtures || []
        fixtures.forEach((fixture, index) => {
          const updatedFixture = { ...fixture }
          const keyNames = ['mainMarkets', 'markets']

          keyNames.forEach(marketType => {
            updatedFixture[marketType] = updatedFixture[marketType].map(market => ({
              ...market,
              betLines: market.betLines.map(line => ({
                ...line,
                bets: line.bets.map(bet => ({
                  ...bet,
                  isActive: activeBetIds.has(bet.betId), // betId가 activeBetIds에 있으면 true, 없으면 false로 설정
                })),
              })),
            }))
          })
          // 업데이트된 픽스처를 원래 배열에 할당
          state.overseasFixtures[key].fixtures[index] = updatedFixture
        })
      })
    },

    setFixtureMarketDataByWebSocket: (state, action) => {
      const { sportEvent, marketTab, bettingCartItems } = action.payload

      const key = `${sportEvent.fixture.leagueId}-${sportEvent.fixture.startDate}`

      // 해당 키로 저장된 fixtures의 인덱스 찾기
      const fixtureIndex = state.overseasFixtures[key]?.fixtures.findIndex(
        item => item.fixtureId === sportEvent.fixtureId,
      )

      // 해당 fixture가 없다면 아무 동작도 하지 않음
      if (fixtureIndex === -1) return

      // 모든 bettingCartItems에서 betId 수집
      const activeBetIds = new Set(bettingCartItems.map(item => item.selectedBetInfo?.betId))

      // 해당 fixture 찾기
      const currentFixture = state.overseasFixtures[key].fixtures[fixtureIndex]

      // 새로운 markets 데이터를 생성하거나 기존 데이터를 업데이트
      const updatedMarkets = sportEvent.markets.map(newMarket => {
        const existingMarket = currentFixture.markets.find(market => market.marketId === newMarket.marketId)

        return {
          ...existingMarket, // 기존 마켓 데이터 유지
          ...newMarket, // 새로운 마켓 데이터로 업데이트
          betLines: newMarket.betLines.map(newBetLine => {
            const existingBetLine =
              existingMarket?.betLines.find(betLine => betLine.betLineId === newBetLine.betLineId) || {}

            return {
              ...existingBetLine,
              ...newBetLine,
              bets: newBetLine.bets.map(newBet => {
                return {
                  ...(existingBetLine.bets.find(bet => bet.betId === newBet.betId) || {}),
                  ...newBet,
                  isActive: activeBetIds.has(newBet.betId),
                }
              }),
            }
          }),
        }
      })

      // 마켓 정보 업데이트
      state.overseasFixtures[key].fixtures[fixtureIndex].markets = updatedMarkets
    },

    // 경기데이터 변경 - 웹소켓
    updateFixtureByWebSocket: (state, action) => {
      const { fixtureId, fixture } = action.payload

      const key = `${fixture.leagueId}-${fixture.startDate}`

      if (!state.overseasFixtures[key]) return

      const fixtureIndex = state.overseasFixtures[key]?.fixtures.findIndex(item => item.fixtureId === fixtureId) ?? -1

      if (fixtureIndex === -1) return

      const currentFixture = state.overseasFixtures[key]?.fixtures[fixtureIndex]

      if (!currentFixture) return

      currentFixture.fixture = {
        ...currentFixture.fixture,
        fixture,
      }
    },

    // 라이브 스코어 변경 - 웹소켓
    updateLiveScoreByWebSocket: (state, action) => {
      // 무시
    },
    // 마켓 데이터 변경 - 웹소켓
    updateMarketByWebSocket: (state, action) => {
      const { fixture, market, mainMarket, marketTab, bettingCartItems } = action.payload

      const key = `${fixture.leagueId}-${fixture.startDate}`

      if (!state.overseasFixtures[key]) return

      const fixtureIndex =
        state.overseasFixtures[key]?.fixtures.findIndex(item => item.fixtureId === fixture.fixtureId) ?? -1

      // 해당 fixture가 없다면 아무 동작도 하지 않음
      if (fixtureIndex === -1) return

      const currentFixture = state.overseasFixtures[key]?.fixtures[fixtureIndex]

      if (!currentFixture) return

      // 모든 bettingCartItems에서 betId 수집
      const activeBetIds = new Set(bettingCartItems.map(item => item.selectedBetInfo?.betId))

      if (mainMarket) {
        const mainMarketIndex =
          currentFixture.mainMarkets?.findIndex(
            mainMarketItem => mainMarketItem.eventMarketKey === mainMarket.eventMarketKey,
          ) ?? -1

        if (mainMarketIndex !== -1) {
          currentFixture.mainMarkets[mainMarketIndex] = {
            ...mainMarket,
            betLines: mainMarket.betLines.map(betLine => ({
              ...betLine,
              bets: betLine.bets.map(bet => ({
                ...bet,
                isActive: activeBetIds.has(bet.betId),
              })),
            })),
          }
        }
      }

      if (market) {
        const marketIndex =
          currentFixture.markets?.findIndex(marketItem => marketItem.eventMarketKey === market.eventMarketKey) ?? -1

        if (marketIndex !== -1) {
          currentFixture.markets[marketIndex] = {
            ...market,
            betLines: market.betLines.map(betLine => ({
              ...betLine,
              bets: betLine.bets.map(bet => ({
                ...bet,
                isActive: activeBetIds.has(bet.betId),
              })),
            })),
          }
        }
      }
    },
  },
  extraReducers: builder => {},
})

export const {
  selectSideSportMenu,
  setSelectedSportId,
  setSortType,
  setSearchKeywordParam,
  setPageNum,
  setFixtureMarketParam,
  setOverseasSports,
  setOverseasFixtureMarkets,
  setOverseasLoading,
  setOverseasPreMatchCategory,
  setFixtureCollapse,
  setFixtureMarketAdditionalOption,
  setSelectedFixtureMarketCollapse,
  setOverseasActiveBetInfo,
  setFixtureMarketDataByWebSocket,
  setOverseasParamsByPopularFixtureId,
  setOverseasParamsByPopularLeagueId,
  updateFixtureByWebSocket,
  updateLiveScoreByWebSocket,
  updateMarketByWebSocket,
} = sportsOverseasInfoSlice.actions

export default sportsOverseasInfoSlice.reducer
