import Error from '@/shared/components/form/Error'
import { FormInputWrap } from '@/shared/components/form/FormElements'
import { convertFromFront } from '@/utils/editor'
import { OnetoOneValidate } from '@/utils/validate/customCenter/oneToOneValidate'
import React, { useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import './customStyle.css'
import { createOnetoOne } from '../../../api/customCenter/customCenterApi'
import { CustomButtonInfos } from '../../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../../mobile/mobileCasinoGame/MobileLiveCasino'
import {
  CustomCenterFormBottomWrap,
  CustomCenterFormField,
  CustomCenterFormInput,
  CustomerCenterFormGroup,
  CustomerCenterFormLabel,
  CustomerCenterFormWrap,
} from '../../../shared/components/MoneyCustomElement'
import { decodeAccessToken } from '../../../utils/token'
import CustomLoading from '../../CustomLoading'
import '../Notice/Notice.css'

const OnetoOneWrite = ({ setWriteOnetoOneShow, fetchSearchOnetoOneList }) => {
  const [questionLoading, setQuestionLoading] = useState(false)
  const [questionTitle, setQuestionTitle] = useState('')
  const onChangeQuestionTitle = e => {
    setQuestionTitle(e.target.value)
  }

  // 문의 내용
  const [questionContent, setQuestionContent] = useState('')
  const onChangeQuestionContentHandler = editorState => {
    setQuestionContent(editorState)
  }
  const onSubmit = e => {
    if (questionLoading) return
    setQuestionLoading(true)
    // TODO userID에 로그인한사람 ID박아주기
    const body = {
      questionTitle,
      questionContent,
    }
    createOnetoOne(body)
      .then(res => {
        alert('1:1문의가 접수되었습니다.')
        setWriteOnetoOneShow(false)
        setQuestionLoading(false)
        fetchSearchOnetoOneList()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('1:1문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }

  return (
    <>
      {questionLoading && <CustomLoading />}
      <Form
        onSubmit={onSubmit}
        initialValues={{
          userId: decodeAccessToken().userId,
          questionTitle,
          questionContent,
        }}
        validate={OnetoOneValidate}
      >
        {({ handleSubmit, form: { reset } }) => (
          <CustomerCenterFormWrap horizontal onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
            <CustomerCenterFormGroup>
              <CustomerCenterFormLabel>문의 제목</CustomerCenterFormLabel>
              <CustomCenterFormField>
                <Field name="questionTitle">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <CustomCenterFormInput
                        {...input}
                        style={{ background: '#C2C3C4' }}
                        type="text"
                        value={questionTitle}
                        onChange={e => {
                          input.onChange(e)
                          onChangeQuestionTitle(e)
                        }}
                        placeholder="제목을 작성하세요"
                      />
                      {meta.touched && meta.error && <Error error={meta.error} />}
                    </FormInputWrap>
                  )}
                </Field>
              </CustomCenterFormField>
            </CustomerCenterFormGroup>
            <CustomerCenterFormGroup>
              <CustomerCenterFormLabel>문의 내용</CustomerCenterFormLabel>
              <CustomCenterFormField>
                <Field name="questionContent">
                  {({ input, meta }) => (
                    <FormInputWrap style={{ background: '#c2c3c4' }}>
                      <ReactQuill
                        placeholder={`해당 영역에 문의내용을 입력해주세요 \n\n매너 있는 채팅을 부탁드리며 '욕설 및 협박' 등의 내용을 기재 시 회원자격이 박탈됩니다`}
                        onChange={value => {
                          input.onChange(value)
                          setQuestionContent(value) // questionContent 업데이트
                        }}
                        modules={{
                          toolbar: [
                            // [{ header: '1' }, { header: '2' }, { font: [] }],
                            // [{ list: 'ordered' }, { list: 'bullet' }],
                            // ['bold', 'italic', 'underline'],
                            // ['link'],
                          ],
                        }}
                      />
                      {meta.touched && meta.error && <Error error={meta.error} />}
                    </FormInputWrap>
                  )}
                </Field>
              </CustomCenterFormField>
            </CustomerCenterFormGroup>
            <CustomCenterFormBottomWrap>
              <CustomDeleteButton
                variant="primary"
                type="submit"
                style={{ width: '30%', margin: '2px 2px', background: '#880000' }}
              >
                <CustomButtonInfos>1:1 문의 등록하기</CustomButtonInfos>
              </CustomDeleteButton>
            </CustomCenterFormBottomWrap>
          </CustomerCenterFormWrap>
        )}
      </Form>
    </>
  )
}

export default OnetoOneWrite

// endregion
