import React, { useState } from 'react'
import styled from 'styled-components'
import { FaChevronLeft, FaTrash } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import MobileMiniGameBettingHistory from './MobileMiniGameBettingHistory'
import MobileSportsBettingHistory from './MobileSportsBettingHistory'
import Portal from '../../shared/components/Portal'
import { onClickTitleHandler } from '../../redux/modalSlice'
import {
  ContentInfo,
  ContentRowSecondWrap,
  ContentRowSmall,
  ContentRowThirdWrap,
  ContentRowWrap,
  ContentWrap,
  CustomButtonInfo,
  CustomerCenterFormGroup,
  MoneyButton,
  SignUpModalCloseButton,
  SignUpModalContentWrap,
  SignUpModalHeader,
  SignUpModalHeaderLogo,
  SignUpModalInner,
  SignUpModalOverlay,
  SignUpModalWrapper,
  TableBodyTwoWrap,
} from '../../shared/components/MobileMoneyCustomElement'
import { HermesMainBorder, HermesMainText } from '../../utils/palette'
import { MobileTopWrap } from '../../shared/components/MoneyCustomElement'
import MobileCasinoBettingHistoryPage from './MobileCasinoBettingHistory/MobileCasinoBettingHistoryPage'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MobileSportsBettingHistoryV2 from './MobileSportsBettingHistoryV2'
import MobileVirtualGameBettingHistory from './MobileVirtualGameBettingHistory'
import MobileHoldemBettingHistory from './MobileHoldemBettingHistory/MobileHoldemBettingHistory'
import MobileWildGamesBettingHistory from './MobileWildGamesBettingHistory/MobileWildGamesBettingHistory'

const MobileBettingHistoryPage = ({ visible, onClose }) => {
  const [active, setActive] = useState('sports')
  const dispatch = useDispatch()
  const { holdingMoney, memberTotalGameMoney } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
    }
  })

  const getBettingHistoryComponent = () => {
    switch (active) {
      case 'sports':
        return <MobileSportsBettingHistoryV2 />
      case 'casino':
        return <MobileCasinoBettingHistoryPage />
      case 'minigame':
        return <MobileMiniGameBettingHistory />
      case 'virtual-game':
        return <MobileVirtualGameBettingHistory />
      case 'revolution-holdem':
        return <MobileHoldemBettingHistory />
      case 'wild-games':
        return <MobileWildGamesBettingHistory />
      default:
        return <></>
    }
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  const [selectedIndex, setSelectedIndex] = useState(0)
  return (
    <Portal elementId="signup-modal">
      <SignUpModalOverlay visible={visible} />
      <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" onClick={close} />
            <SignUpModalCloseButton
              onClick={close}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <MobileTopWrap>
              <ContentWrap>
                <ContentRowWrap>
                  <FaChevronLeft
                    onClick={e => {
                      dispatch(onClickTitleHandler('MYPAGE'))
                    }}
                  />
                  <ContentRowSmall>
                    <div>
                      <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                        {holdingMoney?.toString().replace(commonReg2, ',')} 원
                      </span>
                    </div>
                  </ContentRowSmall>
                  <ContentRowSmall>
                    <div>
                      <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                        {memberTotalGameMoney?.toString().replace(commonReg2, ',')} 원
                      </span>
                    </div>
                  </ContentRowSmall>
                </ContentRowWrap>
                <ContentRowThirdWrap>
                  <CustomContentRowBig selectedIndex={selectedIndex}>
                    <div
                      onClick={() => {
                        setSelectedIndex(0)
                        setActive('sports')
                      }}
                    >
                      스포츠
                    </div>
                    <div
                      onClick={() => {
                        setSelectedIndex(1)
                        setActive('casino')
                      }}
                    >
                      카지노
                    </div>
                    <div
                      onClick={() => {
                        setSelectedIndex(2)
                        setActive('minigame')
                      }}
                    >
                      미니게임
                    </div>
                    <div
                      onClick={() => {
                        setSelectedIndex(3)
                        setActive('virtual-game')
                      }}
                    >
                      가상게임
                    </div>
                    <div
                      onClick={() => {
                        setSelectedIndex(4)
                        setActive('revolution-holdem')
                      }}
                    >
                      레볼루션 홀덤
                    </div>
                    <div
                      onClick={() => {
                        setSelectedIndex(5)
                        setActive('wild-games')
                      }}
                    >
                      와일드 게임즈
                    </div>
                  </CustomContentRowBig>
                </ContentRowThirdWrap>
                <ContentInfo>
                  <BettingHistoryContentWrap>{getBettingHistoryComponent()}</BettingHistoryContentWrap>
                </ContentInfo>
              </ContentWrap>
            </MobileTopWrap>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default MobileBettingHistoryPage

export const CustomContentRowBig = styled.div`
  color: rgb(255, 255, 255, 0.5);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    flex: 33%;
    border: solid 1px #252525;

    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    height: 62px;
    width: 100%;

    font-size: 13px;
    color: #fff;
    font-weight: 700 !important;
    font-family: 'Malgun Gothic', dotum;

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`

const BettingHistoryPageWrap = styled.div`
  background: #000000;
  width: 100%;
  min-height: calc(100vh - 105px);
  border: 1px solid #edae07;
  padding-left: 0px;
  padding-bottom: 10px;

  position: relative;
`

const BettingHistoryContentWrap = styled.div`
  width: 100%;
  height: calc(100% - 52px);
  padding: 2px;
  color: white;
`
