import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { searchPartnerUrl } from '../../api/authManage/authManageApi'
import CustomAlert from '../../containers/CustomAlert'
import {
  selectSideSportMenu,
  setOverseasParamsByPopularFixtureId,
  setOverseasParamsByPopularLeagueId,
  setSelectedSportId as setOverseasSelectedSportId,
} from '../../redux/sportsOverseasInfoSlice'
import { onClickMobileSignInHandlerInSideBar, onShowModalHandler } from '../../redux/modalSlice'
import { fetchSportsEventCount, setSportsType } from '../../redux/sportsGameBettingSlice'
import isLogin from '../../utils/enums/authority'
import { decodeAccessToken } from '../../utils/token'
import { HermesMainText } from '../../utils/palette'
import CustomCollapse2 from '../../containers/Layout/CustomCollapse2'
import { convertToKstDateTimeSimple } from '../../utils/dateTime'
import { setSelectedSportId as setDomesticSelectedSportId } from '../../redux/sportsDomesticInfoSlice'
import { setSelectedSportId as setSpecialSelectedSportId } from '../../redux/sportsSpecialInfoSlice'
import { setSelectedSportId as setLiveSelectedSportId } from '../../redux/sportsLiveInfoSlice'

const MobileMenuSidebar = ({ isMobileMenuSidebarShow, changeMobileMenuSidebarShowHandler }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { domesticCategory } = useSelector(state => {
    const { sportsDomesticInfo } = state

    return {
      domesticCategory: sportsDomesticInfo.domesticCategory,
    }
  })

  const { overseasCategory, overseasPopularLeagueCategory, overseasPopularFixtureCategory } = useSelector(state => {
    const { sportsOverseasInfo } = state

    return {
      overseasCategory: sportsOverseasInfo.overseasCategory,
      overseasPopularLeagueCategory: sportsOverseasInfo.overseasPopularLeagueCategory,
      overseasPopularFixtureCategory: sportsOverseasInfo.overseasPopularFixtureCategory,
    }
  })

  const { specialCategory } = useSelector(state => {
    const { sportsSpecialInfo } = state

    return {
      specialCategory: sportsSpecialInfo.specialCategory,
    }
  })

  const { liveCategory } = useSelector(state => {
    const { sportsLiveInfo } = state

    return {
      liveCategory: sportsLiveInfo.liveCategory,
    }
  })

  const { sideMenuModalShow } = useSelector(state => {
    const { modalInfo } = state

    return {
      sideMenuModalShow: modalInfo.modalShow,
    }
  })

  const {
    noteRedisSize,
    oneToOneRedisSize,
    realTimeDepositList,
    realTimeWithdrawalList,
    domesticSportsUsing,
    domesticSportsName,
    overseasSportsUsing,
    overseasSportsName,
    specialSportsUsing,
    specialSportsName,
    liveSportsUsing,
    liveSportsName,
    revolutionHoldemInfo,
    wildHoldemInfo,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      realTimeDepositList: memberInfo.realTimeDepositList,
      realTimeWithdrawalList: memberInfo.realTimeWithdrawalList,
      domesticSportsUsing: memberInfo.domesticSportsUsing,
      domesticSportsName: memberInfo.domesticSportsName,
      overseasSportsUsing: memberInfo.overseasSportsUsing,
      overseasSportsName: memberInfo.overseasSportsName,
      specialSportsUsing: memberInfo.specialSportsUsing,
      specialSportsName: memberInfo.specialSportsName,
      liveSportsUsing: memberInfo.liveSportsUsing,
      liveSportsName: memberInfo.liveSportsName,
      revolutionHoldemInfo: memberInfo.revolutionHoldemInfo,
      wildHoldemInfo: memberInfo.wildHoldemInfo,
    }
  })

  const { virtualSportList } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      virtualSportList: virtualGameInfo.virtualSportList.toSorted((a, b) => a.sportOrderNum - b.sportOrderNum),
    }
  })

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onCloseHandler = link => {
    changeMobileMenuSidebarShowHandler()
  }

  const ClickHandler = link => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))

      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
    changeMobileMenuSidebarShowHandler()
    window.scrollTo(10, 0)
  }

  const onClickVirtualMenuHandler = () => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))

      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    const { sportType } = virtualSportList[0] ?? ''

    changeMobileMenuSidebarShowHandler()
    window.scrollTo(10, 0)

    switch (sportType) {
      case 'VIRTUAL_SOCCER':
        return navigate('/mobile/virtual/soccer')
      case 'VIRTUAL_BASKETBALL':
        return navigate('/mobile/virtual/basketball')
      case 'VIRTUAL_BASEBALL':
        return navigate('/mobile/virtual/baseball')
      case 'VIRTUAL_GREYHOUNDS':
        return navigate('/mobile/virtual/greyhounds')
      case 'VIRTUAL_HORSE':
        return navigate('/mobile/virtual/horse')
      default:
        return navigate('/')
    }
  }

  const mobileMenuRef = useRef()

  const clickMenuOutside = event => {
    if (isMobileMenuSidebarShow && !mobileMenuRef.current.contains(event.target)) {
      changeMobileMenuSidebarShowHandler()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickMenuOutside)

    return () => {
      document.removeEventListener('mousedown', clickMenuOutside)
    }
  })

  const [selectedSportId, setSelectedSportId] = useState(-1)
  const [selectedLocationId, setSelectedLocationId] = useState(-1)

  // 종목 선택했을때
  const onClickSportHandler = sportId => {
    if (selectedSportId === -1 || selectedSportId !== sportId) {
      setSelectedSportId(sportId)
    } else {
      if (!isLogin()) {
        changeMobileMenuSidebarShowHandler()
        dispatch(onClickMobileSignInHandlerInSideBar(true))
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId: -1,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/mobile/sports/overseas') {
        changeMobileMenuSidebarShowHandler()
        window.scrollTo(10, 0)
        navigate('/mobile/sports/overseas')
      }
    }
  }

  // 국가 선택시
  const onClickLocationHandler = (sportId, locationId) => {
    if (selectedLocationId === -1 || selectedLocationId !== locationId) {
      setSelectedSportId(sportId)
      setSelectedLocationId(locationId)
    } else {
      if (!isLogin()) {
        changeMobileMenuSidebarShowHandler()
        dispatch(onClickMobileSignInHandlerInSideBar(true))
        return
      }

      if (noteRedisSize > 0) {
        setNoteAlertShow(true)
        return
      }

      // 초기화
      setSelectedSportId(-1)
      setSelectedLocationId(-1)

      dispatch(
        selectSideSportMenu({
          sportId,
          locationId,
          leagueId: -1,
        }),
      )

      // TODO 국내 해외 스페셜 라이브 구분하기
      if (location.pathname !== '/mobile/sports/overseas') {
        changeMobileMenuSidebarShowHandler()
        window.scrollTo(10, 0)
        navigate('/mobile/sports/overseas')
      }
    }
  }

  // 스포츠 리그 선택시
  const onClickLeagueHandler = (sportId, locationId, leagueId) => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    // 초기화
    setSelectedSportId(-1)
    setSelectedLocationId(-1)

    dispatch(
      selectSideSportMenu({
        sportId,
        locationId,
        leagueId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/mobile/sports/overseas') {
      changeMobileMenuSidebarShowHandler()
      window.scrollTo(10, 0)
      navigate('/mobile/sports/overseas')
    }
  }

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const [isOpenedMainLinks, setIsOpenedMainLinks] = useState(true)
  const [isOpenedQuickLinks, setIsOpenedQuickLinks] = useState(true)
  const [isOpenedQuickLinks2, setIsOpenedQuickLinks2] = useState(true)
  const [isOpenedQuickLinks3, setIsOpenedQuickLinks3] = useState(true)
  const [isOpenedQuickLinks4, setIsOpenedQuickLinks4] = useState(true)
  const [isOpenedSportsLinks, setIsOpenedSportsLinks] = useState(true)
  useEffect(() => {
    if (sideMenuModalShow) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [sideMenuModalShow])

  const onClickPartnerUrlHandler = () => {
    searchPartnerUrl().then(res => {
      const { partnerUrl } = res.data

      if (partnerUrl.includes('localhost')) {
        window.open(`http://${partnerUrl}`)
      } else {
        window.open(`https://${partnerUrl}`)
      }
    })
  }

  // 인기게임 선택했을때
  const onClickPopularFixtureHandler = (sportId, fixtureId) => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    dispatch(
      setOverseasParamsByPopularFixtureId({
        sportId,
        fixtureId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/mobile/sports/overseas') {
      changeMobileMenuSidebarShowHandler()
      window.scrollTo(10, 0)
      navigate('/mobile/sports/overseas')
    }
  }

  // 인기게임 선택했을때
  const onClickPopularLeagueHandler = (sportId, leagueId) => {
    if (!isLogin()) {
      changeMobileMenuSidebarShowHandler()
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    dispatch(
      setOverseasParamsByPopularLeagueId({
        sportId,
        leagueId,
      }),
    )

    // TODO 국내 해외 스페셜 라이브 구분하기
    if (location.pathname !== '/mobile/sports/overseas') {
      changeMobileMenuSidebarShowHandler()
      window.scrollTo(10, 0)
      navigate('/mobile/sports/overseas')
    }
  }
  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 쪽지를 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      <MobileMenu $show={isMobileMenuSidebarShow} ref={mobileMenuRef}>
        <MobileMenuCloseButton
          onClick={() => {
            onCloseHandler()
          }}
          src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
          alt=""
        />
        <SlimScrollDiv>
          <SidbarMenuWrap className="sidebar-container">
            <QuickLinksWrap>
              <QuickLinksCollapse
                onClick={() => {
                  setIsOpenedMainLinks(prev => !prev)
                }}
              >
                <QuickLinksText>Main Menu</QuickLinksText>
                {isOpenedMainLinks ? <FaChevronDown /> : <FaChevronUp />}
              </QuickLinksCollapse>

              <CustomCollapse in={isOpenedMainLinks}>
                <QuickLinksBox>
                  {decodeAccessToken()?.auth === 'PARTNER' && (
                    <CustomLink onClick={onClickPartnerUrlHandler}>
                      <SidbarQuickItem>
                        <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-partner.png`} alt="" />
                        <SidbarQuickItemText>파트너 페이지</SidbarQuickItemText>
                      </SidbarQuickItem>
                    </CustomLink>
                  )}
                  {domesticSportsUsing && (
                    <CustomLink
                      onClick={() => {
                        dispatch(setDomesticSelectedSportId(-1))
                        ClickHandler('/mobile/sports/domestic')
                      }}
                    >
                      <SidbarQuickItem>
                        <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-sports.png`} alt="" />
                        <SidbarQuickItemText active={location.pathname === '/mobile/sports/domestic'}>
                          {domesticSportsName}
                        </SidbarQuickItemText>
                      </SidbarQuickItem>
                    </CustomLink>
                  )}

                  {overseasSportsUsing && (
                    <CustomLink
                      onClick={() => {
                        dispatch(setOverseasSelectedSportId(-1))
                        ClickHandler('/mobile/sports/overseas')
                      }}
                    >
                      <SidbarQuickItem>
                        <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-sports-special.png`} alt="" />
                        <SidbarQuickItemText active={location.pathname === '/mobile/sports/overseas'}>
                          {overseasSportsName}
                        </SidbarQuickItemText>
                      </SidbarQuickItem>
                    </CustomLink>
                  )}

                  {specialSportsUsing && (
                    <CustomLink
                      onClick={() => {
                        dispatch(setSpecialSelectedSportId(-1))
                        ClickHandler('/mobile/sports/special')
                      }}
                    >
                      <SidbarQuickItem>
                        <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-live-sports.png`} alt="" />
                        <SidbarQuickItemText active={location.pathname === '/mobile/sports/special'}>
                          {specialSportsName}
                        </SidbarQuickItemText>
                      </SidbarQuickItem>
                    </CustomLink>
                  )}

                  {liveSportsUsing && (
                    <CustomLink
                      onClick={() => {
                        dispatch(setLiveSelectedSportId(-1))
                        ClickHandler('/mobile/sports/live')
                      }}
                    >
                      <SidbarQuickItem>
                        <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-live-sports.png`} alt="" />
                        <SidbarQuickItemText active={location.pathname === '/mobile/sports/live'}>
                          {liveSportsName}
                        </SidbarQuickItemText>
                      </SidbarQuickItem>
                    </CustomLink>
                  )}

                  <CustomLink onClick={() => ClickHandler('/mobile/live-casino')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-live.png`} alt="" />
                      <SidbarQuickItemText active={location.pathname === '/mobile/live-casino'}>
                        라이브 카지노
                      </SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>

                  <CustomLink onClick={() => ClickHandler('/mobile/slot-casino')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-slot.png`} alt="" />
                      <SidbarQuickItemText active={location.pathname === '/mobile/slot-casino'}>
                        슬롯 카지노
                      </SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>

                  <CustomLink onClick={() => ClickHandler('/mobile/mini-game')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-mini.png`} alt="" />
                      <SidbarQuickItemText active={location.pathname === '/mobile/mini-game'}>
                        미니게임
                      </SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>

                  <CustomLink onClick={() => onClickVirtualMenuHandler()}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-virtual.png`} alt="" />
                      <SidbarQuickItemText active={location.pathname.includes('/mobile/virtual')}>
                        가상게임
                      </SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>

                  <CustomLink onClick={() => ClickHandler('/mobile/holdem/revolution')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-holdem.png`} alt="" />
                      <SidbarQuickItemText active={location.pathname === '/mobile/holdem/revolution'}>
                        {revolutionHoldemInfo.title}
                      </SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>
                  <CustomLink onClick={() => ClickHandler('/mobile/holdem/wild-games')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-holdem.png`} alt="" />
                      <SidbarQuickItemText active={location.pathname === '/mobile/holdem/wild-games'}>
                        {wildHoldemInfo.title}
                      </SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>
                </QuickLinksBox>
              </CustomCollapse>
            </QuickLinksWrap>
            {/* 서브 메뉴 */}
            <QuickLinksWrap style={{ paddingTop: '0px' }}>
              <QuickLinksCollapse
                onClick={() => {
                  setIsOpenedQuickLinks(prev => !prev)
                }}
              >
                <QuickLinksText>Quick Links</QuickLinksText>
                {isOpenedQuickLinks ? <FaChevronDown /> : <FaChevronUp />}
              </QuickLinksCollapse>

              <CustomCollapse in={isOpenedQuickLinks}>
                <QuickLinksBox>
                  {/* <CustomLink onClick={() => ClickHandler('/mobile/attendance')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-attendance.png`} alt="" />
                      <SidbarQuickItemText>출석현황</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink> */}

                  <CustomLink>
                    <SidbarQuickItem
                      onClick={e => {
                        onClickMenuModalHandler('BETTING')
                      }}
                    >
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-betting-history.png`} alt="" />
                      <SidbarQuickItemText>배팅내역</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>
                  <CustomLink onClick={() => ClickHandler('/mobile/moneyexchangelist')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-money-exchange.png`} alt="" />
                      <SidbarQuickItemText>머니이동내역</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>

                  <CustomLink>
                    <SidbarQuickItem
                      onClick={e => {
                        onClickMenuModalHandler('COUPONUSE')
                      }}
                    >
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-coupon.png`} alt="" />
                      <SidbarQuickItemText>쿠폰사용</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>

                  <CustomLink>
                    <SidbarQuickItem
                      onClick={e => {
                        onClickMenuModalHandler('POINTEXCHANGE')
                      }}
                    >
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-exchange.png`} alt="" />
                      <SidbarQuickItemText>포인트 전환</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>
                  <CustomLink onClick={() => ClickHandler('/mobile/friendlist')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-friend.png`} alt="" />
                      <SidbarQuickItemText>지인추천</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>
                  <CustomLink onClick={() => ClickHandler('/mobile/event')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-event.png`} alt="" />
                      <SidbarQuickItemText>이벤트</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>
                  <CustomLink onClick={() => ClickHandler('/mobile/notice')}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-notice.png`} alt="" />
                      <SidbarQuickItemText>공지 / 규정</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink>
                  {/* <CustomLink to="/mobile/mini-game" onClick={ClickHandler}>
                    <SidbarQuickItem>
                      <SideLogoImg src={`${process.env.PUBLIC_URL}/img/sidebar/side-mini.png`} alt="" />
                      <SidbarQuickItemText>캐쉬내역</SidbarQuickItemText>
                    </SidbarQuickItem>
                  </CustomLink> */}
                </QuickLinksBox>
              </CustomCollapse>
            </QuickLinksWrap>
            {/* 인기리그, 인기게임 */}
            <PopularWrap>
              <PopularTitle
                onClick={() => {
                  setIsOpenedQuickLinks3(prev => !prev)
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/cup.png`} alt="" />
                최신 인기게임
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/down.png`} alt="" />
              </PopularTitle>
              <CustomCollapse2 in={isOpenedQuickLinks3}>
                {overseasPopularFixtureCategory.length === 0 ? (
                  <PopularTeamList>
                    <CustomTeamDiv style={{ paddingLeft: '10px' }}>최신 인기경기가 없습니다.</CustomTeamDiv>
                  </PopularTeamList>
                ) : (
                  overseasPopularFixtureCategory.map(sport => (
                    <PopularTeamList
                      key={sport.sportId}
                      onClick={() => {
                        onClickPopularFixtureHandler(sport.sportId, sport.fixtureId)
                      }}
                    >
                      <span style={{ color: `${HermesMainText}` }}>{convertToKstDateTimeSimple(sport.startAt)}</span>
                      <img
                        style={{ width: '23px', height: '23px' }}
                        src={`${process.env.PUBLIC_URL}/img/sidebar/side-${sport.sportId}.png`}
                        alt=""
                      />
                      <CustomTeamDiv>
                        <span>
                          {sport.homeImageUrl && <img src={`${sport.homeImageUrl}`} alt="" />}

                          {sport.homeName}
                        </span>
                        <span>
                          {sport.awayImageUrl && <img src={`${sport.awayImageUrl}`} alt="" />}
                          {sport.awayName}
                        </span>
                      </CustomTeamDiv>
                    </PopularTeamList>
                  ))
                )}
              </CustomCollapse2>
            </PopularWrap>
            <PopularWrap>
              <PopularTitle
                onClick={() => {
                  setIsOpenedQuickLinks4(prev => !prev)
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/top.png`} alt="" />
                최신 인기리그
                <img src={`${process.env.PUBLIC_URL}/img/sidebar/down.png`} alt="" />
              </PopularTitle>
              <CustomCollapse2 in={isOpenedQuickLinks4}>
                {overseasPopularLeagueCategory.length === 0 ? (
                  <PopularLeagueList>
                    <CustomLeagueDiv>최신 인기리그가 없습니다.</CustomLeagueDiv>
                  </PopularLeagueList>
                ) : (
                  overseasPopularLeagueCategory.map(sport => (
                    <PopularLeagueList
                      key={sport.sportId}
                      onClick={() => {
                        onClickPopularLeagueHandler(sport.sportId, sport.leagueId)
                      }}
                    >
                      <img
                        style={{ width: '23px', height: '23px' }}
                        src={`${process.env.PUBLIC_URL}/img/sidebar/side-${sport.sportId}.png`}
                        alt=""
                      />
                      <CustomLeagueDiv>
                        {sport.leagueImageUrl && <img src={`${sport.leagueImageUrl}`} alt="" />}
                        {sport.leagueName}
                      </CustomLeagueDiv>
                    </PopularLeagueList>
                  ))
                )}
              </CustomCollapse2>
            </PopularWrap>
            {/* 스포츠 */}
            <QuickLinksWrap style={{ paddingTop: '0px' }}>
              <QuickLinksCollapse
                onClick={() => {
                  setIsOpenedSportsLinks(prev => !prev)
                }}
              >
                <QuickLinksText>스포츠</QuickLinksText>
                {isOpenedSportsLinks ? <FaChevronDown /> : <FaChevronUp />}
              </QuickLinksCollapse>

              <CustomCollapse in={isOpenedSportsLinks}>
                <QuickLinksBox>
                  <SportsMenuBodyWrap>
                    <SportsMenuContentBox>
                      {overseasCategory.map(sport => {
                        if (sport.sportId === -1) return

                        return (
                          <>
                            <SportsMenuItemWrap
                              onClick={() => {
                                onClickSportHandler(sport.sportId)
                              }}
                            >
                              <SportsMenuItemBox>
                                <div
                                  style={{
                                    whiteSpace: 'nowrap',
                                    position: 'relative',
                                    display: 'block',
                                    width: '100%',
                                  }}
                                >
                                  <SportsMenuItemCount>{sport.sportCount}</SportsMenuItemCount>
                                  <SportsMenuItemIconBox>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/img/sidebar/side-${sport.sportId}.png`}
                                      alt=""
                                    />
                                  </SportsMenuItemIconBox>
                                  <SportsMenuItemText>{sport.sportName}</SportsMenuItemText>
                                </div>
                              </SportsMenuItemBox>
                            </SportsMenuItemWrap>
                            {sport.locations.length > 0 && (
                              <SportCustomCollapse in={sport.sportId === selectedSportId}>
                                <div>
                                  {sport.locations.map(locationData => {
                                    return (
                                      <>
                                        <SportsMenuItemWrap
                                          onClick={() => {
                                            onClickLocationHandler(sport.sportId, locationData.locationId)
                                          }}
                                        >
                                          <SportsMenuItemBox active={locationData.locationId === selectedLocationId}>
                                            <div
                                              style={{
                                                whiteSpace: 'nowrap',
                                                position: 'relative',
                                                display: 'block',
                                                width: '100%',
                                              }}
                                            >
                                              <SportsMenuItemCount>{locationData.locationCount}</SportsMenuItemCount>
                                              <SportsMenuItemIconBox>
                                                <img src={locationData.locationImageUrl} alt="" />
                                              </SportsMenuItemIconBox>
                                              <SportsMenuItemText>{locationData.locationName}</SportsMenuItemText>
                                            </div>
                                          </SportsMenuItemBox>
                                        </SportsMenuItemWrap>
                                        {locationData.leagues.length > 0 && (
                                          <SportCustomCollapse in={locationData.locationId === selectedLocationId}>
                                            <div>
                                              {locationData.leagues.map(league => {
                                                return (
                                                  <SportsMenuItemWrap
                                                    onClick={() => {
                                                      onClickLeagueHandler(
                                                        sport.sportId,
                                                        locationData.locationId,
                                                        league.leagueId,
                                                      )
                                                    }}
                                                  >
                                                    <SportsMenuItemBox isLeague>
                                                      <div
                                                        style={{
                                                          whiteSpace: 'nowrap',
                                                          position: 'relative',
                                                          display: 'block',
                                                          width: '100%',
                                                        }}
                                                      >
                                                        <SportsMenuItemCount>{league.leagueCount}</SportsMenuItemCount>
                                                        <SportsMenuItemIconBox>
                                                          <img src={league.leagueImageUrl} alt="" />
                                                        </SportsMenuItemIconBox>
                                                        <SportsMenuItemText>{league.leagueName}</SportsMenuItemText>
                                                      </div>
                                                    </SportsMenuItemBox>
                                                  </SportsMenuItemWrap>
                                                )
                                              })}
                                            </div>
                                          </SportCustomCollapse>
                                        )}
                                      </>
                                    )
                                  })}
                                </div>
                              </SportCustomCollapse>
                            )}
                          </>
                        )
                      })}
                    </SportsMenuContentBox>
                  </SportsMenuBodyWrap>
                </QuickLinksBox>
              </CustomCollapse>
            </QuickLinksWrap>
          </SidbarMenuWrap>
        </SlimScrollDiv>
      </MobileMenu>
    </>
  )
}

export default MobileMenuSidebar

const PopularWrap = styled.div`
  opacity: 1;
  overflow: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease, max-height 0.5s ease;
`

const CustomLeagueDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  img {
    width: 23px;
    height: 23px;
  }
`

const CustomTeamDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-left: -10px;
  span {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    width: 100%;
    font-size: 12px;

    img {
      width: 18px;
      height: 18px;
    }
  }
`

const PopularTeamList = styled.div`
  border-bottom: 1px solid #262626;
  color: #fff;
  cursor: pointer;
  height: 44px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 20px;
  img {
    width: 23px;
    height: 23px;
  }
  &:hover {
    background: #1f1f1f;
  }
`

const PopularLeagueList = styled.div`
  border-bottom: 1px solid #262626;
  color: #fff;
  cursor: pointer;
  height: 44px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 30px;
  img {
    width: 23px;
    height: 23px;
  }
  &:hover {
    background: #1f1f1f;
  }
`

const PopularTitle = styled.div`
  border-top: 1px solid #565656;
  border-bottom: 3px solid #111;
  background-image: linear-gradient(180deg, #101010 0, #121212 20%, #181617 50%, #1c1a1b 80%, #201c1b) !important;
  color: #fff;
  font-weight: 900;
  font-size: 14px;
  position: relative;
  height: 46px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  img:first-child {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  img:last-child {
    margin-left: auto; /* 오른쪽으로 밀기 */
    margin-right: 10px;
    width: 20px;
    height: 9px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7); // 검은색 배경에 70% 투명도
  z-index: 999; // 다른 요소들 위에 나타나도록 z-index 설정
`

const CustomLink = styled.div`
  text-decoration: none;
  color: #fff;
`

const SidebarWrap = styled.div`
  transition: all 0.5s ease-out 0s;
  // 열고 닫는부분 처리해주기
  width: 271px;

  position: fixed;
  margin-top: 65px;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1010;
  -webkit-transform: translateZ(0);

  font-family: 'Malgun Gothic', 'dotum';

  background-color: #141517;
`

// 스크롤 용도
const SlimScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`

const SidbarMenuWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

// text-white 부분
const QuickLinksWrap = styled.div`
  color: #fff;
  height: max-content;
  padding: 35px 8px 0 8px;
`

// text-white 부분
const QuickLinksWrap2 = styled.div`
  color: #fff;
  padding: 0px 8px 0 8px;
`

// collapse 부분
const QuickLinksCollapse = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;

  &:hover {
    background-color: #1f1f1f;
  }

  margin-top: 8px;
  font-weight: 900;
  cursor: pointer;
`

const QuickLinksText = styled.div`
  width: calc(100% - 24px);
  float: left;
  font-size: 14px;
`

const CustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const SportCustomCollapse = styled(Collapse)`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: height, visibility;
    // transition: height 3s ease-in-out !important;
  }
`

const QuickLinksBox = styled.div``

const SidbarQuickItem = styled.div`
  height: 44px;
  padding: 12px 8px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  display: table;
  transition: background-color 0.1s ease-out 0s;
  white-space: nowrap;
`

const SidbarQuickItemText = styled.div`
  float: right;
  padding-left: 24px;
  font-size: 13px;
  width: calc(100% - 16px);
  ${props =>
    props.active &&
    `
    color: ${HermesMainText} !important;
  `}
`

const SideLogoImg = styled.img`
  width: 16px;
  height: 16px;
  src: url(${props => `${process.env.PUBLIC_URL}/img/sidebar/${props.menuName}.png`});
`

// 레벨 이미지
export const LevelImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 3px;
  &:hover {
    cursor: pointer;
  }
`

const slideInFromRight = keyframes`
  from {
    transform: translateX(-270px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

const MobileMenu = styled.div`
  border: 2px solid #4a4a4a;
  overflow-y: scroll;

  position: fixed;
  left: -270px;
  top: 0;
  z-index: 1000;
  height: 100%;
  width: 271px;
  -webkit-transform: translateZ(0);
  background: rgb(20, 21, 23);
  transition: all 1s ease-out !important;
  box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
  animation: ${slideInFromRight} 0.5s ease-out;
  ${props =>
    props.$show &&
    `
    left: 0;
  `}
`

const SportsMenuItemCount = styled.div`
  font-weight: 100;
  float: right;
  padding: 5px 0;
  margin-top: 1px;
  display: inline;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  width: 16.66667%;
`
const SportsMenuItemIconBox = styled.div`
  width: 12.5%;
  float: left;
  display: block;
  margin-top: 4px;

  img {
    width: 16px;
  }
`

const SportsMenuItemText = styled.div`
  padding: 2px 3px 3px 3px;
  width: 70.83333%;
  float: left;
  display: block;
  font-size: 13px;
  word-break: break-all;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 1;
  }
`

const SportsMenuBodyWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #141517;
  padding: 0 8px;
  display: block;
  font-family: 'Malgun Gothic', dotum;
`

const SportsMenuContentBox = styled.div`
  display: block;
`

const SportsMenuItemWrap = styled.div`
  position: relative;
  display: block;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
`
const SportsMenuItemBox = styled.div`
  line-height: 20px;
  position: relative;
  display: flex;
  background: #141517;
  padding: 12px 8px;
  border: none;
  transition: background-color 0.1s ease-out 0s;
  color: #fff;
  cursor: pointer;
  height: 100%;

  &:hover {
    cursor: pointer;
    background-color: #232425;
  }

  ${props =>
    props.active &&
    `
    background-color: #232425;
`}

  ${props =>
    props.isLeague &&
    `
    padding-left: 17px;
    &:hover {
      background-color: #555759;
    }
`}
`
const MobileMenuCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1;
`
