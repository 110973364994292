import React, { useState, useEffect, useRef, Fragment } from 'react'
import styled from 'styled-components'
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { FaStar, FaLock } from 'react-icons/fa'
import {
  addBettingCart,
  onClickHideSportsEventCrossMarket,
  onClickHideSportsEventSpecialMarket,
} from '../../redux/sportsGameBettingSlice'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../utils/palette'

const MobileSportsMarketList = () => {
  const dispatch = useDispatch()

  // CROSS , SPECIAL
  const [tapActive, setTapActive] = useState('CROSS')

  function createKey(item) {
    return `${item.sportsEventId}-${item.marketId}-${item.selectedSportsMarketOddKey}-${item.selectedOddName}-${item.name}`
  }

  function isActive(activeSportsMarketKeySet, sportsEventId, marketId, sportsMarketOddKey, selectedOddName, name) {
    const key = createKey({
      sportsEventId,
      marketId,
      selectedSportsMarketOddKey: sportsMarketOddKey,
      selectedOddName,
      name,
    })
    return activeSportsMarketKeySet.has(key)
  }

  const { crossMarkets } = useSelector(state => {
    const { sportsGameBetting } = state

    const { CROSS } = sportsGameBetting.bettingCartList

    const activeSportsMarketKeySet = new Set([...CROSS.map(item => createKey(item))])
    const tempCrossMarket =
      sportsGameBetting.selectedSportsEventId === -1
        ? {}
        : sportsGameBetting?.crossMarkets[sportsGameBetting.selectedSportsEventId]

    return {
      crossMarkets: {
        ...tempCrossMarket,
        markets: tempCrossMarket?.markets?.map(market => ({
          ...market,
          points: market?.points?.map(point => ({
            ...point,
            isActive: isActive(
              activeSportsMarketKeySet,
              tempCrossMarket.sportsEventId,
              market.marketId,
              point.sportsMarketOddKey,
              point.oddName,
              point.name,
            ),
          })),
        })),
      },
    }
  })

  const onClickBetting = item => {
    dispatch(addBettingCart(item))
  }

  // Cross 접기 / 펼치기
  const onClickHideSportsEventCrossMarketHandler = (sportsEventId, marketId, visibled) => {
    dispatch(onClickHideSportsEventCrossMarket({ sportsEventId, marketId, isVisible: visibled }))
  }

  const getMarketListType6Component = (eventInfo, market) => {
    const { sportsEventId, sportsType, leagueName, homeName, awayName, ccKr, startAt, type } = eventInfo
    const { marketUiType, points } = market

    if (marketUiType !== 6) return points

    const updatedPoints = points.map(point => ({ ...point }))

    let i = 0
    while (i < updatedPoints.length) {
      if (updatedPoints[i].oddValue === '') {
        const currentOddName = updatedPoints[i].oddNameKr
        let j = i + 1
        while (j < updatedPoints.length) {
          if (updatedPoints[j].oddNameKr === currentOddName) {
            updatedPoints[i] = updatedPoints[j] // updatedPoints[i]를 updatedPoints[j]로 대체
            updatedPoints.splice(j, 1) // updatedPoints[j]를 리스트에서 제거
            break
          }
          j++
        }
        if (j === updatedPoints.length) {
          updatedPoints.splice(i, 1) // 해당 요소를 삭제
        }
      } else {
        i++
      }
    }

    const groupedPoints = updatedPoints.reduce((result, point) => {
      const { oddNameKr } = point
      if (!result[oddNameKr]) {
        result[oddNameKr] = []
      }
      result[oddNameKr].push(point)
      return result
    }, {})

    const maxValuesLength = Math.max(
      groupedPoints['홈팀승'] ? groupedPoints['홈팀승'].length : 0,
      groupedPoints['무승부'] ? groupedPoints['무승부'].length : 0,
      groupedPoints['원정승'] ? groupedPoints['원정승'].length : 0,
    )

    return (
      <>
        {Array.from({ length: maxValuesLength }, (_, idx) => (
          <SportsMarketPointBox key={idx}>
            {groupedPoints['홈팀승'] && groupedPoints['홈팀승'][idx] ? (
              <SportsMarketPointItem
                onClick={() => {
                  const item = {
                    sportsEventId,
                    sportsType,
                    ccKr,
                    startAt,
                    sportsMarketKey: market.sportsMarketKey,
                    marketId: market.marketId,
                    leagueName,
                    homeName,
                    awayName,
                    marketName: market.marketName,
                    name: groupedPoints['홈팀승'][idx].name,
                    homeSportsMarketOddKey: groupedPoints['홈팀승'][idx].sportsMarketOddKey,
                    homeOddName: groupedPoints['홈팀승'][idx].oddName,
                    homeOddNameKr: groupedPoints['홈팀승'][idx].oddNameKr,
                    homeOddValue: groupedPoints['홈팀승'][idx].oddValue,
                    awaySportsMarketOddKey: '',
                    awayOddName: '',
                    awayOddNameKr: '',
                    awayOddValue: '',
                    drawSportsMarketOddKey: '',
                    drawOddName: '',
                    drawOddNameKr: '',
                    drawOddValue: '',
                    selectedSportsMarketPointKey: groupedPoints['홈팀승'][idx].sportsMarketPointKey,
                    selectedSportsMarketOddKey: groupedPoints['홈팀승'][idx].sportsMarketOddKey,
                    selectedOddName: groupedPoints['홈팀승'][idx].oddName,
                    selectedOddNameKr: groupedPoints['홈팀승'][idx].oddNameKr,
                    selectedOddValue: groupedPoints['홈팀승'][idx].oddValue,
                  }

                  onClickBetting({ type, item })
                }}
              >
                <SportsMarketPointItemDetail isActive={groupedPoints['홈팀승'][idx].isActive}>
                  {groupedPoints['홈팀승'][idx].oddValue && (
                    <>
                      <SportsMarketPointItemDetailInfo1>
                        <span>{groupedPoints['홈팀승'][idx].oddNameKr}</span>
                        <span style={{ marginLeft: '10px' }}>{groupedPoints['홈팀승'][idx].name}</span>
                      </SportsMarketPointItemDetailInfo1>
                      <SportsMarketPointItemDetailInfo2>
                        <span>{groupedPoints['홈팀승'][idx].oddValue}</span>
                      </SportsMarketPointItemDetailInfo2>
                    </>
                  )}
                </SportsMarketPointItemDetail>
              </SportsMarketPointItem>
            ) : (
              <SportsMarketPointItem>
                <SportsMarketPointItemDetail />
              </SportsMarketPointItem>
            )}
            {groupedPoints['무승부'] && groupedPoints['무승부'][idx] ? (
              <SportsMarketPointItem
                onClick={() => {
                  const item = {
                    sportsEventId,
                    sportsType,
                    ccKr,
                    startAt,
                    sportsMarketKey: market.sportsMarketKey,
                    marketId: market.marketId,
                    leagueName,
                    homeName,
                    awayName,
                    marketName: market.marketName,
                    name: groupedPoints['무승부'][idx].name,
                    homeSportsMarketOddKey: groupedPoints['무승부'][idx].sportsMarketOddKey,
                    homeOddName: groupedPoints['무승부'][idx].oddName,
                    homeOddNameKr: groupedPoints['무승부'][idx].oddNameKr,
                    homeOddValue: groupedPoints['무승부'][idx].oddValue,
                    awaySportsMarketOddKey: '',
                    awayOddName: '',
                    awayOddNameKr: '',
                    awayOddValue: '',
                    drawSportsMarketOddKey: '',
                    drawOddName: '',
                    drawOddNameKr: '',
                    drawOddValue: '',
                    selectedSportsMarketPointKey: groupedPoints['무승부'][idx].sportsMarketPointKey,
                    selectedSportsMarketOddKey: groupedPoints['무승부'][idx].sportsMarketOddKey,
                    selectedOddName: groupedPoints['무승부'][idx].oddName,
                    selectedOddNameKr: groupedPoints['무승부'][idx].oddNameKr,
                    selectedOddValue: groupedPoints['무승부'][idx].oddValue,
                  }
                  onClickBetting({ type, item })
                }}
              >
                <SportsMarketPointItemDetail isActive={groupedPoints['무승부'][idx].isActive}>
                  {groupedPoints['무승부'][idx].oddValue && (
                    <>
                      <SportsMarketPointItemDetailInfo1>
                        <span>{groupedPoints['무승부'][idx].oddNameKr}</span>
                        <span style={{ marginLeft: '10px' }}>{groupedPoints['무승부'][idx].name}</span>
                      </SportsMarketPointItemDetailInfo1>
                      <SportsMarketPointItemDetailInfo2>
                        <span>{groupedPoints['무승부'][idx].oddValue}</span>
                      </SportsMarketPointItemDetailInfo2>
                    </>
                  )}
                </SportsMarketPointItemDetail>
              </SportsMarketPointItem>
            ) : (
              <SportsMarketPointItem>
                <SportsMarketPointItemDetail />
              </SportsMarketPointItem>
            )}
            {groupedPoints['원정승'] && groupedPoints['원정승'][idx] ? (
              <SportsMarketPointItem
                onClick={() => {
                  const item = {
                    sportsEventId,
                    sportsType,
                    ccKr,
                    startAt,
                    sportsMarketKey: market.sportsMarketKey,
                    marketId: market.marketId,
                    leagueName,
                    homeName,
                    awayName,
                    marketName: market.marketName,
                    name: groupedPoints['원정승'][idx].name,
                    homeSportsMarketOddKey: groupedPoints['원정승'][idx].sportsMarketOddKey,
                    homeOddName: groupedPoints['원정승'][idx].oddName,
                    homeOddNameKr: groupedPoints['원정승'][idx].oddNameKr,
                    homeOddValue: groupedPoints['원정승'][idx].oddValue,
                    awaySportsMarketOddKey: '',
                    awayOddName: '',
                    awayOddNameKr: '',
                    awayOddValue: '',
                    drawSportsMarketOddKey: '',
                    drawOddName: '',
                    drawOddNameKr: '',
                    drawOddValue: '',
                    selectedSportsMarketPointKey: groupedPoints['원정승'][idx].sportsMarketPointKey,
                    selectedSportsMarketOddKey: groupedPoints['원정승'][idx].sportsMarketOddKey,
                    selectedOddName: groupedPoints['원정승'][idx].oddName,
                    selectedOddNameKr: groupedPoints['원정승'][idx].oddNameKr,
                    selectedOddValue: groupedPoints['원정승'][idx].oddValue,
                  }
                  onClickBetting({ type, item })
                }}
              >
                <SportsMarketPointItemDetail isActive={groupedPoints['원정승'][idx].isActive}>
                  {groupedPoints['원정승'][idx].oddValue && (
                    <>
                      <SportsMarketPointItemDetailInfo1>
                        <span>{groupedPoints['원정승'][idx].oddNameKr}</span>
                        <span style={{ marginLeft: '10px' }}>{groupedPoints['원정승'][idx].name}</span>
                      </SportsMarketPointItemDetailInfo1>
                      <SportsMarketPointItemDetailInfo2>
                        <span>{groupedPoints['원정승'][idx].oddValue}</span>
                      </SportsMarketPointItemDetailInfo2>
                    </>
                  )}
                </SportsMarketPointItemDetail>
              </SportsMarketPointItem>
            ) : (
              <SportsMarketPointItem>
                <SportsMarketPointItemDetail />
              </SportsMarketPointItem>
            )}
          </SportsMarketPointBox>
        ))}
      </>
    )
  }

  const getMarketListCommonComponent = (eventInfo, market) => {
    const { sportsEventId, sportsType, leagueName, homeName, awayName, ccKr, startAt, type } = eventInfo
    const { marketUiType, points } = market

    const uls = []
    let num

    switch (marketUiType) {
      case 1:
      case 2:
      case 3:
        num = marketUiType
        break
      case 4:
      case 6:
        num = 3
        break
      case 5:
        num = 2
        break
      default:
        num = 1
        break
    }

    points.forEach((value, index) => {
      if (index % num === 0) {
        uls.push(
          <SportsMarketPointBox key={index}>
            <SportsMarketPointItem
              onClick={() => {
                let item = {}

                switch (true) {
                  case num === 2:
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index].sportsMarketOddKey,
                      homeOddName: points[index].oddName,
                      homeOddNameKr: points[index].oddNameKr,
                      homeOddValue: points[index].oddValue,
                      awaySportsMarketOddKey: points[index + 1].sportsMarketOddKey,
                      awayOddName: points[index + 1].oddName,
                      awayOddNameKr: points[index + 1].oddNameKr,
                      awayOddValue: points[index + 1].oddValue,
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                  case num === 3 && marketUiType === 4:
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                  case num === 3 && marketUiType !== 4:
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index].sportsMarketOddKey,
                      homeOddName: points[index].oddName,
                      homeOddNameKr: points[index].oddNameKr,
                      homeOddValue: points[index].oddValue,
                      awaySportsMarketOddKey: points[index + 2].sportsMarketOddKey,
                      awayOddName: points[index + 2].oddName,
                      awayOddNameKr: points[index + 2].oddNameKr,
                      awayOddValue: points[index + 2].oddValue,
                      drawSportsMarketOddKey: points[index + 1].sportsMarketOddKey,
                      drawOddName: points[index + 1].oddName,
                      drawOddNameKr: points[index + 1].oddNameKr,
                      drawOddValue: points[index + 1].oddValue,
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                  default:
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                    break
                }

                if (item) {
                  onClickBetting({ type, item })
                }
              }}
            >
              <SportsMarketPointItemDetail isActive={value.isActive}>
                <SportsMarketPointItemDetailInfo1>
                  <span>{value.oddNameKr}</span> <span style={{ marginLeft: '10px' }}>{value.name}</span>
                </SportsMarketPointItemDetailInfo1>
                <SportsMarketPointItemDetailInfo2>
                  <div>{value.oddValue}</div>
                </SportsMarketPointItemDetailInfo2>
              </SportsMarketPointItemDetail>
            </SportsMarketPointItem>
          </SportsMarketPointBox>,
        )
      } else {
        const lastIndex = uls.length - 1

        const updatedChildren = React.Children.toArray(uls[lastIndex].props.children)

        // 핸디 , num==2 일때만 사용
        let awayPointName

        if (market.marketName.includes('핸디')) {
          const parsedValue = parseFloat(points[index - 1].name)
          awayPointName = parsedValue === 0 ? '0.0' : (-parsedValue).toFixed(1)
        } else {
          awayPointName = ''
        }

        updatedChildren.push(
          <SportsMarketPointItem
            key={index}
            onClick={() => {
              let item = {}

              switch (true) {
                case num === 2:
                  item = {
                    sportsEventId,
                    sportsType,
                    ccKr,
                    startAt,
                    sportsMarketKey: market.sportsMarketKey,
                    marketId: market.marketId,
                    leagueName,
                    homeName,
                    awayName,
                    marketName: market.marketName,
                    name: value.name,
                    homeSportsMarketOddKey: points[index - 1].sportsMarketOddKey,
                    homeOddName: points[index - 1].oddName,
                    homeOddNameKr: points[index - 1].oddNameKr,
                    homeOddValue: points[index - 1].oddValue,
                    awaySportsMarketOddKey: points[index].sportsMarketOddKey,
                    awayOddName: points[index].oddName,
                    awayOddNameKr: points[index].oddNameKr,
                    awayOddValue: points[index].oddValue,
                    drawSportsMarketOddKey: '',
                    drawOddName: '',
                    drawOddNameKr: '',
                    drawOddValue: '',
                    selectedSportsMarketPointKey: value.sportsMarketPointKey,
                    selectedSportsMarketOddKey: value.sportsMarketOddKey,
                    selectedOddName: value.oddName,
                    selectedOddNameKr: value.oddNameKr,
                    selectedOddValue: value.oddValue,
                    awayPointName,
                  }
                  break
                case num === 3 && marketUiType === 4:
                  // 무승부 (가운데 선택시)
                  if (index > 0 && index < 2) {
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  } else {
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: value.sportsMarketOddKey,
                      homeOddName: value.oddName,
                      homeOddNameKr: value.oddNameKr,
                      homeOddValue: value.oddValue,
                      awaySportsMarketOddKey: '',
                      awayOddName: '',
                      awayOddNameKr: '',
                      awayOddValue: '',
                      drawSportsMarketOddKey: '',
                      drawOddName: '',
                      drawOddNameKr: '',
                      drawOddValue: '',
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  }
                  break
                case num === 3 && marketUiType !== 4:
                  // 무승부 (가운데 선택시)
                  if (index > 0 && index < 2) {
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index - 1].sportsMarketOddKey,
                      homeOddName: points[index - 1].oddName,
                      homeOddNameKr: points[index - 1].oddNameKr,
                      homeOddValue: points[index - 1].oddValue,
                      awaySportsMarketOddKey: points[index + 1].sportsMarketOddKey,
                      awayOddName: points[index + 1].oddName,
                      awayOddNameKr: points[index + 1].oddNameKr,
                      awayOddValue: points[index + 1].oddValue,
                      drawSportsMarketOddKey: points[index].sportsMarketOddKey,
                      drawOddName: points[index].oddName,
                      drawOddNameKr: points[index].oddNameKr,
                      drawOddValue: points[index].oddValue,
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  } else {
                    item = {
                      sportsEventId,
                      sportsType,
                      ccKr,
                      startAt,
                      sportsMarketKey: market.sportsMarketKey,
                      marketId: market.marketId,
                      leagueName,
                      homeName,
                      awayName,
                      marketName: market.marketName,
                      name: value.name,
                      homeSportsMarketOddKey: points[index - 2].sportsMarketOddKey,
                      homeOddName: points[index - 2].oddName,
                      homeOddNameKr: points[index - 2].oddNameKr,
                      homeOddValue: points[index - 2].oddValue,
                      awaySportsMarketOddKey: points[index].sportsMarketOddKey,
                      awayOddName: points[index].oddName,
                      awayOddNameKr: points[index].oddNameKr,
                      awayOddValue: points[index].oddValue,
                      drawSportsMarketOddKey: points[index - 1].sportsMarketOddKey,
                      drawOddName: points[index - 1].oddName,
                      drawOddNameKr: points[index - 1].oddNameKr,
                      drawOddValue: points[index - 1].oddValue,
                      selectedSportsMarketPointKey: value.sportsMarketPointKey,
                      selectedSportsMarketOddKey: value.sportsMarketOddKey,
                      selectedOddName: value.oddName,
                      selectedOddNameKr: value.oddNameKr,
                      selectedOddValue: value.oddValue,
                    }
                  }
                  break
                default:
                  item = {
                    sportsEventId,
                    sportsType,
                    ccKr,
                    startAt,
                    sportsMarketKey: market.sportsMarketKey,
                    marketId: market.marketId,
                    leagueName,
                    homeName,
                    awayName,
                    marketName: market.marketName,
                    name: value.name,
                    homeSportsMarketOddKey: value.sportsMarketOddKey,
                    homeOddName: value.oddName,
                    homeOddNameKr: value.oddNameKr,
                    homeOddValue: value.oddValue,
                    awaySportsMarketOddKey: '',
                    awayOddName: '',
                    awayOddNameKr: '',
                    awayOddValue: '',
                    drawSportsMarketOddKey: '',
                    drawOddName: '',
                    drawOddNameKr: '',
                    drawOddValue: '',
                    selectedSportsMarketPointKey: value.sportsMarketPointKey,
                    selectedSportsMarketOddKey: value.sportsMarketOddKey,
                    selectedOddName: value.oddName,
                    selectedOddNameKr: value.oddNameKr,
                    selectedOddValue: value.oddValue,
                  }
                  break
              }

              if (item) {
                onClickBetting({ type, item })
              }
            }}
          >
            <SportsMarketPointItemDetail isActive={value.isActive}>
              <SportsMarketPointItemDetailInfo1>
                <span>{value.oddNameKr}</span>
                <span style={{ marginLeft: '10px' }}>{awayPointName || value.name}</span>
              </SportsMarketPointItemDetailInfo1>
              <SportsMarketPointItemDetailInfo2>
                <span>{value.oddValue}</span>
              </SportsMarketPointItemDetailInfo2>
            </SportsMarketPointItemDetail>
          </SportsMarketPointItem>,
        )

        const updatedUl = React.cloneElement(uls[lastIndex], {
          children: updatedChildren,
        })

        uls[lastIndex] = updatedUl
      }
    })

    return uls.map((ul, index) => <SportsMarketPointBox key={index}>{ul.props.children}</SportsMarketPointBox>)
  }

  return (
    <SportsMarketListWrap className="sports-container">
      {/* 크로스 or 스페셜 */}
      <SportsMarketTypeWrap>
        <SportsMarketTypeBox active>
          <SportsMarketTypeInfo>크로스</SportsMarketTypeInfo>
        </SportsMarketTypeBox>
      </SportsMarketTypeWrap>

      <SportsMarketContentWrap>
        {crossMarkets?.markets?.map(market => {
          return (
            <Fragment key={market.sportsMarketKey}>
              <SportsMarketNameBox
                onClick={() =>
                  onClickHideSportsEventCrossMarketHandler(
                    crossMarkets.sportsEventId,
                    market.marketId,
                    !market.isVisible,
                  )
                }
              >
                <span>{market.marketName || ''}</span>
                {market.isVisible ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
              </SportsMarketNameBox>
              {market.isVisible && (
                <SportsMarketPointWrap>
                  {market.isLocked && (
                    <SportsEventContentItemOverlay>
                      <SportsEventContentItemLockIcon />
                    </SportsEventContentItemOverlay>
                  )}
                  {market?.marketUiType === 6
                    ? getMarketListType6Component(
                        {
                          ...crossMarkets,
                          type: 'CROSS',
                        },
                        market,
                      )
                    : getMarketListCommonComponent(
                        {
                          ...crossMarkets,
                          type: 'CROSS',
                        },
                        market,
                      )}
                </SportsMarketPointWrap>
              )}
            </Fragment>
          )
        })}
      </SportsMarketContentWrap>
    </SportsMarketListWrap>
  )
}

export default MobileSportsMarketList

const SportsMarketListWrap = styled.div`
  width: 100%;
  height: max-content;
  overflow: auto;
  display: flex;
  flex-direction: column;
  color: white;
`

const SportsMarketTypeWrap = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
`

const SportsMarketTypeBox = styled.div`
  width: 100%;
  background-color: rgba(48, 48, 48, 0.6);
  color: #fff;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  border-bottom: 1px solid ${HermesMainSelect};

  ${props =>
    props.active &&
    `
    background-color: ${HermesMainSelect};
        border-top: 1px solid ${HermesMainSelect};
        border-left: 1px solid ${HermesMainSelect};
        border-right: 1px solid ${HermesMainSelect};
        border-bottom: 0;
        color: ${HermesMainText};
    `}
`

const SportsMarketTypeInfo = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsMarketContentWrap = styled.div`
  width: 100%;
  border-top: 0;
  border-left: 1px solid ${HermesMainSelect};
  border-right: 1px solid ${HermesMainSelect};
  border-bottom: 1px solid ${HermesMainSelect};
`

const SportsMarketNameBox = styled.div`
  width: 100%;
  padding: 5px 9px;
  background-color: rgba(48, 48, 48, 1);
  border: 1px solid #414244;
  display: flex;

  &:not(:first-child) {
    margin-top: 15px;
  }

  span {
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
`

const SportsMarketPointWrap = styled.div`
  width: 100%;
  position: relative;
`

const SportsMarketPointBox = styled.ul`
  vertical-align: middle;
  width: 100%;
  display: flex;
  list-style: none;
`
const SportsMarketPointItem = styled.li`
  text-align: center;
  display: flex;
  flex: 1 1 0;
  vertical-align: middle;
  padding: 1px;
  position: relative;
`

const SportsMarketPointItemDetail = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  background-color: rgba(48, 48, 48, 0.6);
  border-radius: 0;
  border-top: 1px solid #3f4346;
  border-bottom: 1px solid #000;
  color: #fff;
  padding: 6px 1px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  font-weight: 500;

  cursor: pointer;

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
      `}
`

const SportsMarketPointItemDetailInfo1 = styled.div`
  width: 70%;
  vertical-align: middle;
  text-align: left !important;
  padding: 0 !important;
  cursor: pointer;
  span {
    white-space: pre-wrap;
  }
`

const SportsMarketPointItemDetailInfo2 = styled.div`
  width: 29%;
  vertical-align: middle;
  text-align: right !important;
  cursor: pointer;
  span {
    white-space: pre-wrap;
  }
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: #edae07;
  width: auto;
  height: 20px;
  margin-left: auto;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: #edae07;
  width: auto;
  height: 20px;
  margin-left: auto;
`

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);

  border: 1px solid #eec100;
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #eec100;
`
