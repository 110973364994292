import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { BiLock } from 'react-icons/bi'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { addBettingCartItem } from '../../../redux/virtualGameBettingSlice'
import {
  VirtualGameBettingType,
  VirtualGameBettingTypeKr,
  VirtualGameLeagueTypeKr,
  VirtualResultSettlementTypeEnums,
} from '../../../utils/enums/VirtualGame/VirtualGameEnums'
import { formatSeconds } from '../../../containers/MiniGame/miniGameTimeUtils'

const getColor = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return 'white'
  }
}

const MobileVirtualGreyhoundsContentBotom = () => {
  const dispatch = useDispatch()

  const [leagueList, setLeagueList] = useState([])
  const [newVirtualFixtures, setNewVirtualFixtures] = useState([])

  const { virtualFixtures, virtualMarketInfos, allLeagueList, virtualResultHistory, virtualBetLogs } = useSelector(
    state => {
      const { virtualGameInfo } = state

      return {
        virtualFixtures: virtualGameInfo.virtualFixtures,
        virtualMarketInfos: virtualGameInfo.virtualMarketInfos,
        allLeagueList: virtualGameInfo.virtualSportList,
        virtualResultHistory: virtualGameInfo.virtualResultHistory,
        virtualBetLogs: virtualGameInfo.virtualBetLogs,
      }
    },
  )

  const { bettingCartList, sportType } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      bettingCartList: virtualGameBetting.bettingCartList,
      sportType: virtualGameBetting.selectedVirtuaGameSportType,
    }
  })

  useEffect(() => {
    if (sportType !== 'VIRTUAL_GREYHOUNDS') return

    setLeagueList(
      allLeagueList.find(item => item.sportType === sportType)?.leagueList.filter(item => item.leagueIsShow) ?? [],
    )
  }, [allLeagueList, sportType])

  const [activeKeys, setActiveKeys] = useState([])

  useEffect(() => {
    setActiveKeys(
      bettingCartList.map(item => {
        if (item.selectedBetInfo.raceNumber) {
          return `${item.fixture.eventId}-${item.fixture.leagueType}-${item.selectedBetInfo.betType}-${item.selectedBetInfo.raceNumber}`
        }
        return `${item.fixture.eventId}-${item.fixture.leagueType}-${item.selectedBetInfo.betType}`
      }),
    )
  }, [bettingCartList])

  useEffect(() => {
    const updateFixtures = () => {
      const now = moment().tz('Asia/Seoul')

      const updatedFixtures = virtualFixtures.map(fixture => {
        const target = moment(fixture.startDate).subtract(fixture.bettingEndTime, 'seconds')
        const duration = moment.duration(target.diff(now))

        let remainMins = Math.floor(duration.asMinutes())
        let remainSecs = Math.floor(duration.asSeconds() % 60)

        if (duration.asSeconds() <= 0) {
          remainMins = 0
          remainSecs = 0
        }

        return {
          ...fixture,
          remainMins,
          remainSecs,
        }
      })

      setNewVirtualFixtures(updatedFixtures)
    }

    const intervalId = setInterval(updateFixtures, 1000) // 1초 간격으로 업데이트

    // 초기 호출
    updateFixtures()

    // Cleanup on component unmount
    return () => clearInterval(intervalId)
  }, [virtualFixtures])

  const [newMarketInfos, setNewMarketInfos] = useState({})

  // 마켓 가공
  useEffect(() => {
    setNewMarketInfos(
      virtualMarketInfos.reduce((result, market) => {
        const key = market.marketId

        if (!result[key]) {
          result[key] = market
        }

        return result
      }, {}),
    )
  }, [virtualMarketInfos])

  const [activeLeagueType, setActiveLeagueType] = useState(null)

  const onClickActiveLeagueTypeHandler = league => {
    setActiveLeagueType(league)
  }

  const onClickBettingHandler = (fixture, marketInfo, selectedBetInfo) => {
    dispatch(
      addBettingCartItem({
        fixture,
        marketInfo,
        selectedBetInfo,
      }),
    )
  }

  const [activeTab, setActiveTab] = useState('BET')

  const onClickTabHandler = tabName => {
    setActiveTab(tabName)
  }

  const getBetCompo = () => {
    return (
      <>
        <VirtualGameContentBottomHeaderBox>
          <VirtualGameContentBottomHeaderItem
            active={!activeLeagueType}
            onClick={() => {
              onClickActiveLeagueTypeHandler(null)
            }}
          >
            모든 리그
          </VirtualGameContentBottomHeaderItem>
          {leagueList.map(leagueItem => {
            return (
              <VirtualGameContentBottomHeaderItem
                active={activeLeagueType === leagueItem.leagueType}
                onClick={() => {
                  onClickActiveLeagueTypeHandler(leagueItem.leagueType)
                }}
              >
                {VirtualGameLeagueTypeKr[leagueItem.leagueType]}
              </VirtualGameContentBottomHeaderItem>
            )
          })}
        </VirtualGameContentBottomHeaderBox>
        {newVirtualFixtures.length > 0 &&
          newVirtualFixtures
            .filter(fixture => {
              if (activeLeagueType === null) {
                return true
              }

              return fixture.leagueType === activeLeagueType
            })
            .map(fixture => {
              return (
                <VirtualGameContentBottomContentWrap>
                  <VirtualGameContentBottomContentLeagueWrap>
                    <VirtualGameContentBottomContentLeagueBox>
                      <img
                        src={`${process.env.PUBLIC_URL}/img/virtual-game/VIRTUAL_GREYHOUNDS_COLOR.png`}
                        alt=""
                        style={{ width: '24px', marginRight: '5px', marginLeft: '5px', float: 'left' }}
                      />
                      <img
                        src={`${process.env.PUBLIC_URL}/img/virtual-game/bet365.png`}
                        alt=""
                        style={{ width: '25px', marginRight: '5px', float: 'left' }}
                      />
                      <span>{fixture.leagueName}</span>
                    </VirtualGameContentBottomContentLeagueBox>
                  </VirtualGameContentBottomContentLeagueWrap>

                  <VirtualGameContentBottomContentEventWrap>
                    {(((fixture?.remainMins || 0) === 0 && (fixture?.remainSecs || 0) === 0) ||
                      fixture.fixtureStatus !== 'NSY') && (
                      <VirtualGameContentBottomConntentLockBox>
                        <BiLock />
                      </VirtualGameContentBottomConntentLockBox>
                    )}

                    <VirtualGameContentBottomContentEventBox>
                      <VirtualGameContentBottomContentEventTimeBox>
                        <div className="left">
                          경기 시간 - {convertToKstByFormat(fixture.startDate, 'YYYY-MM-DD HH:mm')}
                        </div>

                        <div className="right">
                          <span>
                            배팅마감: {fixture?.remainMins || 0} : {formatSeconds(fixture?.remainSecs || 0)}
                          </span>
                        </div>
                      </VirtualGameContentBottomContentEventTimeBox>

                      {/* 우승 맞추기 - 8 */}
                      {newMarketInfos?.[8]?.usedMarket && (
                        <VirtualGameContentBottomContentEventBetWrap>
                          <VirtualGameContentBottomContentEventBetBox>
                            <VirtualGameContentBottomContentEventBetBoxItem style={{ flexDirection: 'column' }}>
                              {fixture.raceOddList?.map(raceItem => {
                                return (
                                  <li className="home-line-t">
                                    <VirtualGameContentBottomContentEventBet
                                      active={activeKeys.includes(
                                        `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.RACE_WINNER_PICK}-${raceItem.number}`,
                                      )}
                                      style={{ paddingLeft: '8px', paddingRight: '8px' }}
                                      onClick={() => {
                                        onClickBettingHandler(fixture, newMarketInfos?.[8] || {}, {
                                          betType: VirtualGameBettingType.RACE_WINNER_PICK,
                                          betPrice: raceItem?.odd?.toFixed(2) || 0,
                                          raceNumber: raceItem.number,
                                          raceName: raceItem.name,
                                          raceRider: null,
                                          raceImageUrl: raceItem.imageUrl,
                                        })
                                      }}
                                    >
                                      <ul className="list-box-g">
                                        <li className="hva-txt main-odd-team">
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                              src={`${process.env.PUBLIC_URL}/img/virtual-game/${raceItem.imageUrl}`}
                                              alt=""
                                              style={{ width: '22px', height: '21px', marginRight: '5px' }}
                                            />
                                            {raceItem.name}
                                          </div>
                                        </li>
                                        <li className="hva-txt main-odd-val" style={{ float: 'left' }}>
                                          <span>{raceItem?.odd?.toFixed(2) ?? 0}</span>
                                        </li>
                                      </ul>
                                    </VirtualGameContentBottomContentEventBet>
                                  </li>
                                )
                              })}
                            </VirtualGameContentBottomContentEventBetBoxItem>
                          </VirtualGameContentBottomContentEventBetBox>
                        </VirtualGameContentBottomContentEventBetWrap>
                      )}

                      {/* 홀짝 - 9 */}
                      {newMarketInfos?.[9]?.usedMarket && (
                        <VirtualGameContentBottomContentEventBetWrap>
                          <VirtualGameContentBottomContentEventBetBox>
                            <VirtualGameContentBottomContentEventBetBoxItem>
                              <li className="home-t">
                                <VirtualGameContentBottomContentEventBet
                                  active={activeKeys.includes(
                                    `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.RACE_ODD_EVEN_ODD_PICK}`,
                                  )}
                                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                                  onClick={() => {
                                    onClickBettingHandler(fixture, newMarketInfos?.[9] || {}, {
                                      betType: VirtualGameBettingType.RACE_ODD_EVEN_ODD_PICK,
                                      betPrice: fixture.raceOddOdd?.toFixed(2) || 0,
                                    })
                                  }}
                                >
                                  <ul className="list-box-g">
                                    <li className="hva-txt main-odd-team">
                                      <div>홀</div>
                                    </li>
                                    <li className="hva-txt main-odd-val" style={{ float: 'left' }}>
                                      <span>{fixture.raceOddOdd?.toFixed(2) || 0}</span>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>
                              <li className="vs-t">
                                <VirtualGameContentBottomContentEventBet disableHover>
                                  <ul className="list-box-g">
                                    <li className="hva-txt">
                                      <span>VS</span>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>

                              <li className="away-t">
                                <VirtualGameContentBottomContentEventBet
                                  active={activeKeys.includes(
                                    `${fixture.eventId}-${fixture.leagueType}-${VirtualGameBettingType.RACE_ODD_EVEN_EVEN_PICK}`,
                                  )}
                                  style={{ paddingLeft: '8px', paddingRight: '8px' }}
                                  onClick={() => {
                                    onClickBettingHandler(fixture, newMarketInfos?.[9] || {}, {
                                      betType: VirtualGameBettingType.RACE_ODD_EVEN_EVEN_PICK,
                                      betPrice: fixture.raceEvenOdd?.toFixed(2) || 0,
                                    })
                                  }}
                                >
                                  <ul className="list-box-g">
                                    <li className="hva-txt main-odd-val" style={{ textAlign: 'left', float: 'left' }}>
                                      <span>{fixture.raceEvenOdd?.toFixed(2) || 0}</span>
                                    </li>
                                    <li
                                      className="hva-txt main-odd-team"
                                      style={{ textAlign: 'right', float: 'right' }}
                                    >
                                      <div>짝</div>
                                    </li>
                                  </ul>
                                </VirtualGameContentBottomContentEventBet>
                              </li>
                            </VirtualGameContentBottomContentEventBetBoxItem>
                          </VirtualGameContentBottomContentEventBetBox>
                        </VirtualGameContentBottomContentEventBetWrap>
                      )}
                    </VirtualGameContentBottomContentEventBox>
                  </VirtualGameContentBottomContentEventWrap>
                </VirtualGameContentBottomContentWrap>
              )
            })}
      </>
    )
  }

  const getResultHistoryCompo = () => {
    return (
      <VirtualGameVideoRightContentBox>
        <table>
          <colgroup>
            <col width="75" />
            <col style={{ minWidth: '90px' }} />
            <col width="200" />
          </colgroup>
          <tbody>
            <tr>
              <th>시간</th>
              <th>리그</th>
              <th>우승마</th>
            </tr>
            {virtualResultHistory.map(result => {
              return (
                <tr className="tbody-tr">
                  <td>{convertToKstByFormat(result.startDate, 'HH:mm')}</td>
                  <td> {VirtualGameLeagueTypeKr[result.leagueType]} </td>
                  <td>
                    <div className="text-ellipsis">
                      {result.raceFirstNumber}. {result.raceFirstName}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </VirtualGameVideoRightContentBox>
    )
  }

  const getBetLogCompo = () => {
    return (
      <VirtualGameVideoRightContentBox>
        {virtualBetLogs.length === 0 ? (
          <VirtaulGameBetHistoryEmptyBox>해당 게임에 배팅내역이 존재하지 않습니다.</VirtaulGameBetHistoryEmptyBox>
        ) : (
          <>
            {virtualBetLogs.map(log => {
              return (
                <div style={{ marginBottom: '5px', borderBottom: '1px solid #ccc' }}>
                  <table>
                    <colgroup>
                      <col width="120" />
                      <col style={{ minWidth: '140px' }} />
                    </colgroup>

                    <tbody>
                      <tr>
                        <th>회차</th>
                        <th>리그</th>
                      </tr>
                      <tr className="tbody-tr">
                        <td>{convertToKstByFormat(log.startDate, 'HH:mm')}</td>
                        <td> {VirtualGameLeagueTypeKr[log.leagueType]} </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <colgroup>
                      <col width="90" />
                      <col width="30" />
                    </colgroup>

                    <tbody>
                      <tr>
                        <th>배팅</th>
                        <th>결과</th>
                      </tr>
                      <tr className="tbody-tr">
                        <td style={{ color: `${HermesMainText}` }}>
                          {log.marketId === 8 ? (
                            <>
                              {log.selectedRaceNumber}. {log.selectedRaceName}
                            </>
                          ) : (
                            <>
                              {log.marketName} - {VirtualGameBettingTypeKr[log.selectedBetType]}
                            </>
                          )}
                        </td>
                        <td style={{ color: `${getColor(log.betSettlementType)}`, fontWeight: '700' }}>
                          {VirtualResultSettlementTypeEnums[log.betSettlementType]}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            })}
          </>
        )}
      </VirtualGameVideoRightContentBox>
    )
  }

  const getContentCompo = () => {
    switch (activeTab) {
      case 'BET':
        return getBetCompo()
      case 'RESULT_HISTORY':
        return getResultHistoryCompo()
      case 'BETTING_LOG':
        return getBetLogCompo()
      default:
        return null
    }
  }

  return (
    <VirtualGameContentBottomWrap>
      <VirtualGameContentBottomTabWrap>
        <VirtualGameContentBottomTabItemBox
          active={activeTab === 'BET'}
          onClick={() => {
            onClickTabHandler('BET')
          }}
        >
          회차선택
        </VirtualGameContentBottomTabItemBox>
        <VirtualGameContentBottomTabItemBox
          active={activeTab === 'RESULT_HISTORY'}
          onClick={() => {
            onClickTabHandler('RESULT_HISTORY')
          }}
        >
          지난결과
        </VirtualGameContentBottomTabItemBox>
        <VirtualGameContentBottomTabItemBox
          active={activeTab === 'BETTING_LOG'}
          onClick={() => {
            onClickTabHandler('BETTING_LOG')
          }}
        >
          배팅내역
        </VirtualGameContentBottomTabItemBox>
      </VirtualGameContentBottomTabWrap>

      {getContentCompo()}
    </VirtualGameContentBottomWrap>
  )
}

export default MobileVirtualGreyhoundsContentBotom

const VirtualGameContentBottomWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 14px;
`

const VirtualGameContentBottomTabWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const VirtualGameContentBottomTabItemBox = styled.div`
  width: 100%;
  height: 48px;
  background: #161719;
  border-bottom: 1px solid #242424;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 33%;

  color: #fff;
  font-weight: 700;

  ${props =>
    props.active &&
    `
    border-bottom: 3px solid ${HermesMainSelect};
    color: ${HermesMainText};
 `}
`

const VirtualGameContentBottomHeaderBox = styled.div`
  width: 100%;
  border-radius: 6px 6px 0 0;
  background: #191919;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 7px;
  display: flex;

  white-space: nowrap !important;
  overflow: auto;
`

const VirtualGameContentBottomHeaderItem = styled.div`
  float: left;
  cursor: pointer;
  padding: 16px !important;
  text-align: center;

  ${props =>
    props.active &&
    `
        border-bottom: 1px solid ${HermesMainHover2};
    `}
`

const VirtualGameContentBottomContentWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  background: #141517;

  display: flex;
  flex-direction: column;
`

const VirtualGameContentBottomContentLeagueWrap = styled.div`
  background: #181a1c;
  border-radius: 6px 6px 0 0;
  padding: 10px 8px;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  font-size: 11px;
`

const VirtualGameContentBottomContentLeagueBox = styled.div`
  font-size: 12px;
  float: left;
  font-weight: 700;
  height: 100%;
  width: 100%;
  color: $a0a0a0;

  span {
    float: left;
    margin-top: 3px;
    color: #fff;
  }
`

const VirtualGameContentBottomContentEventWrap = styled.div`
  width: 100%;
  position: relative;
`

const VirtualGameContentBottomConntentLockBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 40px;
  background-color: rgba(0, 0, 0, 0.6);

  z-index: 290;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    color: ${HermesMainText};
  }
`

const VirtualGameContentBottomContentEventBox = styled.div`
  width: 100%;
  padding: 5px; 3px;
  display: flex;
  flex-direction: column;
`

const VirtualGameContentBottomContentEventTimeBox = styled.div`
  width: 100%;
  text-align: left;
  color: #000;
  padding: 0 8px;
  background: #212426;
  border-radius: 6px 6px 0 0;
  font-size: 11px;
  padding-bottom: 8px;
  padding-top: 8px;
  height: 100%;

  .left {
    float: left;
    padding: 8px 8px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
  }

  .right {
    float: right;
    text-align: center;

    color: ${HermesMainText};
    background: none;
    border: none;
    padding: 6px 8px;
    border-radius: 16px;
    font-weight: 900;
    font-size: 14px;
    transition: background-color 0.1s ease-out 0s;

    .hva-txt {
      display: table-cell;
      vertical-align: middle;
      overflow-x: hidden;
    }
  }
`

const VirtualGameContentBottomContentEventBetWrap = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
`

const VirtualGameContentBottomContentEventBetBox = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  background: rgb(31, 33, 36);
  border-radius: 0px 0px 6px 6px;
  padding: 8px !important;
`

const VirtualGameContentBottomContentEventBetBoxItem = styled.ul`
  width: 100%;
  min-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  .home-t,
  .vs-t {
    display: flex;
    text-align: center;
    vertical-align: middle;
    padding: 1px;
    overflow: hidden;
    float: left;
  }

  .home-t {
    width: calc(50% - 39px);
  }

  .home-line-t {
    width: 100%;

    border-top: 1px solid #2a2c30;
  }

  .away-t {
    text-align: center;
    vertical-align: middle;
    padding: 1px;
    display: flex;
    width: calc(50% - 39px);
    overflow: hidden;
    float: left;
  }

  .vs-t {
    width: 78px;
  }
`

const VirtualGameContentBottomContentEventBet = styled.div`
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;

  background: #111;
  border-radius: 6px;
  border: 1px solid #111;
  transition: background-color 0.1s ease-out 0s, border 0.1s ease-out 0s;

  color: #fff;

  ${props =>
    !props.disableHover &&
    `
        &:hover {
            background: black;
            color: ${HermesMainText};
        }
    `}

  ${props =>
    props.active &&
    `
        border: 1px solid ${HermesMainBorder};
        color: ${HermesMainText};
    `}

  .list-box-g {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 24px;
  }

  .hva-txt {
    display: table-cell;
    vertical-align: middle;
    overflow-x: auto;
  }

  .main-odd-team {
    width: calc(100% - 60px);
    float: left;
    text-align: left;
  }

  .main-odd-val {
    width: 60px;
    float: right;
    text-align: right;
  }

  .line {
    color: ${HermesMainText};
  }
`

const VirtualGameVideoRightContentBox = styled.div`
  position: relative;
  margin-top: 1px;
  text-align: center;
  background: #16171a;
  min-height: 362px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(58, 59, 59, 0.5);
  }

  table {
    width: 100%;
    padding: 2px;
    color: #fff;
    font-size: 13px;

    border-collapse: separate;
    border-spacing: 0 8px;

    colgroup col {
      text-align: center;
    }

    th {
      font-weight: 700;
      padding: 3px 0;
      background: #141517;
      border-radius: 6px;
    }

    .tbody-tr {
      background: #1b1c1f;
      border-radius: 6px;

      td {
        padding: 5px 3px;
        text-align: center;

        .text-ellipsis {
          padding: 2px;
          overflow: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap !important;

          &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
          }

          &::-webkit-scrollbar-thumb {
            border: 1px solid transparent;
            background: #ccc;
          }
        }
      }
    }
  }
`

const VirtaulGameBetHistoryEmptyBox = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #fff;
`
