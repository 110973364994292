import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import MainPageCarousel from './MainPageCarousel'
import MainPageFooter from './MainPageFooter'
import MainPageGameCategory from './MainPageGameCategory'
import MainPageNoticeEvent from './MainPageNoticeEvent'

const MainPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.state?.alertMessage) {
      alert(location.state.alertMessage)
      navigate(location.pathname, { replace: true })
    }
  }, [])

  return (
    <MainPageWrap>
      <MainPageCarousel />
      <MainPageContentBox>
        <MainPageNoticeEvent />
        <MainPageGameCategory />
        <MainPageFooter />
      </MainPageContentBox>
    </MainPageWrap>
  )
}

export default MainPage

const MainPageWrap = styled.div`
  width: 1277px;
`
const MainPageContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
