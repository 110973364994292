import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { decodeAccessToken } from '../../utils/token'
import { getHoldemGameLink } from '../../socketio/holdemSocketIOHandler'
import CustomLoading from '../../containers/CustomLoading'

const MobileRevolutionHoldemPage = () => {
  const [gameLoading, setGameLoading] = useState(false)

  const { revolutionHoldemInfo } = useSelector(state => {
    const { memberInfo } = state

    return {
      revolutionHoldemInfo: memberInfo.revolutionHoldemInfo,
    }
  })

  const holdemGameStartHandler = () => {
    if (gameLoading) return

    if (revolutionHoldemInfo.inspection) {
      alert('현재 점검중입니다.')
      return
    }

    setGameLoading(true)

    const params = {
      userId: decodeAccessToken().userId,
    }

    getHoldemGameLink(params)
      .then(res => {
        if (res.status) {
          window.open(res.data.gameUrl)
        } else {
          alert(res.data)
        }
      })
      .catch(error => {
        alert('접속 실패했습니다. 잠시후 다시 시도해주세요.')
      })
      .finally(() => {
        setGameLoading(false)
      })
  }

  return (
    <>
      {gameLoading && <CustomLoading />}
      <HoldemPageWrapper>
        <HeaderWrap>
          <HoldemTitleBox>
            <HoldemTitleIconBox>
              <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-holdem.png`} alt="" />
            </HoldemTitleIconBox>
            <HoldemTitleTextBox>{revolutionHoldemInfo.title}</HoldemTitleTextBox>
          </HoldemTitleBox>
        </HeaderWrap>
        <BannerWrap
          onClick={() => {
            holdemGameStartHandler()
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/img/holdem/mobile-holdem-banner.gif`} alt="" />
        </BannerWrap>
      </HoldemPageWrapper>
    </>
  )
}

export default MobileRevolutionHoldemPage

const HoldemPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0 5px;
`

const HeaderWrap = styled.div`
  width: 100%;
  padding: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
`

const HoldemTitleBox = styled.div`
  width: 100%;
  height: 26px;
`
const HoldemTitleIconBox = styled.div`
  float: left;

  img {
    width: auto;
    height: 24px;
  }
`

const HoldemTitleTextBox = styled.div`
  float: left;
  margin-left: 12px;
  margin-top: 1px;
  font-family: 'Malgun Gothic', dotum;
  color: #fff;
  font-size: 16px;
`

const BannerWrap = styled.div`
  width: 100%;
  display: block;

  cursor: pointer;

  img {
    width: 100%;
  }
`
