import React, { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { searchMainNoticeEventList } from '../../api/customCenter/customCenterApi'
import { onShowModalHandler } from '../../redux/modalSlice'
import isLogin from '../../utils/enums/authority'
import AuthorityAlertPopup from '../Popup/Error/AuthorityAlertPopup'
import { HermesMainText } from '../../utils/palette'
import CustomAlert from '../CustomAlert'

const MainPageNoticeEvent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [noticeList, setNoticeist] = useState([])
  const [eventList, setEventList] = useState([])

  useEffect(() => {
    searchMainNoticeEventList().then(res => {
      setNoticeist(res.data.noticeList)
      setEventList(res.data.eventList)
    })
  }, [])

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const { noteRedisSize, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuModalHandler = title => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    navigate(link)
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <AuthorityAlertPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <MainContentNoticeEventBox>
        <MainNoticeBox>
          <MainNoticeInnerBox>
            <MainNoticeEventHeaderBox>
              <MainNoticeEventHeaderInnerLeftBox>
                <span>
                  <MainNoticeEventHeaderInnerTitle onClick={() => onClickMenuModalHandler('NOTICE_EVENT')}>
                    공지사항
                  </MainNoticeEventHeaderInnerTitle>
                  <MainNoticeEventHeaderInnerTitleEn onClick={() => onClickMenuModalHandler('NOTICE_EVENT')}>
                    <strong>NOTICE</strong>
                  </MainNoticeEventHeaderInnerTitleEn>
                </span>
              </MainNoticeEventHeaderInnerLeftBox>
              <MainNoticeEventHeaderInnerRightBox onClick={() => onClickMenuModalHandler('NOTICE_EVENT')}>
                <MainNoticeEventHeaderInnerMore>MORE</MainNoticeEventHeaderInnerMore>
              </MainNoticeEventHeaderInnerRightBox>
            </MainNoticeEventHeaderBox>

            <MainNoticeEventContentBox>
              <MainNoticeEventContentInnterBox className="sidebar-container">
                {noticeList.length !== 0 ? (
                  <>
                    {noticeList.map(item => {
                      return (
                        <MainNoticeEventContentInnterItem onClick={() => onClickMenuModalHandler('NOTICE_EVENT')}>
                          <MainNoticeEventContentInnterItemIconBox>
                            <img
                              style={{ width: '24px', height: '24px' }}
                              src={`${process.env.PUBLIC_URL}/img/main/main-notice.png`}
                              alt=""
                            />
                          </MainNoticeEventContentInnterItemIconBox>
                          <MainNoticeEventContentInnterItemTextBox>
                            {item?.title}
                          </MainNoticeEventContentInnterItemTextBox>
                          <MainNoticeEventContentInnterItemArrowIconBox>
                            <CustomFaArrowRight />
                          </MainNoticeEventContentInnterItemArrowIconBox>
                        </MainNoticeEventContentInnterItem>
                      )
                    })}
                  </>
                ) : (
                  <MainNoticeEventContentEmptyItem>
                    <span>현재 게시글이 없습니다.</span>
                  </MainNoticeEventContentEmptyItem>
                )}
              </MainNoticeEventContentInnterBox>
            </MainNoticeEventContentBox>
          </MainNoticeInnerBox>
        </MainNoticeBox>
        <MainEventBox>
          <MainEventInnerBox>
            <MainNoticeEventHeaderBox>
              <MainNoticeEventHeaderInnerLeftBox>
                <span>
                  <MainNoticeEventHeaderInnerTitle
                    onClick={e => {
                      onClickMenuHandler('/customcenter/event')
                    }}
                  >
                    이벤트존
                  </MainNoticeEventHeaderInnerTitle>
                  <MainNoticeEventHeaderInnerTitleEn
                    onClick={e => {
                      onClickMenuHandler('/customcenter/event')
                    }}
                  >
                    <strong>EVENT ZONE</strong>
                  </MainNoticeEventHeaderInnerTitleEn>
                </span>
              </MainNoticeEventHeaderInnerLeftBox>
              <MainNoticeEventHeaderInnerRightBox>
                <MainNoticeEventHeaderInnerMore
                  onClick={e => {
                    onClickMenuHandler('/customcenter/event')
                  }}
                >
                  MORE
                </MainNoticeEventHeaderInnerMore>
              </MainNoticeEventHeaderInnerRightBox>
            </MainNoticeEventHeaderBox>

            <MainNoticeEventContentBox>
              <MainNoticeEventContentInnterBox className="sidebar-container">
                {eventList.length !== 0 ? (
                  <>
                    {eventList.map((item, index) => {
                      return (
                        <MainNoticeEventContentInnterItem
                          onClick={e => {
                            onClickMenuHandler('/customcenter/event')
                          }}
                        >
                          <MainNoticeEventContentInnterItemIconBox>
                            <img src={item?.imgUrl} alt="" />
                          </MainNoticeEventContentInnterItemIconBox>
                          <MainNoticeEventContentInnterItemTextBox>
                            {index < 9 ? (
                              <>
                                <span style={{ color: `${HermesMainText}` }}>EVENT.0{index + 1}</span>&nbsp;{' '}
                                {item?.title}
                              </>
                            ) : (
                              <>
                                <span style={{ color: `${HermesMainText}` }}>EVENT.{index + 1}</span>&nbsp;{' '}
                                {item?.title}
                              </>
                            )}
                          </MainNoticeEventContentInnterItemTextBox>
                          <MainNoticeEventContentInnterItemArrowIconBox>
                            <CustomFaArrowRight />
                          </MainNoticeEventContentInnterItemArrowIconBox>
                        </MainNoticeEventContentInnterItem>
                      )
                    })}
                  </>
                ) : (
                  <MainNoticeEventContentEmptyItem>
                    <span>현재 게시글이 없습니다.</span>
                  </MainNoticeEventContentEmptyItem>
                )}
              </MainNoticeEventContentInnterBox>
            </MainNoticeEventContentBox>
          </MainEventInnerBox>
        </MainEventBox>
      </MainContentNoticeEventBox>
    </>
  )
}

export default MainPageNoticeEvent

const MainContentNoticeEventBox = styled.div`
  width: 100%;
  display: flex;
`

const MainNoticeBox = styled.div`
  width: 50%;
  padding: 24px 12px 0 0;
`

const MainNoticeInnerBox = styled.div`
  border-radius: 6px;
  background: #16171a;
`

const MainNoticeEventHeaderBox = styled.div`
  padding: 16px;
  line-height: 26px;
  height: 64px;
  display: flex;
`

const MainNoticeEventHeaderInnerLeftBox = styled.div`
  width: 75%;
`

const MainNoticeEventHeaderInnerTitle = styled.span`
  cursor: pointer;
  line-height: 26px;
  font-size: 14px;
  color: #fff;
`
const MainNoticeEventHeaderInnerTitleEn = styled.span`
  color: ${HermesMainText};
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
  line-height: 26px;
  cursor: pointer;
`
const MainNoticeEventHeaderInnerRightBox = styled.div`
  width: 25%;
  text-align: right;
`

const MainNoticeEventHeaderInnerMore = styled.span`
  color: ${HermesMainText};
  width: 25%;
  cursor: pointer;
`

const MainNoticeEventContentBox = styled.div`
  position: relative;
  overflow: hidden;
`

const MainNoticeEventContentInnterBox = styled.div`
  width: 100%;
  height: 264px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0; /* 스크롤 바 너비를 0으로 설정하여 숨김 */
  }
`

const MainNoticeEventContentInnterItem = styled.div`
  padding: 0 16px;
  background: #141517;
  border-bottom: 1px solid #242424;
  height: 48px;
  line-height: 16px;
  transition: background-color 0.1s ease-out 0s;
  display: flex;
  width: 100%;
  cursor: pointer;

  font-family: 'Malgun Gothic', dotum;
  &:hover {
    background-color: #252526;
  }
`

const MainNoticeEventContentInnterItemIconBox = styled.div`
  display: flex;
  align-items: top;
  float: left;

  margin-right: 24px;

  img {
    width: 40px;
    height: 22px;
  }
`

const MainNoticeEventContentInnterItemTextBox = styled.div`
  display: flex;
  color: #fff;
  align-items: top;
  float: left;
  word-break: keep-all;
  padding-right: 24px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
`

const MainNoticeEventContentInnterItemArrowIconBox = styled.div`
  display: flex;
  align-items: top;
  float: right;
`

const CustomFaArrowRight = styled(FaArrowRight)`
  color: rgba(255, 255, 255, 0.8);
`

const MainEventBox = styled.div`
  width: 50%;
  padding: 24px 0 0 12px;
`

const MainEventInnerBox = styled.div`
  border-radius: 6px;
  background: #16171a;
`

const MainNoticeEventContentEmptyItem = styled.div`
  padding: 0 16px;
  background: #141517;
  border-bottom: 1px solid #242424;
  height: 100%;
  line-height: 16px;
  transition: background-color 0.1s ease-out 0s;
  display: flex;
  width: 100%;
  cursor: pointer;
  padding-top: 100px;
  justify-content: center;
  color: #fff;

  font-family: 'Malgun Gothic', dotum;
  &:hover {
    background-color: #252526;
  }

  span {
    line-height: 16px;
  }
`
