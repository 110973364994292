import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { deleteMemberRevolutionBettingLog, searchMemberRevolutionBettingLog } from '../../../api/game/holdemApi'
import CustomLoading from '../../../containers/CustomLoading'
import CustomPagination from '../../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { HoldemBettingTypeEnums } from '../../../utils/enums/Holdem/HoldemEnums'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { CustomDeleteButton } from '../../mobileCasinoGame/MobileLiveCasino'
import { CustomButtonInfos } from '../MobileMiniGameBettingHistory'
import HoldemBettingLogRadio from './MobileHoldemBettingLogRadio'

const MobileHoldemBettingLog = ({ menuType }) => {
  const [loading, setLoading] = useState(false)

  const bettingHistoryWrapRef = useRef(null)

  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    page: 0,
    size: 10,
  })

  const [totalElement, setTotalElement] = useState(0)
  const [holdemBettingLogList, setHoldemBettingLogList] = useState([])

  const fetchRevolutionHoldemBettingLog = async () => {
    setLoading(true)

    await searchMemberRevolutionBettingLog(params)
      .then(res => {
        setTotalElement(res.data.totalElements)
        setHoldemBettingLogList(res.data.holdemBettingLogList)
      })
      .catch(error => {
        // console.log('dd')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (menuType !== 'BETTING_LOG') return
    fetchRevolutionHoldemBettingLog()
  }, [menuType, params])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 10))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))

    if (bettingHistoryWrapRef.current) {
      bettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (loading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setLoading(true)
      deleteMemberRevolutionBettingLog({
        holdemBettingLogKeyList: checkRadio,
      })
        .then(res => {
          alert('삭제가 완료 되었습니다.')
        })
        .catch(error => {
          alert('삭제가 실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
          setCheckRadio([])
          fetchRevolutionHoldemBettingLog()
        })
    }
  }

  return (
    <>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart />
        </SportsGamePageLoadingOverlay>
      )}
      {holdemBettingLogList.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          홀덤 배팅내역이 없습니다.
        </NoContentDiv>
      ) : (
        <>
          {holdemBettingLogList.map(item => {
            return (
              <BettingHistoryTable key={item.holdemBettingLogKey}>
                <BettingHistoryTopHeaderTr>
                  <HoldemBettingLogRadio
                    holdemBettingLogKey={item.holdemBettingLogKey}
                    bettingResult={item.bettingResult}
                    checkRadio={checkRadio}
                    setCheckRadio={setCheckRadio}
                  />
                  배팅시간 : {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')} &nbsp;&nbsp; 배팅번호 : {item.logId}{' '}
                  &nbsp;&nbsp; 배팅결과 :{' '}
                  {
                    {
                      ING: (
                        <button
                          type="button"
                          style={{
                            padding: '2px 10px',
                            background: 'green',
                            color: 'white',
                            fontWeight: '900',
                            border: 'none',
                          }}
                        >
                          {HoldemBettingTypeEnums[item.bettingResult]}
                        </button>
                      ),

                      WIN: (
                        <button
                          type="button"
                          style={{
                            padding: '2px 10px',
                            background: 'blue',
                            color: 'white',
                            fontWeight: '900',
                            border: 'none',
                          }}
                        >
                          {HoldemBettingTypeEnums[item.bettingResult]}
                        </button>
                      ),
                      LOSE: (
                        <button
                          type="button"
                          style={{
                            padding: '2px 10px',
                            background: 'red',
                            color: 'white',
                            fontWeight: '900',
                            border: 'none',
                          }}
                        >
                          {HoldemBettingTypeEnums[item.bettingResult]}
                        </button>
                      ),
                    }[item.bettingResult]
                  }
                </BettingHistoryTopHeaderTr>
                <BettingHistoryHeaderTr>
                  <th style={{ width: '15%' }}>방번호</th>
                  <th style={{ width: '15%' }}>블라인드</th>
                  <th style={{ width: '15%' }}>배팅(P)</th>
                  <th style={{ width: '15%' }}>당첨(P)</th>
                  <th style={{ width: '20%' }}>배팅(KRW)</th>
                  <th style={{ width: '20%' }}>당첨(KRW)</th>
                </BettingHistoryHeaderTr>
                <BettingHistoryTableBody>
                  <BettingHistoryTableTr>
                    <BettingHistoryContentBox>
                      <BettingHistoryContentItemBox width="15">{item.roomId}</BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="15">{item.blind || '-'}</BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="15">
                        {item.bettingAmount?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox
                        width="15"
                        style={{ color: `${HermesMainText}`, fontWeight: '600' }}
                      >
                        {item.winningAmount?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="20">
                        {item.fiatBettingAmount?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox
                        width="20"
                        style={{ color: `${HermesMainText}`, fontWeight: '600' }}
                      >
                        {item.fiatWinningAmount?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                    </BettingHistoryContentBox>
                  </BettingHistoryTableTr>
                </BettingHistoryTableBody>
              </BettingHistoryTable>
            )
          })}
        </>
      )}

      {totalElement > 0 && (
        <ListWrap>
          <CustomDeleteButton
            onClick={() => {
              setCheckRadio(
                holdemBettingLogList
                  .filter(value => value.bettingResult !== 'ING')
                  .map(value => value.holdemBettingLogKey),
              )
            }}
            type="button"
          >
            <CustomButtonInfos>전체선택</CustomButtonInfos>
          </CustomDeleteButton>
          <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
            <CustomButtonInfos>삭제하기</CustomButtonInfos>
          </CustomDeleteButton>
        </ListWrap>
      )}

      {totalElement > 0 && <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />}
    </>
  )
}

export default MobileHoldemBettingLog

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: rgba(48, 48, 48, 1);
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  align-items: center;
  font-weight: 500;
`

const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  gap: 2px;
  font-weight: 400;
  background: black;
  // border-left: solid 1px gray;
  // border-right: solid 1px gray;
  // border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-right: 10px;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    // border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  // border-left: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: #b88907;
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`
