import { left, marginRight } from '@/utils/directions'
import { HermesMainBorder, colorAdditional, HermesMainText } from '@/utils/palette'
import { Nav } from 'react-bootstrap'
import styled from 'styled-components'

// Tab content - pane 내용을 감싸는 태그
export const TabPaneInnerWrap = styled.div``

export const TabsWrap = styled.div`
  text-align: ${left};
`

export const NavLink = styled(Nav.Link)`
  height: 54px;
  ${marginRight}: 0px;
  transition: background-color 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-color: transparent !important;
    border-color: transparent !important;
    border-bottom-color: none !important;
    color: ${HermesMainText};
  }

  &.active,
  &.active:focus,
  &.active:hover {
    background-color: transparent !important;
    border: none;
    color: ${HermesMainText};
  }
`

export const NavItem = styled(Nav.Item)`
  &.disabled ${NavLink} {
  }
`

export const Tabs = styled.div`
  .tab-pane {
    // padding-top: 15px;

    p:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`

export const BorderedBottomTabs = styled(Tabs)`
  // border-top: 1px solid #575757;
  // background: rgb(33, 35, 38) !important;
  .nav-tabs {
    border-bottom: none;
  }
  ${NavLink} {
    // border-bottom: 1px solid transparent !important;
    border-radius: 0;
  }

  ${NavLink}.active {
    &,
    &:focus,
    &:hover {
      border-radius: 0;
      border-bottom: none !important;
    }
  }
`
