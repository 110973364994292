import { BorderedBottomTabs, NavItem, NavLink, TabPaneInnerWrap, TabsWrap } from '@/shared/components/Tabs'
import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainText } from '../../../utils/palette'

// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const CrownTabs = ({ activeKey }) => {
  const navigate = useNavigate()

  return (
    <CustomBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="1"
                onClick={() => {
                  navigate('/minigame/crown/sutda')
                }}
              >
                섯다
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="2"
                onClick={() => {
                  navigate('/minigame/crown/baccarat')
                }}
              >
                바카라
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="3"
                onClick={() => {
                  navigate('/minigame/crown/odd-even')
                }}
              >
                홀짝
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="4"
                onClick={() => {
                  navigate('/minigame/crown/nine-ball')
                }}
              >
                나인볼
              </MiniGameNavLink>
            </MiniGameNavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </CustomBottomTabs>
  )
}
export default CrownTabs

const CustomBottomTabs = styled(BorderedBottomTabs)`
  border-top: none !important;
  background: rgb(33, 35, 38) !important;
`

const MiniGameNavLink = styled(NavLink)`
  &.active,
  &.active:focus,
  &.active:hover {
    // background-color: #636260 !important;
    color: ${HermesMainText} !important;
    font-weight: 600;
  }
`

const MiniGameNavItem = styled(NavItem)`
  width: 11.11%;
  text-align: center;
  // border: solid 1px red;
`

const NtryGameWrap = styled.div`
  height: 30px;
  position: relative;
  width: 100%;
`

export const TableBettingInfo = styled.div`
  width: 30%;
  position: left;
  color: white;
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`
