import { createGlobalStyle } from 'styled-components'

import { reset } from 'styled-reset'
import circle from '../images/circle.svg'
import {
  colorAccent,
  colorAdditional,
  colorBackgroundBody,
  colorBlue,
  colorBlueHover,
  colorMainBody,
  colorMainBodyBackground,
  colorRed,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '../utils/palette'

/**
 * @see : https://spring-in-wonderland.tistory.com/15 [스크롤]
 */

const GlobalStyles = createGlobalStyle`
  ${reset} 

  // @font-face {
  //   font-family: 'Noto Sans KR';
  //   font-weight: 300;
  //   src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('woff2'),
  //     url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('woff'),
  //     url(${process.env.PUBLIC_URL}/font/NotoSansKR-Light.otf) format('truetype');
  // }

  // @font-face {
  //   font-family: 'Noto Sans KR';
  //   font-weight: 400;
  //   src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('woff2'),
  //     url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('woff'),
  //     url(${process.env.PUBLIC_URL}/font/NotoSansKR-Regular.otf) format('truetype');
  // }
  // @font-face {
  //   font-family: 'Noto Sans KR';
  //   font-weight: 500;
  //   src: url(${process.env.PUBLIC_URL}/font/NotoSansKR-Medium.otf) format('woff2');
  // }

  @font-face {
    font-family: 'Noto Sans KR';
    src: url(${process.env.PUBLIC_URL}/font/Arial.woff) format('woff');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    src: url(${process.env.PUBLIC_URL}/font/Dotum.woff) format('woff');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    src: url(${process.env.PUBLIC_URL}/font/Gulim.woff) format('woff');
  }
  @font-face {
    font-family: 'Noto Sans KR';
    src: url(${process.env.PUBLIC_URL}/font/OpenSans-Regular.woff) format('woff');
  }

  @font-face {
    font-family: 'Malgun Gothic';
    src: url(${process.env.PUBLIC_URL}/font/Malgun Gothic.woff) format('woff');
  }



  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
  }

  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-text-size-adjust: 100%;
  }

  body {
  
    font-family: dotum,gulim,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    font-size: 12px;
    width: 100%;
    overflow: auto !important;
    height: 100vh;
    line-height: 1.42857143;
    background: ${colorMainBodyBackground};
    color: ${colorMainBody}
  }

  a {
    text-decoration: none;
  }

    strong {
    font-weight:600 !important;
  }


  h1 {
    font-size: 2em; /* 원하는 크기로 조정 */
  }

  h2 {
    font-size: 1.5em; /* 원하는 크기로 조정 */
  }

  h3 {
    font-size: 1.17em; /* 원하는 크기로 조정 */
  }

  h4 {
    font-size: 1em; /* 원하는 크기로 조정 */
  }

  h5 {
    font-size: 0.83em; /* 원하는 크기로 조정 */
  }

  h6 {
    font-size: 0.67em; /* 원하는 크기로 조정 */
  }
`

export default GlobalStyles
