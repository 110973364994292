import React from 'react'
import styled from 'styled-components'
import { HermesMainText } from '../../utils/palette'

const MobileFooter = () => {
  return (
    <FooterBottomWrap>
      <FooterBottomInfoWrap>
        <SolutionWrap
          onClick={() => {
            window.open('https://t.me/tysonsol')
          }}
        >
          <img src={`${process.env.PUBLIC_URL}/img/main/cart_logo.png`} alt="" />
        </SolutionWrap>
        <FooterBottomInfo>
          Gaming license provided under the law of Malta - MGA/B2C/543/2018 issued on the 12/01/2022.
        </FooterBottomInfo>
        <FooterBottomInfo>
          COPYRIGHT © <span style={{ color: `${HermesMainText}` }}>TYSON</span> ALL RIGHTS RESERVED.
        </FooterBottomInfo>
      </FooterBottomInfoWrap>
    </FooterBottomWrap>
  )
}

export default MobileFooter

const SolutionWrap = styled.div`
  width: 100%;
  margin-top: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  // background: #424242;
  color: white;
  font-family: dotum, gulim, Arial, sans-serif !important;
`

// Footer bottom
const FooterBottomWrap = styled.div`
  box-sizing: border-box;
  background: rgb(17, 19, 20);
  // border: solid 2px blue;
  /* 라인컬러 */
  z-index: 115;
  // border-top: 2px solid #880000;
  padding: 0px 10px;
  padding-bottom: 10px;
`

const FooterBottomInfoWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  word-break: break-word;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FooterBottomInfo = styled.span`
  font-style: normal;
  font-weight: 400;
  color: rgb(116, 116, 116);
`
