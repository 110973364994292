import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { searchTokenGameUrl } from '../../../api/game/miniGameApi'
import { HermesMainBorder } from '../../../utils/palette'

const TokenGameHiloPage = () => {
  // false : 비정상
  const [status, setStatus] = useState(false)
  const [gameUrl, setGameUrl] = useState('')

  useEffect(() => {
    searchTokenGameUrl({
      gameType: 'HILO',
    })
      .then(res => {
        if (res.data.status) {
          setStatus(true)
          setGameUrl(res.data.gameUrl)
        }
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode ?? ''

        switch (errorCode) {
          case 'MINIGAME-1005':
            alert('점검중인 게임입니다.')
            break
          default:
            alert('잠시후 다시 이용해주세요.')
            break
        }
      })
  }, [])

  return (
    <MoneyDepositWrap>
      {/* <MiniGameTabs activeKey="2" />
      <TokenGameTabs activeKey="2" /> */}
      <TokenGamePageWrap>
        <MiniGameContentWrap>
          {!gameUrl || !status ? (
            <motion.div
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0.5, scale: 0.1 }}
              transition={{ duration: 0.6 }}
              style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            >
              <IframeOverlay>
                <HeaderLogoImg src={`${process.env.PUBLIC_URL}/img/main/iframeLogo.png`} alt="" />

                {!status && <span>점검중</span>}
              </IframeOverlay>
            </motion.div>
          ) : (
            <MiniGameIframe src={gameUrl} scrolling="no" frameBorder="0" allowFullScreen="true" />
          )}
        </MiniGameContentWrap>
      </TokenGamePageWrap>
    </MoneyDepositWrap>
  )
}

export default TokenGameHiloPage

const MoneyDepositWrap = styled.div`
  background: #000000;
  //   border: 2px solid ${HermesMainBorder};
  height: 100%;
  padding-bottom: 10px;

  min-height: 830px;
`

const TokenGamePageWrap = styled.div`
  background: #000000;
`

const MiniGameContentWrap = styled.div`
  width: 100%;
  height: 735px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 15px;
`

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  font-size: 40px;
  color: white;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  span {
    position: absolute;
    flex-direction: column;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
  }

  svg {
    /* 아이콘 스타일 */
    position: absolute;
    flex-direction: column;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: 90px;
    height: 90px;
  }
`

const IframeOverlay = styled.div`
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  span {
    display: block;
    width: max-content;
    height: 35px;
    line-height: 30px;
    font-size: 25px;
    text-align: center;
    margin-top: 10px;
  }
`

const HeaderLogoImg = styled.img`
  width: 270px;
  height: 188px;
`

const MiniGameIframe = styled.iframe`
  width: 100%;
  max-width: 1000px;
  height: 800px;
  margin: 0px;
  z-index: 1;
  border: none;
`
