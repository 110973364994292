import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { HiOutlineRefresh } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { bettingForMiniGame } from '../../api/game/miniGameApi'
import { withdrawCasinoMoney } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { setCloseHandler, setMiniGameCompleteFlag, setResetData } from '../../redux/miniGameBettingSlice'
import { onShowModalHandler } from '../../redux/modalSlice'
import { handleDeleteAuthentication } from '../../socketio/userAuthSocketIOHandler'
import isLogin from '../../utils/enums/authority'
import { MiniGameTypeEnums, PowerBallTypeEnums } from '../../utils/enums/MiniGame/PowerBallEnums'
import { HermesMainBorder, HermesMainHover, HermesMainHover2, HermesMainText } from '../../utils/palette'
import { decodeAccessToken, deleteAccessToken, getAccessToken } from '../../utils/token'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomAlert from '../CustomAlert'
import CustomLoading from '../CustomLoading'
import BettingCartFormField from './BettingCartFormField'

const MiniGameBettingCartSidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loginCheck, setLoginCheck] = useState(false)

  useEffect(() => {
    setLoginCheck(isLogin())
  }, [isLogin()])

  // redux 사용
  const dispatch = useDispatch()

  const {
    holdingMoney,
    miniGameMaxBetting,
    miniGameMinBetting,
    miniGameMaxWinning,
    memberTotalGameMoney,
    userId,
    memberLevel,
    noteRedisSize,
    oneToOneRedisSize,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      memberLevel: memberInfo.depositLevel,
      miniGameMaxBetting: memberInfo.miniGameBettingInfo.miniGameMaxBetting,
      miniGameMinBetting: memberInfo.miniGameBettingInfo.miniGameMinBetting,
      miniGameMaxWinning: memberInfo.miniGameBettingInfo.miniGameMaxWinning,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const {
    miniGameType,
    miniGameRound,
    bettingCloseFlag,
    bettingCompleteFlag,
    bettingMoney,
    miniGameBettingPer,
    miniGameBettingType,
    bettingCartNum,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameRound: miniGameBetting.miniGameRound,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
      bettingMoney: miniGameBetting.bettingMoney,
      miniGameBettingPer: miniGameBetting.miniGameBettingPer,
      miniGameBettingType: miniGameBetting.miniGameBettingType,
      bettingCartNum: miniGameBetting.miniGameBettingPer && 1,
    }
  })
  const [inputBettingMoney, setInputBettingMoney] = useState(Number(bettingMoney) || 0)
  const [expectMoney, setExpectMoney] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  const onClickCloseHandler = event => {
    setExpectMoney(0)
    setInputBettingMoney(0)
    dispatch(setCloseHandler())
  }

  // 배팅 완료 및 초기화 시
  useEffect(() => {
    setExpectMoney(0)
    setInputBettingMoney(0)
  }, [miniGameBettingPer])

  useEffect(() => {
    dispatch(setResetData())
    setExpectMoney(0)
    setInputBettingMoney(0)
  }, [miniGameType])

  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)
    if (!miniGameBettingPer || miniGameBettingPer === 0 || inputBettingMoney === 0) {
      setApiFlag(false)
      return
    }

    if (Number(holdingMoney) < Number(bettingMoney?.toString().replaceAll(',', ''))) {
      alert('지갑머니가 부족합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney?.toString().replaceAll(',', '')) < Number(miniGameMinBetting)) {
      alert('최소 배팅금액 이상으로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(inputBettingMoney) > Number(miniGameMaxBetting)) {
      alert('최대 배팅금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (Number(expectMoney?.toString().replaceAll(',', '')) > Number(miniGameMaxWinning)) {
      alert('최대 당첨금액 이하로 가능 합니다.')
      setApiFlag(false)
      return
    }

    if (window.confirm('배팅하시겠습니까?')) {
      const body = {
        miniGameType,
        miniGameBettingType,
        bettingAmount: inputBettingMoney?.toString().replaceAll(',', ''),
        expectMoney: expectMoney?.toString().replaceAll(',', ''),
        bettingPer: miniGameBettingPer,
        bettingAt: moment().tz('Asia/Seoul').format(),
        gameRound: miniGameRound,
      }

      bettingForMiniGame(body)
        .then(res => {
          alert('배팅이 정상적으로 이루어졌습니다.')
          dispatch(setMiniGameCompleteFlag(!bettingCompleteFlag))
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          switch (errorCode) {
            case 'SYSTEM-1017':
              alert(`${message}`)
              break
            case 'SYSTEM-1018':
              alert(`${message}`)
              break
            case 'MINIGAME-1003':
              alert(`${message}`)
              break
            case 'MINIGAME-1008':
              alert(`${message}`)
              break
            case 'MINIGAME-1009':
              alert(`${message}`)
              break
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('배팅이 실패했습니다.')
          }
        })
        .finally(() => {
          setApiFlag(false)
          onClickCloseHandler()
          dispatch(setResetData())
          dispatch(fetchMemberInfoAction())
        })
    } else {
      setApiFlag(false)
    }
  }

  useEffect(() => {
    const inputRealMoney = Number(inputBettingMoney?.toString().replaceAll(',', '')) || 0 // 입력된 배팅머니
    let resultBettingMoney = inputRealMoney
    let resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)

    if (inputRealMoney > miniGameMaxBetting) {
      // 입력된 배팅머니가, 최대 배팅머니 보다 클 때
      resultBettingMoney = miniGameMaxBetting
      // 당첨금액이 최대 당첨 금액보다 큰 경우 => 300000 320000 -> 299999 배팅머니를 바꿔줘야해, 배팅비율은 고정이고
      if (resultExpectMoney > miniGameMaxWinning) {
        resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
        resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
      }
    }

    if (resultExpectMoney > miniGameMaxWinning) {
      // 입력된 배팅머니 * 비율이 최대 금액을 넘어 설때,
      resultBettingMoney = Math.floor(miniGameMaxWinning / miniGameBettingPer) // 비율에 따른 최대 배팅금액
      resultExpectMoney = Math.floor((resultBettingMoney * Math.floor(miniGameBettingPer * 100)) / 100)
    }

    setInputBettingMoney(resultBettingMoney.toString().replace(commonReg2, ','))

    setExpectMoney(resultExpectMoney.toString().replace(commonReg2, ','))
  }, [inputBettingMoney, miniGameBettingPer])

  const onClickMoneyHandler = amountText => {
    const textNum = amountText || holdingMoney

    setInputBettingMoney(prev => {
      const prevprevValue = prev ? prev.toString().replaceAll(',', '') : ''
      const prevValue = prevprevValue.startsWith('0') ? prevprevValue.slice(1) : prevprevValue
      if ((prevValue === '' || prevValue === '0') && (textNum === '0' || textNum === '00' || textNum === '000')) {
        return prevValue?.toString().replace(commonReg2, ',')
      }

      if (textNum.length > 3) {
        // UI키패드 버튼으로 5만 ~ 200만 까지 눌렀을때
        if (prevValue === '' || prevValue === '0') {
          return textNum?.toString().replace(commonReg2, ',')
        }
        // UI키패드 버튼으로 0,00,000 , 1~9까지 눌렀을때
        return (Number(prevValue) + Number(textNum))?.toString().replace(commonReg2, ',')
      }
      return (prevValue + textNum)?.toString().replace(commonReg2, ',')
    })
  }

  const onClickResetMoneyHandler = event => {
    setInputBettingMoney(0)
  }

  useEffect(() => {
    if (miniGameRound && miniGameBettingPer && bettingCloseFlag) {
      dispatch(setResetData())
    }
  }, [bettingCloseFlag, bettingCompleteFlag, miniGameRound, miniGameBettingPer, miniGameType])

  const getLevelIconUrl = () => {
    switch (true) {
      case memberLevel === 'ONE':
        return `${process.env.PUBLIC_URL}/img/level/level1.png`
      case memberLevel === 'TWO':
        return `${process.env.PUBLIC_URL}/img/level/level2.png`
      case memberLevel === 'THREE':
        return `${process.env.PUBLIC_URL}/img/level/level3.png`
      case memberLevel === 'FOUR':
        return `${process.env.PUBLIC_URL}/img/level/level4.png`
      case memberLevel === 'FIVE':
        return `${process.env.PUBLIC_URL}/img/level/level5.png`
      case memberLevel === 'VIP1':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP1.png`
      case memberLevel === 'VIP2':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP2.png`
      case memberLevel === 'VIP3':
        return `${process.env.PUBLIC_URL}/img/level/levelVIP3.png`
      default:
        return ''
    }
  }

  const onClickSignOutHandler = () => {
    handleDeleteAuthentication({
      accessToken: getAccessToken(),
    }).then(res => {
      deleteAccessToken()
      window.location.href = '/'
    })
  }

  const [showBettingCart, setShowBettingCart] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [isValidPath, setIsValidPath] = useState(false)

  useEffect(() => {
    if (location.pathname.includes('/minigame')) {
      setIsValidPath(true)
    } else {
      setIsValidPath(false)
    }
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      const resultMatches = window.matchMedia('(max-width: 1440px)').matches
      if (resultMatches) {
        setIsSmallScreen(true)
      } else {
        setIsSmallScreen(false)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const toggleIconCart = () => {
    setShowBettingCart(prevShowBettingCart => !prevShowBettingCart)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuHandler = title => {
    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  const [alertPopupShow, setAlertPopupShow] = useState(false)
  // 경고창 용 팝업
  const [alertPopupMessage, setAlertPopupMessage] = useState('')

  const onCloseAlertPopup = () => {
    setAlertPopupShow(false)
    setAlertPopupMessage('')
  }

  const onChangeAlertPopupMessageHandler = text => {
    setAlertPopupShow(true)
    setAlertPopupMessage(text)
  }

  const [moneyExchangeLoading, setMoneyExchangeLoading] = useState(false)

  const onClickCasinoMoneyWithdrawHandler = () => {
    if (moneyExchangeLoading) {
      onChangeAlertPopupMessageHandler('카지노 머니 회수 진행중입니다. 잠시만 기다려주세요.')
      return
    }
    if (memberTotalGameMoney <= 0) return

    setMoneyExchangeLoading(true)

    withdrawCasinoMoney()
      .then(res => {
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'GAME-1014':
            onChangeAlertPopupMessageHandler('카지노 머니 동기화중입니다. \n잠시후에 다시 시도해주세요.')
            break
          case 'ASSET-7001':
            onChangeAlertPopupMessageHandler('잠시후 다시 시도해주세요.')
            break
          default:
            onChangeAlertPopupMessageHandler('처리 실패했습니다.')
        }
      })
      .finally(() => {
        setMoneyExchangeLoading(false)
      })
  }

  return (
    <>
      {apiFlag && <CustomLoading />}
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}

      {alertPopupShow && <CustomAlert info={alertPopupMessage} onClickCloseHandler={onCloseAlertPopup} />}

      {isValidPath && isSmallScreen && !showBettingCart && (
        <SporteBettingCartWrap onClick={toggleIconCart}>
          {bettingCartNum > 0 && (
            <>
              <img src={`${process.env.PUBLIC_URL}/img/main/bettingCart3_num.png`} alt="" />
              <span>{bettingCartNum} </span>
            </>
          )}
        </SporteBettingCartWrap>
      )}

      {isValidPath && isSmallScreen && showBettingCart && (
        <BettingCartCloseWrap showBettingCart={showBettingCart} onClick={toggleIconCart} />
      )}

      <SidbarWrap showBettingCart={showBettingCart || !isSmallScreen}>
        <SlimScrollDiv>
          <SidbarMenuWrap className="sidebar-container">
            {loginCheck && (
              <LoginInfoBox isVisible={loginCheck}>
                <LoginInfoBoxHeader>My Profile</LoginInfoBoxHeader>
                <LoginInfoBoxContentWrap>
                  <LoginInfoBoxContentFirstBox>
                    <LoginInfoBoxContentFirstLevel $url={getLevelIconUrl()} />
                    <LoginInfoBoxContentFirstUserId>{userId}</LoginInfoBoxContentFirstUserId>
                    <LoginInfoBoxContentFirstNickName>
                      {decodeAccessToken()?.nickName || ''}
                    </LoginInfoBoxContentFirstNickName>
                  </LoginInfoBoxContentFirstBox>
                  <LoginInfoBoxContentSecondBox>
                    <div style={{ width: '50%' }}>
                      <LoginInfoBoxContentSecondItem>스포츠캐쉬</LoginInfoBoxContentSecondItem>
                      <div>{holdingMoney?.toString().replace(commonReg2, ',')}원</div>
                    </div>
                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        textAlign: 'right',
                        gap: '0px',
                        marginLeft: 'auto',
                        justifyContent: 'end',
                      }}
                    >
                      <CasinoMoneyWithdrawBox onClick={onClickCasinoMoneyWithdrawHandler}>
                        <CustomHiOutlineRefresh />
                        <span>회수</span>
                      </CasinoMoneyWithdrawBox>
                      <div>
                        <LoginInfoBoxContentSecondItem>카지노캐쉬</LoginInfoBoxContentSecondItem>
                        <div>{memberTotalGameMoney?.toString().replace(commonReg2, ',')}원</div>
                      </div>
                    </div>
                  </LoginInfoBoxContentSecondBox>

                  <LoginInfoBoxContentThirdBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('DEPOSIT')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-deposit.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>입금</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('WITHDRAWAL')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-withdrawal.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>출금</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('NOTE')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-note.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>쪽지</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemBox
                      onClick={() => {
                        onClickMenuHandler('ONETOONE')
                      }}
                    >
                      <LoginInfoBoxContentThirdItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-onetoone.png`} alt="" />
                      </LoginInfoBoxContentThirdItemIcon>
                      <LoginInfoBoxContentThirdItemText>문의</LoginInfoBoxContentThirdItemText>
                    </LoginInfoBoxContentThirdItemBox>
                    <LoginInfoBoxContentThirdItemLogout onClick={onClickSignOutHandler}>
                      <img src={`${process.env.PUBLIC_URL}/img/main/logout.png`} alt="" />
                    </LoginInfoBoxContentThirdItemLogout>
                  </LoginInfoBoxContentThirdBox>
                </LoginInfoBoxContentWrap>
              </LoginInfoBox>
            )}

            <BettingCartBox>
              <BettingCartHeaderBox>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <strong style={{ marginTop: '5px', fontWeight: '700', fontSize: '16px' }}>BET</strong>
                      </td>
                      <td style={{ width: '16.66666%', padding: '7px' }} />
                      <td style={{ fontSize: '14px' }}>
                        <BettingDeleteAll onClick={onClickCloseHandler}>Clear all</BettingDeleteAll>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </BettingCartHeaderBox>

              <BettingListWrap>
                {miniGameBettingType ? (
                  <BettingListInnerWrap>
                    <BettingListItemLeagueBox>{MiniGameTypeEnums[miniGameType]}</BettingListItemLeagueBox>
                    <BettingListItemTeamBox>
                      {MiniGameTypeEnums[miniGameType]} ({miniGameRound}) 회차
                    </BettingListItemTeamBox>
                    <BettingListItemMarketBox>
                      <span>{PowerBallTypeEnums[miniGameBettingType]}</span>
                    </BettingListItemMarketBox>
                    <BettingListItemDeleteBox
                      onClick={() => {
                        onClickCloseHandler()
                      }}
                    >
                      <CustomIoClose />
                    </BettingListItemDeleteBox>
                    <BettingListItemPointBox>
                      <div>
                        <div>{miniGameBettingPer}</div>
                      </div>
                    </BettingListItemPointBox>
                  </BettingListInnerWrap>
                ) : (
                  <BettingListInnerWrap>
                    <BettingListBox>
                      <BettingListEmptyBox>
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/img/sidebar/side_bets_empty.png`}
                            alt=""
                            style={{ marginBottom: '16px' }}
                          />
                        </div>
                        선택된 배팅내역이 없습니다.
                      </BettingListEmptyBox>
                    </BettingListBox>
                  </BettingListInnerWrap>
                )}
              </BettingListWrap>

              <Form
                onSubmit={onSubmit}
                initialValues={{
                  miniGameBettingPer,
                  inputBettingMoney,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <BettingAssetFormWrap onSubmit={handleSubmit}>
                    <BettingInputWrap>
                      <BettingInputInlineBlockWrap>
                        <BettingInputBox>
                          <BettingInputBoxLeftBox>
                            <BettingInputBoxLeftItem>배팅</BettingInputBoxLeftItem>
                            <BettingInputBoxLeftItemRefresh onClick={onClickResetMoneyHandler}>
                              <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-refresh.png`} alt="" />
                            </BettingInputBoxLeftItemRefresh>
                          </BettingInputBoxLeftBox>
                          <BettingInputBoxRightBox>
                            <Field
                              name="inputBettingMoney"
                              component={BettingCartFormField}
                              isAboveError={false}
                              wrapperClassName="betting-amount-input"
                            >
                              {({ input }) => (
                                <input
                                  type="text"
                                  name="inputBettingMoney"
                                  value={inputBettingMoney}
                                  placeholder={inputBettingMoney}
                                  onChange={e => {
                                    const value = e.target.value.trim()
                                    const numbersOnly = value.match(/\d+/g)?.join('')

                                    if (!numbersOnly) {
                                      setInputBettingMoney('0')
                                    } else {
                                      setInputBettingMoney(numbersOnly.replace(/^0+/, '').replace(commonReg2, ','))
                                    }

                                    input.onChange(e)
                                  }}
                                />
                              )}
                            </Field>
                          </BettingInputBoxRightBox>
                        </BettingInputBox>
                      </BettingInputInlineBlockWrap>
                    </BettingInputWrap>

                    <BettingButtonWrap>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ padding: '4px', paddingLeft: '0', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('5000')}>
                                오천
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('10000')}>
                                일만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('50000')}>
                                오만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('100000')}>
                                십만
                              </BettingButton>
                            </td>
                            <td style={{ padding: '4px', width: '20%', textAlign: 'center' }}>
                              <BettingButton type="button" onClick={() => onClickMoneyHandler('')}>
                                전액
                              </BettingButton>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </BettingButtonWrap>

                    <BettingTotalInfoWrap>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>총 배당:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameBettingPer || 0} 배</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>총 배당금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{expectMoney?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>최소 배팅금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameMinBetting?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>최대 배팅금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameMaxBetting?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ borderBottom: '1px solid rgb(46, 49, 54' }}>
                        <tbody>
                          <tr>
                            <BettingTotalInfoFirstTd>
                              <span>최대 당첨금액:</span>
                            </BettingTotalInfoFirstTd>
                            <BettingTotalInfoSecondTd>
                              <span>{miniGameMaxWinning?.toString().replace(commonReg2, ',') || 0} 원</span>
                            </BettingTotalInfoSecondTd>
                          </tr>
                        </tbody>
                      </table>
                    </BettingTotalInfoWrap>

                    <BettingBetButtonWrap>
                      <BettingBetButton type="submit">배팅하기</BettingBetButton>
                    </BettingBetButtonWrap>
                  </BettingAssetFormWrap>
                )}
              </Form>
            </BettingCartBox>

            <BettingBottomBox>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://타이슨.com')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/forever2.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://t.me/tysonsol')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/forever1.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
              <BettingBottomItem
                onClick={() => {
                  window.open('https://t.me/tysonsol')
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/img/main/cart_logo.png`} alt="" style={{ width: '100%' }} />
              </BettingBottomItem>
            </BettingBottomBox>
          </SidbarMenuWrap>
        </SlimScrollDiv>
      </SidbarWrap>
    </>
  )
}

export default MiniGameBettingCartSidebar

const CasinoMoneyWithdrawBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: max-content;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    color: ${HermesMainText};
    span {
      color: ${HermesMainText};
    }
  }
  span {
    font-size: 13px;
    font-weight: 500;
    margin-top: 2px;
    color: #b2b2b2;
    &:hover {
      color: ${HermesMainText} !important;
    }
  }
`

// 카지노 머니 회수 아이콘
const CustomHiOutlineRefresh = styled(HiOutlineRefresh)`
  width: 17px !important;
  height: auto;
  cursor: pointer;
  color: ${HermesMainText} !important;
`

const BettingCartCloseWrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: transparent;
  ${props =>
    props.showBettingCart
      ? `
    display: block;
`
      : `display: none;`}
`

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%); /* 시작 지점을 오른쪽 끝으로 설정 */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* 끝 지점을 오른쪽으로부터 이동 없음으로 설정 */
    opacity: 1;
  }
`

const SidbarWrap = styled.div`
  transition: all 0.5s ease-out 0s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  z-index: 1010;
  -webkit-transform: translateZ(0);
  margin-top: 65px;
  width: 299px;
  z-index: 1010;
  animation: ${slideInFromRight} 0.5s ease-out;

  ${props =>
    props.showBettingCart
      ? `
    display: block;
`
      : `display: none;`}
`

// 스크롤 용도
const SlimScrollDiv = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  height: 100%;
`

const SidbarMenuWrap = styled.div`
  height: 100%;
  overflow: auto;
  margin-bottom: 30px;
`

const LogInInfoBoxRight = keyframes`
  from {
    transform: translateX(100%); /* 시작 지점을 오른쪽 끝으로 설정 */
    opacity: 0;
  }
  to {
    transform: translateX(0); /* 끝 지점을 오른쪽으로부터 이동 없음으로 설정 */
    opacity: 1;
  }
`

const LoginInfoBox = styled.div`
  background: #1c1f21;
  border-radius: 6px;
  color: #fff;
  margin-bottom: 9px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  animation: ${LogInInfoBoxRight} 0.5s ease-out;
`

const LoginInfoBoxHeader = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-bottom: 10px;
  padding-right: 16px;
  padding-top: 10px;
`

const LoginInfoBoxContentWrap = styled.div`
  background: #1a1c1f;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`

const LoginInfoBoxContentFirstBox = styled.div`
  margin-top: 16px;
  display: flex;
  algin-items: center;
  position: relative;
`

const LoginInfoBoxContentFirstLevel = styled.div`
  background-size: 100% 100%;
  width: 50px;
  height: 50px;
  // background-image: url(${process.env.PUBLIC_URL}/img/level/level1.png);
  background-image: url(${props => (props.$url ? `${props.$url}` : '')});
  margin-right: 20px;
`

const LoginInfoBoxContentFirstUserId = styled.div`
  display: flex;
  align-items: center;
`

const LoginInfoBoxContentFirstNickName = styled.div`
  color: ${HermesMainHover2};
  margin-left: auto;
  display: flex;
  align-items: center;
`

const LoginInfoBoxContentSecondBox = styled.div`
  margin-top: 15px;
  display: flex;
`

const LoginInfoBoxContentSecondItem = styled.div`
  color: #b3b3b3;
  margin-bottom: 5px;
`

const LoginInfoBoxContentThirdBox = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
`

const LoginInfoBoxContentThirdItemBox = styled.div`
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  float: left;
  transition: background-color 0.1s ease-out 0s;
  width: 22%;

  &:hover {
    cursor: pointer;
    background-color: #313134;
  }
`

const LoginInfoBoxContentThirdItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  cursor: pointer;
  img {
    cursor: pointer;
    width: 20px;
  }
`
const LoginInfoBoxContentThirdItemText = styled.div`
  text-align: center;
  cursor: pointer;
`

const LoginInfoBoxContentThirdItemLogout = styled.div`
  width: 12%;
  height: 48px;
  border-radius: 6px;
  cursor: pointer;
  float: left;
  transition: background-color 0.1s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #313134;
  }

  img {
    cursor: pointer;
  }
`

// 배팅 카트
const BettingCartBox = styled.div`
  background: #1a1c1f;
  border-radius: 6px;
  margin-top: 8px;
  padding: 0;
  margin-bottom: 9px;
`

const BettingCartHeaderBox = styled.div`
  padding: 12px;
  border: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  table {
    width: 100%;
    padding: 0;
    margin: 0;
    display: table;
    border-color: gray;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;

    tbody tr td {
      vertical-align: middle !important;
      color: #fff;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
`

// 폴더 수
const BettingFolderNum = styled.span`
  font-family: arial;
  border-radius: 50%;

  background: ${HermesMainBorder};
  color: #000;
  opacity: 0.9;
  cursor: pointer;
  width: 22px;
  height: 22px;
  font-weight: 700;
  text-align: center;
  display: inline-block;
  min-width: 10px;
  padding-top: 4px;
  margin-right: 4px;
`

const BettingDeleteAll = styled.div`
  float: right;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.1s ease-out 0s;

  &:hover {
    color: ${HermesMainHover};
  }
`

const BettingListWrap = styled.div`
  box-sizing: border-box;
  padding: 0 8px;
`

const BettingListInnerWrap = styled.div`
  background-size: 100% 100%;
  background-image: url(${process.env.PUBLIC_URL}/img/sidebar/side-betlist.png);
  background-repeat: no-repeat;
  clear: both;
  text-align: left;
  margin: 1px 0 3px 1px;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-border-radius: 3px;
  width: 100%;
  height: 115px;
  position: relative;
`

const BettingListItemLeagueBox = styled.div`
  width: 210px;
  min-width: 210px;
  color: #848c99;
  padding: 16px;
  overflow: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
`

const BettingListItemTeamBox = styled.div`
  width: 210px;
  min-width: 210px;
  color: #fff;
  padding: 16px;
  overflow: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
`
const BettingListItemMarketBox = styled.div`
  width: 190px;
  min-width: 190px;
  color: #fff;
  padding: 0;
  margin: 16px 0 0 16px;
  overflow-x: hidden;
  float: left;
  font-family: 'Malgun Gothic', dotum;
  height: 17px;
  line-height: 17px;
  font-weight: 700;
`

const CustomIoClose = styled(IoClose)``

const BettingListItemDeleteBox = styled.div`
  color: #fff;
  font-size: 22px;
  padding: 2px 5px 5px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  border-radius: 6px;
  float: right;
  font-family: 'Malgun Gothic', dotum;
  line-height: 17px;
  z-index: 333;
`

const BettingListItemPointBox = styled.div`
  float: right;
  font-family: 'Malgun Gothic', dotum;
  line-height: 17px;
  color: ${HermesMainHover2};
  font-weight: 700;
  height: 17px;
  border-radius: 3px;
  margin: 10px 6px 0 0;

  div {
    border-radius: 4px;
    display: inline-block;
    padding: 5px 6px;

    div {
      display: block;
      float: left;
    }
  }
`

const BettingListBox = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  clear: both;
  width: 100%;
  height: 115px;
  text-align: left;
  margin: 1px 0 3px 1px;
  overflow-x: hidden;
  overflow-y: hidden;
`

const BettingListEmptyBox = styled.div`
  width: auto;
  color: #fff;
  display: block;
  text-align: center;
  margin: 0;
`

const BettingInputWrap = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
`

const BettingInputInlineBlockWrap = styled.div`
  display: inline-block;
  width: 100%;
`

const BettingInputBox = styled.div`
  margin-top: 16px;
  background: rgb(31, 33, 36);
  display: flex;
  padding: 16px 16px 12px 16px;
`

const BettingInputBoxLeftBox = styled.div`
  background: rgb(37, 40, 43);
  border-radius: 6px;
  color: ${HermesMainText} !important;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  padding: 2px;
`

const BettingInputBoxLeftItem = styled.div`
  padding: 0 12px;
`
const BettingInputBoxLeftItemRefresh = styled.div`
  padding: 6px;
  background: #2e3236;
  border-radius: 6px;
  cursor: pointer;

  img {
    cursor: pointer;
  }
`

const BettingInputBoxRightBox = styled.div`
  flex: 1 1 0%;
  padding-left: 12px;

  input {
    font-weight: 700;
    color: #fff;
    width: 100%;
    height: 40px;
    background: #181b1c;
    border: 1px solid #738087;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: center;
    border-radius: 8px;
  }
`

const BettingButtonWrap = styled.div`
  background: rgb(31, 33, 36);
  padding: 0 16px;

  table {
    background: #1f2124 !important;
    padding: 0;
    margin: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tbody tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
    }
  }
`

const BettingButton = styled.button`
  color: #fff;
  background: #25282b;

  border: none;
  box-shadow: none;
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;

  border-radius: 5px;
  width: 100%;
  padding: 8px;
  margin-right: 2px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;

  &:hover {
    background: #34383e;
  }
`

const BettingTotalInfoWrap = styled.div`
  background: #1f2124;
  padding: 0 16px;

  table {
    background: #1f2124;
    width: 100%;
    padding: 0;
    margin: 0;
  }
`

const BettingTotalInfoFirstTd = styled.td`
  width: 50%;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  padding: 8px;
  margin: 0;

  span {
    color: #fff;
    float: left;
  }
`

const BettingTotalInfoSecondTd = styled.td`
  width: 50%;
  text-align: center;
  padding: 0;
  margin: 0;

  span {
    color: ${HermesMainText};
    float: right;
  }
`

const BettingBetButtonWrap = styled.div`
  border-radius: 0px 0px 8px 8px;
  background: #1f2124;
  width: 100%;
  padding-top: 8px;
  padding: 16px;
  margin: 0;
`

const BettingBetButton = styled.button`
  background: ${HermesMainHover} !important;
  border: 1px solid ${HermesMainHover} !important;
  transition: background-color 0.1s ease-out 0s, border 0.1s ease-out 0s;

  width: 100%;
  height: 45px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 3px;
  font-weight: 500;
  color: #fff;
  font-size: 13px;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  display: block;
`

// 배팅 카드 밑 이미지
const BettingBottomBox = styled.div`
  background: #1a1c1f;
  border-radius: 6px;
  margin-top: 8px;

  padding: 0;
  margin-bottom: 9px;
`

const BettingBottomItem = styled.div`
  margin-bottom: 5px;
  cursor: pointer;
`

const SolutionWrap = styled.div`
  width: 100%;
  margin-top: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
  font-family: dotum, gulim, Arial, sans-serif !important;
`

const BettingAssetFormWrap = styled.form``

const SporteBettingCartWrap = styled.div`
  display: block;
  position: fixed;
  z-index: 1000000;
  right: 3%;
  margin-left: 0;
  opacity: 0.85;
  bottom: 20px;
  left: calc(50% - 50px);
  width: 100px;
  box-sizing: border-box;

  img {
    cursor: pointer;
    vertical-align: middle;
    border: 0;
    box-sizing: border-box;
    width: 100px;
    overflow-clip-margin: content-box;
    overflow: clip;
  }

  span {
    color: #fff;
    position: absolute;
    font-size: 16px;
    font-weight: 700;
    right: 22px;
    bottom: 9px;
    box-sizing: border-box;
  }
`

const SportsBettingCartIcon = styled.img`
  width: 75px;
  height: 75px;
`

const SportsBettingCartNumWrap = styled.div`
  position: absolute;
  top: -15px;
  right: -10px;
  width: 36px;
  height: 36px;
  border-color: ${HermesMainHover2};
  background-color: ${HermesMainHover};
  border-radius: 50%;
`

const SportsBettingCartNum = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  line-height: 20px;
  color: #fff;
`

const SportsBettingCartNumIcon = styled.img`
  width: 35px;
  height: 35px;
`
