import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { HermesMainHover2, HermesMainText } from '../../../utils/palette'
import {
  VirtualGameBettingTypeKr,
  VirtualGameLeagueTypeKr,
  VirtualResultSettlementTypeEnums,
} from '../../../utils/enums/VirtualGame/VirtualGameEnums'
import { convertToKstByFormat } from '../../../utils/dateTime'

const VirtualSoccerContentTop = () => {
  const [leagueList, setLeagueList] = useState([])

  const [activeLeagueType, setActiveLeagueType] = useState(null)

  const onClickActiveLeagueTypeHandler = league => {
    setActiveLeagueType(league)
  }

  const { sportType } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      sportType: virtualGameBetting.selectedVirtuaGameSportType,
    }
  })
  const { allLeagueList, virtualResultHistory, virtualBetLogs } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      allLeagueList: virtualGameInfo.virtualSportList,
      virtualResultHistory: virtualGameInfo.virtualResultHistory,
      virtualBetLogs: virtualGameInfo.virtualBetLogs,
    }
  })

  useEffect(() => {
    if (sportType !== 'VIRTUAL_SOCCER') return

    setLeagueList(
      allLeagueList.find(item => item.sportType === sportType)?.leagueList.filter(item => item.leagueIsShow) ?? [],
    )
  }, [allLeagueList, sportType])

  useEffect(() => {
    if (leagueList.length === 0) return

    if (activeLeagueType) return

    setActiveLeagueType(leagueList[0].leagueType)
  }, [activeLeagueType, leagueList])

  const getLeagueVideoCompo = () => {
    switch (activeLeagueType) {
      case 'VIRTUAL_PREMIERSHIP':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=1&sw=648&sh=365" />
      case 'VIRTUAL_SUPERLEAGUE':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=2&sw=648&sh=365" />
      case 'VIRTUAL_EURO_CUP':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=13&sw=648&sh=365" />
      case 'VIRTUAL_WORLD_CUP':
        return <VirtualGameVideo src="https://b1.nutv365.com/ios/?vn=3&sw=648&sh=365" />
      default:
        return null
    }
  }

  const [activeSubMenu, setActiveSubMenu] = useState('GAME_RESULT')

  const onClickActiveSubMenuHandler = subMenu => {
    setActiveSubMenu(subMenu)
  }

  return (
    <VirtualGameContentTopWrap>
      <VirtualGameVideoWrap>
        <VirtualGameVideoInnerWrap>
          <VirtualGameVideoInnerWrapHeader count={leagueList.length}>
            {leagueList.length === 0 ? (
              <div
                style={{
                  width: '100%',
                  height: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '20px',
                  color: 'white',
                }}
              >
                영상을 지원하지 않습니다.
              </div>
            ) : (
              <>
                {leagueList.map(leagueItem => {
                  return (
                    <VirtualGameVideoInnerWrapHeaderTab
                      active={activeLeagueType === leagueItem.leagueType}
                      onClick={() => {
                        onClickActiveLeagueTypeHandler(leagueItem.leagueType)
                      }}
                    >
                      {VirtualGameLeagueTypeKr[leagueItem.leagueType]}
                    </VirtualGameVideoInnerWrapHeaderTab>
                  )
                })}
              </>
            )}
          </VirtualGameVideoInnerWrapHeader>

          <VirtualGameVideoInnerWrapBox>{getLeagueVideoCompo()}</VirtualGameVideoInnerWrapBox>
        </VirtualGameVideoInnerWrap>
      </VirtualGameVideoWrap>

      <VirtualGameVideoRightWrap>
        <VirtualGameVideoRightHeaderBox>
          <VirtualGameVideoRightHeaderTab
            active={activeSubMenu === 'GAME_RESULT'}
            onClick={() => {
              onClickActiveSubMenuHandler('GAME_RESULT')
            }}
          >
            지난회차 결과
          </VirtualGameVideoRightHeaderTab>
          <VirtualGameVideoRightHeaderTab
            active={activeSubMenu === 'BET_HISTORY'}
            onClick={() => {
              onClickActiveSubMenuHandler('BET_HISTORY')
            }}
          >
            배팅내역
          </VirtualGameVideoRightHeaderTab>
        </VirtualGameVideoRightHeaderBox>

        <VirtualGameVideoRightContentBox>
          {activeSubMenu === 'GAME_RESULT' ? (
            <table>
              <colgroup>
                <col width="75" />
                <col style={{ minWidth: '160px' }} />
                <col width="160" />
                <col width="65" />
                <col width="160" />
              </colgroup>
              <tbody>
                <tr>
                  <th>시간</th>
                  <th>리그</th>
                  <th>홈팀</th>
                  <th>VS</th>
                  <th>원정</th>
                </tr>
                {virtualResultHistory.map(result => {
                  return (
                    <tr className="tbody-tr">
                      <td>{convertToKstByFormat(result.startDate, 'HH:mm')}</td>
                      <td> {VirtualGameLeagueTypeKr[result.leagueType]} </td>
                      <td>
                        <div className="text-ellipsis">{result.homeName}</div>
                      </td>
                      <td>
                        {' '}
                        {result.homeScore} : {result.awayScore}{' '}
                      </td>
                      <td>
                        <div className="text-ellipsis">{result.awayName}</div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <>
              {virtualBetLogs.length === 0 ? (
                <VirtaulGameBetHistoryEmptyBox>해당 게임에 배팅내역이 존재하지 않습니다.</VirtaulGameBetHistoryEmptyBox>
              ) : (
                <table>
                  <colgroup>
                    <col width="75" />
                    <col style={{ minWidth: '80px' }} />
                    <col width="100" />
                    <col width="100" />
                    <col width="300" />
                    <col width="80" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>회차</th>
                      <th>리그</th>
                      <th>홈</th>
                      <th>원정</th>
                      <th>배팅</th>
                      <th>결과</th>
                    </tr>
                    {virtualBetLogs.map(log => {
                      return (
                        <tr className="tbody-tr">
                          <td>{convertToKstByFormat(log.startDate, 'HH:mm')}</td>
                          <td> {VirtualGameLeagueTypeKr[log.leagueType]} </td>
                          <td>
                            <div className="text-ellipsis">{log.homeName}</div>
                          </td>
                          <td>
                            <div className="text-ellipsis">{log.awayName}</div>
                          </td>
                          <td style={{ color: `${HermesMainText}` }}>
                            {log.marketName} - {VirtualGameBettingTypeKr[log.selectedBetType]}
                          </td>
                          <CustomTd bettingResult={log.betSettlementType}>
                            {VirtualResultSettlementTypeEnums[log.betSettlementType]}
                          </CustomTd>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </VirtualGameVideoRightContentBox>
      </VirtualGameVideoRightWrap>
    </VirtualGameContentTopWrap>
  )
}

export default VirtualSoccerContentTop

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WINNER':
      return 'blue'
    case bettingResult === 'LOSER':
      return 'red'
    case bettingResult === 'CANCELLED':
      return 'grey'
    case bettingResult === 'NOT_SETTLED':
      return 'green'
    default:
      return 'white'
  }
}

const CustomTd = styled.td`
  font-weight: 600;
  color: ${props => getColor(props.bettingResult)};
`

const VirtualGameContentTopWrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const VirtualGameVideoWrap = styled.div`
  width: 648px;
  text-align: center;
  padding-top: 3px;
`

const VirtualGameVideoInnerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const VirtualGameVideoInnerWrapHeader = styled.div`
  width: 100%;
  background: rgb(22, 23, 26);
  border-radius: 6px 6px 0px 0px;
  padding: 0px 50px;
  display: grid;
  grid-template-columns: ${props =>
    props.count !== 0 ? `repeat(${props.count}, minmax(0, 1fr))` : 'repeat($1, minmax(0, 1fr))'};
`
const VirtualGameVideoInnerWrapHeaderTab = styled.div`
  float: left;
  // width: 25%;
  padding: 14px 0;
  color: #fff;
  cursor: pointer;
  transition: color 0.1s ease-out 0s;

  font-weight: 700;

  ${props =>
    props.active &&
    `
        color: ${HermesMainHover2};
    `}
`

const VirtualGameVideoInnerWrapBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const VirtualGameVideo = styled.iframe`
  width: 648px;
  height: 365px;
`

const VirtualGameVideoRightWrap = styled.div`
  width: calc(100% - 648px);
  float: left;
  padding-left: 8px;

  height: 412px;

  display: flex;
  flex-direction: column;
`

const VirtualGameVideoRightHeaderBox = styled.div``

const VirtualGameVideoRightHeaderTab = styled.div`
  padding: 14px 0;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  background: #161719;
  width: 50%;
  float: left;
  cursor: pointer;
  border-bottom: 1px solid #161719;
  border-radius: 6px 6px 0 0;
  color: #fff;
  transition: background-color 0.1s ease-out 0s, border 0.1s ease-out 0s;

  ${props =>
    props.active &&
    `
        background: #1b1c1f;
        border-bottom: 1px solid ${HermesMainHover2};
    `}
`

const VirtualGameVideoRightContentBox = styled.div`
  overflow: auto;
  position: relative;
  margin-top: 1px;
  text-align: center;
  background: #16171a;
  height: 362px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(58, 59, 59, 0.5);
  }

  table {
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 14px;

    border-collapse: separate;
    border-spacing: 0 8px;

    colgroup col {
      text-align: center;
    }

    th {
      font-weight: 700;
      padding: 10px 0;
      background: #141517;
      border-radius: 6px;
    }

    .tbody-tr {
      background: #1b1c1f;
      border-radius: 6px;

      td {
        padding: 10px 5px;
        text-align: center;
      }
    }

    .text-ellipsis {
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
`

const VirtaulGameBetHistoryEmptyBox = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: #fff;
`
