import Error from '@/shared/components/form/Error'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { siginOut } from '../../../api/authManage/authManageApi'
import { searchMemberInfo, updateMemberInfo } from '../../../api/memberManage/memberManageApi'
import { ButtonH40 } from '../../../shared/components/GoldButton'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import { handleDeleteAuthentication } from '../../../socketio/userAuthSocketIOHandler'
import { convertToKstDate } from '../../../utils/dateTime'
import { MemberDepositLevelEnum } from '../../../utils/enums/MemberManage/MemberInfoEnums'
import { deleteAccessToken, getAccessToken } from '../../../utils/token'
import { memberInfoModifyValidate } from '../../../utils/validate/myPage/myPageValidate'
import CustomLoading from '../../CustomLoading'
import { MyPageContentWrap, MyPageHeaderInfo, MyPageHeaderWrap, MyPageSubhead } from '../MyPageElement'

const MyInfo = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [writeLoading, setWriteLoading] = useState(false)

  // 버튼 이름 false : '내정보 수정하기' , true : '내정보 수정완료'
  const [btnToComplete, setBtnToComplete] = useState(false)
  const [myInfoBtnDefault, setMyInfoBtnDefault] = useState(false)

  // 새로운 비밀번호
  const [nowPassword, setNowPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')

  const onChangeMyInfoStatusBtnHandler = () => {
    setBtnToComplete(true)
  }

  // 아이디
  const [userId, setUserId] = useState('')
  // 닉네임
  const [nickName, setNickName] = useState('')
  // 이름
  const [memberName, setMemberName] = useState('')
  // 연락처
  const [memberPhoneNum, setMemberPhoneNum] = useState('')
  // 닉네임
  const [userBirthDay, setUserBirthDay] = useState('')

  // 입금 레벨
  const [depositLevel, setDepositLevel] = useState('')

  // 예금주명
  const [accountHolder, setAccountHolder] = useState('')
  // 은행명
  const [bankName, setBankName] = useState('')
  // 계좌번호
  const [accountNum, setAccountNum] = useState('')

  useEffect(() => {
    setLoading(true)
    searchMemberInfo({})
      .then(res => {
        setUserId(res.data.content.memberId)
        setMemberName(res.data.content.memberName)
        setMemberPhoneNum(res.data.content.phoneNum)
        setNickName(res.data.content.nickName)
        setDepositLevel(MemberDepositLevelEnum[res.data.content.depositLevel])
        setUserBirthDay(res.data.content.userBirthDay)
        setAccountHolder(res.data.content.memberName)
        setBankName(res.data.content.bankInfo.bankName)
        setAccountNum(res.data.content.accountNum)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const onSubmit = e => {
    if (writeLoading) return
    const body = {
      userId,
      password: nowPassword,
      newPassword,
    }
    setWriteLoading(true)
    updateMemberInfo(body)
      .then(res => {
        // onModalShowHandler()
        setWriteLoading(false)
        alert('회원 정보가 수정됐습니다. 다시 로그인해주세요.')
        handleDeleteAuthentication({
          accessToken: getAccessToken(),
        }).then(res1 => {
          deleteAccessToken()
          window.location.href = '/'
        })
      })
      .catch(error => {
        setWriteLoading(false)
        alert('회원 정보 수정에 실패하였습니다. 비밀번호 확인후 다시 시도해주세요.')
        setNowPassword('')
        setNewPassword('')
        setNewPasswordConfirm('')
      })
  }
  return (
    <WebPageWrap>
      <MyPageHeaderWrap>
        <MyPageHeaderInfo>내 정보</MyPageHeaderInfo>
        <MyPageSubhead>* 비밀번호 외 변경사항은 관리자에게 문의 부탁드립니다.</MyPageSubhead>
      </MyPageHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MyPageContentWrap>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              userId,
              memberPhoneNum: memberPhoneNum || '-',
              level: `${depositLevel}`,
              nickName,
              userBirthDay: convertToKstDate(userBirthDay),
              memberName,
              bankName: bankName || '-',
              accountNum: accountNum || '-',
              accountHolder: accountHolder || '-',
              nowPassword,
              newPassword,
              newPasswordConfirm,
            }}
            validate={memberInfoModifyValidate}
          >
            {({ handleSubmit, form: { reset } }) => (
              <MyInfoFormWrap horizontal onSubmit={handleSubmit}>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>아이디</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="userId">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>닉네임</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="nickName">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>이름</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="memberName">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>연락처</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="memberPhoneNum">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>생년월일</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="userBirthDay">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>레벨</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="level">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>예금주명</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="accountHolder">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>은행명</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="bankName">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                <MyInfoFormGroup>
                  <MyInfoFormLabel>계좌번호</MyInfoFormLabel>
                  <MyInfoFormField>
                    <Field name="accountNum">
                      {({ input, meta }) => (
                        <MyInfoFormInputWrap>
                          <MyInfoFormInput {...input} type="text" readOnly disabled />
                        </MyInfoFormInputWrap>
                      )}
                    </Field>
                  </MyInfoFormField>
                </MyInfoFormGroup>
                {btnToComplete ? (
                  <>
                    <MyInfoFormGroup>
                      <MyInfoFormLabel>현재 비밀번호</MyInfoFormLabel>
                      <MyInfoFormField>
                        <Field name="nowPassword">
                          {({ input, meta }) => (
                            <>
                              <MyInfoFormInputWrap>
                                <MyInfoFormInput
                                  {...input}
                                  type="password"
                                  inputMode="latin"
                                  value={nowPassword}
                                  onChange={e => {
                                    setNowPassword(e.target.value)
                                  }}
                                />
                              </MyInfoFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </>
                          )}
                        </Field>
                      </MyInfoFormField>
                    </MyInfoFormGroup>
                    <MyInfoFormGroup>
                      <MyInfoFormLabel>변경 비밀번호</MyInfoFormLabel>
                      <MyInfoFormField>
                        <Field name="newPassword">
                          {({ input, meta }) => (
                            <>
                              <MyInfoFormInputWrap>
                                <MyInfoFormInput
                                  {...input}
                                  type="password"
                                  inputMode="latin"
                                  value={newPassword}
                                  onChange={e => {
                                    setNewPassword(e.target.value)
                                  }}
                                />
                              </MyInfoFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </>
                          )}
                        </Field>
                      </MyInfoFormField>
                    </MyInfoFormGroup>
                    <MyInfoFormGroup>
                      <MyInfoFormLabel>변경 비밀번호 확인</MyInfoFormLabel>
                      <MyInfoFormField>
                        <Field name="newPasswordConfirm">
                          {({ input, meta }) => (
                            <>
                              <MyInfoFormInputWrap>
                                <MyInfoFormInput
                                  {...input}
                                  type="password"
                                  inputMode="latin"
                                  value={newPasswordConfirm}
                                  onChange={e => {
                                    setNewPasswordConfirm(e.target.value)
                                  }}
                                />
                              </MyInfoFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </>
                          )}
                        </Field>
                      </MyInfoFormField>
                    </MyInfoFormGroup>
                  </>
                ) : (
                  <></>
                )}
                <MyInfoFormButtonWrap>
                  {btnToComplete ? (
                    <MyInfoFormButtom variant="outline-secondary" visible type="submit">
                      <MyInfoFormButtomInfo>내정보 수정완료</MyInfoFormButtomInfo>
                    </MyInfoFormButtom>
                  ) : (
                    <MyInfoFormButtom
                      type="button"
                      visible={!btnToComplete}
                      onClick={() => onChangeMyInfoStatusBtnHandler()}
                    >
                      <MyInfoFormButtomInfo>내정보 수정하기</MyInfoFormButtomInfo>
                    </MyInfoFormButtom>
                  )}
                </MyInfoFormButtonWrap>
              </MyInfoFormWrap>
            )}
          </Form>
        </MyPageContentWrap>
      )}
    </WebPageWrap>
  )
}

export default MyInfo

const MyInfoFormWrap = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`

const MyInfoFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

// sign up form label
const MyInfoFormLabel = styled.div`
  width: 160px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

// sign up form field
const MyInfoFormField = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
`

// sign up form input wrap
const MyInfoFormInputWrap = styled.div`
  width: 100%;
  border: 1px solid #4a4a4a;
  position: relative;
  // height: 35px;
  height: 35px;
`

// sign up form input
const MyInfoFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  line-height: 35px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

// 수정하기, 수정완료 button  wrap
const MyInfoFormButtonWrap = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`

// 버튼 안에 텍스트
const MyInfoFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-align: center;

  color: #000000;
`

// sign up from button
const MyInfoFormButtom = styled(ButtonH40)`
  display: ${props => (props.visible ? 'block' : 'none')};

  // width: 100%;
  width: 400px;
  height: 40px;
  border-radius: 0;

  // 호버
  &:hover {
    ${MyInfoFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MyInfoFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MyInfoFormButtomInfo} {
      color: #ffffff4d;
    }
  }
`
