import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { FaLock } from 'react-icons/fa'
import { FiRefreshCw } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMiniGameBettingLog, searchMiniGameInfo } from '../../../api/game/miniGameApi'
import {
  addBettingCart,
  setMiniGameInfo,
  setBettingCloseHandler,
  setMiniGameCompleteFlag,
  setResetData,
} from '../../../redux/miniGameBettingSlice'
import { MiniGameTypeEnums } from '../../../utils/enums/MiniGame/PowerBallEnums'
import { HermesMainBorder, HermesMainText } from '../../../utils/palette'
import MiniGameBettingLogData from '../MiniGameBettingLogData'
import MiniGameTabs from '../MiniGameTabs'
import { formatSeconds, getCurrentTime } from '../miniGameTimeUtils'
import SuremanTabs from './SuremanTabs'

const SuremanLadderTwo = () => {
  // redux 사용
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [params, setParams] = useState({
    miniGameType: 'SUREMAN_LADDER_2MIN',
  })

  const {
    miniGameType,
    miniGameBettingInfo,
    miniGameRound,
    deadLineTime,
    isShow,
    endTime,
    bettingCountOkFlag,
    bettingCloseFlag,
    bettingCompleteFlag,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameBettingInfo: miniGameBetting.miniGameBettingInfo,
      miniGameRound: miniGameBetting.miniGameRound,
      deadLineTime: miniGameBetting.deadLineTime,
      isShow: miniGameBetting.isShow,
      endTime: miniGameBetting.endTime,
      bettingCountOkFlag: miniGameBetting.bettingCountOkFlag,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
    }
  })

  // 서버 시간 == 현재 시간
  const [currentTime, setCurrentTime] = useState(null)

  const intervalRef = useRef(null)
  // svt : serverTime , stt : startTime
  const getCurrentTimeDifference = deadLine => {
    if (!currentTime)
      return {
        minutes: 0,
        seconds: 0,
      }

    let totalSeconds = endTime - currentTime - deadLine

    if (totalSeconds < 0) {
      totalSeconds = 0
    }

    return {
      minutes: Math.floor(totalSeconds / 60),
      seconds: totalSeconds % 60,
    }
  }

  const [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  })

  const fetchSearchMiniGameInfo = async () => {
    await searchMiniGameInfo(params)
      .then(res => {
        dispatch(
          setMiniGameInfo({
            miniGameType: res.data.miniGameType,
            miniGameBettingInfo: JSON.parse(res.data.miniGameBettingPer),
            round: res.data.round,
            bettingCountOkFlag: res.data.bettingCountOkFlag,
            endTime: res.data.endTime,
            deadLineTime: res.data.deadLineTime,
            isShow: res.data.isShow,
          }),
        )

        setCurrentTime(res.data.serverTime)

        setTime(getCurrentTimeDifference(res.data.deadLineTime))

        if (!bettingCompleteFlag) {
          dispatch(setResetData())
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchMiniGameInfo()

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentTime >= endTime) {
        fetchSearchMiniGameInfo()
        dispatch(setMiniGameCompleteFlag(false))
        dispatch(setResetData())
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [currentTime, endTime])

  const updateTimer = () => {
    setCurrentTime(getCurrentTime())
    setTime(getCurrentTimeDifference(deadLineTime))
  }

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(updateTimer, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [time])

  /**
   *  배팅 조회
   */
  const [page, setPage] = useState(0)

  const [miniGameBettingLogParams, setMiniGameBettingLogParams] = useState({
    miniGameType: 'SUREMAN_LADDER_2MIN',
    page,
    size: 5,
  })

  useEffect(() => {
    setMiniGameBettingLogParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMiniGameBettingLog = () => {
    searchMiniGameBettingLog(miniGameBettingLogParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
      })
      .catch(error => {})
  }

  useEffect(() => {
    fetchSearchMiniGameBettingLog()
  }, [miniGameBettingLogParams, bettingCompleteFlag])

  const [miniGameBettingLogData, setMiniGameBettingLogData] = useState(MiniGameBettingLogData(content))
  useEffect(() => {
    setMiniGameBettingLogData(MiniGameBettingLogData(content))
  }, [content])

  const [rows, setData] = useState(miniGameBettingLogData.tableRowsData)
  useEffect(() => {
    setData(miniGameBettingLogData.tableRowsData)
  }, [miniGameBettingLogData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: 5,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  const isDisabled = time.minutes === 0 && time.seconds === 0

  // 배팅 마감일 때 배팅카트 초기화
  useEffect(() => {
    dispatch(setBettingCloseHandler(isDisabled))
  }, [isDisabled])

  // 배팅 카트 담기
  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id

    dispatch(
      addBettingCart({
        miniGameBettingPer,
        miniGameBettingType,
      }),
    )
  }

  return (
    <MoneyDepositWrap>
      <MiniGameTabs activeKey="4" />
      <SuremanTabs activeKey="8" />
      <CustomerCenterWrap>
        <MiniGameContentWrap>
          <MiniGameFirstWrap
            style={{
              background: `url(${process.env.PUBLIC_URL}/img/main/97437ad.jpg) no-repeat center center`,
            }}
          >
            {!isShow && (
              <InspectOverlay>
                <BiLock />
                <span>점검중</span>
              </InspectOverlay>
            )}
            <MiniGameIframeWrap>
              {!loading ? (
                <div className="overflow:hidden;width:830px;height:809px;">
                  <div
                    style={{
                      width: '1740px',
                      height: '1645px',
                      overflow: 'hidden',
                      background: 'rgb(41,41,41) none repeat scroll 0% 0%',
                      transform: 'scale(0.402)',
                      transformOrigin: '0px 0px 0px',
                    }}
                  >
                    <MiniGameIframe
                      src="https://www.sureladder.com/?id=two"
                      scrolling="no"
                      width="1742"
                      height="1645"
                      frameborder="0"
                    />
                  </div>
                </div>
              ) : (
                <motion.div
                  initial={{ opacity: 0, scale: 0.1 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0.5, scale: 0.1 }}
                  transition={{ duration: 0.6 }}
                >
                  <IframeOverlay>
                    <HeaderLogoImg
                      src={`${process.env.PUBLIC_URL}/img/main/iframeLogo.png`}
                      alt=""
                      onClick={e => {
                        window.location.href = '/'
                      }}
                    />
                  </IframeOverlay>
                </motion.div>
              )}
            </MiniGameIframeWrap>
            <MiniGameBettingSortWrap>
              {(isDisabled || !bettingCountOkFlag) && (
                <Overlay>
                  <BettingWhatLockIcon />
                </Overlay>
              )}
              <MiniGameBettingSortHeaderWrap>
                <MiniGameBettingSortHeaderInfo>
                  <MiniGameBettingSortHeaderLabel>회차</MiniGameBettingSortHeaderLabel>
                  <MiniGameBettingSortHeaderText>{miniGameRound || '-'} 회차</MiniGameBettingSortHeaderText>
                </MiniGameBettingSortHeaderInfo>
                <MiniGameBettingSortHeaderInfo>
                  <MiniGameBettingSortHeaderLabel>추첨까지</MiniGameBettingSortHeaderLabel>
                  <MiniGameBettingSortHeaderText>
                    {time.minutes} : {formatSeconds(time.seconds)}
                  </MiniGameBettingSortHeaderText>
                </MiniGameBettingSortHeaderInfo>
              </MiniGameBettingSortHeaderWrap>

              {/* 배팅 종목 */}
              <MiniGameBettingSortCartWrap>
                {/* 결과  */}
                <MiniGameBettingSortCartGroupWrap style={{ marginTop: '20px' }}>
                  <MiniGameBettingSortCartGroup>
                    <MiniGameBettingSortCartTitle>결과</MiniGameBettingSortCartTitle>
                    <MiniGameBettingSortCartMedium
                      id="LADDER_ODD"
                      name={miniGameBettingInfo?.odd || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.odd || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentBlue>홀</MiniGameBettingCartContentBlue>
                      <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                    <MiniGameBettingSortCartMedium
                      id="LADDER_EVEN"
                      name={miniGameBettingInfo?.even || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.even || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentRed>짝</MiniGameBettingCartContentRed>
                      <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                  </MiniGameBettingSortCartGroup>
                </MiniGameBettingSortCartGroupWrap>

                {/* 시작  */}
                <MiniGameBettingSortCartGroupWrap>
                  <MiniGameBettingSortCartGroup>
                    <MiniGameBettingSortCartTitle>시작</MiniGameBettingSortCartTitle>
                    <MiniGameBettingSortCartMedium
                      id="LADDER_LEFT"
                      name={miniGameBettingInfo?.leftStart || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.leftStart || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentBlue>좌</MiniGameBettingCartContentBlue>
                      <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                    <MiniGameBettingSortCartMedium
                      id="LADDER_RIGHT"
                      name={miniGameBettingInfo?.rightStart || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.rightStart || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentRed>우</MiniGameBettingCartContentRed>
                    </MiniGameBettingSortCartMedium>
                  </MiniGameBettingSortCartGroup>
                </MiniGameBettingSortCartGroupWrap>

                {/* 줄 수  */}
                {/* 결과  */}
                <MiniGameBettingSortCartGroupWrap>
                  <MiniGameBettingSortCartGroup>
                    <MiniGameBettingSortCartTitle>줄수</MiniGameBettingSortCartTitle>
                    <MiniGameBettingSortCartMedium
                      id="LADDER_THREE"
                      name={miniGameBettingInfo?.lineThree || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.lineThree || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentBlue>3</MiniGameBettingCartContentBlue>
                      <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                    <MiniGameBettingSortCartMedium
                      id="LADDER_FOUR"
                      name={miniGameBettingInfo?.lineFour || 0}
                      onClick={handleBettingCart}
                    >
                      <MiniGameBettingPerFirst>{miniGameBettingInfo?.lineFour || 0}</MiniGameBettingPerFirst>
                      <MiniGameBettingCartContentRed>4</MiniGameBettingCartContentRed>
                      <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                    </MiniGameBettingSortCartMedium>
                  </MiniGameBettingSortCartGroup>
                </MiniGameBettingSortCartGroupWrap>

                {/* 조합배팅  */}
                <MiniGameBettingSortCartGroupWrap style={{ height: '210px', marginTop: '10px' }}>
                  <MiniGameBettingSortCartGroup style={{ height: '210px' }}>
                    <div style={{ width: '110px' }}>
                      <MiniGameBettingSortCartTitle style={{ width: '100px', height: '205px', marginLeft: '3px' }}>
                        조합배팅
                      </MiniGameBettingSortCartTitle>
                    </div>
                    <div style={{ width: 'calc(100% - 110px)', height: '210px' }}>
                      <MiniGameBettingSortCartGroup style={{ marginBottom: '5px' }}>
                        {/* 좌3짝  */}
                        <MiniGameBettingSortCartBig>
                          <MiniGameBettingPer>{miniGameBettingInfo?.leftThreeEven || 0}</MiniGameBettingPer>
                          <MiniGameMixedBettingWrap
                            id="LADDER_LEFT_THREE_EVEN"
                            name={miniGameBettingInfo?.leftThreeEven || 0}
                            onClick={handleBettingCart}
                          >
                            <MiniGameBettingCartContentMixedBlue>좌</MiniGameBettingCartContentMixedBlue>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedBlue>3</MiniGameBettingCartContentMixedBlue>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedRed>짝</MiniGameBettingCartContentMixedRed>
                          </MiniGameMixedBettingWrap>
                        </MiniGameBettingSortCartBig>
                        {/* 좌4홀  */}
                        <MiniGameBettingSortCartBig>
                          <MiniGameBettingPer>{miniGameBettingInfo?.leftFourOdd || 0}</MiniGameBettingPer>
                          <MiniGameMixedBettingWrap
                            id="LADDER_LEFT_FOUR_ODD"
                            name={miniGameBettingInfo?.leftFourOdd || 0}
                            onClick={handleBettingCart}
                          >
                            <MiniGameBettingCartContentMixedBlue>좌</MiniGameBettingCartContentMixedBlue>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedRed>4</MiniGameBettingCartContentMixedRed>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedBlue>홀</MiniGameBettingCartContentMixedBlue>
                          </MiniGameMixedBettingWrap>
                        </MiniGameBettingSortCartBig>
                      </MiniGameBettingSortCartGroup>
                      <MiniGameBettingSortCartGroup>
                        {/* 우3홀  */}
                        <MiniGameBettingSortCartBig>
                          <MiniGameBettingPer>{miniGameBettingInfo?.rightThreeOdd || 0}</MiniGameBettingPer>
                          <MiniGameMixedBettingWrap
                            id="LADDER_RIGHT_THREE_ODD"
                            name={miniGameBettingInfo?.rightThreeOdd || 0}
                            onClick={handleBettingCart}
                          >
                            <MiniGameBettingCartContentMixedRed>우</MiniGameBettingCartContentMixedRed>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedBlue>3</MiniGameBettingCartContentMixedBlue>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedBlue>홀</MiniGameBettingCartContentMixedBlue>
                          </MiniGameMixedBettingWrap>
                        </MiniGameBettingSortCartBig>
                        {/* 우4짝  */}
                        <MiniGameBettingSortCartBig>
                          <MiniGameBettingPer>{miniGameBettingInfo?.rightFourEven || 0}</MiniGameBettingPer>
                          <MiniGameMixedBettingWrap
                            id="LADDER_RIGHT_FOUR_EVEN"
                            name={miniGameBettingInfo?.rightFourEven || 0}
                            onClick={handleBettingCart}
                          >
                            <MiniGameBettingCartContentMixedRed>우</MiniGameBettingCartContentMixedRed>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedRed>4</MiniGameBettingCartContentMixedRed>
                            {/* <img src={`${process.env.PUBLIC_URL}/img/main/plus.png`} alt="" /> */}
                            <MiniGameBettingCartContentMixedRed>짝</MiniGameBettingCartContentMixedRed>
                          </MiniGameMixedBettingWrap>
                        </MiniGameBettingSortCartBig>
                      </MiniGameBettingSortCartGroup>
                    </div>
                  </MiniGameBettingSortCartGroup>
                </MiniGameBettingSortCartGroupWrap>
              </MiniGameBettingSortCartWrap>
            </MiniGameBettingSortWrap>
          </MiniGameFirstWrap>
          <MiniGameBettingTableWrap>
            <TableBettingInfo>
              <div className="text">{MiniGameTypeEnums.SUREMAN_LADDER_2MIN} 배팅내역</div>
              <div className="icon">
                <FiRefreshCw onClick={fetchSearchMiniGameBettingLog} />
              </div>
            </TableBettingInfo>
            {content.length === 0 ? (
              <NoContentWrap>
                <img src={`${process.env.PUBLIC_URL}/img/main/noslip.svg`} alt="" />
                해당 게임에 배팅내역이 존재하지 않습니다.
              </NoContentWrap>
            ) : (
              <>
                <ReactTableBase
                  columns={miniGameBettingLogData.tableHeaderData}
                  data={rows}
                  tableConfig={tableConfig}
                />
              </>
            )}
          </MiniGameBettingTableWrap>
        </MiniGameContentWrap>
      </CustomerCenterWrap>
    </MoneyDepositWrap>
  )
}
export default SuremanLadderTwo

const MoneyDepositWrap = styled.div`
  background: rgb(22, 23, 26);
  // border: 1px solid #525252;
  border-radius: 10px;
  min-height: 744px;
  padding-bottom: 30px;
  margin-top: 32px;
  width: 1277px;
`

const MiniGameBettingCartContentMixedRed = styled.div`
  cursor: pointer;

  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  padding-top: 3px;
`

const MiniGameMixedBettingWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 100%;
  img {
    width: 15px;
    height: 15px;
  }
  &:hover {
    cursor: pointer;
    img {
      transform: scale(1.2);
    }
    div {
      transform: scale(1.2);
    }
  }
`

const MiniGameBettingCartContentMixedBlue = styled.div`
  cursor: pointer;

  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  padding-top: 3px;
`
const MiniGameBettingPerFirst = styled.div`
  justify-content: center;
  background-color: #4a3427;
  height: 15px;
  position: absolute;
  top: 0;
  text-align: center;
  align-items: center;
  width: 100%;
  color: white;
`
const BettingWhatLockIcon = styled(FaLock)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`

const NoContentWrap = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  color: #fff;
`

const MiniGameFirstWrap = styled.div`
  width: 100%;
  display: flex;
`

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  color: ${HermesMainText};
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    position: absolute;
    flex-direction: column;
    margin-top: 15px;
    top: 35%;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -50%);
  }

  svg {
    /* 아이콘 스타일 */
    position: absolute;
    flex-direction: column;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
  }
`

export const HeaderLogoImg = styled.img`
  width: 270px;
  height: 188px;
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IframeOverlay = styled.div`
  position: relative;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;

  width: 100%;
  padding-left: 650px;
  height: 640px;
  // background-color: rgba(255, 255, 255, 0.1);
`

export const TableBettingInfo = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 48px;
  background: #1b1c1f;
  color: #fff;
  font-size: 14px;
  .text {
    flex-grow: 1;
    display: flex;
    justify-content: left;
    padding-left: 20px;
    align-items: center;
  }

  .icon {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 10px; // 오른쪽 여백을 조정하려면 이 값을 조절하세요.
  }
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

const CustomerCenterWrap = styled.div`
  background: #000000;
  height: 100%;
  min-height: 800px;
  position: relative;
`
// content Wrap
const MiniGameContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
`

const MiniGameBettingTableWrap = styled.div`
  width: 100%;
  background: #16171a;
  margin: 0 auto;
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 10px;
  z-index: 110;
`

const MiniGameBettingSortWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 440px;
  overflow: hidden !important;
  background-color: rgb(153, 97, 72);
  // margin-left: -328px !important;
  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  text-align: center;
  color: white;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const MiniGameBettingSortHeaderWrap = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 5px solid rgb(244, 225, 192);
  height: 55px;
  padding: 7px 0px;
  align-items: center;
  width: 100%;

  gap: 10px;
`

const MiniGameBettingSortTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 90px;
  padding: 7px 0px;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-bottom: 2px solid #4a4a4a;
  margin-bottom: -20px;
`

const MiniGameBettingSortCartWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 580px;
  padding: 0px;
  align-items: center;
  width: 100%;
  gap: 7px;
  margin-top: -5px;
`

const MiniGameBettingSortMixCartWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 525px;
  padding: 0px;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: -5px;
`

const MiniGameBettingSortCartGroupWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 110px;
  padding-bottom: 10px;
  border-bottom: 2px solid #4a4a4a;
  align-items: center;
  width: 97%;
  flex-direction: column;
`
const MiniGameBettingSortCartGroup2 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70%;
`

const MiniGameBettingSortCartGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const MiniGameBettingSortCartTitle = styled.span`
  font-size: 14px;
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
  width: 100px;
  background-color: #674836;
  color: #e9d6b5;
  border: 1px solid #4b3528;
`

const MiniGameBettingCartContentRed = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 3px;
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-size: 17px;
  padding-top: 1px;
  display: flex;
`

const MiniGameBettingCartContentBlue = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  min-width: 40px;
  padding-top: 1px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  cursor: pointer;
  font-size: 17px;
`

const MiniGameBettingCartContentOver = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  border: solid 2px red;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  padding-top: 1px;
  font-size: 17px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: red;
  display: flex;
  cursor: pointer;
`

const MiniGameBettingCartContentUnder = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 3px;
  min-width: 40px;
  padding-top: 1px;
  max-width: 40px;
  font-size: 17px;

  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: blue;
  display: flex;
  position: relative;
  border: solid 2px blue;
`

const MiniGameBettingSortCartMedium = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 100px;
  width: 150px;
  align-items: center;
  border: 1px solid #684935;
  background-color: #e2c65b;
  position: relative;

  &:hover {
    cursor: pointer;
    ${MiniGameBettingCartContentBlue},${MiniGameBettingCartContentRed},${MiniGameBettingCartContentOver},${MiniGameBettingCartContentUnder} {
      color: white;
      max-width: 50px;
      height: 50px;
    }
  }
`

const MiniGameBettingSortCartMixMedium = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  font-size: 14px;
  height: 100px;
  width: 80px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer;
  background-color: #e2c65b;
  position: relative;
  flex-direction: column;
  img {
    margin-top: 10px;
  }
  gap: 5px;
  &:hover {
    img {
      width: 65%;
    }
  }
`

const MiniGameBettingSortCartMixMediumSmall = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  font-size: 14px;
  height: 50px;
  width: 108px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer;
  background-color: #e2c65b;
  position: relative;
  flex-direction: column;
  img {
    margin-top: 19px;
  }
  gap: 2px;
  &:hover {
    img {
      width: 45%;
    }
  }
`

const MiniGameBettingSortCartSmall = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  height:48px;
  width: 60px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
  position: relative;
  font-size: 14px;
  padding-top:1px;
  margin-bottom: 3px;
`

const MiniGameBettingSortCartBig = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  height:100px;
  width: 150px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
  position: relative;
  Padding-top: 10px;
  img {
    position: absolute;
    left:0;
    bottom:0;
  }
`

const MiniGameBettingPer = styled.div`
  justify-content: center;
  background-color: #4a3427;
  height: 15px;
  position: absolute;
  top: 0;
  text-align: center;
  align-items: center;
  width: 100%;
  color: white;
`

const MiniGameBettingCartContentBlack = styled.div`
  margin-top: 14px;
  cursor: pointer;
  border: 3px solid #c0c0c0;
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #5c5c5c 40%, #6c6c6c 60%, #7c7c7c 100%);

  min-width: 30px;
  max-width: 30px;
  height: 30px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  &:hover {
    color: white;
    max-width: 35px;
    height: 35px;
  }
`

const MiniGameBettingCartContentSize = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  min-width: 50px;
  max-width: 50px;
  height: 40px;
  background: transparent;
  justify-content: center;
  align-items: center;
  border: solid 1px #674836;
  width: 100%;
  color: #4b3422;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #674836;
  }
`

const MiniGameBettingPerSecond = styled.div`
  justify-content: center;
  height: 15px;
  align-items: center;
  width: 100%;
  color: black;
  position: absolute;
  bottom: 0;
  text-align: center;
`

const MiniGameBettingSortTitle = styled.span`
  display: flex;
  background-color: #674836;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: ${props => (props.selected ? `1px solid ${HermesMainBorder}` : 'none')};
  color: ${props => (props.selected ? `${HermesMainText}` : 'white')};
  height: 80px;
  align-items: center;
  width: 48%;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
`

const MiniGameBettingSortHeaderInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  font-size: 14px;
`

const MiniGameBettingSortHeaderLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height 40px;
  color: #f9e5c0;
  font-size: 14px;
  font-weight: 700;
  background-color: #4a3427;
`
const MiniGameBettingSortHeaderText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height 40px;
  width: 60%;
  color: white;
  font-weight: 700;
  background-color: #674836;
`

const MiniGameIframe = styled.iframe`
  // width: 100%;
`

const MiniGameIframeWrap = styled.div`
  display: flex;
  width: 683px;
  overflow: hidden !important;
  margin: 0 auto;
  height: 630px;
`
