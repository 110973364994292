import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { GiMoneyStack } from 'react-icons/gi'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { IoWalletOutline } from 'react-icons/io5'
import styled from 'styled-components'
import { searchMemberMoneyExchange } from '../../api/memberHistory/memberHistoryApi'
import CustomLoading from '../../containers/CustomLoading'
import { CustomSelect } from '../../shared/components/CustomSelect'
import { ButtonH40 } from '../../shared/components/GoldButton'
import { MobileTopWrap } from '../../shared/components/MoneyCustomElement'
import {
  colorFormSelect,
  colorMainText,
  colorMoneyExchangeBottomBorder,
  colorMoneyInfo,
  colorMoneySvg,
  colorWalletSvg,
} from '../../utils/palette'
import MobileMoneyExchangeListData from '../mobileMyPage/mobileMoneyExchageHistory/MobileMoneyExchangeListData'

/**
 * 머니이동 내역임
 * 9.14 머니이동 기능은 라이브/슬롯쪽으로 이동
 */
const MobileMoneyExchangeForm = () => {
  const [loading, setLoading] = useState(false)

  /**
   * 회원 머니이동내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberMoneyExchange = async () => {
    setLoading(true)
    await searchMemberMoneyExchange(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyExchange()
  }, [historyParams])

  const [moneyExchangeListData, setMoneyExchangeListData] = useState(MobileMoneyExchangeListData(content))

  useEffect(() => {
    setMoneyExchangeListData(MobileMoneyExchangeListData(content))
  }, [content])

  const [rows, setData] = useState(moneyExchangeListData.tableRowsData)

  useEffect(() => {
    setData(moneyExchangeListData.tableRowsData)
  }, [moneyExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <MobileTopWrap>
      <MoneyExchangeHeaderWrap>
        <MoneyExchangeHeaderInfo>머니 이동내역</MoneyExchangeHeaderInfo>
      </MoneyExchangeHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MoneyExchangeContentWrap>
          <ReactTableBase columns={moneyExchangeListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </MoneyExchangeContentWrap>
      )}
    </MobileTopWrap>
  )
}

export default MobileMoneyExchangeForm

const MoneyExchangeHeaderThirdInfo = styled.span`
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const MoneyExchangeHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyExchangeBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;
`

const MoneyExchangeWrap = styled.div`
  background: #000000;
  // border: 1px solid #4a4a4a;

  height: 100%;
  // min-height: 550px;

  position: relative;
  margin-top: 5px;
`

// Header Wrap
const MoneyExchangeHeaderWrap = styled.div`
  padding: 15px 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #edae07;
`

// header info
const MoneyExchangeHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;

  /* 엘로우폰트 */

  color: #ffc01a;
`

// content Wrap
const MoneyExchangeContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

const MoneyExchangeSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

// 지갑 머니 / 게임 머니 Wrap
const MoneyExchangeHeaderMoneyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  padding: 5px 0;

  padding-left: 10px;

  gap: 5px;
`
// 지갑 머니 아이콘
const MoneyExchangeWalletMoneyIcon = styled(IoWalletOutline)`
  width: 30px;
  height: 30px;
  color: ${colorWalletSvg};
  // margin: 0 5px;
`

// 게임 머니 아이콘
const MoneyExchangeGameMoneyIcon = styled(GiMoneyStack)`
  color: ${colorMoneySvg};
  width: 30px;
  height: 30px;
`

const MoneyExchangeHeaderInnerInfoWrap = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 100%;
`

//
const MoneyExchangeHeaderMoneyInfoWrap = styled.div`
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;

  gap: 10px;

  color: ${colorMoneyInfo};
`

const MoneyExchangeHeaderMoneyInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
`

const MoneyExchangeFormWrap = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;
`

// content  header
const MoneyExchangeContentHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #edae07;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  padding: 10px 0;

  color: ${colorMainText};
`
const MoneyExchangeFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  gap: 10px;
`

// sign up form label
const MoneyExchangeFormLabel = styled.div`
  width: 160px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

// sign up form field
const MoneyExchangeFormField = styled.div`
  // width: 270px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

// sign up form input wrap
const MoneyExchangeFormInputWrap = styled.div`
  width: 100%;
  border: 1px solid #4a4a4a;
  position: relative;
  height: 35px;

  display: flex;
`

// sign up form input
const MoneyExchangeFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  height: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 35px;

  color: white;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    &::placeholder {
      color: black;
    }
    background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

// MoneyExchangeFormGroupIcon
const MoneyExchangeFormGroupIcon = styled.div`
  padding: 6px;
  min-width: max-content;
  height: 100%;
  background: #585858;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: white;
`

// 이동하기 버튼 텍스트
const MoneyExchangeButtonInfo = styled.span`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;

  color: #000000;
`

// 이동하기 버튼
const MoneyExchangeButton = styled(ButtonH40)`
  width: 110px;
  height: 33px;
  border-radius: 0;

  // 비활성화
  &:disabled {
    height: 38px;
    ${MoneyExchangeButtonInfo} {
      color: #ffffff4d;
    }
  }
`

// 라이브 카지노, 슬롯 카지노, 금액 wrap
const MoneyExchangeBottomButtonWrap = styled.div`
  // width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;

  gap: 5px;
`

export const CustomIoWalletOutline = styled(IoWalletOutline)`
  color: red;
  width: 22px;
  height: 22px;
  margin: 0 8px 3px 0;
  color: ${colorWalletSvg};
`
export const CustomIoIosArrowForward = styled(IoIosArrowForward)`
  width: 30px;
  height: 30px;
  color: white;
`

export const CustomIoIosArrowBack = styled(IoIosArrowBack)`
  width: 30px;
  height: 30px;
  color: white;
`

export const SelectedGameButtom = styled.button`
  width: 250px;
  // min-width: max-content;
  padding: 6px;
  height: 32px;
  font-style: normal;
  font-size: 10px;
  font-weight: 600;
  color: black;
  background: #f9e79f;
  border: 1px solid #f2f4f7;
  border-radius: 70%;
`

// 버튼 안에 텍스트
export const MoneyExchangeFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  text-align: center;

  color: white !important;
`

const MoneyExchangeGameButton = styled.button`
  // padding: 5px;
  width: 60px;
  height: 100%;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
  color: black;
  background: #f2f4f7;
  border: 1px solid #f2f4f7;

  &:hover,
  &:focus,
  &:active {
    color: black;
    background: #f9e79f;
  }
`

const MoneyExchangeInfo = styled.div`
  float: left;
  width: 100%;
  border-bottom: solid 2px ${colorMoneyExchangeBottomBorder};
  margin: 0px 0px 7px 0px;
  font-size: 12px;
  font-weight: 700;
  color: ${colorMainText};
`
const MoneyExchageDescription = styled.span`
  font-size: 11px;
  color: black;
  line-height: 13px;
  margin: -5px 0px 20px 5px;
  color: ${colorMainText};
`

const GameImage = styled.img`
  width: 100%;
  height: 100%;

  transition: all 0.2s linear;
`

const GameImageWrap = styled.div`
  width: 140px;
  height: 180px;
  position: relative;

  // 스케일 시 전체 크기는 고정하기 위함
  overflow: hidden;

  &:hover {
    cursor: pointer;

    ${GameImage} {
      transform: scale(1.2);
    }
  }
`

const GameListWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 0 15px;
  padding-bottom: 10px;

  gap: 10px;
`

// Select

const MoneyExchangeFormSelect = styled(CustomSelect)`
  // width: 220px;
  // width: 100%;

  // border: 1px solid red;

  .react-select__control {
    height: 28px;
    border: 1px solid #4a4a4a;
    background-color: transparent;

    &.react-select__control--is-focused,
    &:hover {
      border: solid 2px;
      border-color: white !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: #d3d3d3;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: #d3d3d3 !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: #d3d3d3;
  }

  .react-select__single-value {
    color: #d3d3d3;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    // overflow: hidden;
    background: ${colorFormSelect};
    border: 1px solid #4a4a4a;
  }
`
