import { isEqual } from 'lodash'
import moment from 'moment'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { addBettingCartItem } from '../../../redux/sportsBettingSlice'
import {
  fetchSpecialFixtureListAction,
  fetchSpecialFixtureMarketAction,
  setFixtureCollapse,
  setFixtureMarketParam,
  setPageNum,
} from '../../../redux/sportsSpecialInfoSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import SportsSpecialContentMarket from './SportsSpecialContentMarket'

const SportEventFixtureItem = React.memo(
  ({
    fixtureKey,
    fixtureData,
    onClickSportFixtureMarketAdditionalOptionHandler,
    onClickAddBettingCartHandler,
    selectedFixtureId,
  }) => {
    // const filteredMainMarkets = useMemo(() => {
    //   return fixtureData.mainMarkets.filter(mainMarket => {
    //     return (
    //       (mainMarket.usedMarket && mainMarket.marketVisible) &&
    //       mainMarket.betLines.every(betLine => {
    //         return betLine.bets.every(bet => {
    //           return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
    //         })
    //       })
    //     )
    //   })
    // }, [fixtureData.mainMarkets])

    return (
      <Fragment key={`FixtureItem-${fixtureKey}-${fixtureData.fixtureId}`}>
        <SportsEventContentTopWrap>
          <SportsEventContentTopDate>
            <span>{convertToKstByFormat(fixtureData.fixture.startDate)}</span>
          </SportsEventContentTopDate>
          {fixtureData.totalMarketCount > 0 && (
            <SportsEventContentTopOption
              active={fixtureData.fixtureId === selectedFixtureId}
              onClick={() => {
                onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
              }}
            >
              <span>추가 배팅옵션</span>
            </SportsEventContentTopOption>
          )}
        </SportsEventContentTopWrap>
        <FixtureMarketBetLineBox
          active={fixtureData.fixtureId === selectedFixtureId}
          onClick={() => {
            onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
          }}
        >
          <FixtureMarketBetBoxWrap isActive={fixtureData.additionalOption}>
            <FixtureMarketBetBox>
              <FixtureMarketBetBoxName type="home-box">{fixtureData.fixture.homeName}</FixtureMarketBetBoxName>
            </FixtureMarketBetBox>
            <SportsEventContentItemPoint>VS</SportsEventContentItemPoint>
            <FixtureMarketBetBox>
              <FixtureMarketBetBoxName type="away-box">{fixtureData.fixture.awayName}</FixtureMarketBetBoxName>
            </FixtureMarketBetBox>
          </FixtureMarketBetBoxWrap>
        </FixtureMarketBetLineBox>
      </Fragment>
    )
  },
)

const SportEventFixtures = React.memo(
  ({
    eventKey,
    event,
    onClickFixtureCollapseHandler,
    onClickSportFixtureMarketAdditionalOptionHandler,
    onClickAddBettingCartHandler,
    selectedFixtureId,
  }) => {
    return (
      <SportsEventWrap key={eventKey}>
        <SportsEventTitleBox onClick={() => onClickFixtureCollapseHandler(eventKey)}>
          <SportsEventTitleTypeImg
            src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${event.sportId}.png`}
            alt=""
          />
          {event.locationImageUrl && <SportsEventTitleCountryImg src={event.locationImageUrl} alt="" />}
          <SportsEventTitleCountryInfo>{event.locationName}</SportsEventTitleCountryInfo>
          <SportsEventTitleArrowRightIcon />
          {event.leagueImageUrl && <SportsEventTitleLeagueImg src={event.leagueImageUrl} alt="" />}
          <SportsEventTitleLeagueInfo>{event.leagueName}</SportsEventTitleLeagueInfo>
          <SportsEventTitleLeagueCount>{event.fixtures.length}경기</SportsEventTitleLeagueCount>
          {event.fixtureLeagueCollapse ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
        </SportsEventTitleBox>
        {event.fixtureLeagueCollapse && (
          <SportsEventContentBox>
            {event.fixtures.map(fixtureData => {
              return (
                <SportEventFixtureItem
                  key={`${eventKey}-${fixtureData.fixtureId}`}
                  fixtureKey={eventKey}
                  fixtureData={fixtureData}
                  onClickSportFixtureMarketAdditionalOptionHandler={onClickSportFixtureMarketAdditionalOptionHandler}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                  selectedFixtureId={selectedFixtureId}
                />
              )
            })}
          </SportsEventContentBox>
        )}
      </SportsEventWrap>
    )
  },
)

const SportsSpecialContent = () => {
  const dispatch = useDispatch()

  const { totalElements, specialParams, specialMarketParams, specialFixtures } = useSelector(state => {
    const { sportsSpecialInfo } = state

    return {
      totalElements: sportsSpecialInfo.totalElements,
      specialParams: sportsSpecialInfo.specialParams,
      specialMarketParams: sportsSpecialInfo.specialMarketParams,
      specialFixtures: sportsSpecialInfo.specialFixtures,
    }
  })

  const { bettingCartList, bettingOkTimeCriteria } = useSelector(state => {
    const { sportsBetting } = state

    const sportsBettingInfo = sportsBetting.sportsBettingInfos[SportMarketType.SPECIAL]

    return {
      bettingCartList: sportsBetting.bettingCartList,
      bettingOkTimeCriteria: sportsBettingInfo?.bettingOkTimeCriteria ?? 0,
    }
  })

  const bettingCartListRef = useRef(bettingCartList)

  useEffect(() => {
    bettingCartListRef.current = bettingCartList
  }, [bettingCartList])

  const sportsEventListWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / specialParams.size))
    if (sportsEventListWrapRef.current) {
      sportsEventListWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }, [totalElements, specialParams])

  const onPageChange = pageNumber => {
    dispatch(setPageNum(pageNumber))
  }

  useEffect(() => {
    dispatch(fetchSpecialFixtureListAction())
  }, [dispatch, specialParams])

  useEffect(() => {
    dispatch(fetchSpecialFixtureMarketAction())
  }, [dispatch, specialMarketParams])

  // 경기 리그 펼치기
  const onClickFixtureCollapseHandler = useCallback(
    key => {
      dispatch(setFixtureCollapse(key))
    },
    [dispatch],
  )

  const [selectedFixtureInfo, setSelectedFixtureInfo] = useState(null)
  const selectedFixtureInfoRef = useRef(selectedFixtureInfo)

  useEffect(() => {
    selectedFixtureInfoRef.current = selectedFixtureInfo
  }, [selectedFixtureInfo])

  const onClickSportFixtureMarketAdditionalOptionHandler = useCallback(
    (eventKey, fixtureData) => {
      const diff = !isEqual(selectedFixtureInfoRef.current, {
        eventKey,
        fixtureId: fixtureData.fixtureId,
      })

      if (diff) {
        dispatch(setFixtureMarketParam(fixtureData.fixtureId))
        setSelectedFixtureInfo({
          eventKey,
          fixtureId: fixtureData.fixtureId,
        })
      } else {
        setSelectedFixtureInfo(null)
      }
    },
    [dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.SPECIAL,
        }),
      )
    },
    [dispatch],
  )

  return (
    <SportsContentWrap>
      <SportsEventListWrap ref={sportsEventListWrapRef}>
        {Object.keys(specialFixtures) === 0 ? (
          <SportsGameEmptyInfoBox key={`empty-${specialParams.sportId}-${specialParams.locationId}`}>
            해당 조건에 검색되는 경기가 없습니다.
          </SportsGameEmptyInfoBox>
        ) : (
          <>
            {Object.entries(specialFixtures).map(([key, event]) => {
              const currentTime = moment()
              const startDate = moment(event.startDate)
              const diff = Math.abs(currentTime.diff(startDate, 'seconds'))
              if (bettingOkTimeCriteria !== 0 && diff <= bettingOkTimeCriteria) {
                // 배팅 마감시간 지난 것
                return null
              }

              const filteredEventFixtures = event.fixtures.filter(fixtureItem => {
                return (
                  fixtureItem.fixture.fixtureVisible &&
                  fixtureItem.fixture.sportVisible &&
                  fixtureItem.fixture.locationVisible &&
                  fixtureItem.fixture.leagueVisible &&
                  fixtureItem.fixture.homeVisible &&
                  fixtureItem.fixture.awayVisible &&
                  (fixtureItem.fixture.fixtureStatusId === 1 || fixtureItem.fixture.fixtureStatusId === 9)
                )
              })

              if (filteredEventFixtures.length === 0) {
                return null
              }

              return (
                <SportEventFixtures
                  eventKey={key}
                  event={{
                    ...event,
                    fixtures: filteredEventFixtures,
                  }}
                  onClickFixtureCollapseHandler={onClickFixtureCollapseHandler}
                  onClickSportFixtureMarketAdditionalOptionHandler={onClickSportFixtureMarketAdditionalOptionHandler}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                  selectedFixtureId={selectedFixtureInfo?.fixtureId}
                />
              )
            })}
            <PaginationBox>
              <CustomPagination currentPage={specialParams.page} totalPages={totalPages} onPageChange={onPageChange} />
            </PaginationBox>
          </>
        )}
      </SportsEventListWrap>

      <SportsSpecialContentMarket selectedFixtureInfo={selectedFixtureInfo} />
    </SportsContentWrap>
  )
}

export default SportsSpecialContent

const SportsContentWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`

const SportsEventListWrap = styled.div`
  width: 100%;
  height: calc(100vh - 275px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
`

// 경기가 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

// 페이징
const PaginationBox = styled.div`
  width: 100%;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
`

const SportsEventTitleBox = styled.div`
  padding: 0 10px;
  height: 36px;
  width: 100%;
  color: white;
  background: rgba(48, 48, 48, 1);
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`
const SportsEventTitleCountryImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  color: ${HermesMainHover2};
  width: 17px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleLeagueCount = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  cursor: pointer;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  cursor: pointer;
`

const SportsEventContentBox = styled.div`
  width: 100%;
  min-height: 50px;
  color: white;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border: 2px solid #3a3a3a;
  gap: 2px;
`

const SportsEventContentTopWrap = styled.div`
  width: 100%;
  min-height: 1px;
  color: white;
  margin-bottom: 1px;

  &:not(:first-child) {
    margin-top: 5px;
  }
`

const SportsEventContentTopDate = styled.div`
  border-radius: 3px;
  color: #fff;
  float: left;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    color: ${HermesMainText};
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
  }
`

const SportsEventContentTopOption = styled.div`
  border-radius: 3px;
  color: ${HermesMainText};
  float: right;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  background: ${HermesMainSelect};

  span {
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    color: #fff;
  }

  ${props =>
    props.active &&
    `
      cursor: pointer;
      color: #fff;
    `}
`

const FixtureMarketBetBox = styled.div`
  width: ${props => props.wd || 'calc(50% - 39px);'};
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  background: rgba(48, 48, 48, 0.6);
`

const FixtureMarketBetBoxName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'home-box'
      ? `
    justify-content: start;
    padding-left: 10px;
    `
      : `
    justify-content: end;
    padding-right: 10px;
    `}
`

const SportsEventContentItemPoint = styled.div`
  width: 100px;
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: rgba(48, 48, 48, 0.6);

  font-weight: 600;
`

const FixtureMarketBetLineBox = styled.div`
  width: 100%;
  min-height: 36px;
  height: auto;
  display: flex;
  flex-direction: ${props => props.fd || 'column'};

  ${props =>
    props.active &&
    `
    cursor: pointer;
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const FixtureMarketBetBoxWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;

  &:hover {
    ${FixtureMarketBetBox}, ${SportsEventContentItemPoint} {
      cursor: pointer;
      background: ${HermesMainSelect};
      color: ${HermesMainText};
    }
  }

  ${props =>
    props.isActive &&
    `
    ${FixtureMarketBetBox}, ${SportsEventContentItemPoint} {
        background: ${HermesMainSelect};
        color: ${HermesMainText};
      }
    `}
`
