import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HermesMainBorder } from '../../../utils/palette'
import SportsBettingHistory from '../../BettingHistory/SportsBettingHistory'
import MiniGameBettingHistory from '../../BettingHistory/MiniGameBettingHistory'
import CasinoBettingHistoryPage from '../../BettingHistory/CasinoBettingHistory/CasinoBettingHistoryPage'
import SportsBettingHistoryV2 from '../../BettingHistory/SportsBettingHistoryV2'
import VirtualGameBettingHistory from '../../BettingHistory/VirtualGameBettingHistory'
import HoldemBettingHistory from '../../BettingHistory/HoldemBettingHistory/HoldemBettingHistory'
import WildGamesBettingHistory from '../../BettingHistory/WildGamesBettingHistory/WildGamesBettingHistory'

const BettingHistoryModalContent = () => {
  const [active, setActive] = useState('sports')
  const getBettingHistoryComponent = () => {
    switch (active) {
      case 'sports':
        return <SportsBettingHistoryV2 />
      case 'casino':
        return <CasinoBettingHistoryPage />
      case 'minigame':
        return <MiniGameBettingHistory />
      case 'virtualgame':
        return <VirtualGameBettingHistory />
      case 'revolution-holdem':
        return <HoldemBettingHistory />
      case 'wild-games':
        return <WildGamesBettingHistory />
      default:
        return <></>
    }
  }

  return (
    <ContentWrap>
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <SubMenuItemListWrap>
            <div>
              <SubMenuItemBox active={active === 'sports'} onClick={() => setActive('sports')}>
                스포츠
              </SubMenuItemBox>
              <SubMenuItemBox active={active === 'casino'} onClick={() => setActive('casino')}>
                카지노
              </SubMenuItemBox>
              <SubMenuItemBox active={active === 'minigame'} onClick={() => setActive('minigame')}>
                미니게임
              </SubMenuItemBox>
              <SubMenuItemBox active={active === 'virtualgame'} onClick={() => setActive('virtualgame')}>
                가상게임
              </SubMenuItemBox>
              <SubMenuItemBox active={active === 'revolution-holdem'} onClick={() => setActive('revolution-holdem')}>
                레볼루션 홀덤
              </SubMenuItemBox>
              <SubMenuItemBox active={active === 'wild-games'} onClick={() => setActive('wild-games')}>
                와일드 게임즈
              </SubMenuItemBox>
            </div>
          </SubMenuItemListWrap>

          <ContentBox>{getBettingHistoryComponent()}</ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default BettingHistoryModalContent

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  // height: 550px;
  height: calc(100vh - 270px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  // active 일때
  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`
