import {
  CustomCenterFormBottomWrap,
  MobileAttendanceHeaderWrap,
  MobileTopWrap,
} from '@/shared/components/MoneyCustomElement'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  searchMemberAttendanceHistory,
  searchMemberYesterdayAttendance,
} from '../../api/memberHistory/memberHistoryApi'
import { createUserAttendance } from '../../api/memberManage/memberManageApi'
import CustomLoading from '../../containers/CustomLoading'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import SilverButtonH40 from '../../shared/components/SilverButton'
import { MoneyFormWrap } from '../../shared/components/form/newForm'
import { HermesMainBorder, HermesMainText, colorMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MobileAttendanceListData from './MobileAttendanceListData'

const MobileAttendanceForm = () => {
  const [myInfoLoading] = useState(true)
  const [assetLoading, setAssetLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  /**
   *  회원 어제 출석연속 번호 조회
   */
  const [consecutiveNum, setConsecutiveNum] = useState(0)
  const [minimumAttendanceAmount, setMinimumAttendanceAmount] = useState(0)
  const [thirdAttendanceBonus, setThirdAttendanceBonus] = useState(0)
  const [sixthAttendanceBonus, setSixthAttendanceBonus] = useState(0)

  useEffect(() => {
    setLoading(true)
    searchMemberYesterdayAttendance()
      .then(res => {
        setConsecutiveNum(res.data.consecutiveNum)
        setMinimumAttendanceAmount(res.data.minimumAttendanceAmount)
        setThirdAttendanceBonus(res.data.thirdAttendanceBonus)
        setSixthAttendanceBonus(res.data.sixthAttendanceBonus)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [])

  const fetchSearchMemberConsecutiveNum = async () => {
    await searchMemberYesterdayAttendance()
      .then(res => {
        setConsecutiveNum(res.data.consecutiveNum)
      })
      .catch(error => {})
  }

  /**
   *  회원 출석내역 조회
   */

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberAttendanceHistory(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberAttendance = async () => {
    setLoading(true)
    await searchMemberAttendanceHistory(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [historyListData, setHistoryListData] = useState(MobileAttendanceListData(content))

  useEffect(() => {
    setHistoryListData(MobileAttendanceListData(content))
  }, [content])

  const [rows, setData] = useState(historyListData.tableRowsData)

  useEffect(() => {
    setData(historyListData.tableRowsData)
  }, [historyListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  // redux 사용
  const dispatch = useDispatch()

  const onSubmit = e => {
    console.log('ddd')
    if (apiFlag) return
    setApiFlag(true)
    const body = {}

    createUserAttendance(body)
      .then(res => {
        if (consecutiveNum === 2) {
          alert(`${thirdAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else if (consecutiveNum === 5) {
          alert(`${sixthAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else {
          alert('출석이 완료됐습니다.')
        }
        dispatch(fetchMemberInfoAction())
        fetchSearchMemberConsecutiveNum()
        fetchSearchMemberAttendance()
        setApiFlag(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ATTD-1001':
            alert(`${message}`)
            break
          case 'ATTD-1002':
            alert(`${message}`)
            break
          case 'EVENT-1002':
            alert(`${message}`)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('출석이 실패했습니다. 다시 시도해주세요.')
        }
        setApiFlag(false)
      })
  }

  return (
    <MobileTopWrap>
      <MobileAttendanceHeaderWrap>
        <MoneyDepositHeaderInfo>출석하기</MoneyDepositHeaderInfo>
        <MoneyDepositCardSubhead>
          * 당일 1회에 {minimumAttendanceAmount?.toString().replace(commonReg2, ',')}원 이상 입금내역이 있어야
          출석체크가 가능합니다.
          <br />
          &nbsp;&nbsp;(2만원 입금 후, 추가 3만원 시 불가)
        </MoneyDepositCardSubhead>
      </MobileAttendanceHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MoneyDepositBodyWrap>
          <MoneyDepositInfoWrap style={{ gap: '10px' }}>
            <AttendanceWrap>
              {consecutiveNum > 0 ? (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                  <AttendanceInfo style={{ color: `${HermesMainText}` }}>출석 1일차</AttendanceInfo>
                </>
              ) : (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                  <AttendanceInfo>출석 1일차</AttendanceInfo>
                </>
              )}
            </AttendanceWrap>
            <AttendanceWrap>
              {consecutiveNum > 1 ? (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                  <AttendanceInfo style={{ color: `${HermesMainText}` }}>출석 2일차</AttendanceInfo>
                </>
              ) : (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                  <AttendanceInfo>연속 2일차</AttendanceInfo>
                </>
              )}
            </AttendanceWrap>
            <AttendanceWrap>
              {consecutiveNum > 2 ? (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                  <AttendanceInfo style={{ color: `${HermesMainText}` }}>
                    {thirdAttendanceBonus?.toString().replace(commonReg2, ',')}포인트
                  </AttendanceInfo>
                </>
              ) : (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                  <AttendanceInfo>{thirdAttendanceBonus?.toString().replace(commonReg2, ',')}포인트</AttendanceInfo>
                </>
              )}
            </AttendanceWrap>
          </MoneyDepositInfoWrap>
          <MoneyDepositInfoWrap style={{ gap: '10px' }}>
            <AttendanceWrap>
              {consecutiveNum > 3 ? (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                  <AttendanceInfo style={{ color: `${HermesMainText}` }}>연속 4일차</AttendanceInfo>
                </>
              ) : (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                  <AttendanceInfo>연속 4일차</AttendanceInfo>
                </>
              )}
            </AttendanceWrap>
            <AttendanceWrap>
              {consecutiveNum > 4 ? (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                  <AttendanceInfo style={{ color: `${HermesMainText}` }}>연속 5일차</AttendanceInfo>
                </>
              ) : (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                  <AttendanceInfo>연속 5일차</AttendanceInfo>
                </>
              )}
            </AttendanceWrap>
            <AttendanceWrap>
              {consecutiveNum > 5 ? (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                  <AttendanceInfo style={{ color: `${HermesMainText}` }}>
                    {sixthAttendanceBonus?.toString().replace(commonReg2, ',')}포인트
                  </AttendanceInfo>
                </>
              ) : (
                <>
                  <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                  <AttendanceInfo>{sixthAttendanceBonus?.toString().replace(commonReg2, ',')}포인트</AttendanceInfo>
                </>
              )}
            </AttendanceWrap>
          </MoneyDepositInfoWrap>
          <Form
            onSubmit={onSubmit}
            initialValues={
              {
                // inquiryButton,
              }
            }
          >
            {({ handleSubmit, form: { reset } }) => (
              <CustomCenterFormBottomWrap onSubmit={handleSubmit}>
                <AttendanceBtn type="submit" onClick={handleSubmit}>
                  <AttendanceBtnInfo>출석하기</AttendanceBtnInfo>
                </AttendanceBtn>
              </CustomCenterFormBottomWrap>
            )}
          </Form>
        </MoneyDepositBodyWrap>
      )}
      <MoneyDepositHeaderThirdInfo>&lt;&nbsp;출석 내역&nbsp;&gt;</MoneyDepositHeaderThirdInfo>
      <ReactTableBase columns={historyListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
    </MobileTopWrap>
  )
}

export default MobileAttendanceForm

const AttendanceBtn = styled.button`
  width: 100px;
  height: 38px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 2px solid ${HermesMainBorder};
`

const AttendanceBtnInfo = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 700;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }
`

const AttendanceWrap = styled.div`
  width: 120px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: solid 1px #525252;
`

const AttendanceImg = styled.img`
  width: 110px;
  height: 110px;
  display: flex;
`

const AttendanceInfo = styled.div`
  display: flex;
  color: #b6b6b6;
`

const MoneyDepositHeaderThirdInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  margin-left: 10px;
  /* 엘로우폰트 */

  color: ${HermesMainText};
`

const MoneyDepositHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;
`

const MoneyDepositWrap = styled.div`
  margin-top: 10px;
  background: #000000;
  border: 1px solid #edae07;

  height: 100%;
  min-height: 550px;

  // position: relative;
  // top: 30px;
`

const MoneyDepositHeaderWrap = styled.div`
  height: 130px;
  border-top: 1px solid #edae07;
  padding: 0 0 0 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
  /* 엘로우폰트 */

  color: ${HermesMainText};
`
export const MoneyDepositCardSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

const MoneyDepositBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 20px;
  gap: 10px;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
`
