import React, { useState } from 'react'
import styled from 'styled-components'
import { HermesMainSelect, HermesMainText } from '../../../utils/palette'
import HoldemBettingLog from './HoldemBettingLog'
import HoldemBuyInOutLog from './HoldemBuyInOutLog'
import HoldemDetailBettingHistory from './HoldemDetailBettingLog'

const HoldemBettingHistory = () => {
  const [subMenu, setSubMenu] = useState('BETTING_LOG')

  // 미니게임 종목 선택
  const onClickSubMenuHandler = menu => {
    setSubMenu(menu)
  }

  const getHistoryCompo = () => {
    switch (subMenu) {
      case 'BETTING_LOG':
        return <HoldemBettingLog menuType="BETTING_LOG" />
      case 'DETAIL_BETTING_LOG':
        return <HoldemDetailBettingHistory menuType="DETAIL_BETTING_LOG" />
      case 'BUYINOUT_LOG':
        return <HoldemBuyInOutLog menuType="BUYINOUT_LOG" />
      default:
        return null
    }
  }

  return (
    <BettingHistoryTableWrap className="sports-container">
      <ListWrap>
        <SubMenuItemListWrap>
          <SubMenuItemBox
            active={subMenu === 'BETTING_LOG'}
            onClick={() => {
              onClickSubMenuHandler('BETTING_LOG')
            }}
          >
            배팅내역
          </SubMenuItemBox>
          <SubMenuItemBox
            active={subMenu === 'DETAIL_BETTING_LOG'}
            onClick={() => {
              onClickSubMenuHandler('DETAIL_BETTING_LOG')
            }}
          >
            배팅 세부내역
          </SubMenuItemBox>
          <SubMenuItemBox
            active={subMenu === 'BUYINOUT_LOG'}
            onClick={() => {
              onClickSubMenuHandler('BUYINOUT_LOG')
            }}
          >
            바이인/아웃 내역
          </SubMenuItemBox>
        </SubMenuItemListWrap>
      </ListWrap>
      {getHistoryCompo()}
    </BettingHistoryTableWrap>
  )
}

export default HoldemBettingHistory

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  height: 55px;
  // padding-left: 10px;
  gap: 10px;
  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  background-color: #1b1c1f;
  padding: 10px 10px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 140px;
  height: 43px;
  &:hover {
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-top: -20px;
`
