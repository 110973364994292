import { SocketIOEventName } from '../utils/enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../utils/enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../utils/socket'

/**
 * 홀덤 게임 접속 링크
 */
export const getHoldemGameLink = async params => {
  try {
    const payload = {
      type: SocketIORequestMessageType.HOLDEM_GAME_LINK,
      payload: params,
    }

    return await emitPromise(socket, SocketIOEventName.FIND, payload)
  } catch (error) {
    console.log('error ', error)
  }
}

/**
 * 와일드게임즈 게임 접속 링크
 */
export const getWildGamesGameLink = async params => {
  try {
    const payload = {
      type: SocketIORequestMessageType.WILD_GAMES_GAME_LINK,
      payload: params,
    }

    return await emitPromise(socket, SocketIOEventName.FIND, payload)
  } catch (error) {
    console.log('error ', error)
  }
}
