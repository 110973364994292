import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import CustomLoading from '../../../containers/CustomLoading'
import { fetchEventBoardList } from '../../../redux/customCenterSlice'
import {
  MobileCustomerCenterHeaderInfo,
  MobileCustomerCenterHeaderWrap,
  MobileTopWrap,
} from '../../../shared/components/MoneyCustomElement'
import Portal from '../../../shared/components/Portal'

const MobileEventList = () => {
  const [isModalShow, setModalShow] = useState(false)
  useEffect(() => {
    if (isModalShow) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [isModalShow])
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)

  useEffect(() => {
    setScreenHeight(window.innerHeight)
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  const [boardParams, setBoardParams] = useState({
    boardType: 'EVENT',
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [content, setContent] = useState([])
  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.eventInfo.totalElement,
      apiContent: customCenter.eventInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchBoardList = async () => {
    try {
      dispatch(fetchEventBoardList(boardParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const [hangData, setHandData] = useState({})
  const onClickRowHandler = item => {
    setHandData({
      boardContent: item.boardContent,
      boardTitle: item.boardTitle,
      imgUrl: item.imgUrl,
    })
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)

  const onChangeModalState = () => {
    setModalShow(prev => !prev)
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <motion.div initial={{ y: screenHeight }} animate={{ y: 0 }} exit={{ y: -50 }} transition={{ duration: 0.6 }}>
          <MobileTopWrap ref={webPageWrapRef}>
            {loading ? (
              <CustomLoading />
            ) : (
              <EventRealWrap>
                {content.length === 0 ? (
                  <NoContentDiv>
                    <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
                    최근 이벤트가 없습니다.
                  </NoContentDiv>
                ) : (
                  <EventWrap>
                    {content.map((item, index) => (
                      <>
                        <EventContentBox
                          onClick={() => {
                            onChangeModalState()
                            onClickRowHandler(item)
                          }}
                        >
                          <div
                            style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <img src={`${item.imgUrl}`} alt="" />
                          </div>
                          {/* <EventContentTitle>Event{index + 1}</EventContentTitle>
                        <EventContentTitle2>{ReactHtmlParser(item.boardTitle)}</EventContentTitle2> */}
                        </EventContentBox>
                      </>
                    ))}
                  </EventWrap>
                )}
              </EventRealWrap>
            )}
          </MobileTopWrap>
        </motion.div>
      </div>
      {isModalShow && (
        <Portal elementId="signup-modal">
          <SignUpModalOverlay visible={isModalShow} />
          <SignUpModalWrapper
            // onClick={onChangeModalState}
            tabIndex="-1"
            visible={isModalShow}
            className="sidebar-container"
          >
            <SignUpModalInner tabIndex="0">
              <SignUpModalHeader>
                <SignUpModalCloseButton
                  onClick={onChangeModalState}
                  src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                  alt=""
                />
              </SignUpModalHeader>
              <SignUpModalContentWrap className="game-container">
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <SignUpModalContentFirst src={`${hangData.imgUrl}`} alt="" />
                </div>

                <SignUpModalContentSecondWrap>
                  <SignUpModalContentSecond>{ReactHtmlParser(hangData.boardTitle)}</SignUpModalContentSecond>
                  <SignUpModalContentThird>
                    {ReactHtmlParser(hangData.boardContent, { transform: transformFn })}
                  </SignUpModalContentThird>
                </SignUpModalContentSecondWrap>
              </SignUpModalContentWrap>
            </SignUpModalInner>
          </SignUpModalWrapper>
        </Portal>
      )}
    </>
  )
}
export default MobileEventList

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

export const MobileNoContentBox = styled.div`
  display: flex
  width: 100%;
  color: #fff;
  font-size: 13px;
  text-align: center;
  align-items: center;
  margin-top: 30px;
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9991;
  overflow: auto;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9990;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background: rgb(16, 16, 18, 1);
  width: 100vw;
  margin: 0 auto;
  max-height: 550px;
  margin-top: 5vh;
  overflow: auto;
  word-break: break-all;
  border: solid 1px #525252;
`

const SignUpModalHeader = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  color: #606266;
  word-break: break-all;
  padding: 0 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // padding-bottom: 30px;
`

// modal body content
const SignUpModalContentFirst = styled.img`
  width: 348px;
  height: 192px;
`

const SignUpModalContentSecondWrap = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const SignUpModalContentSecond = styled.div`
  width: 100%;
  color: #fff;
  font-size: 14px;
`

const SignUpModalContentThird = styled.div``

const EventRealWrap = styled.div``

const EventWrap = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(auto-fill, minmax(348px, 1fr));
  margin-top: 20px;
  padding: 10px 2px;
  gap: 20px;
`

const EventContentBox = styled.div`
  width: 370px;
  height: 200px;
  background: rgb(26, 28, 31);
  display: flex;
  padding: 5px;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  transition: transform 0.3s ease-out, background 0.3s ease-out; // 애니메이션 효과 지속 시간과 형태 설정

  &:hover {
    // 호버 상태 설정
    cursor: pointer;
    background: rgb(39, 43, 46);
    transform: translateY(-10px); // 상단으로 10픽셀만큼 올림
  }

  img {
    width: 100%;
    height: auto;
  }
`

const EventContentTitle = styled.div`
  width: 100%;
  color: #eec100;
  padding-left: 20px;
`
const EventContentTitle2 = styled.div`
  width: 100%;
  color: #fff;
  padding-left: 20px;
`
