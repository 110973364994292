import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  createUserLogAction,
  initHomeInfoAction,
  initPopupInfoAction,
  initSportsInfoAction,
  initVirtualInfoAction,
  reAuthenticationAction,
  setNoteMessageInfo,
} from '../../redux/memberInfoSlice'
import { onShowModalHandler, setModalShow } from '../../redux/modalSlice'
import useAudio from '../../utils/audioHook'
import isLogin from '../../utils/enums/authority'
import { decodeAccessToken } from '../../utils/token'
import NoteAlert from '../NoteAlert'
import OneToOneAlert from '../OneToOneAlert'
import Header from './Header'
import LiveSportsBettingCartSidebar from './LiveSportsBettingCartSidebar'
import MiniGameBettingCartSidebar from './MiniGameBettingCartSidebar'
import SideMenuModal from './SideMenu/SideMenuModal'
import SportsBettingCartSidebar from './SportsBettingCartSidebar'
import SportsGameSidebar from './SportsGameSidebar'
import VirtualGameBettingCartSidebar from './VirtualGameBettingCartSidebar'

// TODO  https://fourwingsy.medium.com/%ED%8C%9D%EC%97%85%EC%99%80-%EB%A9%94%EC%8B%9C%EC%A7%80-with-react-afd1923797d8
const Layout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pathName, setPathName] = useState('')

  const [alarmSrc, setAlarmSrc] = useState(`${process.env.PUBLIC_URL}/audio/noteAudio.mp3`)

  const [play, pause, paused, audioRef] = useAudio(alarmSrc, {
    // playbackRate: 2,
  })

  useEffect(() => {
    setPathName(location.pathname)
  }, [location])

  const getBettingCartComponent = path => {
    switch (path) {
      case '/sportsgame':
        return <SportsBettingCartSidebar />
      case '/live-sports':
        return <LiveSportsBettingCartSidebar />
      default:
        if (path.includes('/minigame')) {
          return <MiniGameBettingCartSidebar />
        }
        if (path.includes('/virtual')) {
          return <VirtualGameBettingCartSidebar />
        }

        return <SportsBettingCartSidebar />
    }
  }

  const { noteRedisSize, noteMessageInfo, userId, userName, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      noteMessageInfo: memberInfo.noteMessageInfo,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const { sideMenuModalShow, modalTitle, isShowLeftMenuSidebar } = useSelector(state => {
    const { modalInfo } = state

    return {
      sideMenuModalShow: modalInfo.modalShow,
      modalTitle: modalInfo.modalTitle,
      isShowLeftMenuSidebar: modalInfo.isShowLeftMenuSidebar,
    }
  })

  useEffect(() => {
    dispatch(initHomeInfoAction())
    dispatch(initPopupInfoAction())
    dispatch(initSportsInfoAction())
    dispatch(initVirtualInfoAction())

    if (isLogin()) {
      dispatch(reAuthenticationAction({ newWindow: false }))
    }
  }, [dispatch])

  useEffect(() => {
    setPathName(location.pathname)

    if (isLogin()) {
      const userTokenInfo = decodeAccessToken()

      dispatch(
        createUserLogAction({
          userId: userTokenInfo.userId,
          userName: userTokenInfo.userName,
          position: location.pathname,
          devicePixelRatio: window.devicePixelRatio,
          userAgent: window.navigator.userAgent,
          resolution: `${window.screen.width}X${window.screen.height}`,
          windowSize: `${window.innerWidth}X${window.innerHeight}`,
          platform: window.navigator?.platform || '',
        }),
      )
    }
  }, [location])

  // 쪽지 모달이 켜져 있으면 false
  const [isValidPath, setIsValidPath] = useState(false)

  useEffect(() => {
    if (sideMenuModalShow && modalTitle === 'NOTE') {
      setIsValidPath(false)
    } else if (sideMenuModalShow && modalTitle === 'ONETOONE') {
      setIsValidPath(false)
    } else {
      setIsValidPath(true)

      if (noteRedisSize > 0 && paused()) {
        play()
      }

      if (oneToOneRedisSize > 0 && paused()) {
        play()
      }
    }
  }, [oneToOneRedisSize, noteRedisSize, sideMenuModalShow, modalTitle, paused, play])

  const onClickCloseHandler = () => {
    dispatch(setNoteMessageInfo(''))
    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title: 'NOTE',
      }),
    )
  }

  const containerSecondWrapRef = useRef(null)
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    if (containerSecondWrapRef.current) {
      setScrollPosition(containerSecondWrapRef.current.scrollTop)
    }
  }

  const scrollToTop = () => {
    if (containerSecondWrapRef.current) {
      containerSecondWrapRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <ContainerWrap className="game-container">
      {isValidPath && noteRedisSize > 0 && <NoteAlert noteSize={noteRedisSize} />}
      {isValidPath && noteRedisSize === 0 && oneToOneRedisSize > 0 && <OneToOneAlert noteSize={oneToOneRedisSize} />}
      {sideMenuModalShow && <SideMenuModal visible={sideMenuModalShow} onClose={() => dispatch(setModalShow(false))} />}
      <Header />
      <SportsGameSidebar />
      <ContainerSecondWrap className="game-container" ref={containerSecondWrapRef} onScroll={handleScroll}>
        <Container className="game-container" isShowLeftMenuSidebar={isShowLeftMenuSidebar}>
          <Outlet />
        </Container>
        {/* <ScrollButton scrollPosition={scrollPosition} scrollToTop={scrollToTop} /> */}
      </ContainerSecondWrap>

      {getBettingCartComponent(pathName)}
      {/* <LiveChat /> */}
    </ContainerWrap>
  )
}

export default Layout

const ContainerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const LeftSidebarWrap = styled.div`
  position: fixed;
  top: 160px;
  left: 0;
  margin-top: 10px;
  width: 300px;
  height: calc(100vh - 240px);
  border: solid 1px #edae07;
  // z-index: 301;
`

const RightSidebarWrap = styled.div`
  position: fixed;
  top: 160px;
  right: 5px;
  margin-top: 10px;
  width: 300px;
  height: calc(100vh - 240px);
  // border: solid 1px #edae07;
  // z-index: 301;
`

const BettingCartButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const ContainerSecondWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll !important;
`

const Container = styled.div`
  margin-top: 65px;
  background-color: #111314;
  transition: all 0.5s ease-out 0s;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-family: 'Malgun Gothic', dotum;

  width: 100%;
  padding-left: 310px;
  padding-right: 330px;

  ${props =>
    !props.isShowLeftMenuSidebar &&
    `
    padding-left: 200px;
    padding-right: 440px;
`}

  @media (max-width: 1670px) {
    /* 대형 데스크탑보다 작은 화면에 적용되는 스타일 */
    padding-left: 70px;
    padding-right: 310px;
  }

  @media (max-width: 1440px) {
    padding-left: 70px;
    padding-right: 30px;
    min-width: max-content;
  }
`
