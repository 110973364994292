import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CustomLoading from '../../CustomLoading'
import SportsOverseasContent from './SportsOverseasContent'
import SportsOverseasHeader from './SportsOverseasHeader'

const SportsOverseasPage = () => {
  const { overseasLoading, bettingCartLoading } = useSelector(state => {
    const { sportsOverseasInfo, sportsBetting } = state

    return {
      overseasLoading: sportsOverseasInfo.overseasLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })

  return (
    <>
      <SportsPageWrap>
        {(overseasLoading || bettingCartLoading) && (
          <SportsPageLoadingOverlay>
            <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
          </SportsPageLoadingOverlay>
        )}
        <SportsContentWrap>
          <SportsOverseasHeader />
          <SportsOverseasContent />
        </SportsContentWrap>
      </SportsPageWrap>
    </>
  )
}

export default SportsOverseasPage

const SportsPageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsPageWrap = styled.div`
  width: 1277px;
  overflow: hidden;
`

const SportsContentWrap = styled.div`
  border-radius: 0 0 3px 3px;
  background: #111314;
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: 'Malgun Gothic', dotum;
  padding-bottom: 10px;
`
