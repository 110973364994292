import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BiLock } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { decodeAccessToken } from '../../utils/token'
import { getHoldemGameLink, getWildGamesGameLink } from '../../socketio/holdemSocketIOHandler'
import CustomLoading from '../../containers/CustomLoading'
import { searchWildGamesGameList } from '../../api/game/holdemApi'
import { HermesMainBorder, HermesMainHover, HermesMainText } from '../../utils/palette'

const MobileWildGamesPage = () => {
  const [loading, setLoading] = useState(false)

  const { wildHoldemInfo } = useSelector(state => {
    const { memberInfo } = state

    return {
      wildHoldemInfo: memberInfo.wildHoldemInfo,
    }
  })

  const [gameList, setGameList] = useState([])

  const fetchWildGamesGameList = async () => {
    setLoading(true)

    await searchWildGamesGameList()
      .then(res => {
        setGameList(res.data.gameList)
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchWildGamesGameList()
  }, [])

  const holdemGameStartHandler = wildGamesGameInfoKey => {
    if (loading) return

    if (wildHoldemInfo.inspection) {
      alert('현재 점검중입니다.')
      return
    }

    setLoading(true)

    const params = {
      userId: decodeAccessToken().userId,
      wildGamesGameInfoKey,
      platform: 'WEB',
    }

    getWildGamesGameLink(params)
      .then(res => {
        if (res.status) {
          window.open(res.data.gameUrl)
        } else {
          alert(res.data)
        }
      })
      .catch(error => {
        alert('접속 실패했습니다. 잠시후 다시 시도해주세요.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      {loading && <CustomLoading />}
      <HoldemPageWrapper>
        <HeaderWrap>
          <HoldemTitleBox>
            <HoldemTitleIconBox>
              <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-holdem.png`} alt="" />
            </HoldemTitleIconBox>
            <HoldemTitleTextBox>{wildHoldemInfo.title}</HoldemTitleTextBox>
          </HoldemTitleBox>
        </HeaderWrap>
        <BannerWrap>
          <img src={`${process.env.PUBLIC_URL}/img/holdem/mobile-holdem-banner.gif`} alt="" />
        </BannerWrap>

        <GameListWrap>
          {gameList.map(game => {
            return (
              <GameBox
                key={game.wildGamesGameInfoKey}
                isInspection={game.isInspection}
                onClick={() => {
                  if (game.isInspection) return
                  holdemGameStartHandler(game.wildGamesGameInfoKey)
                }}
              >
                {game.isInspection && (
                  <InspectOverlay>
                    <BiLock />
                    <span>점검중</span>
                  </InspectOverlay>
                )}
                <GameBoxImg src={game.gameIconUrl} alt="" />
                <GameBoxTitle>{game.gameKoreanName}</GameBoxTitle>
              </GameBox>
            )
          })}
        </GameListWrap>
      </HoldemPageWrapper>
    </>
  )
}

export default MobileWildGamesPage

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  color: ${HermesMainText};
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  span {
    position: absolute;
    top: 70%;
    left: 50%;
    font-size: 18px;
    font-weight: 700;
    transform: translate(-50%, -70%);
  }

  svg {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 90px;
    height: 90px;
  }
`

const HoldemPageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0 5px;
`

const HeaderWrap = styled.div`
  width: 100%;
  padding: 10px;
  padding-top: 20px;
  margin-bottom: 20px;
`

const HoldemTitleBox = styled.div`
  width: 100%;
  height: 26px;
`
const HoldemTitleIconBox = styled.div`
  float: left;

  img {
    width: auto;
    height: 24px;
  }
`

const HoldemTitleTextBox = styled.div`
  float: left;
  margin-left: 12px;
  margin-top: 1px;
  font-family: 'Malgun Gothic', dotum;
  color: #fff;
  font-size: 16px;
`

const BannerWrap = styled.div`
  width: 100%;
  display: block;

  cursor: pointer;

  img {
    width: 100%;
  }
`

const GameListWrap = styled.div`
  width: 100%;
  display: flex;
  flex: 48%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  gap: 5px;

  margin-top: 10px;
`

const GameBoxImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5% 5% 0 0;

  border-bottom: 1px solid ${HermesMainBorder};
`

const GameBoxTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;

  font-size: 15px;
  font-weight: 700;

  color: #fff;
`

const GameBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;

  position: relative;
  z-index: 1;

  border: 1px solid ${HermesMainBorder};
  border-radius: 5% 5% 0 0;

  &:hover {
    ${({ isInspection }) =>
      !isInspection &&
      `
      cursor: pointer;
      
      ${GameBoxTitle} {
        background: ${HermesMainHover};
      }

      ${GameBoxImg} {
        opacity: 0.7;
      }
    `}
  }
`
