import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { addBettingCartItem } from '../../../redux/sportsBettingSlice'
import { setSelectedFixtureMarketCollapse } from '../../../redux/sportsLiveInfoSlice'
import { CommonBetBox } from '../../../shared/components/SportCommonBetBox'
import { SportMarketType } from '../../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainHover2 } from '../../../utils/palette'

const ContentMarket = React.memo(({ fixture, market, onClickMarketCollapseHandler, onClickAddBettingCartHandler }) => {
  return (
    <MarketWrap>
      <MarketNameBox
        onClick={() => {
          onClickMarketCollapseHandler(market.marketId)
        }}
      >
        <span>{market.marketName}</span>
        {market.marketCollapse ? <MarketCollapseArrowUpIcon /> : <MarketCollapseArrowDownIcon />}
      </MarketNameBox>
      {market.marketCollapse && (
        <MarketBetListBox>
          <CommonBetBox
            fixtureInfo={fixture}
            marketInfo={market}
            onClickAddBettingCartHandler={onClickAddBettingCartHandler}
          />
        </MarketBetListBox>
      )}
    </MarketWrap>
  )
})

const MobileSportsLiveContentMarket = ({ selectedFixtureInfo }) => {
  const dispatch = useDispatch()

  const [selectedFixture, setSelectedFixture] = useState({})

  const { liveFixtures } = useSelector(state => {
    const { sportsLiveInfo } = state

    return {
      liveFixtures: sportsLiveInfo.liveFixtures,
    }
  })

  const parentRef = useRef(null)
  const [liveTrackerHeight, setLiveTrackerHeight] = useState(400)

  useEffect(() => {
    if (!selectedFixtureInfo) {
      setSelectedFixture({})
      return
    }

    if (Object.keys(selectedFixtureInfo).length === 0) return

    const fixture = liveFixtures[selectedFixtureInfo.eventKey]?.fixtures.find(
      fixtureItem => fixtureItem.fixtureId === selectedFixtureInfo.fixtureId,
    )

    if (!fixture) {
      setSelectedFixture({})
      return
    }

    setSelectedFixture(fixture)
  }, [liveFixtures, selectedFixtureInfo])

  const setCalculatedHeight = sportId => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.offsetWidth

      if (sportId === 6046) {
        // [축구] (404 / 800 * width) + 50 (142 - 92 통계 height )
        setLiveTrackerHeight(400)
        return
      }

      if (sportId === 48242) {
        // [농구] (404 / 800 * width) + 64 (156 - 92 통계 height)
        setLiveTrackerHeight(400)

        return
      }

      if (sportId === 54094) {
        // [테니스]
        setLiveTrackerHeight(400)

        return
      }

      if (sportId === 154830) {
        // [배구] (404 / 800 * width) + 50 (142 - 92 통계 height )
        setLiveTrackerHeight(355)
        return
      }

      if (sportId === 131506) {
        // [미식 축구]
        setLiveTrackerHeight(400)

        return
      }

      if (sportId === 154914) {
        // [야구] (404 / 800 * width) + 50 (142 - 92 통계 height )
        setLiveTrackerHeight(355)

        return
      }

      if (sportId === 35232) {
        // [아이스하키] (404 / 800 * width) + 50 (142 - 92 통계 height )
        setLiveTrackerHeight(355)
      }
    }
  }

  useEffect(() => {
    if (Object.keys(selectedFixture).length === 0) return

    setCalculatedHeight(selectedFixture.fixture.sportId)

    window.addEventListener('resize', setCalculatedHeight(selectedFixture.fixture.sportId))

    return () => {
      window.removeEventListener('resize', setCalculatedHeight(selectedFixture.fixture.sportId))
    }
  }, [selectedFixture])

  const onClickMarketCollapseHandler = useCallback(
    marketId => {
      if (!selectedFixture) return

      dispatch(
        setSelectedFixtureMarketCollapse({
          selectedFixture,
          marketId,
        }),
      )
    },
    [selectedFixture, dispatch],
  )

  // 배팅 카트 담기
  const onClickAddBettingCartHandler = useCallback(
    bettingCartItem => {
      dispatch(
        addBettingCartItem({
          ...bettingCartItem,
          sportMarketType: SportMarketType.LIVE,
        }),
      )
    },
    [dispatch],
  )

  return (
    <SportsContentMarketWrap>
      {Object.keys(selectedFixture || {}).length === 0 ? (
        <DefaultWrap>
          <img src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
          <DefaultWrapText>최소한의 제재, 신속한 입출금</DefaultWrapText>
        </DefaultWrap>
      ) : (
        <>
          <HeaderBoxWrap>
            <HeaderLeagueBox>
              {selectedFixture?.fixture.leagueImageUrl && (
                <img src={selectedFixture?.fixture.leagueImageUrl} alt="" style={{ marginRight: '10px' }} />
              )}
              <span>{selectedFixture?.fixture.leagueName}</span>
            </HeaderLeagueBox>

            {selectedFixture?.fixture?.liveTrackerUrl ? (
              <HeaderBox ref={parentRef}>
                <LiveTrackerIframe
                  src={selectedFixture?.fixture?.liveTrackerUrl}
                  scrolling="no"
                  style={{ height: `${liveTrackerHeight}px` }}
                />
              </HeaderBox>
            ) : (
              <HeaderBox>
                <HeaderImg
                  src={`${process.env.PUBLIC_URL}/img/sports/sports-bg/sports-bg-${selectedFixture?.fixture.sportId}.png`}
                  alt=""
                />
                <HeaderTextBox>
                  <HeaderTeamBox style={{ paddingRight: '8px' }}>
                    {selectedFixture?.fixture.homeImageUrl && (
                      <HeaderTeamImage
                        src={selectedFixture?.fixture.homeImageUrl}
                        alt=""
                        style={{ float: 'right', paddingRight: '10px', marginLeft: '16px' }}
                      />
                    )}
                    <HeaderTeamText style={{ float: 'right', textAlign: 'right' }}>
                      {selectedFixture?.fixture.homeName}
                    </HeaderTeamText>
                  </HeaderTeamBox>
                  <span style={{ color: '#fff', fontSize: '16px', fontWeight: '700' }}>: </span>
                  <HeaderTeamBox style={{ paddingLeft: '8px' }}>
                    {selectedFixture?.fixture.awayImageUrl && (
                      <HeaderTeamImage
                        src={selectedFixture?.fixture.awayImageUrl}
                        alt=""
                        style={{ float: 'left', paddingLeft: '10px', marginRight: '16px' }}
                      />
                    )}
                    <HeaderTeamText style={{ float: 'left', textAlign: 'left' }}>
                      {selectedFixture?.fixture.awayName}
                    </HeaderTeamText>
                  </HeaderTeamBox>
                </HeaderTextBox>
              </HeaderBox>
            )}
          </HeaderBoxWrap>
          <ContentWrap>
            {selectedFixture?.markets.map(market => {
              // market.betLines가 없거나 비어 있으면 아무 것도 렌더링하지 않음
              if (!market.usedMarket || !market.marketVisible) return null

              const filterBetLines = market.betLines.filter(betLine => {
                // 모든 bets가 'SUSPENDED'인 경우를 체크합니다.
                const allSuspended = betLine.bets.every(bet => bet.betStatus === 'SUSPENDED')

                if (allSuspended) return false

                // 각 betLine의 bets 배열을 검사하여 모든 조건을 충족하는지 확인합니다.
                return betLine.bets.every(bet => {
                  // showStatus가 true여야 하고, betStatus가 'OPEN' 또는 'SUSPEND'여야 합니다.
                  return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
                })
              })

              if (filterBetLines.length === 0) return null

              const customMarket = {
                ...market,
                betLines: filterBetLines,
              }

              return (
                <ContentMarket
                  key={`${market.marketId}-mm`}
                  fixture={{
                    ...selectedFixture?.fixture,
                    fixtureId: selectedFixture?.fixtureId,
                  }}
                  market={customMarket}
                  onClickMarketCollapseHandler={onClickMarketCollapseHandler}
                  onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                />
              )
            })}
          </ContentWrap>
        </>
      )}
    </SportsContentMarketWrap>
  )
}

export default MobileSportsLiveContentMarket

const LiveTrackerIframe = styled.iframe`
  width: 100%;
  margin: 0 auto;
`

const SportsContentMarketWrap = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  margin-top: 3px;

  border-bottom: 1px solid #3a3a3a;
`

const DefaultWrap = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(48, 48, 48, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 60%;
    height: auto;
  }
`

const DefaultWrapText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  color: #878e99;
  margin-top: 20px;
`

const HeaderBoxWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-bottom: 8px;
`

const HeaderLeagueBox = styled.div`
  display: flex;
  align-items: center;
  background: rgb(31, 33, 36);
  border-radius: 6px 6px 0px 0px;
  padding: 10px 16px;

  font-size: 14px;
  color: #fff;

  img {
    width: 23px;
  }
`

const HeaderBox = styled.div`
  width: 100%;
  position: relative;
`

const HeaderImg = styled.img`
  width: 100%;
  height: 70px;
  vertical-align: middle;
  border: 0;
`

const HeaderTextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 90%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(18px);
  border-radius: 8px;
`

const HeaderTeamBox = styled.div`
  width: 50%;
  float: left;
  display: block;
  color: #fff;
  font-size: 14px;
`
const HeaderTeamText = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
`

const HeaderTeamImage = styled.img`
  overflow-clip-margin: content-box;
  overflow: clip;
  border: 0;
  width: auto;
  height: 25px;
  vertical-align: middle;
`

/**
 * 아래부터 Content 부분
 */

const ContentWrap = styled.div`
  width: 100%;
  height: 100%;
`

const MarketWrap = styled.div`
  width: 100%;
  margin-bottom: 8px;
`

const MarketNameBox = styled.div`
  width: 100%;
  padding: 10px 16px;
  background: rgba(48, 48, 48, 1);
  border: 1px solid #414244;
  border-radius: 6px 6px 0 0;

  display: flex;
  align-items: center;

  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
`

const MarketCollapseArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${HermesMainHover2};
  width: 22px;
  height: auto;
  cursor: pointer;
  margin-left: auto;
`

const MarketCollapseArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: ${HermesMainHover2};
  width: 22px;
  height: auto;
  cursor: pointer;
  margin-left: auto;
`

const MarketBetListBox = styled.div`
  width: 100%;
  background: rgb(24, 26, 28);
  padding: 8px;
  border-radius: 0px 0px 6px 6px;
`
