import React, { useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import isLogin from '../../utils/enums/authority'
import SignInModal from '../Layout/SignInModal'
import SignUpModal from '../Layout/SignUpModal'
import AuthorityAlertPopup from '../Popup/Error/AuthorityAlertPopup'
import { HermesMainText } from '../../utils/palette'
import CustomAlert from '../CustomAlert'
import { setSelectedSportId as setDomesticSelectedSportId } from '../../redux/sportsDomesticInfoSlice'
import { setSelectedSportId as setLiveSelectedSportId } from '../../redux/sportsLiveInfoSlice'

const MainPageGameCategory = () => {
  const loginCheck = isLogin()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { noteRedisSize, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
  }

  return (
    <MainHomeCategory>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <AuthorityAlertPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <MainHomeCategoryInnerBox
        onClick={e => {
          dispatch(setDomesticSelectedSportId(-1))
          onClickMenuHandler('/sports/domestic')
        }}
      >
        <MainHomeCategoryItemBox>
          <MainHomeCategoryItemHeaderBox />
          <MainHomeCategoryItemContentBox />
          <MainHomeCategoryItemBottomBox>
            <MainHomeCategoryItemBottomTextBox>국내형 스포츠</MainHomeCategoryItemBottomTextBox>
            <MainHomeCategoryItemBottomImageBox>
              <CustomFaArrowRight />
            </MainHomeCategoryItemBottomImageBox>
          </MainHomeCategoryItemBottomBox>
          <MainHomeCategoryItemBannerBox>
            <img src={`${process.env.PUBLIC_URL}/img/main/main-sports.png`} alt="" />
          </MainHomeCategoryItemBannerBox>
          <MainHomeCategoryItemCoverBox />
        </MainHomeCategoryItemBox>
      </MainHomeCategoryInnerBox>
      <MainHomeCategoryInnerBox
        onClick={e => {
          dispatch(setLiveSelectedSportId(-1))
          onClickMenuHandler('/sports/live')
        }}
      >
        <MainHomeCategoryItemBox>
          <MainHomeCategoryItemHeaderBox />
          <MainHomeCategoryItemContentBox />
          <MainHomeCategoryItemBottomBox>
            <MainHomeCategoryItemBottomTextBox>인플레이</MainHomeCategoryItemBottomTextBox>
            <MainHomeCategoryItemBottomImageBox>
              <CustomFaArrowRight />
            </MainHomeCategoryItemBottomImageBox>
          </MainHomeCategoryItemBottomBox>
          <MainHomeCategoryItemBannerBox>
            <img src={`${process.env.PUBLIC_URL}/img/main/main-live-sports.png`} alt="" />
          </MainHomeCategoryItemBannerBox>
          <MainHomeCategoryItemCoverBox />
        </MainHomeCategoryItemBox>
      </MainHomeCategoryInnerBox>
      <MainHomeCategoryInnerBox
        onClick={e => {
          onClickMenuHandler('/live-casino')
        }}
      >
        <MainHomeCategoryItemBox>
          <MainHomeCategoryItemHeaderBox />
          <MainHomeCategoryItemContentBox />
          <MainHomeCategoryItemBottomBox>
            <MainHomeCategoryItemBottomTextBox>라이브 카지노</MainHomeCategoryItemBottomTextBox>
            <MainHomeCategoryItemBottomImageBox>
              <CustomFaArrowRight />
            </MainHomeCategoryItemBottomImageBox>
          </MainHomeCategoryItemBottomBox>
          <MainHomeCategoryItemBannerBox>
            <img src={`${process.env.PUBLIC_URL}/img/main/main-live-casino.png`} alt="" />
          </MainHomeCategoryItemBannerBox>
          <MainHomeCategoryItemCoverBox />
        </MainHomeCategoryItemBox>
      </MainHomeCategoryInnerBox>
      <MainHomeCategoryInnerBox
        onClick={e => {
          onClickMenuHandler('/slot-casino')
        }}
      >
        <MainHomeCategoryItemBox>
          <MainHomeCategoryItemHeaderBox />
          <MainHomeCategoryItemContentBox />
          <MainHomeCategoryItemBottomBox>
            <MainHomeCategoryItemBottomTextBox>슬롯 카지노</MainHomeCategoryItemBottomTextBox>
            <MainHomeCategoryItemBottomImageBox>
              <CustomFaArrowRight />
            </MainHomeCategoryItemBottomImageBox>
          </MainHomeCategoryItemBottomBox>
          <MainHomeCategoryItemBannerBox>
            <img src={`${process.env.PUBLIC_URL}/img/main/main-slot-casino.png`} alt="" />
          </MainHomeCategoryItemBannerBox>
          <MainHomeCategoryItemCoverBox />
        </MainHomeCategoryItemBox>
      </MainHomeCategoryInnerBox>
      <MainHomeCategoryInnerBox
        onClick={e => {
          onClickMenuHandler('/minigame')
        }}
      >
        <MainHomeCategoryItemBox>
          <MainHomeCategoryItemHeaderBox />
          <MainHomeCategoryItemContentBox />
          <MainHomeCategoryItemBottomBox>
            <MainHomeCategoryItemBottomTextBox>미니게임</MainHomeCategoryItemBottomTextBox>
            <MainHomeCategoryItemBottomImageBox>
              <CustomFaArrowRight />
            </MainHomeCategoryItemBottomImageBox>
          </MainHomeCategoryItemBottomBox>
          <MainHomeCategoryItemBannerBox>
            <img src={`${process.env.PUBLIC_URL}/img/main/main-minigame.png`} alt="" />
          </MainHomeCategoryItemBannerBox>
          <MainHomeCategoryItemCoverBox />
        </MainHomeCategoryItemBox>
      </MainHomeCategoryInnerBox>
    </MainHomeCategory>
  )
}

export default MainPageGameCategory

const CustomFaArrowRight = styled(FaArrowRight)``

// 스포츠 실시간 미니게임 가상 등등 카테고리
const MainHomeCategory = styled.div`
  width: 100%;
  margin-top: 24px;
  color: #fff;
  font-family: 'Malgun Gothic', dotum;
  display: flex;
  justify-content: space-between;
`

const MainHomeCategoryItemBox = styled.div`
  position: relative;
`
const MainHomeCategoryItemHeaderBox = styled.div`
  height: 23px;
  width: 100%;
`
const MainHomeCategoryItemContentBox = styled.div`
  height: 148px;
  width: 100%;
  background: linear-gradient(180deg, rgba(24, 26, 28, 0) 0%, #241e06 100%);
  opacity: 0.61;
  border-radius: 6px 6px 0 0;
`
const MainHomeCategoryItemBottomBox = styled.div`
  height: 46px;
  width: 100%;
  background: linear-gradient(180deg, rgba(24, 26, 28, 0) 0%, #241e06 100%);
  border-radius: 0 0 6px 6px;

  &:hover {
    color: ${HermesMainText};
  }
`

const MainHomeCategoryItemBottomTextBox = styled.div`
  float: left;
  padding-left: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
`
const MainHomeCategoryItemBottomImageBox = styled.div`
  float: right;
  padding-right: 12px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  ${CustomFaArrowRight} {
    width: 15px;
    height: 15px;
  }
`

const MainHomeCategoryItemBannerBox = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
`
const MainHomeCategoryItemCoverBox = styled.div`
  position: absolute;
  left: 0;
  bottom: 46px;
  height: 51px;
  width: 100%;
  background: linear-gradient(180deg, rgba(24, 26, 28, 0) 0%, #241e06 100%);
`

const MainHomeCategoryInnerBox = styled.div`
  width: 238px;
  height: 217px;
  cursor: pointer;

  &:hover {
    // 이미지 효과
    ${MainHomeCategoryItemBannerBox} {
      img {
        transition: transform 0.5s ease; // 0.5초 동안 transform 효과를 부드럽게 적용합니다.
        transform: translateX(10px); // 호버될 때 이미지를 오른쪽으로 50px 이동시킵니다.
      }
    }

    ${MainHomeCategoryItemBottomBox} {
      transition: all 0.7s ease;
      color: ${HermesMainText};

      ${CustomFaArrowRight} {
        transition: all 0.2s ease;
        width: 20px;
        height: 20px;
      }
    }
  }
`
