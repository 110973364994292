import { BorderedBottomTabs, NavItem, NavLink, TabPaneInnerWrap, TabsWrap } from '@/shared/components/Tabs'
import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainText } from '../../../utils/palette'

// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const GopickTabs = ({ activeKey }) => {
  const navigate = useNavigate()

  return (
    <CustomBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="1"
                onClick={() => {
                  navigate('/minigame/gopick/raccoon')
                }}
              >
                너구리
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="2"
                onClick={() => {
                  navigate('/minigame/gopick/soccer')
                }}
              >
                축구
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="3"
                onClick={() => {
                  navigate('/minigame/gopick/baseball')
                }}
              >
                야구
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="4"
                onClick={() => {
                  navigate('/minigame/gopick/octopus')
                }}
              >
                오징어게임
              </MiniGameNavLink>
            </MiniGameNavItem>
            {/* <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="5"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerball1m')
                }}
              >
                EVO 파워볼1분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="6"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerball2m')
                }}
              >
                EVO 파워볼2분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="7"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerball3m')
                }}
              >
                EVO 파워볼3분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="8"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerball4m')
                }}
              >
                EVO 파워볼4분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="9"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerball5m')
                }}
              >
                EVO 파워볼5분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '12%' }}>
              <MiniGameNavLink
                eventKey="10"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerladder1m')
                }}
              >
                EVO 파워사다리1분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '12%' }}>
              <MiniGameNavLink
                eventKey="11"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerladder2m')
                }}
              >
                EVO 파워사다리2분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '12%' }}>
              <MiniGameNavLink
                eventKey="12"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerladder3m')
                }}
              >
                EVO 파워사다리3분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '12%' }}>
              <MiniGameNavLink
                eventKey="13"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerladder4m')
                }}
              >
                EVO 파워사다리4분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '12%' }}>
              <MiniGameNavLink
                eventKey="14"
                onClick={() => {
                  navigate('/minigame/gopick/evo/powerladder5m')
                }}
              >
                EVO 파워사다리5분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="15"
                onClick={() => {
                  navigate('/minigame/gopick/evo/dragon')
                }}
              >
                EVO 드래곤
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="16"
                onClick={() => {
                  navigate('/minigame/gopick/evo/baseball')
                }}
              >
                EVO 베이스볼
              </MiniGameNavLink>
            </MiniGameNavItem> */}
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </CustomBottomTabs>
  )
}
export default GopickTabs

const CustomBottomTabs = styled(BorderedBottomTabs)`
  border-top: none !important;
  background: rgb(33, 35, 38) !important;
`

const MiniGameNavLink = styled(NavLink)`
  &.active,
  &.active:focus,
  &.active:hover {
    // background-color: #636260 !important;
    color: ${HermesMainText} !important;
    font-weight: 600;
  }
`

const MiniGameNavItem = styled(NavItem)`
  width: 11.11%;
  text-align: center;
  // border: solid 1px red;
`

const NtryGameWrap = styled.div`
  height: 30px;
  position: relative;
  width: 100%;
`

export const TableBettingInfo = styled.div`
  width: 30%;
  position: left;
  color: white;
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`
