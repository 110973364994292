import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { FaCircle, FaClock, FaExpandAlt } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { sycnCasinoMoney } from '../../api/game/gameApi'
import Portal from '../../shared/components/Portal'
import { HermesMainHover2 } from '../../utils/palette'
import CustomLoading from '../CustomLoading'

const CasinoGameViewPopup = ({ casinoGameUrl, onCloseCasinoGameViewPopupShowHandler }) => {
  const dispatch = useDispatch()
  const modalRef = useRef(null) // 모달 DOM에 접근하기 위한 ref

  // 팝업 창 닫는 핸들러
  const onCloseCasinoGameViewPopupHandler = () => {
    // // 팝업 닫으면 카지노 종료 신호
    sycnCasinoMoney()
      .then(res => {
        // dispatch(setTempCurrentAccessGame(false))
        // dispatch(fetchMemberInfoAction())
        onCloseCasinoGameViewPopupShowHandler()
      })
      .catch(error => {
        // dispatch(setTempCurrentAccessGame(false))
        onCloseCasinoGameViewPopupShowHandler()
      })
  }

  const [isFullScreen, setIsFullScreen] = useState(false)

  const [currentTime, setCurrentTime] = useState(moment().tz('Asia/Seoul'))

  const [iframeLoading, setIframeLoading] = useState(true)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().tz('Asia/Seoul'))
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    // 접속시
    // dispatch(setTempCurrentAccessGame(true))

    // const handleBeforeUnload = () => {
    //   // 새로고침 시 카지노 종료 신호
    //   sycnCasinoMoney()
    // }

    function handleFullscreenChange() {
      // 전체 화면 모드가 비활성화되었는지 확인합니다.
      if (!document.fullscreenElement) {
        // 모달 상태를 업데이트합니다.
        setIsFullScreen(false)
      }
    }

    // window.addEventListener('beforeunload', handleBeforeUnload)

    // 이벤트 리스너를 등록합니다.
    document.addEventListener('fullscreenchange', handleFullscreenChange)

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
    return () => {
      // 종료시
      // dispatch(setTempCurrentAccessGame(false))
      // window.removeEventListener('beforeunload', handleBeforeUnload)
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, []) // 빈 의존성 배열은 useEffect가 마운트 시에만 실행됨을 의미합니다.

  const goFullScreen = () => {
    // 전체 화면을 지원하는지 확인
    const elem = modalRef.current

    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen()
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen()
    }
  }

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen()
    }
  }

  const onClickFullScreenHandler = value => {
    setIsFullScreen(value)
    if (value) {
      goFullScreen()
    } else {
      exitFullScreen()
    }
  }

  return (
    <Portal elementId="game-modal">
      <div style={{ zIndex: '10000' }}>
        <FullscreenDiv ref={modalRef} isFullScreen={isFullScreen}>
          <CasinoGameViewDialogWrapper>
            <CasinoGameViewDialog isFullScreen={isFullScreen}>
              <CasinoGameViewDialogHeader>
                <span>Header</span>
                <button type="button">닫기</button>
              </CasinoGameViewDialogHeader>
              <CasinoGameViewDialogBody>
                <CasinoGameViewDialogBodyBox>
                  <CasinoGameViewDialogBodyHeader>
                    <CasinoGameViewDialogBodyHeaderTitle>
                      <CustomFaCircle /> 라이브게임 실행중
                    </CasinoGameViewDialogBodyHeaderTitle>
                    <CasinoGameViewDialogBodyHeaderTime>
                      <CustomFaClock />
                      <span>{currentTime.locale('ko').format('HH:mm:ss')}</span>
                    </CasinoGameViewDialogBodyHeaderTime>
                    <CasinoGameViewDialogBodyHeaderButton>
                      <CustomFaExpandAlt
                        onClick={() => {
                          onClickFullScreenHandler(!isFullScreen)
                        }}
                      />
                      <CustomAiOutlineClose onClick={onCloseCasinoGameViewPopupHandler} />
                    </CasinoGameViewDialogBodyHeaderButton>
                  </CasinoGameViewDialogBodyHeader>
                  <CasinoGameViewDialogBodyCasinoContainer>
                    <div style={{ width: '100%', height: '100%' }}>
                      {iframeLoading && <CustomLoading />}
                      <CasinoGameViewDialogBodyCasinoFrame
                        id="casinoFrame"
                        src={casinoGameUrl}
                        onLoad={() => {
                          setIframeLoading(false)
                        }}
                        title="casinoFrame"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                      />
                    </div>
                  </CasinoGameViewDialogBodyCasinoContainer>
                </CasinoGameViewDialogBodyBox>
              </CasinoGameViewDialogBody>
            </CasinoGameViewDialog>
          </CasinoGameViewDialogWrapper>
        </FullscreenDiv>
      </div>
    </Portal>
  )
}

export default CasinoGameViewPopup

const FullscreenDiv = styled.div`
  ${props =>
    props.isFullScreen &&
    `
    &:fullscreen,
    &:-webkit-full-screen,
    &:-moz-full-screen,
    &:-ms-fullscreen {
        object-fit: contain;
        user-select: text;
        position: fixed;
        box-sizing: border-box;
        min-width: 0;
        max-width: none;
        min-height: 0;
        max-height: none;
        width: 100%;
        height: 100%;
        transform: none;
        inset: 0;
        margin: 0;
    }
    
  `}
`

const CasinoGameViewDialogWrapper = styled.div`
  z-index: 9991;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7);
`

const CasinoGameViewDialog = styled.div`
  width: 1400px;
  height: 750px;
  margin-top: 100px !important;

  transform: none;
  left: 0;
  position: relative;
  margin: 0 auto;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  ${props =>
    props.isFullScreen &&
    `
    width: 100% !important;
    height: 100% !important;
    margin-top: 0 !important;
    `}

  @media screen and (max-width: 1600px) {
    width: 1340px;
    height: 750px;
  }

  @media screen and (max-width: 1440px) {
    width: 1240px;
    height: 650px;
  }

  @media screen and (max-width: 1366px) {
    width: 1140px;
    height: 550px;
  }

  @media screen and (max-width: 1280px) {
    width: 100%;
    height: 100%;
    margin-top: 0 !important;
  }
`

const CasinoGameViewDialogHeader = styled.div`
  display: none;
  padding: 20px 20px 10px;
  box-sizing: border-box;

  span {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
  }

  button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
  }
`

const CasinoGameViewDialogBody = styled.div`
  padding: 0;
  height: 100%;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
`

const CasinoGameViewDialogBodyBox = styled.div`
  background: #232a2f !important;

  color: #fff !important;
  height: 100% !important;
  padding: 10px !important;
`

const CasinoGameViewDialogBodyHeader = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
`

const CasinoGameViewDialogBodyHeaderTitle = styled.div`
  color: ${HermesMainHover2} !important;
  text-align: left !important;
  padding-left: 10px !important;
  width: 33.33333%;
  float: left;
  box-sizing: border-box;
`

const CasinoGameViewDialogBodyHeaderTime = styled.div`
  color: #ff2c2c !important;
  float: left !important;
  text-align: center !important;
  width: 33.33333%;
  box-sizing: border-box;

  span {
    color: #fff !important;
  }
`

// 최대/최소화 버튼, 닫기버튼
const CasinoGameViewDialogBodyHeaderButton = styled.div`
  text-align: right !important;
  padding-right: 10px !important;
  width: 33.33333%;
  float: left;
  box-sizing: border-box;
`

const CustomFaCircle = styled(FaCircle)`
  width: 12px !important;
  height: 14px !important;
  color: ${HermesMainHover2} !important;
  margin: -3px 0 0 0 !important;
`

const CustomFaClock = styled(FaClock)`
  width: 12px !important;
  height: 14px !important;
  color: ${HermesMainHover2} !important;
  margin: -3px 5px 0 5px !important;
`

const CustomFaExpandAlt = styled(FaExpandAlt)`
  width: auto !important;
  height: 17px !important;
  color: ${HermesMainHover2} !important;
  cursor: pointer;
  margin: -6px 10px 0 0 !important;
`

const CustomAiOutlineClose = styled(AiOutlineClose)`
  width: auto !important;
  height: 23px !important;
  color: ${HermesMainHover2} !important;
  cursor: pointer;
  margin: -5px 0 0 0 !important;
`

const CasinoGameViewDialogBodyCasinoContainer = styled.div`
  overflow: auto;
  width: 100%;
  height: calc(100% - 25px) !important;

  margin-top: 5px !important;
  box-sizing: border-box;
  position: relative;
`

const CasinoGameViewDialogBodyCasinoFrame = styled.iframe`
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  z-index: 999999;
`
