import React, { useContext, useEffect, useRef, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { FaChevronLeft, FaTrash } from 'react-icons/fa'
import { border } from 'polished'
import {
  createOnetoOne,
  deleteOnetoOneList,
  memberConfirmOneToOneAnswer,
} from '../../../api/customCenter/customCenterApi'
import OnetoOneRadio from '../../../containers/CustomCenter/OnetoOne/OnetoOneRadio'
import CustomLoading from '../../../containers/CustomLoading'
import { fetchOneToOneList } from '../../../redux/customCenterSlice'
import Badge from '../../../shared/components/Badge'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import {
  CustomCollapse,
  MobileCustomerCenterContentWrap,
  MobileCustomerCenterHeaderInfo,
  MobileCustomerCenterHeaderWrap,
  MobileListButton,
  MobileListButtonWrap,
  MobileQuestionAccountButton,
  MobileTopWrap,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/MoneyCustomElement'
import PollingContext from '../../../utils/contexts/PollingInfo'
import { convertToKstByFormat } from '../../../utils/dateTime'
import {
  OneToOneAnswerStatusEnums,
  OneToOneConfirmAnswerStatusEnums,
} from '../../../utils/enums/Operation/OneToOneEnums'
import { HermesMainText, colorMainText } from '../../../utils/palette'
import MobileOnetoOneWrite from './MobileOnetoOneWrite'
import { onClickTitleHandler } from '../../../redux/modalSlice'
import Portal from '../../../shared/components/Portal'
import {
  ContentInfo,
  ContentRowSecondWrap,
  ContentRowSmall,
  ContentRowThirdWrap,
  ContentRowWrap,
  ContentWrap,
  CustomButtonInfo,
  CustomerCenterFormGroup,
  MoneyButton,
  SignUpModalCloseButton,
  SignUpModalContentWrap,
  SignUpModalHeader,
  SignUpModalHeaderLogo,
  SignUpModalInner,
  SignUpModalOverlay,
  SignUpModalWrapper,
  TableBodyTwoWrap,
} from '../../../shared/components/MobileMoneyCustomElement'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { MobileNoContentBox } from '../MobileEvent/MobileEventList'
import { fetchOneToOneSize } from '../../../redux/memberInfoSlice'

const MobileOnetoOneList = ({ visible, onClose }) => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const [isWriteOnetoOneShow, setWriteOnetoOneShow] = useState(false)

  const contextValue = useContext(PollingContext)

  const [oneToOneSize, setOneToOneSize] = useState(!contextValue?.oneToOneSize ? 0 : contextValue.oneToOneSize)
  useEffect(() => {
    if (!contextValue) return

    setOneToOneSize(contextValue.oneToOneSize)
  }, [contextValue])

  /**
   *  1:1문의 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [oneToOneParams, setOneToOneParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [checkRadio, setCheckRadio] = useState([])
  const [content, setContent] = useState([])
  const { totalElement, apiContent, holdingMoney, memberTotalGameMoney } = useSelector(state => {
    const { customCenter, memberInfo } = state

    return {
      totalElement: customCenter.oneToOneInfo.totalElement,
      apiContent: customCenter.oneToOneInfo.content,
      holdingMoney: memberInfo.memberHoldingMoney,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.oneToOneKey === item.oneToOneKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchOneToOneList = async () => {
    try {
      dispatch(fetchOneToOneList(oneToOneParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchOneToOneList()
  }, [oneToOneParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = oneToOneKey => {
    const idx = content.findIndex(item => item.oneToOneKey === oneToOneKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        const idx2 = content.findIndex(item => item.oneToOneKey === oneToOneKey)
        if (idx2 !== -1 && content[idx2]?.confirmStatus === 'INCOMPLETE') {
          memberConfirmOneToOneAnswer({
            oneToOneKey: content[idx2].oneToOneKey,
          }).then(res => {
            dispatch(fetchOneToOneList(oneToOneParams))
            dispatch(fetchOneToOneSize())
          })
        }
        return newContent
      })
    }
  }

  const [questionLoading, setQuestionLoading] = useState(false)

  const onQuestionAccountHandler = () => {
    if (questionLoading) return
    const body = {
      questionTitle: '자동 계좌문의',
      questionContent: '계좌문의 드립니다.',
    }
    setQuestionLoading(true)
    createOnetoOne(body)
      .then(res => {
        alert('계좌문의가 접수되었습니다.')
        setQuestionLoading(false)
        fetchSearchOneToOneList()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('계좌문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setQuestionLoading(false)
      })
  }

  // 문의하기 버튼
  const onWriteFromHandler = () => {
    setWriteOnetoOneShow(prev => !prev)
  }

  const [deleteLoading, setDeleteLoading] = useState(false)

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 1:1문의를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchOneToOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchOneToOneList()
        })
    }
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  return (
    <Portal elementId="signup-modal">
      <SignUpModalOverlay visible={visible} />
      <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
            <SignUpModalCloseButton
              onClick={close}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <MobileTopWrap ref={webPageWrapRef}>
              <ContentWrap>
                <ContentRowWrap>
                  <FaChevronLeft
                    onClick={e => {
                      dispatch(onClickTitleHandler('MYPAGE'))
                    }}
                  />
                  <ContentRowSmall>
                    <div>
                      <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                        {holdingMoney?.toString().replace(commonReg2, ',')} 원
                      </span>
                    </div>
                  </ContentRowSmall>
                  <ContentRowSmall>
                    <div>
                      <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                        {memberTotalGameMoney?.toString().replace(commonReg2, ',')} 원
                      </span>
                    </div>
                  </ContentRowSmall>
                </ContentRowWrap>
                <div style={{ padding: '8px' }}>
                  <ContentRowWrap>
                    <CustomButtonInfo>
                      1:1 문의 {!oneToOneSize ? '' : <MobileBadge bg="danger">NEW</MobileBadge>}
                    </CustomButtonInfo>
                    <CustomButtonWrap>
                      <CustomDeleteButton
                        onClick={() => {
                          setCheckRadio(content.map(value => value.oneToOneKey))
                        }}
                        type="button"
                      >
                        <CustomButtonInfos>전체선택</CustomButtonInfos>
                      </CustomDeleteButton>
                      <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
                        <FaTrash />
                        <CustomButtonInfos>삭제</CustomButtonInfos>
                      </CustomDeleteButton>
                      <CustomDeleteButton onClick={() => onWriteFromHandler()} type="button">
                        <CustomButtonInfos>문의</CustomButtonInfos>
                      </CustomDeleteButton>
                      <CustomDeleteButton onClick={() => onQuestionAccountHandler()} type="button">
                        <CustomButtonInfos>계좌문의</CustomButtonInfos>
                      </CustomDeleteButton>
                    </CustomButtonWrap>
                  </ContentRowWrap>
                  <ContentInfo>
                    {loading || deleteLoading ? (
                      <CustomLoading />
                    ) : (
                      <TableBodyTwoWrap>
                        {isWriteOnetoOneShow ? (
                          <MobileOnetoOneWrite
                            setWriteOnetoOneShow={setWriteOnetoOneShow}
                            fetchSearchOnetoOneList={fetchSearchOneToOneList}
                          />
                        ) : (
                          <CustomerCenterContentTable>
                            <CustomerCenterContentTableHead>
                              <CustomerCenterContentTableHeadTr>
                                <th style={{ width: '10%' }}>선택</th>
                                <th style={{ width: '50%' }}>내용</th>
                                <th style={{ width: '25%' }}>문의날짜</th>
                                <th style={{ width: '15%' }}>답변</th>
                              </CustomerCenterContentTableHeadTr>
                            </CustomerCenterContentTableHead>
                            <CustomerCenterContentTableBody>
                              {content.length === 0 ? (
                                <NoContentDiv>
                                  <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
                                  최근 게시물이 없습니다.
                                </NoContentDiv>
                              ) : (
                                <>
                                  {content.map(item => (
                                    <>
                                      <CustomerCenterContentTableBodyTr key={item.oneToOneKey}>
                                        <CustomerCenterContentBox>
                                          <CustomerCenterContenItemBox width="10">
                                            <OnetoOneRadio
                                              rowInfo={{
                                                oneToOneKey: item.oneToOneKey,
                                                answerStatus: item.answerStatus,
                                              }}
                                              checkRadio={checkRadio}
                                              setCheckRadio={setCheckRadio}
                                            />
                                          </CustomerCenterContenItemBox>
                                          <CustomerCenterContenItemBox
                                            width="50"
                                            style={{ cursor: 'pointer', flexDirection: 'column' }}
                                            onClick={() => onClickRowHandler(item.oneToOneKey)}
                                          >
                                            {ReactHtmlParser(item.questionContent, { transform: transformFn })}
                                          </CustomerCenterContenItemBox>
                                          <CustomerCenterContenItemBox
                                            width="25"
                                            onClick={() => onClickRowHandler(item.oneToOneKey)}
                                          >
                                            {convertToKstByFormat(item.registrationDate, 'MM-DD HH:mm')}
                                          </CustomerCenterContenItemBox>
                                          <CustomerCenterContenItemBox
                                            width="15"
                                            onClick={() => onClickRowHandler(item.oneToOneKey)}
                                          >
                                            {item.answerStatus === 'COMPLETE' &&
                                              item.confirmStatus === 'INCOMPLETE' && (
                                                <>
                                                  <Badge bg="danger">N</Badge> &nbsp;
                                                </>
                                              )}

                                            {OneToOneAnswerStatusEnums[item.answerStatus]}
                                          </CustomerCenterContenItemBox>
                                        </CustomerCenterContentBox>
                                      </CustomerCenterContentTableBodyTr>
                                      <CustomCollapse in={item.isVisible}>
                                        <CustomerCenterContentTableBodyTr style={{ border: 'solid 1px #525252' }}>
                                          <CustomerCenterContentDetailBox>
                                            {item.answerContent === null
                                              ? '문의가 접수됐습니다. 잠시만 기다려주세요'
                                              : ReactHtmlParser(item.answerContent, { transform: transformFn })}
                                          </CustomerCenterContentDetailBox>
                                        </CustomerCenterContentTableBodyTr>
                                      </CustomCollapse>
                                    </>
                                  ))}
                                </>
                              )}
                            </CustomerCenterContentTableBody>
                          </CustomerCenterContentTable>
                        )}

                        {totalElement > 0 && (
                          <MobileCustomPagination
                            currentPage={page}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                          />
                        )}
                      </TableBodyTwoWrap>
                    )}
                  </ContentInfo>
                </div>
              </ContentWrap>
            </MobileTopWrap>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default MobileOnetoOneList

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

export const CustomButtonWrap = styled(MobileListButtonWrap)`
  padding: 0px 10px;
`

export const CustomButtonInfos = styled(CustomButtonInfo)``

export const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: max-content;
  min-width: 50px;
  padding: 0px 5px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 5px;
    color: grey;
  }
`

export const MobileBadge = styled(Badge)`
  width: 50px;
  height: 20px;
  padding-top: 5px;
`

export const MobileOneToOneHeaderWrap = styled.div`
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
`

export const MobileOnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 13px;
  opacity: 0.7;
  color: ${colorMainText};
`

const ListWrap = styled.div`
  display: flex;
`
export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 0px;
  opacity: 0.7;
  margin-top: 10px;
  margin-bottom: 2px;
  color: ${colorMainText};
`
export const OneToOneHeaderWrap = styled.div`
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  height: 70px;
  border-bottom: solid 1px #edae07;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  th {
    padding: 16px 0px !important;
    background: rgba(9, 9, 10);
    borde: none;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px #757575;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;

  span {
  }
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
  font-weight: 400;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  padding: 16px 5px;
`
