import axios from '../base/axios'

// 스포츠 경기 목록 조회
export async function searchSportsEventList(params) {
  const response = await axios({
    url: '/tyson/member/v1/sports',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스페셜 스포츠 경기 목록 조회
export async function searchSpecialSportsEventList(params) {
  const response = await axios({
    url: '/tyson/member/v1/sports/special',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 라이브 스포츠 경기 목록 조회
export async function searchLiveSportsEventList(params) {
  const response = await axios({
    url: '/tyson/member/v1/sports/live',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 종목 별 금일 경기 수
export async function searchTodaySportsEventCount(params) {
  const response = await axios({
    url: '/tyson/member/v1/sports/count',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅하기
export async function bettingSportsEvent(body) {
  const response = await axios({
    url: '/tyson/member/v1/sports',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅하기 - V2
export async function bettingSportsEventV2(body) {
  const response = await axios({
    url: '/tyson/member/v2/sports/betting',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 라이브 스포츠 배팅하기
export async function bettingLiveSportsEvent(body) {
  const response = await axios({
    url: '/tyson/member/v1/sports/live',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅내역 조회
export async function searchSportsBettingHistory(params) {
  const response = await axios({
    url: '/tyson/member/v1/sports/betting-history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅내역 조회
export async function searchSportsBettingHistoryV2(params) {
  const response = await axios({
    url: '/tyson/member/v2/sports/betting-history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅내역 삭제
export async function deleteSportsBettingHistory(body) {
  const response = await axios({
    url: '/tyson/member/v1/sports/betting-history',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

export async function deleteSportsBettingHistoryV2(body) {
  const response = await axios({
    url: '/tyson/member/v2/sports/betting-history',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅내역 취소
export async function cancelSportsBettingHistory(body) {
  const response = await axios({
    url: '/tyson/member/v1/sports/betting-history',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

export async function cancelSportsBettingHistoryV2(body) {
  const response = await axios({
    url: '/tyson/member/v2/sports/betting-history',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 크로스 스포츠 다폴더 보너스 조회
export async function searchSportsSettingInfo(params) {
  const response = await axios({
    url: '/tyson/member/v1/sports/info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}
