import {
  CustomCenterFormBottomWrap,
  MoneyApplyButton,
  MoneyInfo,
  MoneyInfoContent,
  MoneyInfoGameMoney,
} from '@/shared/components/MoneyCustomElement'
import Error from '@/shared/components/form/Error'
import { FormGroupField, FormGroupIcon } from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MoneyDepositValidate } from '@/utils/validate/moneyFormValidate'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMemberMoneyDeposit } from '../../api/memberHistory/memberHistoryApi'
import { createUserMoneyDeposit, searchMinimumAmount } from '../../api/memberManage/memberManageApi'
import {
  CustomMoneyFormGroupField,
  MoneyFormButtomInfo,
  MoneyFormButton,
  MoneyFormField,
  MoneyFormGroup,
  MoneyFormInput,
  MoneyFormInputWrap,
  MoneyFormLabel,
  MoneyFormModifyButtomInfo,
  MoneyFormModifyButton,
  MoneyFormWrap,
  WebPageWrap,
} from '../../shared/components/form/newForm'
import { BonusStatusEnumsRadioOptions } from '../../utils/enums/MemberHistory/MemberDepositEnums'
import { colorMainText } from '../../utils/palette'
import CustomLoading from '../CustomLoading'
import MoneyDepositListData from '../MyPage/MoneyDepositHistory/MoneyDepositListData'

const MoneyDepositForm = () => {
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })
  /**
   *  회원 입금내역 조회
   */

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyDeposit(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberMoneyDeposit = async () => {
    setLoading(true)
    await searchMemberMoneyDeposit(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [depositListData, setDepositListData] = useState(MoneyDepositListData(content))

  useEffect(() => {
    setDepositListData(MoneyDepositListData(content))
  }, [content])

  const [rows, setData] = useState(depositListData.tableRowsData)

  useEffect(() => {
    setData(depositListData.tableRowsData)
  }, [depositListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 최소 입금액
  const [minimumDeposit, setMinimumDeposit] = useState('0')

  useEffect(() => {
    setMyInfoLoading(true)

    searchMinimumAmount()
      .then(res => {
        setMinimumDeposit(res.data.minimumDeposit)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])
  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  const [bonusType, setBonusType] = useState('BONUS_SPORTS')
  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)

    const body = {
      moneyType: 'DEPOSIT',
      applyMoney: applyMoney.toString().replaceAll(',', ''),
      bonusType,
      // realMoney: realMoney.toString().replaceAll(',', ''),
    }
    setLoading(true)
    createUserMoneyDeposit(body)
      .then(res => {
        setLoading(false)
        alert('입금신청이 완료되었습니다.')
        setApiFlag(false)
        setBonusType('BONUS_SPORTS')
        fetchSearchMemberMoneyDeposit()
        onClearClickHandler()
      })
      .catch(error => {
        const { errorCode } = error.response.data
        setLoading(false)
        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          default:
            alert('입금신청이 실패했습니다. 다시 시도해주세요.')
        }

        setApiFlag(false)
        fetchSearchMemberMoneyDeposit()
      })
  }
  return (
    <WebPageWrap>
      <MoneyDepositHeaderWrap>
        <MoneyDepositHeaderInfo>입금신청</MoneyDepositHeaderInfo>
        <MoneyDepositCardSubhead>
          * 입금시 등록한 계좌정보와 동일해야 입금이 가능하니, 재확인 부탁드립니다.
        </MoneyDepositCardSubhead>
        <MoneyDepositCardSubhead>
          * 수표나 타인계좌로 이름 변경하여 입금 시 머니몰수 및 정지 처리되며, 타인명의 및 타 계좌로 입금할 경우 문의를
          먼저하시기 바랍니다.
        </MoneyDepositCardSubhead>
        <MoneyDepositCardSubhead>
          * 온라인은행 계좌(핀크.페이코.신세계아이앤씨)로 입금시에는 처리불가합니다.
        </MoneyDepositCardSubhead>
      </MoneyDepositHeaderWrap>
      {myInfoLoading || loading ? (
        <CustomLoading />
      ) : (
        <MoneyDepositBodyWrap>
          <MoneyDepositInfoWrap>
            <MoneyInfo>
              <MoneyInfoGameMoney style={{ width: '50px', height: '50px' }} />
              <MoneyInfoContent>지갑머니: {holdingMoney?.toString().replace(commonReg2, ',')}</MoneyInfoContent>
            </MoneyInfo>
            <MoneyInfo>
              <img
                style={{ width: '50px', height: '50px' }}
                src={`${process.env.PUBLIC_URL}/img/header/slot-casino-icon.png`}
                alt=""
              />
              <MoneyInfoContent>카지노 머니: {totalGameMoney?.toString().replace(commonReg2, ',')}</MoneyInfoContent>
            </MoneyInfo>
          </MoneyDepositInfoWrap>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              memberId: userId,
              memberName: userName,
              bankName,
              accountHolder: accountNum,
              applyMoney,
              holdingMoney,
              minimumDeposit,
              bonusType,
              // inquiryButton,
            }}
            validate={MoneyDepositValidate}
          >
            {({ handleSubmit, form: { reset } }) => (
              <MoneyFormWrap horizontal onSubmit={handleSubmit}>
                <MoneyFormGroup>
                  <MoneyFormLabel>아이디</MoneyFormLabel>
                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="memberId">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup>
                  <MoneyFormLabel>예금주명</MoneyFormLabel>
                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="memberName">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup>
                  <MoneyFormLabel>은행</MoneyFormLabel>
                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="bankName">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup>
                  <MoneyFormLabel>회원계좌</MoneyFormLabel>
                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="accountHolder">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup style={{ borderBottom: 'none' }}>
                  <MoneyFormLabel>신청금액</MoneyFormLabel>
                  <MoneyFormField>
                    <Field name="applyMoney">
                      {({ input, meta }) => (
                        <MoneyFormInputWrap>
                          <MoneyFormInput
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                              setApplyMoney(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </MoneyFormInputWrap>
                      )}
                    </Field>
                    <FormGroupIcon>원</FormGroupIcon>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup style={{ justifyContent: 'center' }}>
                  <CustomMoneyFormGroupField>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="10000"
                      onClick={e => {
                        onClickMoneyHandler('10000')
                      }}
                    >
                      <MoneyFormButtomInfo>1만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="50000"
                      onClick={e => {
                        onClickMoneyHandler('50000')
                      }}
                    >
                      <MoneyFormButtomInfo>5만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="100000"
                      onClick={e => {
                        onClickMoneyHandler('100000')
                      }}
                    >
                      <MoneyFormButtomInfo>10만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="500000"
                      onClick={e => {
                        onClickMoneyHandler('500000')
                      }}
                    >
                      <MoneyFormButtomInfo>50만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="1000000"
                      onClick={e => {
                        onClickMoneyHandler('1000000')
                      }}
                    >
                      <MoneyFormButtomInfo>100만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="5000000"
                      onClick={e => {
                        onClickMoneyHandler('5000000')
                      }}
                    >
                      <MoneyFormButtomInfo>500만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      onClick={e => {
                        onClickMoneyHandler('10000000')
                      }}
                    >
                      <MoneyFormButtomInfo>1000만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormModifyButton
                      $active
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      onClick={() => {
                        onClearClickHandler()
                      }}
                    >
                      <MoneyFormModifyButtomInfo>정정</MoneyFormModifyButtomInfo>
                    </MoneyFormModifyButton>{' '}
                  </CustomMoneyFormGroupField>
                </MoneyFormGroup>
                <MoneyFormGroup>
                  <MoneyFormLabel>보너스 동의</MoneyFormLabel>
                  <CustomFormGroupWrap>
                    {BonusStatusEnumsRadioOptions().map(item => (
                      <CustomFormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          onCustomChange={e => {
                            setBonusType(e)
                          }}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </CustomFormGroupField>
                    ))}
                  </CustomFormGroupWrap>
                </MoneyFormGroup>
                <CustomCenterFormBottomWrap>
                  <MoneyApplyButton variant="primary" type="submit">
                    <MoneyFormModifyButtomInfo>입금신청</MoneyFormModifyButtomInfo>
                  </MoneyApplyButton>
                </CustomCenterFormBottomWrap>
              </MoneyFormWrap>
            )}
          </Form>
          <MoneyDepositHeaderTwoWrap style={{ marginTop: '-20px' }}>
            <MoneyDepositHeaderThirdInfo>&lt;&nbsp;입금 내역&nbsp;&gt;</MoneyDepositHeaderThirdInfo>
          </MoneyDepositHeaderTwoWrap>
          <MoneyDepositBodyTwoWrap>
            <ReactTableBase columns={depositListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </MoneyDepositBodyTwoWrap>
        </MoneyDepositBodyWrap>
      )}
    </WebPageWrap>
  )
}

export default MoneyDepositForm
const CustomFormGroupWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
`

const CustomFormGroupField = styled(FormGroupField)``

const MoneyDepositHeaderThirdInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

const MoneyDepositWrap = styled.div`
  margin-top: 10px;
  background: #000000;
  border: 1px solid #edae07;

  height: 100%;
  min-height: 550px;

  // position: relative;
  // top: 30px;
`

const MoneyDepositHeaderWrap = styled.div`
  border-top: 1px solid #edae07;
  padding: 20px 10px 10px 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;

  border-bottom: 1px solid #edae07;
`

const MoneyDepositHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyDepositCardSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

const MoneyDepositBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
