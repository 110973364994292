import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { HermesMainBorder, HermesMainHover2, HermesMainText } from '../../../utils/palette'
import { createMemberCouponUse, searchCouponUseSelectList } from '../../../api/memberManage/memberManageApi'
import CouponListData from '../../MyPage/CouponHistory/CouponListData'
import { searchCouponUseList } from '../../../api/memberHistory/memberHistoryApi'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import Error from '../../../shared/components/form/Error'
import {
  MobileMoneyFormButtomInfo,
  MoneyFormInputWrap,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/form/newForm'
import { CouponUseValidate } from '../../../utils/validate/moneyFormValidate'
import { CustomButtonInfo } from '../../../shared/components/MobileMoneyCustomElement'
import { CustomSelect } from '../../../shared/components/CustomSelect'
import CustomLoading from '../../CustomLoading'

const CouponModalContent = () => {
  const [subMenuTitle, setSubMenuTitle] = useState('COUPON')
  const [couponLoading, setCouponLoading] = useState(true)

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })

  // 쿠폰선택
  const [couponOptionValue, setCouponOptionValue] = useState(null)
  const [couponOption, setCouponOption] = useState([])

  useEffect(() => {
    setCouponLoading(true)

    searchCouponUseSelectList()
      .then(res => {
        res.data.content.map(item =>
          setCouponOption(prev => [
            ...prev,
            {
              value: item.couponUseKey,
              label: item.couponTitle,
              couponPrice: item.couponPrice,
            },
          ]),
        )
        setCouponLoading(false)
      })
      .catch(error => {
        setCouponLoading(false)
      })
  }, [])
  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onChangeCouponSelectHandler = option => {
    if (option) {
      setCouponOptionValue(option)
      setApplyMoney(option.couponPrice)
    } else {
      setCouponOptionValue(null)
      setApplyMoney('0')
    }
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)
  const [couponUseLoading, setCouponUseLoading] = useState(false)

  /**
   쿠폰 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [couponUseListparams, setCouponUseListparamsParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setCouponUseListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  const fetchSearchCouponUseList = async () => {
    setLoading(true)
    await searchCouponUseList(couponUseListparams)
      .then(response => {
        setLoading(false)
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (subMenuTitle !== 'COUPON_HISTORY') return
    fetchSearchCouponUseList()
  }, [couponUseListparams, subMenuTitle])

  const [couponListData, setCouponListData] = useState(CouponListData(content))
  useEffect(() => {
    setCouponListData(CouponListData(content))
  }, [content])

  const [rows, setData] = useState(couponListData.tableRowsData)
  useEffect(() => {
    setData(couponListData.tableRowsData)
  }, [couponListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)
    setCouponUseLoading(true)
    const body = {
      couponUseKey: couponOptionValue?.value,
    }

    createMemberCouponUse(body)
      .then(res => {
        setApiFlag(false)
        setCouponUseLoading(false)
        alert('쿠폰 사용이 완료됐습니다.')
        window.location.reload()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('쿠폰 사용이 실패했습니다.')
        }
        setApiFlag(false)
        setCouponUseLoading(false)
        window.location.reload()
      })
  }

  return (
    <ContentWrap>
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <SubMenuItemListWrap>
            <div>
              <SubMenuItemBox active={subMenuTitle === 'COUPON'} onClick={() => setSubMenuTitle('COUPON')}>
                쿠폰
              </SubMenuItemBox>
              <SubMenuItemBox
                active={subMenuTitle === 'COUPON_HISTORY'}
                onClick={() => setSubMenuTitle('COUPON_HISTORY')}
              >
                쿠폰사용내역
              </SubMenuItemBox>
            </div>
          </SubMenuItemListWrap>

          <ContentBox>
            {
              {
                COUPON: (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '70%' }}>
                      <MoneyDepositInfoWrap>
                        <MoneyDepositInfoLeftWrap>
                          <MoneyDepositInfoLeftBox>
                            <MoneyDepositInfoLeftTextBox>
                              <div>스포츠캐쉬</div>
                              <MoneyDepositInfoLeftText>
                                {holdingMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoLeftText>
                            </MoneyDepositInfoLeftTextBox>
                          </MoneyDepositInfoLeftBox>
                        </MoneyDepositInfoLeftWrap>
                        <MoneyDepositInfoRightWrap>
                          <MoneyDepositInfoRightBox>
                            <MoneyDepositInfoRightTextBox>
                              <div>카지노캐쉬</div>
                              <MoneyDepositInfoRightText>
                                {totalGameMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoRightText>
                            </MoneyDepositInfoRightTextBox>
                          </MoneyDepositInfoRightBox>
                        </MoneyDepositInfoRightWrap>
                      </MoneyDepositInfoWrap>
                      <Form
                        onSubmit={onSubmit}
                        initialValues={{
                          memberId: userId,
                          applyMoney,
                          couponOptionValue,
                        }}
                        validate={CouponUseValidate}
                      >
                        {({ handleSubmit, form: { reset } }) => (
                          <div onSubmit={handleSubmit}>
                            <ContentRowSecondWrap>
                              <CustomerCenterFormGroup style={{ marginBottom: '-15px' }}>
                                <CustomerCenterFormLabel>쿠폰 목록</CustomerCenterFormLabel>
                                <CustomCenterFormField>
                                  <Field name="couponOptionValue">
                                    {({ input, meta }) => (
                                      <>
                                        <CouponSelect
                                          {...input}
                                          value={couponOptionValue}
                                          onChange={option => {
                                            input.onChange(option)
                                            onChangeCouponSelectHandler(option)
                                          }}
                                          options={couponOption}
                                          placeholder="쿠폰을 선택하세요"
                                          isClearable
                                          className="react-select"
                                          classNamePrefix="react-select"
                                          noOptionsMessage={() => '사용할 수 있는 쿠폰 없음'} // 사용자 정의된 문구 추가
                                        />
                                        {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                                      </>
                                    )}
                                  </Field>
                                </CustomCenterFormField>
                              </CustomerCenterFormGroup>
                              <CustomerCenterFormGroup style={{ marginBottom: '30px' }}>
                                <CustomerCenterFormLabel>전환 금액</CustomerCenterFormLabel>
                                <CustomCenterFormField>
                                  <Field name="applyMoney">
                                    {({ input, meta }) => (
                                      <MoneyFormInputWrap>
                                        <MoneyFormInput
                                          {...input}
                                          type="text"
                                          value={input.value}
                                          disabled
                                          // onChange={e => {
                                          //   input.onChange(e)
                                          // }}
                                        />
                                        {meta.error && <Error style={{ marginTop: '10px' }} error={meta.error} />}
                                      </MoneyFormInputWrap>
                                    )}
                                  </Field>
                                </CustomCenterFormField>
                              </CustomerCenterFormGroup>
                              <MoneyButton variant="primary" type="submit" onClick={onSubmit}>
                                <CustomButtonInfo2>전환하기</CustomButtonInfo2>
                              </MoneyButton>
                            </ContentRowSecondWrap>
                          </div>
                        )}
                      </Form>
                    </div>
                  </div>
                ),
                COUPON_HISTORY: (
                  <>
                    {loading ? (
                      <CustomLoading />
                    ) : (
                      <>
                        <MoneyDepositHeaderTwoWrap>
                          <MoneyDepositHeaderThirdInfoBox>
                            <MoneyDepositHeaderThirdInfo>쿠폰 사용 내역</MoneyDepositHeaderThirdInfo>
                          </MoneyDepositHeaderThirdInfoBox>
                        </MoneyDepositHeaderTwoWrap>
                        <MoneyDepositBodyTwoWrap>
                          <ReactTableBase
                            columns={couponListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyDepositBodyTwoWrap>
                      </>
                    )}
                  </>
                ),
              }[subMenuTitle]
            }
          </ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default CouponModalContent

export const MoneyFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;

  &:disabled {
    // background: #848484;
    &:hover {
      cursor: not-allowed;
      caret-color: red;
    }
  }
`

const CustomButtonInfo2 = styled(MoneyFormModifyButtomInfo)`
  color: #000 !important;
  font-size: 14px;
  font-weight: 700;
`

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  // height: 550px;
  height: calc(100vh - 270px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`

const MoneyDepositHeaderThirdInfoBox = styled.div`
  float: left;
  padding-top: 10px;
  height: 40px;
  display: block;
`

const MoneyDepositHeaderThirdInfo = styled.span`
  color: #fff;
  font-size: 14px;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #171719;
  font-weight: 900;
  display: flex;
`

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 20px 0;

  gap: 10px;

  .react-table__column-header {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
  }

  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 16px 5px;
    font-size: 14px;
    font-weight: 700;
  }
`

const MoneyDepositInfoWrap = styled.div`
  font-weight: 700;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  box-sizing: border-box;
  display: flex;
  font-family: 'Malgun Gothic', dotum;
`

const MoneyDepositInfoLeftWrap = styled.div`
  background: rgb(18, 18, 20);
  width: calc(50% - 8px);
  float: left;
  display: block;
`
const MoneyDepositInfoLeftBox = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
`

const MoneyDepositInfoLeftTextBox = styled.div`
  float: left;
  padding-left: 24px;
  font-weight: 700;
`
const MoneyDepositInfoLeftText = styled.div`
  color: ${HermesMainHover2};
  font-size: 14px;
`

const MoneyDepositInfoRightWrap = styled.div`
  background: rgb(18, 18, 20);
  width: calc(50% - 8px);
  float: right;
  display: block;
`
const MoneyDepositInfoRightBox = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
`

const MoneyDepositInfoRightTextBox = styled.div`
  float: right;
  text-align: right;
  padding-right: 24px;
`
const MoneyDepositInfoRightText = styled.div`
  color: ${HermesMainHover2};
  font-size: 14px;
`

const MoneyButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

const ContentRowSecondWrap = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
  }
`

const CustomerCenterFormGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const CustomCenterFormField = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`

const CustomerCenterFormLabel = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
`

export const CouponSelect = styled(CustomSelect)`
  .react-select__control {
    height: 28px;
    border: 1px solid #4a4a4a;
    background-color: transparent;
    &.react-select__control--is-focused,
    &:hover {
      border: solid 2px;
      border-color: white !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: #d3d3d3;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: #d3d3d3 !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto !important;
    background: rgba(0, 0, 0, 0.7) !important;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: ${HermesMainText};
  }

  .react-select__single-value {
    color: #d3d3d3;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    border: 1px solid black !important;
    color: #fff;
    font-weight: 600;
  }

  .react-select__option {
    color: #fff;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: rgb(16, 16, 18);
  }
`
