import { CompApplyButton } from '@/shared/components/MoneyCustomElement'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroupDescription,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { minusDayToKst } from '@/utils/dateTime'
import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMemberPointExchange } from '../../api/memberHistory/memberHistoryApi'
import { createMemberPointExchange } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { MoneyFormModifyButtomInfo, WebPageWrap } from '../../shared/components/form/newForm'
import {
  colorMainText,
  colorMoneyExchangeBottomBorder,
  colorMoneyExchangeSvg,
  colorWalletSvg,
} from '../../utils/palette'
import CustomLoading from '../CustomLoading'
import PointExchangeData from './PointExchangeData'
import './pointExchange.css'

const PointExchangeForm = ({ tabTitle }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const dispatch = useDispatch()

  const { moneyAmount, pointAmount } = useSelector(state => {
    const { memberInfo } = state

    return {
      moneyAmount: memberInfo.memberHoldingMoney,
      pointAmount: memberInfo.memberHoldingPoint,
    }
  })

  /**
    회원 최근 일주일 롤링 전환내역 조회
*/
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [pointWeekHistoryParams, setPointWeekHistoryParams] = useState({
    pointType: 'ROLLING',
    page,
    size,
    startDate: minusDayToKst(7),
  })

  useEffect(() => {
    setPointWeekHistoryParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberPointExchange = async () => {
    setPageLoading(true)
    await searchMemberPointExchange(pointWeekHistoryParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberPointExchange()
  }, [tabTitle])

  const [pointExchangeListData, setPointExchangeListData] = useState(PointExchangeData(content))

  useEffect(() => {
    setPointExchangeListData(PointExchangeData(content))
  }, [content])

  const [rows, setData] = useState(pointExchangeListData.tableRowsData)

  useEffect(() => {
    setData(pointExchangeListData.tableRowsData)
  }, [pointExchangeListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  const [compExchangeLoading, setCompExchangeLoading] = useState(false)
  const onSubmit = () => {
    if (compExchangeLoading) return
    if (pointAmount === 0) return
    setCompExchangeLoading(true)
    createMemberPointExchange()
      .then(res => {
        setCompExchangeLoading(false)
        alert('포인트 전환이 완료되었습니다.')
        fetchSearchMemberPointExchange()
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('포인트 전환이 실패했습니다. 다시 시도해주세요.')
        }
        setCompExchangeLoading(false)
      })
  }

  return (
    <WebPageWrap>
      <CompExchangeHeaderWrap>
        <CompExchangeHeaderInfo>포인트 전환</CompExchangeHeaderInfo>
        <CompExchangeCardSubhead>* 포인트 전환시 전부 지갑머니로 전환됩니다.</CompExchangeCardSubhead>
      </CompExchangeHeaderWrap>
      {pageLoading || compExchangeLoading ? (
        <CustomLoading />
      ) : (
        <>
          <CompExchangeBodyOneWrap>
            <div className="box_wrap01" style={{ marginRight: '5px' }}>
              <div className="box_box01">
                <div className="box_font01">지갑 머니</div>
                <div className="box_font02">{moneyAmount?.toString().replace(commonReg2, ',')}</div>
              </div>
            </div>
            <div className="box_wrap01" style={{ marginRight: '5px' }}>
              <div className="box_box01">
                <div className="box_font01">보유 포인트</div>
                <div className="box_font02">{pointAmount?.toString().replace(commonReg2, ',')}</div>
              </div>
            </div>
          </CompExchangeBodyOneWrap>
          <CompExchangeBodyTwoWrap>
            <CompApplyButton onClick={onSubmit}>
              <MoneyFormModifyButtomInfo>포인트 전환하기</MoneyFormModifyButtomInfo>
            </CompApplyButton>
          </CompExchangeBodyTwoWrap>

          <CompExchangeHeaderTwoWrap>
            <CompExchangeHeaderInfo>전환내역</CompExchangeHeaderInfo>
          </CompExchangeHeaderTwoWrap>
          <CompExchangeBodyThirdWrap>
            <ReactTableBase columns={pointExchangeListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </CompExchangeBodyThirdWrap>
        </>
      )}
    </WebPageWrap>
  )
}

export default PointExchangeForm

// sign up form label
const CompFormLabel = styled.div`
  width: max-content;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

export const CompFormField = styled.div`
  width: 400px;
  display: flex;
`

const CompExchangeWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;
  margin-top: 10px;

  svg {
    width: 40px;
    height: 40px;
    color: ${colorWalletSvg};
    margin: 0 5px;
  }
`

const CompExchangeHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  border-bottom: 1px solid #edae07;
`
const CompExchangeHeaderTwoWrap = styled.div`
  height: 50px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const CompExchangeBodyOneWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2px !important;
  padding: 10px 20px;
`

const CompExchangeBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  gap: 10px;
`

const CompExchangeBodyThirdWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

const CompExchangeHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const CompExchangeHeaderThirdInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

export const CompExchangeCardSubhead = styled.p`
  text-transform: none;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

export const CompExchangeInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CompFormContainer = styled(FormContainer)`
  ${props =>
    props.horizontal &&
    `

    ${FormGroupField} {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 120px;
    }

    @media screen and (min-width: 480px) {

      ${FormGroupLabel} {
        width: 120px;
      }

      ${FormGroupField} {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;

      }

      ${FormGroupDescription}, ${FormButtonToolbar} {
        margin-left: 0px;
      }
    }
  `}
`

export const CompIoIosArrowBack = styled(IoIosArrowBack)`
  color: ${colorMoneyExchangeSvg} !important;
`

// endregion
