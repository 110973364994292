export const BoardTypeEnums = {
  NOTICE: '공지사항',
  EVENT: '이벤트',
  BETTING_RULE: '배팅규정',
}

export const BoardTypeEnumsSelectOptions = () => {
  return Object.entries(BoardTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const BoardTypeEnumsRaidoOptions = () => {
  return Object.entries(BoardTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'boardType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'boardType',
          label: value,
          radioValue: key,
        },
  )
}

export const BoardTargetEnums = {
  MEMBER: '회원',
  PARTNER: '파트너',
  MEMBERPARTNER: '회원+파트너',
}

export const BoardTargetEnumsSelectOptions = () => {
  return Object.entries(BoardTargetEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const BoardTargetEnumsRadioOptions = () => {
  return Object.entries(BoardTargetEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'boardTarget',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'boardTarget',
          label: value,
          radioValue: key,
        },
  )
}

export const BoardStatusEnums = {
  SHOW: '보이기',
  HIDDEN: '감추기',
}

export const BoardStatusEnumsSelectOptions = () => {
  return Object.entries(BoardStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const BoardStatusEnumsRadioOptions = () => {
  return Object.entries(BoardStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'boardStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'boardStatus',
          label: value,
          radioValue: key,
        },
  )
}
