import React, { useMemo, useState } from 'react'
import { convertToKstDateTime } from '@/utils/dateTime'
import { commonReg2 } from '@/utils/validate/commonValidate'

const PointExchangeData = content => {
  const columns = [
    {
      Header: '신청일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      width: 30,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
    {
      Header: '변경전',
      accessor: 'beforePoint',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '전환액',
      accessor: 'changePoint',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후',
      accessor: 'afterPoint',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPoint.toString().replace(commonReg2, ',')
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default PointExchangeData
