import React, { useState } from 'react'
import PropTypes from 'prop-types'

const MobileWildGamesDetailBettingLogRadio = ({ transactionId, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        setCheckRadio(prev => [...prev, transactionId])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== transactionId))
        break
      default:
        break
    }
  }
  return (
    <div>
      <input
        style={{ width: '100%' }}
        checked={checkRadio.find(e => e === transactionId)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default MobileWildGamesDetailBettingLogRadio

MobileWildGamesDetailBettingLogRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  transactionId: PropTypes.string,
}

MobileWildGamesDetailBettingLogRadio.defaultProps = {
  transactionId: null,
  checkRadio: [],
}
