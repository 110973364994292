import { CustomSelect } from '@/shared/components/CustomSelect'
import { CustomCenterFormBottomWrap, MoneyApplyButton } from '@/shared/components/MoneyCustomElement'
import Error from '@/shared/components/form/Error'
import { FormGroupIcon } from '@/shared/components/form/FormElements'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { CouponUseValidate } from '@/utils/validate/moneyFormValidate'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchCouponUseList } from '../../api/memberHistory/memberHistoryApi'
import { createMemberCouponUse, searchCouponUseSelectList } from '../../api/memberManage/memberManageApi'
import {
  MoneyFormField,
  MoneyFormGroup,
  MoneyFormInput,
  MoneyFormInputWrap,
  MoneyFormLabel,
  MoneyFormModifyButtomInfo,
  MoneyFormWrap,
  WebPageWrap,
} from '../../shared/components/form/newForm'
import { colorMainText } from '../../utils/palette'
import CustomLoading from '../CustomLoading'
import CouponListData from '../MyPage/CouponHistory/CouponListData'

const CouponUseForm = () => {
  const [couponLoading, setCouponLoading] = useState(true)

  const { userId } = useSelector(state => {
    const { memberInfo } = state

    return {
      userId: memberInfo.userId,
    }
  })

  // 쿠폰선택
  const [couponOptionValue, setCouponOptionValue] = useState(null)
  const [couponOption, setCouponOption] = useState([])

  useEffect(() => {
    setCouponLoading(true)

    searchCouponUseSelectList()
      .then(res => {
        res.data.content.map(item =>
          setCouponOption(prev => [
            ...prev,
            {
              value: item.couponUseKey,
              label: item.couponTitle,
              couponPrice: item.couponPrice,
            },
          ]),
        )
        setCouponLoading(false)
      })
      .catch(error => {
        setCouponLoading(false)
      })
  }, [])
  // 신청금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onChangeCouponSelectHandler = option => {
    if (option) {
      setCouponOptionValue(option)
      setApplyMoney(option.couponPrice)
    } else {
      setCouponOptionValue(null)
      setApplyMoney('0')
    }
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)
  const [couponUseLoading, setCouponUseLoading] = useState(false)

  /**
   쿠폰 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [couponUseListparams, setCouponUseListparamsParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setCouponUseListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchCouponUseList(couponUseListparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [couponUseListparams])

  const fetchSearchCouponUseList = async () => {
    setLoading(true)
    await searchCouponUseList(couponUseListparams)
      .then(response => {
        setLoading(false)
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [couponListData, setCouponListData] = useState(CouponListData(content))
  useEffect(() => {
    setCouponListData(CouponListData(content))
  }, [content])

  const [rows, setData] = useState(couponListData.tableRowsData)
  useEffect(() => {
    setData(couponListData.tableRowsData)
  }, [couponListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const onSubmit = e => {
    if (apiFlag) return

    setApiFlag(true)
    setCouponUseLoading(true)
    const body = {
      couponUseKey: couponOptionValue?.value,
    }

    createMemberCouponUse(body)
      .then(res => {
        setApiFlag(false)
        setCouponUseLoading(false)
        alert('쿠폰 사용이 완료됐습니다.')
        window.location.reload()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('쿠폰 사용이 실패했습니다.')
        }
        setApiFlag(false)
        setCouponUseLoading(false)
        window.location.reload()
      })
  }

  return (
    <WebPageWrap>
      <MoneyDepositHeaderWrap>
        <MoneyDepositHeaderInfo>쿠폰사용</MoneyDepositHeaderInfo>
        <MoneyDepositCardSubhead>* 쿠폰 사용 시 해당 금액은 지갑머니로 지급 됩니다.</MoneyDepositCardSubhead>
        {/* <MoneyDepositCardSubhead>* 만료일이 지난 쿠폰은 사용하실 수 없습니다.</MoneyDepositCardSubhead> */}
      </MoneyDepositHeaderWrap>
      {couponLoading || couponUseLoading ? (
        <CustomLoading />
      ) : (
        <>
          <MoneyDepositBodyWrap>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                memberId: userId,
                applyMoney,
                couponOptionValue,
              }}
              validate={CouponUseValidate}
            >
              {({ handleSubmit, form: { reset } }) => (
                <MoneyFormWrap horizontal onSubmit={handleSubmit}>
                  <MoneyFormGroup>
                    <MoneyFormLabel>아이디</MoneyFormLabel>
                    <MoneyFormField>
                      <MoneyFormInputWrap>
                        <Field name="memberId">
                          {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                        </Field>
                      </MoneyFormInputWrap>
                    </MoneyFormField>
                  </MoneyFormGroup>
                  <MoneyFormGroup>
                    <MoneyFormLabel>쿠폰목록</MoneyFormLabel>
                    <MoneyFormField>
                      <Field name="couponOptionValue">
                        {({ input, meta }) => (
                          <>
                            <CouponSelect
                              {...input}
                              value={couponOptionValue}
                              onChange={option => {
                                input.onChange(option)
                                onChangeCouponSelectHandler(option)
                              }}
                              options={couponOption}
                              placeholder="쿠폰을 선택하세요"
                              isClearable
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </>
                        )}
                      </Field>
                    </MoneyFormField>
                  </MoneyFormGroup>
                  <MoneyFormGroup style={{ borderBottom: 'none' }}>
                    <MoneyFormLabel>신청금액</MoneyFormLabel>
                    <MoneyFormField>
                      <Field name="applyMoney">
                        {({ input, meta }) => (
                          <MoneyFormInputWrap>
                            <MoneyFormInput
                              {...input}
                              type="text"
                              value={input.value}
                              disabled
                              onChange={e => {
                                input.onChange(e)
                                setApplyMoney(e.target.value)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </MoneyFormInputWrap>
                        )}
                      </Field>
                      <FormGroupIcon>원</FormGroupIcon>
                    </MoneyFormField>
                  </MoneyFormGroup>
                  <CustomCenterFormBottomWrap>
                    <MoneyApplyButton variant="primary" type="submit">
                      <MoneyFormModifyButtomInfo>쿠폰 전환</MoneyFormModifyButtomInfo>
                    </MoneyApplyButton>
                  </CustomCenterFormBottomWrap>
                </MoneyFormWrap>
              )}
            </Form>
          </MoneyDepositBodyWrap>
          <WeekHeaderWrap style={{ marginTop: '-40px' }}>
            <WeekHeaderInfo>&lt;쿠폰 지급 내역&gt;</WeekHeaderInfo>
          </WeekHeaderWrap>

          <WeekBodyWrap>
            <ReactTableBase columns={couponListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </WeekBodyWrap>
        </>
      )}
    </WebPageWrap>
  )
}

export default CouponUseForm

const MemberCouponSelect = styled(CustomSelect)`
  width: 100%;
  .react-select__menu {
    z-index: 101;
  }
  color: black;
  .react-select__menu-list {
    overflow: auto;
  }
`

const MoneyDepositWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 450px;

  margin-top: 10px;
`

const MoneyDepositHeaderWrap = styled.div`
  padding: 10px 0 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1px;
  height: 90px;
  border-bottom: solid 1px #edae07;
`

const MoneyDepositHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyDepositCardSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  // margin-top: 3px;
  // margin-bottom: 5px;
  color: ${colorMainText};
`

const MoneyDepositBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WeekHeaderWrap = styled.div`
  height: 50px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const WeekHeaderInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const WeekBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

// Select
export const CouponSelect = styled(CustomSelect)`
  .react-select__control {
    height: 28px;
    border: 1px solid #4a4a4a;
    background-color: transparent;

    &.react-select__control--is-focused,
    &:hover {
      border: solid 2px;
      border-color: white !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: #d3d3d3;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: #d3d3d3 !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto !important;
    background: rgba(0, 0, 0, 0.3) !important;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: #d3d3d3;
  }

  .react-select__single-value {
    color: #d3d3d3;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    border: 1px solid black !important;
  }
`
