import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import { Collapse } from 'react-bootstrap'
import { deleteNoteList, updateNoteStatus, updateNoteStatusAll } from '../../../api/customCenter/customCenterApi'
import { fetchNoteList } from '../../../redux/customCenterSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import { HermesMainBorder, colorMainText, colorTableBodyBackground } from '../../../utils/palette'
import CustomLoading from '../../CustomLoading'
import {
  CustomCollapse,
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  GoldQuestionButton,
  ListButton,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/MoneyCustomElement'
import { convertToKstByFormat, convertToKstDateTime } from '../../../utils/dateTime'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import { NoteReadStatusEnums } from '../../../utils/enums/Operation/NoteEnums'
import NoteRadio from '../../CustomCenter/Note/NoteRadio'
import { fetchNoteSize } from '../../../redux/memberInfoSlice'

const NoteModalContent = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  /**
   * 회원 쪽지내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [noteParams, setNoteParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setNoteParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  // const { totalElement, content } = useSearchMemberMoneyExchange(params)

  const [content, setContent] = useState([])
  const { totalElement, apiContent, noReadSize } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noteInfo.totalElement,
      apiContent: customCenter.noteInfo.content,
    }
  })

  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.noteKey === item.noteKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchNoteList = async () => {
    try {
      dispatch(fetchNoteList(noteParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchNoteList()
  }, [noteParams])

  const [checkRadio, setCheckRadio] = useState([])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = noteKey => {
    const idx = content.findIndex(item => item.noteKey === noteKey)
    if (idx !== -1) {
      // isVisible 상태를 업데이트합니다.
      setContent(prevContent => {
        const newContent = [...prevContent]
        newContent[idx] = {
          ...newContent[idx],
          isVisible: !newContent[idx].isVisible,
        }
        return newContent
      })

      const idx2 = content.findIndex(item => item.noteKey === noteKey)
      if (idx2 !== -1 && content[idx2]?.status === 'NOREAD') {
        updateNoteStatus({
          noteKey: content[idx2].noteKey,
        }).then(res => {
          fetchSearchNoteList()
          dispatch(fetchNoteSize())
        })
      }
    }
  }

  const [readLoading, setReadLoading] = useState(false)
  // 삭제하기 버튼
  const onReadHandler = () => {
    if (readLoading) return

    setReadLoading(true)
    updateNoteStatusAll({})
      .then(res => {
        setReadLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
      .catch(error => {
        setReadLoading(false)
        dispatch(fetchNoteSize())
        fetchSearchNoteList()
      })
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 쪽지를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteNoteList({
        noteKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
    }
  }
  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
  }

  return (
    <ContentWrap>
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <ContentBox>
            {loading ? (
              <CustomLoading />
            ) : (
              <CustomerCenterContentWrap>
                <MoneyDepositHeaderTwoWrap>
                  <MoneyDepositHeaderThirdInfoBox>
                    <MoneyDepositHeaderThirdInfo>쪽지 리스트</MoneyDepositHeaderThirdInfo>
                  </MoneyDepositHeaderThirdInfoBox>
                  <ListWrap>
                    <CustomDeleteButton
                      onClick={() => {
                        setCheckRadio(content.map(value => value.noteKey))
                      }}
                      type="button"
                    >
                      <CustomButtonInfos>전체선택</CustomButtonInfos>
                    </CustomDeleteButton>
                    <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
                      <CustomButtonInfos>삭제하기</CustomButtonInfos>
                    </CustomDeleteButton>
                    <CustomDeleteButton
                      style={{ background: `${HermesMainBorder}` }}
                      onClick={() => {
                        onReadHandler()
                      }}
                      variant="primary"
                      type="button"
                    >
                      <CustomButtonInfos2 style={{ color: 'black' }}>전체읽기</CustomButtonInfos2>
                    </CustomDeleteButton>
                  </ListWrap>
                </MoneyDepositHeaderTwoWrap>

                <CustomerCenterContentTable>
                  <CustomerCenterContentTableHead>
                    <CustomerCenterContentTableHeadTr>
                      <th style={{ width: '5%' }}>선택</th>
                      <th style={{ width: '5%' }}>번호</th>
                      <th style={{ width: '70%' }}>내용</th>
                      <th style={{ width: '12%' }}>수신일</th>
                      <th style={{ width: '8%' }}>상태</th>
                    </CustomerCenterContentTableHeadTr>
                  </CustomerCenterContentTableHead>
                  <CustomerCenterContentTableBody>
                    {content.length === 0 ? (
                      <NoContentDiv>
                        <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
                        최근 게시물이 없습니다.
                      </NoContentDiv>
                    ) : (
                      <>
                        {content.map(item => (
                          <>
                            <CustomerCenterContentTableBodyTr key={item.noteKey}>
                              <CustomerCenterContentBox>
                                <CustomerCenterContenItemBox width="5">
                                  <NoteRadio
                                    rowInfo={{
                                      noteKey: item.noteKey,
                                      status: item.status,
                                    }}
                                    setCheckRadio={setCheckRadio}
                                    checkRadio={checkRadio}
                                  />
                                </CustomerCenterContenItemBox>
                                <CustomerCenterContenItemBox width="5" onClick={() => onClickRowHandler(item.noteKey)}>
                                  {item.num}
                                </CustomerCenterContenItemBox>
                                <CustomerCenterContenItemBox
                                  width="70"
                                  onClick={() => onClickRowHandler(item.noteKey)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {item.noteTitle}
                                </CustomerCenterContenItemBox>
                                <CustomerCenterContenItemBox width="12" onClick={() => onClickRowHandler(item.noteKey)}>
                                  {convertToKstByFormat(item.sendDate, 'MM-DD HH:mm')}
                                </CustomerCenterContenItemBox>
                                <CustomerCenterContenItemBox width="8" onClick={() => onClickRowHandler(item.noteKey)}>
                                  {NoteReadStatusEnums[item.status]}
                                </CustomerCenterContenItemBox>
                              </CustomerCenterContentBox>
                            </CustomerCenterContentTableBodyTr>
                            <CustomCollapse in={item.isVisible}>
                              <CustomerCenterContentTableBodyTr>
                                <CustomerCenterContentDetailBox>
                                  {ReactHtmlParser(item.sendContent, { transform: transformFn })}
                                </CustomerCenterContentDetailBox>
                              </CustomerCenterContentTableBodyTr>
                            </CustomCollapse>
                          </>
                        ))}
                      </>
                    )}
                  </CustomerCenterContentTableBody>
                </CustomerCenterContentTable>
                {totalElement > 0 && (
                  <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                )}
              </CustomerCenterContentWrap>
            )}
          </ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default NoteModalContent

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  img {
    width: 120px;
    height: auto;
  }
`

const MoneyDepositHeaderThirdInfoBox = styled.div`
  float: left;
  padding-top: 10px;
  height: 40px;
  display: block;
  width: 100%;
`

const MoneyDepositHeaderThirdInfo = styled.span`
  color: #fff;
  font-size: 14px;
`

const MoneyDepositHeaderTwoWrap = styled.div`
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #171719;
  font-weight: 900;
  display: flex;
`

const CustomDeleteButton = styled.button`
  background-color rgb(44, 52, 59);
  border: none;
  border-radius: 3px;
  display: flex;
  width: 120px;
  padding: 0px 8px;
  height: 42px;
  text-align:center;
  justify-content: center;
  align-items: center;
  svg {
    color: grey;
  }
`

const CustomButtonInfos = styled(MoneyFormModifyButtomInfo)`
  font-size: 13px;
`

const CustomButtonInfos2 = styled(MoneyFormModifyButtomInfo)`
  font-size: 13px;
  color: #000 !important;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

export const OnetoOneSubhead = styled.p`
  text-transform: none;
  font-style: normal;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`
export const OneToOneHeaderWrap = styled.div`
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px
  border-bottom: solid 1px #edae07;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    padding: 16px 4px;
    background: rgba(9, 9, 10) !important;
    &:last-child {
      border-right: 0;
    }
    font-weight: 700;
    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: rgb(22, 23, 25);
  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
  font-size: 14px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 16px 5px;
`

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  // height: 550px;
  height: calc(100vh - 270px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  // active 일때
  // border-bottom: 1px solid ${HermesMainBorder};
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`
