import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { FaArrowUp } from 'react-icons/fa'
import { colorScrollButtonBackground, colorScrollButtonBorder, colorScrollButtonHover } from '../../utils/palette'

const MobileScrollButton = () => {
  const [scrolling, setScrolling] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolling(true)
      } else {
        setScrolling(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <ScrollButtonWrap>
      <SmothScroll scrolling={scrolling} onClick={scrollToTop}>
        <SmothScrollContent>
          <FaArrowUp />
        </SmothScrollContent>
      </SmothScroll>
    </ScrollButtonWrap>
  )
}

export default MobileScrollButton

const ScrollButtonWrap = styled.div``

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const SmothScroll = styled.ul`
  position: fixed;
  bottom: 30px;
  left: 30px;
  list-style: none;
  z-index: 120;
  display: ${props => (props.scrolling ? 'block' : 'none')};

  ${({ scrolling }) =>
    scrolling
      ? css`
          animation: ${fadeIn} 1s forwards;
        `
      : css`
          animation: ${fadeOut} 1s forwards;
        `}
`

const SmothScrollContent = styled.li`
  background: ${colorScrollButtonBackground};
  width: 50px;
  height: 50px;
  display: block;
  text-align: center;
  color: rgba(255, 192, 26, 1);
  border-radius: 50%;
  border: 2px solid ${colorScrollButtonBorder};

  &:hover {
    background: ${colorScrollButtonHover};
    color: rgba(255, 192, 26, 1);
  }

  svg {
    width: 25px !important;
    height: 45px !important;
  }
`
