import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HermesMainBorder } from '../../../utils/palette'
import FriendListData from '../../MyPage/FriendListHistory/FriendListData'
import { searchFriendList } from '../../../api/memberHistory/memberHistoryApi'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'

const FriendHistoryModalContent = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [friendListparams, setFriendListparamsParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setFriendListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchFriendList(friendListparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [friendListparams])

  const [friendListData, setFriendListData] = useState(FriendListData(content))
  useEffect(() => {
    setFriendListData(FriendListData(content))
  }, [content])

  const [rows, setData] = useState(friendListData.tableRowsData)
  useEffect(() => {
    setData(friendListData.tableRowsData)
  }, [friendListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <ContentWrap>
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <ContentBox>
            <MoneyWithdrawalHeaderTwoWrap>
              <MoneyWithdrawalHeaderThirdInfoBox>
                <MoneyWithdrawalHeaderThirdInfo>지인 내역</MoneyWithdrawalHeaderThirdInfo>
              </MoneyWithdrawalHeaderThirdInfoBox>
            </MoneyWithdrawalHeaderTwoWrap>
            <MoneyWithdrawalBodyTwoWrap>
              <ReactTableBase columns={friendListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
            </MoneyWithdrawalBodyTwoWrap>
          </ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default FriendHistoryModalContent

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  // height: 550px;
  height: calc(100vh - 270px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid ${HermesMainBorder};
  }

  // active 일때
  // border-bottom: 2px solid ${HermesMainBorder};
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`

const MoneyWithdrawalHeaderThirdInfoBox = styled.div`
  float: left;
  padding-top: 10px;
  height: 40px;
  display: block;
`

const MoneyWithdrawalHeaderThirdInfo = styled.span`
  color: #fff;
  font-size: 14px;
`

const MoneyWithdrawalHeaderTwoWrap = styled.div`
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #171719;
  font-weight: 900;
  display: flex;
`

const MoneyWithdrawalBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 20px 0;

  gap: 10px;

  .react-table__column-header {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
  }

  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 16px 5px;
    font-size: 14px;
    font-weight: 700;
  }
`
