import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import CustomLoading from '../CustomLoading'
import SportsBettingHistory from './SportsBettingHistory'
import MiniGameBettingHistory from './MiniGameBettingHistory'

const BettingHistoryPage = () => {
  const [active, setActive] = useState('sports')
  const getBettingHistoryComponent = () => {
    switch (active) {
      case 'sports':
        return <SportsBettingHistory />
      case 'minigame':
        return <MiniGameBettingHistory />
      default:
        return <></>
    }
  }
  return (
    <BettingHistoryPageWrap>
      <BettingHistorySubHeaderWrap>
        <BettingHistorySubHeaderBox active={active === 'sports'} onClick={() => setActive('sports')}>
          <BettingHistorySubHeaderInfo>스포츠 배팅내역</BettingHistorySubHeaderInfo>
        </BettingHistorySubHeaderBox>
        <BettingHistorySubHeaderBox active={active === 'minigame'} onClick={() => setActive('minigame')}>
          <BettingHistorySubHeaderInfo>미니게임 배팅내역</BettingHistorySubHeaderInfo>
        </BettingHistorySubHeaderBox>
      </BettingHistorySubHeaderWrap>

      <BettingHistoryContentWrap>{getBettingHistoryComponent()}</BettingHistoryContentWrap>
    </BettingHistoryPageWrap>
  )
}

export default BettingHistoryPage

const BettingHistoryPageWrap = styled.div`
  width: 100%;
  background-color: #111111;
  border: 2px solid #edae07;
  //   height: 100%;
  height: 797px;
  padding-bottom: 10px;
  position: relative;
`

const BettingHistorySubHeaderWrap = styled.div`
  width: 100%;
  min-height: 60px;
  //   margin-top: 10px;
  display: flex;
`

const BettingHistorySubHeaderBox = styled.div`
  width: 100%;
  background-color: rgba(48, 48, 48, 0.6);
  color: #fff;
  text-align: center;
  font-weight: 700;
  border-bottom: 1px solid #edae07;
  cursor: pointer;

  ${props =>
    props.active &&
    `
    
    background-color: #ffba00;
    border-top: 1px solid #edae07;
    border-left: 1px solid #edae07;
    border-right: 1px solid #edae07;
    border-bottom: 0;
    color: black;
    `}
`

const BettingHistorySubHeaderInfo = styled.div`
  width: 100%;
  font-weight: 700;
  cursor: pointer;

  padding: 9px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BettingHistoryContentWrap = styled.div`
  width: 100%;
  height: calc(100% - 52px);
  padding: 10px;
  color: white;
`
