import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { Collapse } from 'react-bootstrap'
import { searchCasinoBettingHistory, deleteCasinoBettingHistory } from '../../../api/game/gameApi'
import CustomPagination from '../../../shared/components/CustomPagination'
import {
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  CustomerCenterHeaderWrap,
  ListButton,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/MoneyCustomElement'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import CustomLoading from '../../CustomLoading'
import CasinoBettingHistoryRadio from './CasinoBettingHistoryRadio'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { CustomDeleteButton } from '../../../mobile/mobileCasinoGame/MobileLiveCasino'
import { CustomButtonInfos } from '../../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { HermesMainText } from '../../../utils/palette'

const CasinoBettingHistoryPage = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [boardParams, setBoardParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [content, setContent] = useState([])

  const fetchCasinoBettingHistory = async params => {
    await searchCasinoBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchCasinoBettingHistory(boardParams)
  }, [boardParams])

  /**
   * 페이징처리
   */
  const casinoBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (casinoBettingHistoryWrapRef.current) {
      casinoBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteCasinoBettingHistory({
        casinoBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchCasinoBettingHistory(boardParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchCasinoBettingHistory(boardParams)
        })
    }
  }

  return (
    <CustomWebPageWrap ref={casinoBettingHistoryWrapRef} style={{ border: 'none' }}>
      {loading ? (
        <CustomLoading />
      ) : (
        <CustomerCenterContentWrap>
          <CustomerCenterContentWrap>
            <CustomerCenterContentTable>
              {content.length === 0 ? (
                <></>
              ) : (
                <CustomerCenterContentTableHead>
                  <CustomerCenterContentTableHeadTr>
                    <th style={{ width: '3%' }}>&nbsp;</th>
                    <th style={{ width: '10%' }}>배팅시간</th>
                    <th style={{ width: '20%' }}>게임사</th>
                    <th style={{ width: '20%' }}>게임명</th>
                    <th style={{ width: '15%' }}>배팅액</th>
                    <th style={{ width: '16%' }}>당첨금</th>
                    <th style={{ width: '16%' }}>배팅후 잔액</th>
                  </CustomerCenterContentTableHeadTr>
                </CustomerCenterContentTableHead>
              )}

              <CustomerCenterContentTableBody>
                {content.length === 0 ? (
                  <NoContentDiv>
                    <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
                    카지노 배팅내역이 없습니다.
                    {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
                  </NoContentDiv>
                ) : (
                  <>
                    {content.map(item => (
                      <>
                        <CustomerCenterContentTableBodyTr key={item.bettingId}>
                          <CustomerCenterContentBox>
                            <CustomerCenterContenItemBox width="3">
                              <CasinoBettingHistoryRadio
                                casinoBettingLogKey={item.bettingId}
                                checkRadio={checkRadio}
                                setCheckRadio={setCheckRadio}
                              />
                            </CustomerCenterContenItemBox>
                            <CustomerCenterContenItemBox width="10">
                              {convertToKstByFormat(item.bettingTime, 'MM-DD HH:mm')}
                            </CustomerCenterContenItemBox>
                            <CustomerCenterContenItemBox width="20">{item.vendorName}</CustomerCenterContenItemBox>
                            <CustomerCenterContenItemBox width="20">{item.gameName}</CustomerCenterContenItemBox>

                            <CustomerCenterContenItemBox width="15">
                              {item.bettingAmount?.toString().replace(commonReg2, ',')}
                            </CustomerCenterContenItemBox>
                            <CustomerCenterContenItemBox
                              style={{ color: `${HermesMainText}`, fontWeight: '600' }}
                              width="16"
                            >
                              {item.bettingResultAmount?.toString().replace(commonReg2, ',')}
                            </CustomerCenterContenItemBox>
                            <CustomerCenterContenItemBox width="16">
                              {item.bettingAfterAmount?.toString().replace(commonReg2, ',')}
                            </CustomerCenterContenItemBox>
                          </CustomerCenterContentBox>
                        </CustomerCenterContentTableBodyTr>
                      </>
                    ))}
                  </>
                )}
              </CustomerCenterContentTableBody>
            </CustomerCenterContentTable>
            {totalElements > 0 && (
              <>
                <ListWrap>
                  <CustomDeleteButton
                    onClick={() => {
                      setCheckRadio(content.map(value => value.bettingId))
                    }}
                    type="button"
                  >
                    <CustomButtonInfos>전체선택</CustomButtonInfos>
                  </CustomDeleteButton>
                  <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
                    <CustomButtonInfos>삭제하기</CustomButtonInfos>
                  </CustomDeleteButton>
                </ListWrap>
                <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
              </>
            )}
          </CustomerCenterContentWrap>
        </CustomerCenterContentWrap>
      )}
    </CustomWebPageWrap>
  )
}
export default CasinoBettingHistoryPage

const CustomWebPageWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`
const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 25px;
  img {
    width: 120px;
    height: auto;
  }
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const NoContent = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  th {
    height: 40px;
    padding: 4px 4px;
    border-top: 1px solid #525252;
    // border-right: 1px solid #888;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead`
  th {
    background: none;
  }
`

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
  font-weight: 700;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;

  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 25px;
  font-weight: 500;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;
  padding: 10px 5px;

  &:not(:last-child) {
    border-right: 1px solid #525252;
  }

  span {
    font-weight: 600;
  }
`
