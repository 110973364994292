import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { HermesMainBorder, HermesMainHover, HermesMainHover2 } from '../../../utils/palette'
import SilverButtonH40 from '../../../shared/components/SilverButton'
import { MoneyFormWrap } from '../../../shared/components/form/newForm'
import {
  searchMemberAttendanceHistory,
  searchMemberYesterdayAttendance,
} from '../../../api/memberHistory/memberHistoryApi'
import { createUserAttendance } from '../../../api/memberManage/memberManageApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { fetchMemberInfoAction } from '../../../redux/memberInfoSlice'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import AttendanceListData from '../../Attendance/AttendanceListData'
import CustomLoading from '../../CustomLoading'

const AttendanceModalContent = () => {
  const [subMenuTitle, setSubMenuTitle] = useState('ATTENDANCE')
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [consecutiveNum, setConsecutiveNum] = useState(0)
  const [minimumAttendanceAmount, setMinimumAttendanceAmount] = useState(0)
  const [thirdAttendanceBonus, setThirdAttendanceBonus] = useState(0)
  const [sixthAttendanceBonus, setSixthAttendanceBonus] = useState(0)
  const [apiFlag, setApiFlag] = useState(false)

  const fetchSearchMemberConsecutiveNum = async () => {
    setLoading(true)
    await searchMemberYesterdayAttendance()
      .then(res => {
        setConsecutiveNum(res.data.consecutiveNum)
        setMinimumAttendanceAmount(res.data.minimumAttendanceAmount)
        setThirdAttendanceBonus(res.data.thirdAttendanceBonus)
        setSixthAttendanceBonus(res.data.sixthAttendanceBonus)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberConsecutiveNum()
  }, [])

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberAttendance = async () => {
    setLoading(true)
    await searchMemberAttendanceHistory(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (subMenuTitle !== 'ATTENDANCE_HISTORY') return
    fetchSearchMemberAttendance()
  }, [historyParams, subMenuTitle])

  const [historyListData, setHistoryListData] = useState(AttendanceListData(content))

  useEffect(() => {
    setHistoryListData(AttendanceListData(content))
  }, [content])

  const [rows, setData] = useState(historyListData.tableRowsData)

  useEffect(() => {
    setData(historyListData.tableRowsData)
  }, [historyListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)
    const body = {}

    createUserAttendance(body)
      .then(res => {
        if (consecutiveNum === 2) {
          alert(`${thirdAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else if (consecutiveNum === 5) {
          alert(`${sixthAttendanceBonus?.toString().replace(commonReg2, ',')} 포인트 지급이 완료됐습니다.`)
        } else {
          alert('출석이 완료됐습니다.')
        }
        dispatch(fetchMemberInfoAction())
        fetchSearchMemberConsecutiveNum()
        fetchSearchMemberAttendance()
        setApiFlag(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ATTD-1001':
            alert(`${message}`)
            break
          case 'ATTD-1002':
            alert(`${message}`)
            break
          case 'EVENT-1002':
            alert(`${message}`)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('출석이 실패했습니다. 다시 시도해주세요.')
        }
        setApiFlag(false)
      })
  }

  return (
    <ContentWrap>
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <SubMenuItemListWrap>
            <div>
              <SubMenuItemBox active={subMenuTitle === 'ATTENDANCE'} onClick={() => setSubMenuTitle('ATTENDANCE')}>
                출석
              </SubMenuItemBox>
              <SubMenuItemBox
                active={subMenuTitle === 'ATTENDANCE_HISTORY'}
                onClick={() => setSubMenuTitle('ATTENDANCE_HISTORY')}
              >
                출석내역
              </SubMenuItemBox>
            </div>
          </SubMenuItemListWrap>

          <ContentBox>
            {
              {
                ATTENDANCE: (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '70%' }}>
                      <MoneyDepositInfoWrap>
                        <AttendanceWrap>
                          {consecutiveNum > 0 ? (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                          ) : (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                          )}
                          <AttendanceInfo>출석 1일차</AttendanceInfo>
                        </AttendanceWrap>
                        <AttendanceWrap>
                          {consecutiveNum > 1 ? (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                          ) : (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                          )}
                          <AttendanceInfo>연속 2일차</AttendanceInfo>
                        </AttendanceWrap>
                        <AttendanceWrap>
                          {consecutiveNum > 2 ? (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                          ) : (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                          )}
                          <AttendanceInfo>
                            {thirdAttendanceBonus?.toString().replace(commonReg2, ',')}포인트
                          </AttendanceInfo>
                        </AttendanceWrap>
                        <AttendanceWrap>
                          {consecutiveNum > 3 ? (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                          ) : (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                          )}
                          <AttendanceInfo>연속 4일차</AttendanceInfo>
                        </AttendanceWrap>
                        <AttendanceWrap>
                          {consecutiveNum > 4 ? (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                          ) : (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                          )}
                          <AttendanceInfo>연속 5일차</AttendanceInfo>
                        </AttendanceWrap>
                        <AttendanceWrap>
                          {consecutiveNum > 5 ? (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendanceyes.png`} />
                          ) : (
                            <AttendanceImg src={`${process.env.PUBLIC_URL}/img/main/attendance.png`} />
                          )}
                          <AttendanceInfo>
                            {sixthAttendanceBonus?.toString().replace(commonReg2, ',')}포인트
                          </AttendanceInfo>
                        </AttendanceWrap>
                      </MoneyDepositInfoWrap>
                      <Form
                        onSubmit={onSubmit}
                        initialValues={
                          {
                            // inquiryButton,
                          }
                        }
                      >
                        {({ handleSubmit, form: { reset } }) => (
                          <MoneyFormWrap horizontal onSubmit={handleSubmit}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <MoneyButton variant="primary" type="submit">
                                <span style={{ fontSize: '14px' }}>출석하기</span>
                              </MoneyButton>
                            </div>
                          </MoneyFormWrap>
                        )}
                      </Form>
                    </div>
                  </div>
                ),
                ATTENDANCE_HISTORY: (
                  <>
                    {loading ? (
                      <CustomLoading />
                    ) : (
                      <>
                        <MoneyWithdrawalHeaderTwoWrap>
                          <MoneyWithdrawalHeaderThirdInfoBox>
                            <MoneyWithdrawalHeaderThirdInfo>출석 내역</MoneyWithdrawalHeaderThirdInfo>
                          </MoneyWithdrawalHeaderThirdInfoBox>
                        </MoneyWithdrawalHeaderTwoWrap>
                        <MoneyWithdrawalBodyTwoWrap>
                          <ReactTableBase
                            columns={historyListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyWithdrawalBodyTwoWrap>
                      </>
                    )}
                  </>
                ),
              }[subMenuTitle]
            }
          </ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default AttendanceModalContent

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  // height: 550px;
  height: calc(100vh - 270px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`

export const MoneyDepositInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
`

const AttendanceBtn = styled(SilverButtonH40)`
  width: 202px;
  height: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:hover {
    cursor: pointer;
    background: #333;
  }
`

const AttendanceWrap = styled.div`
  width: 120px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: solid 1px #575757;
`

const AttendanceImg = styled.img`
  width: 110px;
  height: 110px;
  display: flex;
`

const AttendanceInfo = styled.div`
  display: flex;
  color: #b6b6b6;
`

const MoneyWithdrawalHeaderThirdInfoBox = styled.div`
  float: left;
  padding-top: 10px;
  height: 40px;
  display: block;
`

const MoneyWithdrawalHeaderThirdInfo = styled.span`
  color: #fff;
  font-size: 14px;
`

const MoneyWithdrawalHeaderTwoWrap = styled.div`
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #171719;
  font-weight: 900;
  display: flex;
`

const MoneyWithdrawalBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 20px 0;

  gap: 10px;

  .react-table__column-header {
    font-size: 14px;
    padding: 10px 0;
  }
`

const MoneyButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;
  color: #000 !important;
  font-weight: 500;
  border: 2px solid ${HermesMainBorder};
`
