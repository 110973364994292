import React from 'react'
import styled from 'styled-components'
import MobileMainCarousel from './MobileMainCarousel/MobileMainCarousel'
import MobileMainGame from './MobileMainGame/MobileMainGame'

const MobileMainPageSecond = () => {
  return (
    <>
      <MobileMainPageWrap>
        <MobileMainCarousel />
        <MobileMainGame />
      </MobileMainPageWrap>
    </>
  )
}

export default MobileMainPageSecond

const MobileMainPageWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgb(17, 19, 20);
  gap: 7px;
`

const MobileCustomerCenterWrap = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(0, 0, 0, 0.2);
  padding: 0 10px;

  border: 1px solid #edae07;
`

const CustomerCenterInnerWrap = styled.div`
  width: calc(100% / 3);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${props =>
    props.isNotice &&
    `
    cursor: pointer;
`}
`

// Customer Center Left Icon
const CustomerCenterIcon = styled.img`
  width: 60px;
  height: 60px;
`

// Customer Center Left Info
const CustomerCenterInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const CustomerCenterTopInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: right;
  color: #76ff03;
`

const CustomerCenterBottomInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 35px;
  text-align: right;
  color: #ffc01a;
`

const CustomerCenterLine = styled.div`
  width: 1px;
  height: 80px;

  margin: 8px 0;

  background: #4a4a4a;
`
