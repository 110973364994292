import React, { useEffect, useRef, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'style-to-object'
import styled from 'styled-components'
import { Collapse } from 'react-bootstrap'
import CustomLoading from '../../../containers/CustomLoading'
import { fetchNoticeBoardList } from '../../../redux/customCenterSlice'
import MobileCustomPagination from '../../../shared/components/MobileCustomPagination'
import {
  CustomCollapse,
  MobileCustomerCenterContentWrap,
  MobileCustomerCenterHeaderInfo,
  MobileCustomerCenterHeaderWrap,
  MobileTopWrap,
} from '../../../shared/components/MoneyCustomElement'
import { MobileNoContentBox } from '../MobileEvent/MobileEventList'
import { HermesMainHover, HermesMainText } from '../../../utils/palette'
import { BoardTypeEnums } from '../../../utils/enums/Operation/boardEnums'

const MobileNoticeList = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [boardType, setBoardType] = useState('NOTICE')

  const onClickBoardTypeHandler = type => {
    setBoardType(type)
  }

  const [boardParams, setBoardParams] = useState({
    boardType,
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      boardType,
      page,
      size,
    }))
  }, [page, size, boardType])

  const [content, setContent] = useState([])

  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noticeInfo.totalElement,
      apiContent: customCenter.noticeInfo.content,
    }
  })
  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  console.log('boardType :: ', boardType)

  const fetchSearchBoardList = async () => {
    try {
      dispatch(fetchNoticeBoardList(boardParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = boardKey => {
    setContent(prev => {
      const idx = prev.findIndex(item => item.boardKey === boardKey)

      if (idx !== -1) {
        // 원래의 state를 복사하여 새로운 배열을 만듭니다.
        const newState = [...prev]
        newState[idx] = {
          ...newState[idx],
          isVisible: !newState[idx].isVisible,
        }

        // 새로운 배열을 반환합니다.
        return newState
      }

      // 인덱스를 찾지 못한 경우, 원래의 state를 그대로 반환합니다.
      return prev
    })
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={{ maxWidth: '100%' }} alt="" />
    }
  }

  return (
    <MobileTopWrap ref={webPageWrapRef}>
      <MobileCustomerCenterHeaderWrap>
        <MobileCustomerCenterHeaderInfo>공지/규정</MobileCustomerCenterHeaderInfo>
      </MobileCustomerCenterHeaderWrap>
      <div style={{ padding: '8px' }}>
        <NoticeButtonWrap>
          <NoticeButtonBox>
            <NoticeButton
              isActive={boardType === 'NOTICE'}
              onClick={() => {
                onClickBoardTypeHandler('NOTICE')
              }}
            >
              공지사항
            </NoticeButton>
          </NoticeButtonBox>
          <NoticeButtonBox>
            <NoticeButton
              isActive={boardType === 'BETTING_RULE'}
              onClick={() => {
                onClickBoardTypeHandler('BETTING_RULE')
              }}
            >
              배팅규정
            </NoticeButton>
          </NoticeButtonBox>
        </NoticeButtonWrap>
        {loading ? (
          <CustomLoading />
        ) : (
          <MobileCustomerCenterContentWrap>
            <CustomerCenterContentTable>
              <CustomerCenterContentTableHead>
                <CustomerCenterContentTableHeadTr>
                  <th style={{ width: '20%' }}>타입</th>
                  <th style={{ width: '80%' }}>내용</th>
                </CustomerCenterContentTableHeadTr>
              </CustomerCenterContentTableHead>
              <CustomerCenterContentTableBody>
                {content.length === 0 ? (
                  <NoContentDiv>
                    <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
                    최근 게시물이 없습니다.
                  </NoContentDiv>
                ) : (
                  <>
                    {content.map(item => (
                      <>
                        <CustomerCenterContentTableBodyTr key={item.boardKey}>
                          <CustomerCenterContentBox>
                            {/* <CustomerCenterContenItemBox width="10" onClick={() => onClickRowHandler(item.boardKey)}>
                        {item.num}
                      </CustomerCenterContenItemBox> */}
                            <CustomerCenterContenItemBox
                              width="20"
                              onClick={() => onClickRowHandler(item.boardKey)}
                              style={{ color: `${HermesMainText}` }}
                            >
                              {BoardTypeEnums[item.type]}
                            </CustomerCenterContenItemBox>
                            <CustomerCenterContenItemBox
                              width="80"
                              onClick={() => onClickRowHandler(item.boardKey)}
                              style={{ cursor: 'pointer', flexDirection: 'column' }}
                            >
                              {item.boardTitle}
                            </CustomerCenterContenItemBox>
                          </CustomerCenterContentBox>
                        </CustomerCenterContentTableBodyTr>
                        <CustomCollapse in={item.isVisible}>
                          <CustomerCenterContentTableBodyTr style={{ border: 'solid 1px #525252' }}>
                            <CustomerCenterContentDetailBox>
                              {ReactHtmlParser(item.boardContent, { transform: transformFn })}
                            </CustomerCenterContentDetailBox>
                          </CustomerCenterContentTableBodyTr>
                        </CustomCollapse>
                      </>
                    ))}
                  </>
                )}
              </CustomerCenterContentTableBody>
            </CustomerCenterContentTable>
            {totalElement > 0 && (
              <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
            )}
          </MobileCustomerCenterContentWrap>
        )}
      </div>
    </MobileTopWrap>
  )
}
export default MobileNoticeList

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  color: #fff;
  img {
    width: 100px;
    height: auto;
  }
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    padding: 16px 0px !important;
    background: rgba(9, 9, 10);
    borde: none;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  //   gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px #757575;
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  justify-content: center;
  align-items: start;
  color: white;
  font-weight: 700;
  padding: 16px 5px;
  word-break: keep-all;
`

const NoticeButtonWrap = styled.div`
  margin-bottom: 16px;
  box-sizing: border-box;
  position: relative;
  display: block;
  margin-top: 10px;
`

const NoticeButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 50%;
  box-sizing: border-box;
`

const NoticeButton = styled.div`
  width: 98%;
  padding: 8px 32px;

  background-color: #1b1c1f;
  float: left;
  padding: 12px 27px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 5px;
  margin-right: 3px;
  color: #fff;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;

  &:hover {
    background-color: ${HermesMainHover};
  }

  ${props =>
    props.isActive &&
    `
    background-color: ${HermesMainHover};
`}

  margin: 0;
  box-sizing: border-box;
  display: block;
`
