import React from 'react'
import styled from 'styled-components'
import { HermesMainText } from '../../utils/palette'

const MainPageFooter = () => {
  return (
    <MainFooter>
      <MainFooterInner>
        <MainFooterInnerLogoBox style={{ padding: '0 50px' }}>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Evolution.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Micro.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Asia.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Taishan.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Oriental.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Vivo.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Dream.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Sexy.png`} alt="" />
          </MainFooterInnerLogoItem>
        </MainFooterInnerLogoBox>
        <MainFooterInnerLogoBox>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Best.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Haba.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Prag.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Play.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Evo.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Game.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/RTG.png`} alt="" />
          </MainFooterInnerLogoItem>
          <MainFooterInnerLogoItem>
            <img src={`${process.env.PUBLIC_URL}/img/main/casino-vendor/Soft.png`} alt="" />
          </MainFooterInnerLogoItem>
        </MainFooterInnerLogoBox>
        <MainFooterInnerCopyRightWrap>
          <MainFooterInnerCopyRightItemBox>
            <div style={{ width: '25%' }} />
            <MainFooterInnerCopyRightItem>
              Gaming license provided under the law of Malta - MGA/B2C/543/2018 issued on the 7/01/2019.
              <br />
              COPYRIGHT ©{' '}
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open('https://t.me/tysonsol')
                }}
              >
                <span style={{ color: `${HermesMainText}` }}>TYSON SOLUTION</span>
              </span>{' '}
              ALL RIGHTS RESERVED.
            </MainFooterInnerCopyRightItem>
            <MainFooterInnerCopyRightItemSolution>
              {/* <img src={`${process.env.PUBLIC_URL}/img/main/Solution2.png`} alt="" /> */}
            </MainFooterInnerCopyRightItemSolution>
          </MainFooterInnerCopyRightItemBox>
        </MainFooterInnerCopyRightWrap>
      </MainFooterInner>
    </MainFooter>
  )
}

export default MainPageFooter

const MainFooter = styled.div`
  width: 100%;
  margin-top: 24px;
`

const MainFooterInner = styled.div`
  border-top: 1px solid rgb(36, 36, 36);
  width: 100%;
  padding: 0;
  margin: 0;
`

const MainFooterInnerLogoBox = styled.div`
  width: 100%:
  margin-top: 24px;
  display: flex;

`

const MainFooterInnerLogoItem = styled.div`
  width: 12.5%;
  padding: 0 10px;

  img {
    width: 90%;
    overflow-clip-margin: content-box;
    overflow: clip;
  }
`

const MainFooterInnerCopyRightWrap = styled.div`
  border-top: 1px solid rgb(36, 36, 36);
  width: 100%;
  padding: 0;
  margin: 0;
`

const MainFooterInnerCopyRightItemBox = styled.div`
  margin: 24px 0 48px;
  display: flex;
`
// 카피라이터
const MainFooterInnerCopyRightItem = styled.div`
  width: 50%;
  display: block;
  text-align: center;

  a {
    color: #337ab7;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;

    span {
      color: ${HermesMainText};
    }
  }
`

const MainFooterInnerCopyRightItemSolution = styled.div`
  width: 25%;
  img {
    float: right;
    height: 50px;
  }
`
