import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { FiRefreshCw } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMiniGameBettingLog, searchMiniGameInfo } from '../../../api/game/miniGameApi'
import { formatSeconds, getCurrentTime } from '../../../containers/MiniGame/miniGameTimeUtils'
import { BettingWhatLockIcon } from '../../../containers/MiniGame/Ntry/EosPowerBallFive'
import {
  addBettingCart,
  setBettingCloseHandler,
  setMiniGameCompleteFlag,
  setMiniGameInfo,
  setResetData,
} from '../../../redux/miniGameBettingSlice'
import { MiniGameTypeEnums } from '../../../utils/enums/MiniGame/PowerBallEnums'
import { HermesMainBorder, HermesMainText } from '../../../utils/palette'
import MobileMiniGameBettingLogData from '../MobileMiniGameBettingLogData'
import MobileMiniGameTabs from '../MobileMiniGameTabs'
import MobileLotusTabs from './MobileLotusTabs'

const MobileLotusSicbo = () => {
  // redux 사용
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const [params, setParams] = useState({
    miniGameType: 'LOTUS_SICBO',
  })

  const {
    miniGameType,
    miniGameBettingInfo,
    miniGameRound,
    deadLineTime,
    isShow,
    endTime,
    bettingCountOkFlag,
    bettingCloseFlag,
    bettingCompleteFlag,
  } = useSelector(state => {
    const { miniGameBetting } = state

    return {
      miniGameType: miniGameBetting.miniGameType,
      miniGameBettingInfo: miniGameBetting.miniGameBettingInfo,
      miniGameRound: miniGameBetting.miniGameRound,
      deadLineTime: miniGameBetting.deadLineTime,
      isShow: miniGameBetting.isShow,
      endTime: miniGameBetting.endTime,
      bettingCountOkFlag: miniGameBetting.bettingCountOkFlag,
      bettingCloseFlag: miniGameBetting.bettingCloseFlag,
      bettingCompleteFlag: miniGameBetting.bettingCompleteFlag,
    }
  })

  // 서버 시간 == 현재 시간
  const [currentTime, setCurrentTime] = useState(null)

  const intervalRef = useRef(null)
  // svt : serverTime , stt : startTime
  const getCurrentTimeDifference = deadLine => {
    if (!currentTime)
      return {
        minutes: 0,
        seconds: 0,
      }

    let totalSeconds = endTime - currentTime - deadLine

    if (totalSeconds < 0) {
      totalSeconds = 0
    }

    return {
      minutes: Math.floor(totalSeconds / 60),
      seconds: totalSeconds % 60,
    }
  }

  const [time, setTime] = useState({
    minutes: 0,
    seconds: 0,
  })

  const fetchSearchMiniGameInfo = async () => {
    await searchMiniGameInfo(params)
      .then(res => {
        dispatch(
          setMiniGameInfo({
            miniGameType: res.data.miniGameType,
            miniGameBettingInfo: JSON.parse(res.data.miniGameBettingPer),
            round: res.data.round,
            bettingCountOkFlag: res.data.bettingCountOkFlag,
            endTime: res.data.endTime,
            deadLineTime: res.data.deadLineTime,
            isShow: res.data.isShow,
          }),
        )

        setCurrentTime(res.data.serverTime)

        setTime(getCurrentTimeDifference(res.data.deadLineTime))

        if (!bettingCompleteFlag) {
          dispatch(setResetData())
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchMiniGameInfo()

    const timeoutId = setTimeout(() => {
      setLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentTime >= endTime) {
        fetchSearchMiniGameInfo()
        dispatch(setMiniGameCompleteFlag(false))
        dispatch(setResetData())
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [currentTime, endTime])

  const updateTimer = () => {
    setCurrentTime(getCurrentTime())
    setTime(getCurrentTimeDifference(deadLineTime))
  }

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(updateTimer, 1000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [time])

  /**
   *  배팅 조회
   */
  const [page, setPage] = useState(0)

  const [miniGameBettingLogParams, setMiniGameBettingLogParams] = useState({
    miniGameType: 'LOTUS_SICBO',
    page,
    size: 5,
  })

  useEffect(() => {
    setMiniGameBettingLogParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMiniGameBettingLog = () => {
    searchMiniGameBettingLog(miniGameBettingLogParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
      })
      .catch(error => {})
  }

  useEffect(() => {
    fetchSearchMiniGameBettingLog()
  }, [miniGameBettingLogParams, bettingCompleteFlag])

  const [miniGameBettingLogData, setMiniGameBettingLogData] = useState(MobileMiniGameBettingLogData(content))
  useEffect(() => {
    setMiniGameBettingLogData(MobileMiniGameBettingLogData(content))
  }, [content])

  const [rows, setData] = useState(miniGameBettingLogData.tableRowsData)
  useEffect(() => {
    setData(miniGameBettingLogData.tableRowsData)
  }, [miniGameBettingLogData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: 5,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  const isDisabled = time.minutes === 0 && time.seconds === 0

  // 배팅 마감일 때 배팅카트 초기화
  useEffect(() => {
    dispatch(setBettingCloseHandler(isDisabled))
  }, [isDisabled])

  // 배팅 카트 담기
  const handleBettingCart = event => {
    const miniGameBettingPer = event.currentTarget.getAttribute('name') || 0
    const miniGameBettingType = event.currentTarget.id

    dispatch(
      addBettingCart({
        miniGameBettingPer,
        miniGameBettingType,
      }),
    )
  }

  const [selectedTab, setSelectedTab] = useState(1)

  const handleClick = tabNumber => {
    setSelectedTab(tabNumber)
  }

  return (
    <>
      <MobileMiniGameTabs activeKey="8" />
      <MobileLotusTabs activeKey="3" />
      <CustomerCenterWrap>
        <MiniGameContentWrap>
          {!isShow && (
            <InspectOverlay>
              <BiLock />
              <span>점검중</span>
            </InspectOverlay>
          )}
          <MiniGameIframeWrap
            style={{
              background: `url(${process.env.PUBLIC_URL}/img/main/97437ad.jpg) no-repeat center center`,
            }}
          >
            {!loading ? (
              <MiniGameIframe
                src="https://lotus.nutv765.com/m/sicbo.html"
                title="LOTUS_SICBO"
                // scrolling="no"
                // frameBorder="0"
              />
            ) : (
              <motion.div
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0.5, scale: 0.1 }}
                transition={{ duration: 0.6 }}
              >
                <IframeOverlay>
                  <HeaderLogoImg
                    src={`${process.env.PUBLIC_URL}/img/main/iframeLogo.png`}
                    alt=""
                    onClick={e => {
                      window.location.href = '/'
                    }}
                  />
                </IframeOverlay>
              </motion.div>
            )}
          </MiniGameIframeWrap>
          <MiniGameBettingSortWrap>
            {(isDisabled || !bettingCountOkFlag) && (
              <Overlay>
                <BettingWhatLockIcon />
              </Overlay>
            )}
            <MiniGameBettingSortHeaderWrap>
              <MiniGameBettingSortHeaderInfo>
                <MiniGameBettingSortHeaderLabel>회차</MiniGameBettingSortHeaderLabel>
                <MiniGameBettingSortHeaderText>{miniGameRound || '-'} 회차</MiniGameBettingSortHeaderText>
              </MiniGameBettingSortHeaderInfo>
              <MiniGameBettingSortHeaderInfo>
                <MiniGameBettingSortHeaderLabel>추첨까지</MiniGameBettingSortHeaderLabel>
                <MiniGameBettingSortHeaderText>
                  {time.minutes} : {formatSeconds(time.seconds)}
                </MiniGameBettingSortHeaderText>
              </MiniGameBettingSortHeaderInfo>
            </MiniGameBettingSortHeaderWrap>

            <MiniGameBettingSortCartWrap>
              <MiniGameBettingSortCartGroupWrap>
                <MiniGameBettingSortCartTitle>홀짝</MiniGameBettingSortCartTitle>
                <MiniGameBettingSortCartGroup>
                  <MiniGameBettingSortCartBig2
                    id="LOTUS_SICBO_ODD"
                    name={miniGameBettingInfo?.sicboOdd || 0}
                    onClick={handleBettingCart}
                  >
                    <img src={`${process.env.PUBLIC_URL}/img/main/green.png`} alt="" />
                    <MiniGameBettingPer>{miniGameBettingInfo?.sicboOdd || 0}</MiniGameBettingPer>
                    <MiniGameBettingCartContentBlue>홀</MiniGameBettingCartContentBlue>
                    <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                  </MiniGameBettingSortCartBig2>
                  <MiniGameBettingSortCartBig2
                    id="LOTUS_SICBO_EVEN"
                    name={miniGameBettingInfo?.sicboEven || 0}
                    onClick={handleBettingCart}
                  >
                    <img src={`${process.env.PUBLIC_URL}/img/main/red.png`} alt="" />

                    <MiniGameBettingPer>{miniGameBettingInfo?.sicboEven || 0}</MiniGameBettingPer>
                    <MiniGameBettingCartContentRed>짝</MiniGameBettingCartContentRed>
                    <MiniGameBettingPerSecond>&nbsp;</MiniGameBettingPerSecond>
                  </MiniGameBettingSortCartBig2>
                </MiniGameBettingSortCartGroup>
              </MiniGameBettingSortCartGroupWrap>
              {/* 일반 배팅 - 파워볼  */}
              <MiniGameBettingSortCartGroupWrap>
                <MiniGameBettingSortCartTitle>스몰/빅</MiniGameBettingSortCartTitle>
                <MiniGameBettingSortCartGroup>
                  <MiniGameBettingSortCartBig2
                    id="LOTUS_SICBO_SMALL"
                    name={miniGameBettingInfo?.sicboSmall || 0}
                    onClick={handleBettingCart}
                  >
                    <img src={`${process.env.PUBLIC_URL}/img/main/blue.png`} alt="" />

                    <MiniGameBettingPer>{miniGameBettingInfo?.sicboSmall || 0}</MiniGameBettingPer>
                    <MiniGameBettingCartContentSize style={{ color: '#0080ff', textShadow: '2px 2px 2px #000000' }}>
                      SMALL
                    </MiniGameBettingCartContentSize>
                    <MiniGameBettingPerSecond>스몰</MiniGameBettingPerSecond>
                  </MiniGameBettingSortCartBig2>
                  <MiniGameBettingSortCartBig2
                    id="LOTUS_SICBO_BIG"
                    name={miniGameBettingInfo?.sicboBig || 0}
                    onClick={handleBettingCart}
                  >
                    <img src={`${process.env.PUBLIC_URL}/img/main/red.png`} alt="" />

                    <MiniGameBettingPer>{miniGameBettingInfo?.sicboBig || 0}</MiniGameBettingPer>
                    <MiniGameBettingCartContentSize style={{ color: '#ff1515', textShadow: '2px 2px 2px #000000' }}>
                      BIG
                    </MiniGameBettingCartContentSize>
                    <MiniGameBettingPerSecond>BIG</MiniGameBettingPerSecond>
                  </MiniGameBettingSortCartBig2>
                </MiniGameBettingSortCartGroup>
              </MiniGameBettingSortCartGroupWrap>
            </MiniGameBettingSortCartWrap>
          </MiniGameBettingSortWrap>
          <MiniGameBettingTableWrap>
            <TableBettingInfo>
              <div className="text">{MiniGameTypeEnums.LOTUS_SICBO} 배팅내역</div>
              <div className="icon">
                <FiRefreshCw onClick={fetchSearchMiniGameBettingLog} />
              </div>
            </TableBettingInfo>
            {content.length === 0 ? (
              <NoContentWrap>
                <img src={`${process.env.PUBLIC_URL}/img/main/noslip.svg`} alt="" />
                해당 게임에 배팅내역이 존재하지 않습니다.
              </NoContentWrap>
            ) : (
              <>
                <ReactTableBase
                  columns={miniGameBettingLogData.tableHeaderData}
                  data={rows}
                  tableConfig={tableConfig}
                />
              </>
            )}
          </MiniGameBettingTableWrap>
        </MiniGameContentWrap>
      </CustomerCenterWrap>
    </>
  )
}
export default MobileLotusSicbo

const NoContentWrap = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  color: #fff;
  img {
    width: 50px;
    height: 50px;
  }
`

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 102;
  text-align: center;
  font-size: 12px;
  color: white;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    position: absolute;
    flex-direction: column;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    /* 아이콘 스타일 */
    position: absolute;
    flex-direction: column;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
  }
`

export const HeaderLogoImg = styled.img`
  width: 270px;
  height: 188px;
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IframeOverlay = styled.div`
  position: relative;
  color: white;
  justify-content: center;
  width: 100%;
  height: 640px;
  background-color: rgba(255, 255, 255, 0.1);
`

export const TableBettingInfo = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 30px;
  background: #1b1c1f;
  color: #fff;
  font-size: 14px;

  .text {
    flex-grow: 1;
    display: flex;
    justify-content: left;
    padding-left: 20px;
    align-items: center;
  }

  .icon {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 10px; // 오른쪽 여백을 조정하려면 이 값을 조절하세요.
  }
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

const CustomerCenterWrap = styled.div`
  background: #000000;
  height: 100%;
  min-height: 800px;
  padding-top: 5px;
  padding-bottom: 5px;
`
// content Wrap
const MiniGameContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  position: relative;
`

const MiniGameBettingTableWrap = styled.div`
  width: 100%;
  background: #16171a;
  margin: 0 auto;
  display: flex;
  padding-bottom: 5px;
  flex-direction: column;
  gap: 10px;
  z-index: 110;
`

const MiniGameBettingSortWrap = styled.div`
  position: relative;
  display: flex;
  border-radius: 33px;
  border: 4px solid #00000029;
  background-color: rgb(153, 97, 72);
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  overflow: hidden !important;
  margin-bottom: -10px;
  // // 1450 이상
  // @media screen and (min-width: 1450px) {
  //   width: calc(100% - 560px);
  // }

  ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  text-align: center;
  font-size: 12px;
  color: white;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const MiniGameBettingSortHeaderWrap = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 5px solid rgb(244, 225, 192);
  height: 55px;
  padding: 7px 0px;
  align-items: center;
  width: 100%;

  gap: 10px;
`

const MiniGameBettingSortTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 7px 0px;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
`

const MiniGameBettingSortCartWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 305px;
  padding: 0px;
  align-items: center;
  width: 100%;
  gap: 7px;
  margin-top: -5px;
`

const MiniGameBettingSortMixCartWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 525px;
  padding: 0px;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: -5px;
`

const MiniGameBettingSortCartGroupWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 125px;
  padding-bottom: 2px;
  border-bottom: 2px solid #4a4a4a;
  align-items: center;
  width: 97%;
  flex-direction: column;
`

const MiniGameBettingSortCartGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const MiniGameBettingSortCartTitle = styled.span`
  display: flex;
  justify-content: center;
  height: 27px;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  background-color: #674836;
  color: #e9d6b5;
  border: 1px solid #4b3528;
`

const MiniGameBettingSortCartMedium = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 80px;
  width: 100px;
  align-items: center;
  border: 1px solid #684935;
  background-color: #e2c65b;
  position: relative;
`

const MiniGameBettingSortCartBig2 = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  height: 80px;
  width: 150px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
  position: relative;
   img:first-child {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  img:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: scaleX(-1);  // 이미지 좌우 대칭 적용

  }
  
`

const MiniGameBettingSortCartMixMedium = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 70px;
  width: 100px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer;
  background-color: #e2c65b;
  position: relative;
  flex-direction: column;
  img {
    margin-top: 10px;
  }
  gap: 5px;
  &:hover {
    img {
      width: 65%;
    }
    span {
      font-size: 12px;
    }
  }
`

const MiniGameBettingSortCartMixMediumSmall = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 50px;
  width: 100px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer;
  background-color: #e2c65b;
  position: relative;
  font-size: 12px;
  flex-direction: column;
  img {
    margin-top: 15px;
  }
  gap: 2px;
  &:hover {
    img {
      width: 45%;
    }
    span {
      font-size: 12px;
    }
  }
`

const MiniGameBettingSortCartSmall = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  height:50px;
  width: 60px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
  position: relative;
`

const MiniGameBettingSortCartBig = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  height:80px;
  width: 120px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
  position: relative;
  img {
    position: absolute;
    left:0;
    bottom:0;
  }
`

const MiniGameBettingPer = styled.div`
  justify-content: center;
  background-color: #4a3427;
  height: 15px;
  font-size: 12px;
  position: absolute;
  top: 0;
  text-align: center;
  align-items: center;
  width: 100%;
  color: white;
`

const MiniGameBettingCartContentBlack = styled.div`
  margin-top: 14px;
  cursor: pointer;
  border: 3px solid #c0c0c0;
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #5c5c5c 40%, #6c6c6c 60%, #7c7c7c 100%);

  min-width: 30px;
  max-width: 30px;
  font-size: 12px;
  height: 30px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  &:hover {
    color: white;
    max-width: 35px;
    height: 35px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentRed = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 3px;
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentBlue = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  cursor: pointer;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentSize = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  min-width: 50px;
  max-width: 80px;
  height: 40px;
  background: transparent;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: solid 1px #674836;
  width: 100%;
  color: #4b3422;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #674836;
  }
`

const MiniGameBettingCartContentOver = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  border: solid 2px red;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: red;
  display: flex;
  cursor: pointer;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentUnder = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 3px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: blue;
  display: flex;
  position: relative;
  border: solid 2px blue;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingPerSecond = styled.div`
  justify-content: center;
  height: 15px;
  font-size: 12px;
  align-items: center;
  width: 100%;
  color: black;
  position: absolute;
  bottom: 0;
  text-align: center;
`

const MiniGameBettingSortTitle = styled.span`
  display: flex;
  background-color: #674836;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: ${props => (props.selected ? `2px solid ${HermesMainBorder}` : 'none')};
  color: ${props => (props.selected ? `${HermesMainText}` : 'white')};
  font-size: 12px;
  height: 50px;
  align-items: center;
  width: 48%;
  cursor: pointer;
`

const MiniGameBettingSortHeaderInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
`

const MiniGameBettingSortHeaderLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height 40px;
  color: #f9e5c0;
  background-color: #4a3427;
`
const MiniGameBettingSortHeaderText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height 40px;
  width: 60%;
  color: white;
  background-color: #674836;
`

const MiniGameIframe = styled.iframe`
  width: 420px;
  height: 515px;
`

const MiniGameIframeWrap = styled.div`
  width: 100%;
  height: 530px;
  display: flex;
  background-color: #2f343a;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  border-radius: 33px;
  margin-bottom: -10px;
`
