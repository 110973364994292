import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'reactjs-crontab/dist/index.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { VirtualGameSportType } from '../../utils/enums/VirtualGame/VirtualGameEnums'
import { HermesMainSelect, HermesMainText } from '../../utils/palette'
import { setVirtualGameParam } from '../../redux/virtualGameInfoSlice'

const MobileVirtualGameHeader = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { selectedVirtuaGameSportType } = useSelector(state => {
    const { virtualGameBetting } = state

    return {
      selectedVirtuaGameSportType: virtualGameBetting.selectedVirtuaGameSportType,
    }
  })

  const { virtualSportList } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      virtualSportList: virtualGameInfo.virtualSportList.toSorted((a, b) => a.sportOrderNum - b.sportOrderNum),
    }
  })

  const onClickSelectedVirtualSportHandler = sportType => {
    switch (sportType) {
      case 'VIRTUAL_SOCCER':
        if (window.location.pathname === '/mobile/virtual/soccer') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/mobile/virtual/soccer')

      case 'VIRTUAL_BASKETBALL':
        if (window.location.pathname === '/mobile/virtual/basketball') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/mobile/virtual/basketball')
      case 'VIRTUAL_BASEBALL':
        if (window.location.pathname === '/mobile/virtual/baseball') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/mobile/virtual/baseball')
      case 'VIRTUAL_GREYHOUNDS':
        if (window.location.pathname === '/mobile/virtual/greyhounds') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/mobile/virtual/greyhounds')
      case 'VIRTUAL_HORSE':
        if (window.location.pathname === '/mobile/virtual/horse') {
          dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
          return
        }
        return navigate('/mobile/virtual/horse')
      default:
        return navigate('/')
    }
  }

  return (
    <HeaderWrap>
      <VirtualGameHeaderInnerBox>
        <div style={{ width: '100%', position: 'relative' }}>
          <div style={{ position: 'relative' }}>
            <VirtualGameTypeListBox>
              {virtualSportList.map(item => {
                return (
                  <VirtualGameTypeItemBox
                    onClick={() => {
                      onClickSelectedVirtualSportHandler(item.sportType)
                    }}
                    active={item.sportType === selectedVirtuaGameSportType}
                  >
                    <VirtualGameTypeIcon>
                      <img src={`${process.env.PUBLIC_URL}/img/virtual-game/${item.sportType}.png`} alt="" />
                    </VirtualGameTypeIcon>
                    <VirtualGameTypeTitle>{VirtualGameSportType[item.sportType]}</VirtualGameTypeTitle>
                  </VirtualGameTypeItemBox>
                )
              })}
            </VirtualGameTypeListBox>
          </div>
        </div>
      </VirtualGameHeaderInnerBox>
    </HeaderWrap>
  )
}

export default MobileVirtualGameHeader

const HeaderWrap = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-top: 6px;
  box-sizing: border-box;
  position: relative;
  font-family: 'Malgun Gothic', dotum;
`
const VirtualGameHeaderInnerBox = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
`

const VirtualGameTypeListBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
`

const VirtualGameTypeItemBox = styled.div`
  background: #1c1f21;
  padding: 10px 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 18%;

  // active 일 때,
  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    border-bottom: 3px solid ${HermesMainSelect};
    color: ${HermesMainText};
 `}

  &:hover {
    background: ${HermesMainSelect};
    border-bottom: 3px solid ${HermesMainSelect};
    color: ${HermesMainText};
  }
`

const VirtualGameTypeIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 3px;
    width: 20px;
    height: 20px;
  }
`

const VirtualGameTypeTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
`
