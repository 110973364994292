import React, { useEffect, useState, Fragment } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'
import { GrFormClose } from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux'
import { searchNormalPopupImgList } from '../../../api/popupImg/popupImgApi'
import {
  CloseButton,
  CloseButtonWrap,
  PopupBody,
  PopupContent,
  PopupHeader,
  PopupImgItem,
  PopupImgWrap,
  PopupOverlay,
  PopupWrapper,
  MobilePopupWrapper,
  MobilePopupContent,
  MobilePopupBody,
  MobilePopupImgItem,
  CloseButtonWrap2,
  CloseButton2,
  CloseButton3,
} from '../PopupElements'
import Portal from '../../../shared/components/Portal'
import CustomLoading from '../../CustomLoading'
import { setPopupInitData, updatePopupData } from '../../../redux/memberInfoSlice'

/**
 * @see : https://sycdev.tistory.com/m/19
 */

const NormalPopup = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [pathName, setPathName] = useState(null)
  const [locationFlag, setLocationFlag] = useState(false)
  useEffect(() => {
    setPathName(location.pathname)
    if (
      location.pathname === '/' ||
      location.pathname === '/live-casino' ||
      location.pathname === '/minigame' ||
      location.pathname === '/mobile/mini-game' ||
      location.pathname === '/mobile/live-casino'
    ) {
      setLocationFlag(true)
      return
    }
    setLocationFlag(false)
  }, [location])

  // visible : localStorage에서 가져와 -> 데이터가 있으면 false , 없으면 true
  const { popupList } = useSelector(state => {
    const { memberInfo } = state

    return {
      popupList: memberInfo.popupList,
    }
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!locationFlag) return
    if (loading) return
    setLoading(true)
    searchNormalPopupImgList({ page: 0, size: 4 }).then(res => {
      let filteredContent = []
      if (pathName === '/') {
        filteredContent = res.data.content.filter(item => item.title !== '금지게임 규정 팝업')
      } else if (
        pathName === '/live-casino' ||
        pathName === '/mobile/live-casino' ||
        pathName === '/minigame' ||
        pathName === '/mobile/mini-game'
      ) {
        filteredContent = res.data.content.filter(item => item.title === '금지게임 규정 팝업')
      }

      dispatch(setPopupInitData({ content: filteredContent }))

      // filteredContent.map(item =>
      //   setPopupList(prev => [
      //     ...prev,
      //     {
      //       title: item.title,
      //       imgName: item.imgName,
      //       visible: checkVisible(item.title),
      //     },
      //   ]),
      // )
      if (pathName === '/') {
        setTimeout(() => {
          setLoading(false)
        }, 500)
      }
      setLoading(false)
    })
  }, [locationFlag, pathName])

  useEffect(() => {
    if (popupList.filter(el => el.visible).length <= 0) {
      return () => {
        document.body.style = `overflow: auto`
      }
    }

    if (
      location.pathname !== '/' ||
      location.pathname !== '/live-casino' ||
      location.pathname !== '/mobile/live-casino' ||
      location.pathname !== '/minigame' ||
      location.pathname !== '/mobile/mini-game'
    ) {
      return () => {
        document.body.style = `overflow: auto`
      }
    }

    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [popupList, location])

  const [visible, setVisible] = useState(true)
  const [totalVisible, setTotalVisible] = useState(false)

  const checkTotalVisible = () => {
    const visiblePopup = popupList.filter(value => value.visible === true)

    if (visiblePopup.length > 0) {
      setTotalVisible(true)
    } else {
      setTotalVisible(false)
    }
  }
  // 오늘 그만보기 , 닫기 핸들러

  const closeHandler = (e, isDayClose = false) => {
    checkTotalVisible()
    const title = e.target.getAttribute('value')
    // 오늘 그만보기 일때
    if (isDayClose) {
      // +1일 계산
      const expiredDate = new Date().getDate()
      // 로컬스토리지 저장
      localStorage.setItem(`popup-${title}`, expiredDate)
    }
    setVisible(false)
    dispatch(updatePopupData(title))
  }

  useEffect(() => {
    checkTotalVisible()
  }, [popupList])

  const popupWidth = 400 // 각 팝업의 너비
  const screenWidth = window.innerWidth // 화면의 너비
  const maxPopupsPerRow = Math.floor(screenWidth / popupWidth) // 한 행당 최대 팝업 수

  const calculatePopupPosition = index => {
    const xPosition = (index % maxPopupsPerRow) * popupWidth + 140 // 가로 위치 계산
    return { x: xPosition }
  }

  return (
    <>
      {loading ? (
        <CustomLoading />
      ) : (
        <>
          {locationFlag ? (
            <>
              {
                {
                  true: (
                    <Portal elementId="normal-popup">
                      <PopupOverlay totalVisible={totalVisible} />
                      <MobilePopupWrapper tabIndex="-1" visible={totalVisible}>
                        {popupList.map((item, index) => {
                          return (
                            <>
                              <MobilePopupContent visible={item.visible} tabIndex="0" key={index}>
                                <MobilePopupBody>
                                  <CloseButtonWrap2>
                                    <CloseButton3>
                                      <img
                                        value={item.title}
                                        src={`${process.env.PUBLIC_URL}/img/popup/popup_notice.png`}
                                        alt=""
                                      />
                                      공지사항
                                    </CloseButton3>
                                    <CloseButton2
                                      value={item.title}
                                      onClick={e => {
                                        closeHandler(e, true)
                                      }}
                                    >
                                      오늘 하루 열지 않기 <CustomClose />
                                    </CloseButton2>
                                  </CloseButtonWrap2>
                                  <PopupImgWrap>
                                    <MobilePopupImgItem src={item.imgName} />
                                  </PopupImgWrap>
                                  <CloseButtonWrap
                                    value={item.title}
                                    onClick={e => {
                                      closeHandler(e)
                                    }}
                                  >
                                    <img
                                      value={item.title}
                                      src={`${process.env.PUBLIC_URL}/img/popup/popup_new_close.png`}
                                      alt=""
                                    />
                                  </CloseButtonWrap>
                                </MobilePopupBody>
                              </MobilePopupContent>
                            </>
                          )
                        })}
                      </MobilePopupWrapper>
                    </Portal>
                  ),
                  false: (
                    <Portal elementId="normal-popup">
                      <PopupOverlay totalVisible={totalVisible} />
                      <PopupWrapper tabIndex="-1" visible={totalVisible}>
                        {popupList.map((item, index) => {
                          const position = calculatePopupPosition(index)

                          return (
                            <PopupContent
                              visible={item.visible}
                              tabIndex="0"
                              key={index}
                              style={{ left: `${position.x}px` }} // 계산된 가로 위치 적용
                            >
                              <PopupBody>
                                <CloseButtonWrap2>
                                  <CloseButton3>
                                    <img
                                      value={item.title}
                                      src={`${process.env.PUBLIC_URL}/img/popup/popup_notice.png`}
                                      alt=""
                                    />
                                    공지사항
                                  </CloseButton3>
                                  <CloseButton2
                                    value={item.title}
                                    onClick={e => {
                                      closeHandler(e, true)
                                    }}
                                  >
                                    오늘 하루 열지 않기 <CustomClose />
                                  </CloseButton2>
                                </CloseButtonWrap2>

                                <PopupImgWrap>
                                  <PopupImgItem src={item.imgName} />
                                </PopupImgWrap>
                                <CloseButtonWrap
                                  value={item.title}
                                  onClick={e => {
                                    closeHandler(e)
                                  }}
                                >
                                  <img
                                    value={item.title}
                                    src={`${process.env.PUBLIC_URL}/img/popup/popup_new_close.png`}
                                    alt=""
                                  />
                                </CloseButtonWrap>
                              </PopupBody>
                            </PopupContent>
                          )
                        })}
                      </PopupWrapper>
                    </Portal>
                  ),
                }[isMobile]
              }
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}

export default React.memo(NormalPopup)

const CustomClose = styled(GrFormClose)``

export const PopupCloseBtn = styled.div`
  &:hover {
    cursor: pointer;
    background: black;
  }
  position: absolute;
  left: 340px;
  // right: 5%;
  top: 10px;
  // bottom: 90%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MobilePopupCloseBtn = styled.div`
  &:hover {
    cursor: pointer;
    background: black;
  }
  position: absolute;
  // left: 260px;
  right: 10px;
  top: 10px;
  // bottom: 90%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PopupIcon = styled.img`
  width: 25px;
  height: 25px;
`
