import React, { Fragment } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import styled from 'styled-components'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'

const SportEventNotInProgressFixtureItem = React.memo(({ fixtureKey, fixtureData }) => {
  return (
    <Fragment key={`FixtureItem-${fixtureKey}-${fixtureData.fixtureId}`}>
      <NotInProgressFixtureItemWrap>
        <NotInProgressFixtureItemBox>
          <NotInProgressFixtureItemInnerBox>
            <NotInProgressFixtureItemInnerBoxItem>
              {fixtureData.fixture.homeImageUrl ? (
                <img src={fixtureData.fixture.homeImageUrl} alt="" />
              ) : (
                <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
              )}
              <div>{fixtureData.fixture.homeName}</div>
            </NotInProgressFixtureItemInnerBoxItem>
            <NotInProgressFixtureItemInnerBoxItem>
              <div style={{ color: `${HermesMainHover2}` }}>{convertToKstByFormat(fixtureData.fixture.startDate)}</div>
              <div style={{ fontSize: '20px', fontWeight: '700' }}>VS</div>
            </NotInProgressFixtureItemInnerBoxItem>
            <NotInProgressFixtureItemInnerBoxItem>
              {fixtureData.fixture.awayImageUrl ? (
                <img src={fixtureData.fixture.awayImageUrl} alt="" />
              ) : (
                <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
              )}
              <div>{fixtureData.fixture.awayName}</div>
            </NotInProgressFixtureItemInnerBoxItem>
          </NotInProgressFixtureItemInnerBox>
        </NotInProgressFixtureItemBox>
      </NotInProgressFixtureItemWrap>
    </Fragment>
  )
})

const NotInProgressFixtureItemWrap = styled.div`
  display: block;
  padding: 0 16px;
  background: rgb(29, 30, 33);
  margin-top: 20px;
  margin-bottom: 3px;
`

const NotInProgressFixtureItemBox = styled.div`
  height: 92px;
  padding: 2px;
  padding-top: 10px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const NotInProgressFixtureItemInnerBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-size: 12px;
  text-align: center;
  color: #fff;
`
const NotInProgressFixtureItemInnerBoxItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 33.333%;
  flex-direction: column;

  img {
    width: auto;
    height: 40px;
    margin-bottom: 2px;
  }
`

export const SportEventNotInProgressFixtures = React.memo(({ eventKey, event, onClickFixtureCollapseHandler }) => {
  return (
    <>
      <div>
        <div style={{ position: 'relative' }}>
          <SportFixtureLeagueTitleWrap onClick={() => onClickFixtureCollapseHandler(eventKey)}>
            <SportFixtureLeagueTitleBox>
              <SportsEventTitleTypeImg
                src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${event.sportId}.png`}
                alt=""
              />
              {event.locationImageUrl && <SportsEventTitleCountryImg src={event.locationImageUrl} alt="" />}
              <SportsEventTitleCountryInfo>{event.locationName}</SportsEventTitleCountryInfo>
              <SportsEventTitleArrowRightIcon />
              {event.leagueImageUrl && <SportsEventTitleLeagueImg src={event.leagueImageUrl} alt="" />}
              <SportsEventTitleLeagueInfo>{event.leagueName}</SportsEventTitleLeagueInfo>
              <SportsEventTitleLeagueCount>{event.fixtures.length}경기</SportsEventTitleLeagueCount>
              {event.fixtureLeagueCollapse ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
            </SportFixtureLeagueTitleBox>
          </SportFixtureLeagueTitleWrap>
        </div>
      </div>
      <SportsEventWrap key={eventKey}>
        {event.fixtureLeagueCollapse && (
          <SportsEventContentBox>
            {event.fixtures.map(fixtureData => {
              return (
                <SportEventNotInProgressFixtureItem
                  key={`${eventKey}-${fixtureData.fixtureId}`}
                  fixtureKey={eventKey}
                  fixtureData={fixtureData}
                />
              )
            })}
          </SportsEventContentBox>
        )}
      </SportsEventWrap>
    </>
  )
})

const SportFixtureLeagueTitleWrap = styled.div`
  margin-top: 20px;
  padding: 8px 16px;
  cursor: pointer;
  background: rgba(48, 48, 48, 1);
  height: 36px;
  color: #fff;
  border-radius: 6px 6px 0 0;
`

const SportFixtureLeagueTitleBox = styled.div`
  font-weight: 700;
  font-size: 14px;
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
`

const SportsContentWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`

const SportsEventListWrap = styled.div`
  width: 100%;
  height: calc(100vh - 167px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
`

// 경기가 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const SportsInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

const SportsNotInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

// 진행예정경기 문구
const SportsNotInProgressTextBox = styled.div`
  display: block;
  padding: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  background: rgb(29, 30, 33);
`

// 페이징
const PaginationBox = styled.div`
  width: 100%;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
`

const SportsEventTitleBox = styled.div`
  padding: 0 10px;
  height: 36px;
  width: 100%;
  color: white;
  background: rgba(48, 48, 48, 1);
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`
const SportsEventTitleCountryImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  color: ${HermesMainHover2};
  width: 17px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: 23px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleLeagueCount = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  cursor: pointer;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: ${HermesMainHover2};
  width: 20px;
  height: auto;
  cursor: pointer;
`

const SportsEventContentBox = styled.div`
  width: 100%;
  min-height: 50px;
  color: white;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border: 2px solid #3a3a3a;

  background: rgb(20, 21, 23);
  border-radius: 0px 0px 6px 6px;
  gap: 2px;
`

const SportsEventContentTopWrap = styled.div`
  width: 100%;
  min-height: 1px;
  color: white;
  margin-bottom: 1px;

  &:not(:first-child) {
    margin-top: 5px;
  }
`

const SportsEventContentTopDate = styled.div`
  border-radius: 3px;
  color: #fff;
  float: left;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    color: ${HermesMainText};
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
  }
`

const SportsEventContentTopOption = styled.div`
  border-radius: 3px;
  color: #fff;
  float: right;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    width: 100%;
    color: ${HermesMainText};
    display: block;
    text-align: center;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
  }
`

const FixtureMarketBetBox = styled.div`
  width: ${props => props.wd || 'calc(50% - 39px);'};
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  background: rgba(48, 48, 48, 0.6);
`

const FixtureMarketBetBoxName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'home-box'
      ? `
      justify-content: start;
      padding-left: 10px;
      `
      : `
      justify-content: end;
      padding-right: 10px;
      `}
`

const SportsEventContentItemPoint = styled.div`
  width: 100px;
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: rgba(48, 48, 48, 0.6);

  font-weight: 600;
`

const FixtureMarketBetLineBox = styled.div`
  width: 100%;
  min-height: 36px;
  height: auto;
  display: flex;
  flex-direction: ${props => props.fd || 'column'};
`

const FixtureMarketBetBoxWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;

  &:hover {
    ${FixtureMarketBetBox}, ${SportsEventContentItemPoint} {
      cursor: pointer;
      background: ${HermesMainSelect};
      color: ${HermesMainText};
    }
  }

  ${props =>
    props.isActive &&
    `
      ${FixtureMarketBetBox}, ${SportsEventContentItemPoint} {
          background: ${HermesMainSelect};
          color: ${HermesMainText};
        }
      `}
`
