export const memberInfoModifyValidate = values => {
  const errors = {}
  if (!values.nowPassword) {
    errors.nowPassword = '필수 입력 항목입니다.'
  }
  if (!values.newPassword) {
    errors.newPassword = '필수 입력 항목입니다.'
  }

  if (values.newPassword && values.nowPassword === values.newPassword) {
    errors.newPassword = '현재 비밀번호와 동일하게는 불가능합니다.'
  }

  if (!values.newPasswordConfirm) {
    errors.newPasswordConfirm = '필수 입력 항목입니다.'
  }
  if (values.newPassword && !(values.newPassword === values.newPasswordConfirm)) {
    errors.newPasswordConfirm = '비밀번호가 일치하지 않습니다.'
  }
  return errors
}
