import { BorderedBottomTabs, NavItem, NavLink, TabPaneInnerWrap, TabsWrap } from '@/shared/components/Tabs'
import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainText } from '../../../utils/palette'

// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const NamedTabs = ({ activeKey }) => {
  const navigate = useNavigate()

  return (
    <CustomBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <MiniGameNavItem style={{ width: '10%' }}>
              <MiniGameNavLink
                eventKey="1"
                onClick={() => {
                  navigate('/minigame/named/n/powerball3m')
                }}
              >
                N파워볼 3분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '10%' }}>
              <MiniGameNavLink
                eventKey="2"
                onClick={() => {
                  navigate('/minigame/named/n/powerball5m')
                }}
              >
                N파워볼 5분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '12.1%' }}>
              <MiniGameNavLink
                eventKey="3"
                onClick={() => {
                  navigate('/minigame/named/n/powerladder3m')
                }}
              >
                N파워사다리 3분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem style={{ width: '12.1%' }}>
              <MiniGameNavLink
                eventKey="4"
                onClick={() => {
                  navigate('/minigame/named/n/powerladder5m')
                }}
              >
                N파워사다리 5분
              </MiniGameNavLink>
            </MiniGameNavItem>

            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="5"
                onClick={() => {
                  navigate('/minigame/named/red/powerball')
                }}
              >
                레드파워볼
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="6"
                onClick={() => {
                  navigate('/minigame/named/red/powerladder')
                }}
              >
                레드파워사다리
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="7"
                onClick={() => {
                  navigate('/minigame/named/running/speed4')
                }}
              >
                런닝볼 스피드4
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="8"
                onClick={() => {
                  navigate('/minigame/named/running/speed6')
                }}
              >
                런닝볼 스피드6
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="9"
                onClick={() => {
                  navigate('/minigame/named/running/maze2')
                }}
              >
                런닝볼 메이즈2
              </MiniGameNavLink>
            </MiniGameNavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </CustomBottomTabs>
  )
}
export default NamedTabs

const CustomBottomTabs = styled(BorderedBottomTabs)`
  border-top: none !important;
  background: rgb(33, 35, 38) !important;
`

const MiniGameNavLink = styled(NavLink)`
  &.active,
  &.active:focus,
  &.active:hover {
    // background-color: #636260 !important;
    color: ${HermesMainText} !important;
    font-weight: 600;
  }
`

const MiniGameNavItem = styled(NavItem)`
  width: 11.11%;
  text-align: center;
  // border: solid 1px red;
`

const NtryGameWrap = styled.div`
  height: 30px;
  position: relative;
  width: 100%;
`

export const TableBettingInfo = styled.div`
  width: 30%;
  position: left;
  color: white;
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`
