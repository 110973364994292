import React, { useEffect, useState } from 'react'
import { BiLock } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  fetchVirtualFixtureListAction,
  setVirtualGameLoading,
  setVirtualGameParam,
} from '../../../redux/virtualGameInfoSlice'
import { HermesMainText } from '../../../utils/palette'
import MobileVirtualGreyhoundsContentBotom from './MobileVirtualGreyhoundsContentBotom'
import MobileVirtualGreyhoundsContentTop from './MobileVirtualGreyhoundsContentTop'

const MobileVirtualGreyhoundsContent = () => {
  const dispatch = useDispatch()

  const { virtualGameParams, inspection, selectedVirtuaGameSportType } = useSelector(state => {
    const { virtualGameInfo, virtualGameBetting } = state

    const sportType = virtualGameBetting.selectedVirtuaGameSportType

    const sportInfo = virtualGameInfo.virtualSportList.find(item => item.sportType === sportType)

    let tempInspection

    if (sportInfo) {
      tempInspection = sportInfo.sportIsShow
    } else {
      tempInspection = false
    }

    return {
      virtualGameParams: virtualGameInfo.virtualGameParams,
      inspection: tempInspection,
      selectedVirtuaGameSportType: sportType,
    }
  })

  useEffect(() => {
    if (!selectedVirtuaGameSportType) return

    if (selectedVirtuaGameSportType !== 'VIRTUAL_GREYHOUNDS') return

    dispatch(setVirtualGameParam(selectedVirtuaGameSportType))
  }, [selectedVirtuaGameSportType])

  useEffect(() => {
    if (!virtualGameParams?.sportType || virtualGameParams?.sportType !== 'VIRTUAL_GREYHOUNDS') return

    dispatch(setVirtualGameLoading(true))

    dispatch(fetchVirtualFixtureListAction())
  }, [dispatch, virtualGameParams])

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchVirtualFixtureListAction())
    }, 10000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <VirtualGameContentWrap>
      {inspection && (
        <InspectOverlay>
          <BiLock />
          <span> 현재 점검중으로 이용이 불가능합니다. </span>
        </InspectOverlay>
      )}

      <MobileVirtualGreyhoundsContentTop />
      <MobileVirtualGreyhoundsContentBotom />
    </VirtualGameContentWrap>
  )
}

export default MobileVirtualGreyhoundsContent

const VirtualGameContentWrap = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  min-height: 630px;
`

const InspectOverlay = styled.div`
  position: absolute;
  z-index: 299;
  text-align: center;
  color: ${HermesMainText};
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  span {
    position: absolute;
    flex-direction: column;
    // margin-top: 15px;
    top: 300px;
    left: 50%;
    font-size: 18px;
    font-weight: 700;
    transform: translateX(-50%);
  }

  svg {
    position: absolute;
    flex-direction: column;
    top: 200px;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
  }
`
