import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  cancelSportsBettingHistoryV2,
  deleteSportsBettingHistoryV2,
  searchSportsBettingHistoryV2,
} from '../../api/game/sportsGameApi'
import { CustomButtonInfos } from '../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../mobile/mobileCasinoGame/MobileLiveCasino'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  SportMarketTypeV2,
  SportsResultSettlementTypeEnumsV2,
  SportsResultStatusEnums,
} from '../../utils/enums/SportsGame/SportsGameEnums'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../CustomLoading'
import BettingHistoryRadio from './BettingHistoryRadio'

const SportsBettingHistoryV2 = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [subMenuTitle, setSubMenuTitle] = useState('PREMATCH')

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
    subMenuTitle,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      subMenuTitle,
      page: 0,
      size,
    }))
  }, [subMenuTitle])

  const [totalElements, setTotalElements] = useState(0)
  const [sportsBettingHistoryContent, setSportsBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchSportsBettingHistory = async params => {
    await searchSportsBettingHistoryV2(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setSportsBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchSportsBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  // 라이브트래커 노출여부
  const onClickLiveTrackerShowHandler = sportsDetailBettingLogKey => {
    setSportsBettingHistoryContent(prevContent =>
      prevContent.map(detailItem => {
        const updatedLogList = detailItem.sportsDetailBettingLogList.map(log => {
          if (log.sportsDetailBettingLogKey === sportsDetailBettingLogKey) {
            return {
              ...log,
              // isLiveTrackerShow가 없으면 true로, 있으면 현재 값의 반대로 설정
              isLiveTrackerShow: log.isLiveTrackerShow ? !log.isLiveTrackerShow : true,
            }
          }
          return log
        })

        return {
          ...detailItem,
          sportsDetailBettingLogList: updatedLogList,
        }
      }),
    )
  }

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          // <img src={`${process.env.PUBLIC_URL}/img/sports/icon_h.png`} alt="" style={{ width: '7px', height: '7px' }} />
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const onClickBettingHistoryCancelHandler = item => {
    // 현재시간
    const currentTime = moment().tz('Asia/Seoul')

    // 취소 가능 시간
    const availableCancelTime = moment(item.availableCancelTime).tz('Asia/Seoul')

    if (currentTime >= availableCancelTime) {
      alert('해당 배팅의 취소가능 시간이 지나서 배팅 취소가 불가능합니다.')
      return
    }

    // 경기가 시작된 것이 하나라도 있는 지
    const findIdx = item.sportsDetailBettingLogList.findIndex(
      log => moment(log.bettingCancelTimeCriteriaBeforeStart).tz('Asia/Seoul') < currentTime,
    )

    if (findIdx !== -1) {
      // 이미 시작된 경기가 존재하는 상황
      alert('이미 시작된 경기가 있어, 배팅 취소가 불가능합니다.')
      return
    }

    setLoading(true)
    setLoadingInfo('배팅 취소 처리중입니다.')

    cancelSportsBettingHistoryV2({
      sportsBettingLogKey: item.sportsBettingLogKey,
      bettingAmount: item.bettingAmount,
      cancelTime: currentTime,
    })
      .then(res => {
        alert('해당 배팅건에 대해 취소 처리 되었습니다.')
        fetchSportsBettingHistory(sportsBettingHistoryParams)
        dispatch(fetchMemberInfoAction())
        setLoading(false)
        setLoadingInfo('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'SPORTS-1017':
            alert(`${message}`)
            break
          case 'SPORTS-1043':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'SPORTS-1044':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('해당 배팅건에 대해 취소 처리 실패하였습니다.')
        }

        setLoading(false)
        setLoadingInfo('')
      })
  }
  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteSportsBettingHistoryV2({
        sportsBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const getBetBox = detail => {
    const pattern = /^-?\d+\.\d+ \(\d+-\d+\)$/
    const match = detail.baseLine?.match(pattern)

    // 핸디일때
    if (detail.marketFamilyType === 11 && match) {
      const innerPattern = /\((\d+)-(\d+)\)/
      const scoreMatch = detail.baseLine.match(innerPattern)

      const homeScore = Number.isNaN(Number(scoreMatch[1])) ? 0 : Number(scoreMatch[1])
      const awayScore = Number.isNaN(Number(scoreMatch[2])) ? 0 : Number(scoreMatch[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine = detail.baseLineNum - homeScore + awayScore

      // 원정팀 선택시
      if (detail.betName === '2') {
        finalLine *= -1
      }

      return (
        <>
          {detail.betNameKr} {detail.line && <>({finalLine.toFixed(1) || '-'})</>}
        </>
      )
    }

    return (
      <>
        {detail.betNameKr} {detail.line && <>({detail.lineNum.toFixed(1) || '-'})</>}
      </>
    )
  }

  const getLiveTrackerIframeSize = sportId => {
    if (sportId === 6046) {
      // [축구] (404 / 800 * width) + 142 (142 - 92 통계 height )
      return {
        minWidth: '320px',
        width: '630px',
        height: '460px',
      }
    }

    if (sportId === 48242) {
      // [농구] (404 / 800 * width) + 156 (156 - 92 통계 height)

      return { minWidth: '320px', width: '630px', height: '460px' }
    }

    if (sportId === 54094) {
      // [테니스] (404 / 800 * width) + 142
      return { width: '630px', height: '460px' }
    }

    if (sportId === 154830) {
      // [배구] (404 / 800 * width) + 142 (142 - 92 통계 height )

      return { width: '630px', height: '460px' }
    }

    if (sportId === 131506) {
      // [미식축구] (404 / 800 * width) + 142 (142 - 92 통계 height )

      return {
        minWidth: '320px',
        width: '630px',
        height: '500px',
      }
    }

    if (sportId === 154914) {
      // [야구] (404 / 800 * width) + 142 (142 - 92 통계 height )

      return { width: 630, height: 460 }
    }

    if (sportId === 35232) {
      // [아이스하키] (404 / 800 * width) + 142 (142 - 92 통계 height )
      return { width: 630, height: 460 }
    }
  }

  return (
    <BettingHistoryTableWrap className="sports-container" ref={sportsBettingHistoryWrapRef}>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo} />
        </SportsGamePageLoadingOverlay>
      )}
      <ListWrap>
        <SubMenuItemListWrap>
          <SubMenuItemBox active={subMenuTitle === 'PREMATCH'} onClick={() => setSubMenuTitle('PREMATCH')}>
            프리매치
          </SubMenuItemBox>
          <SubMenuItemBox active={subMenuTitle === 'INPLAY'} onClick={() => setSubMenuTitle('INPLAY')}>
            인플레이
          </SubMenuItemBox>
        </SubMenuItemListWrap>
      </ListWrap>
      {sportsBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          스포츠 배팅내역이 없습니다.
          {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
        </NoContentDiv>
      ) : (
        <>
          {sportsBettingHistoryContent.map((item, index) => (
            <BettingHistoryTable key={item.sportsBettingLogKey}>
              <BettingHistoryTableHead>
                <BettingHistoryTableTr>
                  <th style={{ width: '8%' }}>경기일시</th>
                  <th style={{ width: '5%' }}>타입</th>
                  <th style={{ width: '20%' }}>국가 - 리그</th>
                  <th style={{ width: '35%' }}>경기</th>
                  <th style={{ width: '12%' }}>마켓</th>
                  <th style={{ width: '10%' }}>배팅</th>
                  <th style={{ width: '5%' }}>배당</th>
                  {/* <th style={{ width: '5%' }}>점수</th> */}
                  <th style={{ width: '5%' }}>결과</th>
                  <th style={{ width: '5%' }}>보기</th>
                </BettingHistoryTableTr>
              </BettingHistoryTableHead>
              <BettingHistoryTableBody>
                {/* 폴더 수만큼 생겨야 함 */}
                {item.bonusFolderNum !== 0 && (
                  <>
                    <BettingHistoryTableTr>
                      <BettingHistoryContentBox>
                        <BettingHistoryContentItemBox width="8">
                          {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="5" sportMarketType={item.sportMarketType}>
                          {SportMarketTypeV2[item.sportMarketType]}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="20">-</BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="35">
                          [{item.bonusFolderNum}폴더 이상]
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="12">
                          <SportsEventTitleTypeImg
                            src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                            style={{ width: '20px', height: '20px' }}
                            alt=""
                          />
                          보너스 배당
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="10" active>
                          -
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="5">{item.bonusFolderPer}</BettingHistoryContentItemBox>
                        {/* <BettingHistoryContentItemBox width="5">-</BettingHistoryContentItemBox> */}
                        <BettingHistoryContentItemBox width="5">
                          <BettingHistoryContentBoxFooterInfo bettingResult="WINNER">
                            적중
                          </BettingHistoryContentBoxFooterInfo>
                        </BettingHistoryContentItemBox>
                      </BettingHistoryContentBox>
                    </BettingHistoryTableTr>
                  </>
                )}
                {item.sportsDetailBettingLogList.map(detail => (
                  <>
                    <BettingHistoryTableTr key={detail.sportsDetailBettingLogKey}>
                      <BettingHistoryContentBox>
                        <BettingHistoryContentItemBox width="8">
                          {convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="5" sportMarketType={item.sportMarketType}>
                          {SportMarketTypeV2[item.sportMarketType]}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="20">
                          <img
                            style={{ width: '20px', height: '20px' }}
                            src={`${process.env.PUBLIC_URL}/img/sports/sportsIdType/${detail.sportId}.png`}
                            alt=""
                          />
                          {detail.locationName || '-'} - {detail.leagueName || '-'}
                        </BettingHistoryContentItemBox>

                        <BettingHistoryContentItemBox width="35">
                          {detail.homeName} VS {detail.awayName}
                          {/* <BettingHistoryContentItemDetailInfo1 textAlign="left">
                            {detail.homeName && (
                              <span style={{ marginRight: '10px' }}>
                                {detail.homeName} VS {detail.awayName}
                              </span>
                            )}

                            {detail.selectedOddNameKr && !detail.marketName.includes('정확한') && (
                              <span style={{ marginRight: '10px' }}> [{detail.selectedOddNameKr}]</span>
                            )}
                            {detail.pointName && <span style={{ marginRight: '10px' }}> [{detail.pointName}]</span>}
                            <span>{detail.homeOddValue}</span>
                          </BettingHistoryContentItemDetailInfo1>
                          <BettingHistoryContentItemDetailInfo2 /> */}
                        </BettingHistoryContentItemBox>

                        <BettingHistoryContentItemBox width="12">{detail.marketName}</BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="10" active>
                          {getBetBox(detail)}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="5">{detail.priceNum || '-'}</BettingHistoryContentItemBox>
                        {/* <BettingHistoryContentItemBox width="5">-</BettingHistoryContentItemBox> */}
                        <BettingHistoryContentItemBox width="5">
                          <BettingHistoryContentBoxFooterInfo bettingResult={detail.betSettlementType}>
                            {SportsResultSettlementTypeEnumsV2[detail.betSettlementType]}
                          </BettingHistoryContentBoxFooterInfo>
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="5">
                          {detail?.liveTrackerUrl ? (
                            <BtnLiveTracker
                              onClick={e => {
                                e.preventDefault()
                                onClickLiveTrackerShowHandler(detail.sportsDetailBettingLogKey)
                              }}
                            >
                              <i className="fas fa-eye" aria-hidden="true" />
                            </BtnLiveTracker>
                          ) : (
                            <span>-</span>
                          )}
                        </BettingHistoryContentItemBox>
                      </BettingHistoryContentBox>
                    </BettingHistoryTableTr>

                    {detail.isLiveTrackerShow && (
                      <BettingHistoryTableTr>
                        <LiveTrackerIframe
                          src={detail.liveTrackerUrl}
                          scrolling="no"
                          style={getLiveTrackerIframeSize(detail.sportId)}
                        />
                      </BettingHistoryTableTr>
                    )}
                  </>
                ))}
                <BettingHistoryTableTr>
                  <BettingHistoryContentBox>
                    <BettingHistoryRadio
                      sportsBettingLogKey={item.sportsBettingLogKey}
                      sportsResultStatus={item.sportsResultStatus}
                      checkRadio={checkRadio}
                      setCheckRadio={setCheckRadio}
                    />
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 시간 :</span>{' '}
                      {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배팅 금액 : </span>
                      {item.bettingAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>배당 : </span>
                      {(() => {
                        const {
                          realReduceBettingPerForSingleFolder,
                          realReduceBettingPerForCombination,
                          totalOddValue,
                          originTotalOddValue,
                        } = item
                        const reduceBettingValue =
                          realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                        if (reduceBettingValue === null) {
                          return <span>{totalOddValue}</span>
                        }

                        return (
                          <>
                            {Number(reduceBettingValue) < 0 ? (
                              <span>
                                ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                {reduceBettingValue?.toFixed(2)})
                              </span>
                            ) : (
                              <span>
                                ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                {reduceBettingValue?.toFixed(2)})
                              </span>
                            )}
                          </>
                        )
                      })()}
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>예상 적중금액 : </span>
                      {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>적중 금액 : </span>
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo>
                    <BettingHistoryContentBoxFooterInfo2 bettingResult={item.sportsResultStatus}>
                      <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>결과 : </span>
                      {SportsResultStatusEnums[item.sportsResultStatus]}
                    </BettingHistoryContentBoxFooterInfo2>
                  </BettingHistoryContentBox>
                </BettingHistoryTableTr>
              </BettingHistoryTableBody>
              {item.allowBettingCancel && (
                <BettingCancelBox>
                  <BettingCancelButton
                    onClick={() => {
                      onClickBettingHistoryCancelHandler(item)
                    }}
                  >
                    배팅 취소
                  </BettingCancelButton>
                </BettingCancelBox>
              )}
            </BettingHistoryTable>
          ))}
        </>
      )}

      {totalElements > 0 && (
        <>
          <ListWrap>
            <CustomDeleteButton
              onClick={() => {
                setCheckRadio(
                  sportsBettingHistoryContent
                    .filter(value => value.sportsResultStatus !== 'ING')
                    .map(value => value.sportsBettingLogKey),
                )
              }}
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton>
          </ListWrap>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </>
      )}
    </BettingHistoryTableWrap>
  )
}

export default SportsBettingHistoryV2

const BtnLiveTracker = styled.span`
  border: 1px solid #000;
  padding: 1px 5px;
  cursor: pointer;

  background: linear-gradient(to bottom, rgb(183 47 47) 1%, rgb(119 14 14) 100%);
  text-shadow: 0 0px 0px rgb(0 0 0 / 80%);
  color: #fff;
`

const LiveTrackerIframe = styled.iframe`
  width: 630px;
  margin: 0 auto;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  height: 55px;
  // padding-left: 10px;
  gap: 10px;
  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  background-color: #1b1c1f;
  padding: 10px 10px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 100px;
  height: 43px;
  &:hover {
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 120px;
    height: auto;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-top: -20px;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #525252;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead`
  th {
    background: none;
  }
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return 'white'
  }
}

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 600;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  font-weight: 600;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;

  div {
    font-weight: 500;
  }
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
  // &:hover {
  //   border: none;
  //   cursor: pointer;
  // }
  font-weight: 700;
`

const getColor3 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'white'
  }
}

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: ${props => getColor3(props.sportMarketType)};
  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid #525252;
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: ${HermesMainSelect};
    color: ${HermesMainText};
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 10%;
  position: relative;
  color: red;
  margin-top: 2px;
  margin-left: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 70%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 5px;
  float: left;
  font-weight: 600;
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 29%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;
`
