import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  cancelSportsBettingHistory,
  cancelSportsBettingHistoryV2,
  deleteSportsBettingHistory,
  deleteSportsBettingHistoryV2,
  searchSportsBettingHistory,
  searchSportsBettingHistoryV2,
} from '../../api/game/sportsGameApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { ListButton, MobileListButton, MoneyFormModifyButtomInfo } from '../../shared/components/MoneyCustomElement'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  SportMarketTypeV2,
  SportsResultSettlementTypeEnumsV2,
  SportsResultStatusEnums,
} from '../../utils/enums/SportsGame/SportsGameEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../../containers/CustomLoading'
import MobileBettingHistoryRadio from './MobileBettingHistoryRadio'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../utils/palette'
import { CustomButtonInfos, CustomDeleteButton } from './MobileMiniGameBettingHistory'

const MobileSportsBettingHistoryV2 = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [subMenuTitle, setSubMenuTitle] = useState('PREMATCH')

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
    subMenuTitle,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      ...prev,
      subMenuTitle,
      page: 0,
      size,
    }))
  }, [subMenuTitle])

  const [totalElements, setTotalElements] = useState(0)
  const [sportsBettingHistoryContent, setSportsBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchSportsBettingHistory = async params => {
    await searchSportsBettingHistoryV2(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setSportsBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchSportsBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  // 라이브트래커 노출여부
  const onClickLiveTrackerShowHandler = sportsDetailBettingLogKey => {
    setSportsBettingHistoryContent(prevContent =>
      prevContent.map(detailItem => {
        const updatedLogList = detailItem.sportsDetailBettingLogList.map(log => {
          if (log.sportsDetailBettingLogKey === sportsDetailBettingLogKey) {
            return {
              ...log,
              // isLiveTrackerShow가 없으면 true로, 있으면 현재 값의 반대로 설정
              isLiveTrackerShow: log.isLiveTrackerShow ? !log.isLiveTrackerShow : true,
            }
          }
          return log
        })

        return {
          ...detailItem,
          sportsDetailBettingLogList: updatedLogList,
        }
      }),
    )
  }

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          // <img src={`${process.env.PUBLIC_URL}/img/sports/icon_h.png`} alt="" style={{ width: '7px', height: '7px' }} />
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const onClickBettingHistoryCancelHandler = item => {
    // 현재시간
    const currentTime = moment().tz('Asia/Seoul')

    // 취소 가능 시간
    const availableCancelTime = moment(item.availableCancelTime).tz('Asia/Seoul')

    if (currentTime >= availableCancelTime) {
      alert('해당 배팅의 취소가능 시간이 지나서 배팅 취소가 불가능합니다.')
      return
    }

    // 경기가 시작된 것이 하나라도 있는 지
    const findIdx = item.sportsDetailBettingLogList.findIndex(
      log => moment(log.bettingCancelTimeCriteriaBeforeStart).tz('Asia/Seoul') < currentTime,
    )

    if (findIdx !== -1) {
      // 이미 시작된 경기가 존재하는 상황
      alert('이미 시작된 경기가 있어, 배팅 취소가 불가능합니다.')
      return
    }

    setLoading(true)
    setLoadingInfo('배팅 취소 처리중입니다.')

    cancelSportsBettingHistoryV2({
      sportsBettingLogKey: item.sportsBettingLogKey,
      bettingAmount: item.bettingAmount,
      cancelTime: currentTime,
    })
      .then(res => {
        alert('해당 배팅건에 대해 취소 처리 되었습니다.')
        fetchSportsBettingHistory(sportsBettingHistoryParams)
        dispatch(fetchMemberInfoAction())
        setLoading(false)
        setLoadingInfo('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'SPORTS-1017':
            alert(`${message}`)
            break
          case 'SPORTS-1043':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'SPORTS-1044':
            alert('취소 가능 시간을 지나서 취소가 불가능합니다.')
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('해당 배팅건에 대해 취소 처리 실패하였습니다.')
        }

        setLoading(false)
        setLoadingInfo('')
      })
  }
  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteSportsBettingHistoryV2({
        sportsBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  const getBetBox = detail => {
    const pattern = /^-?\d+\.\d+ \(\d+-\d+\)$/
    const match = detail.baseLine?.match(pattern)

    // 핸디일때
    if (detail.marketFamilyType === 11 && match) {
      const innerPattern = /\((\d+)-(\d+)\)/
      const scoreMatch = detail.baseLine.match(innerPattern)

      const homeScore = Number.isNaN(Number(scoreMatch[1])) ? 0 : Number(scoreMatch[1])
      const awayScore = Number.isNaN(Number(scoreMatch[2])) ? 0 : Number(scoreMatch[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine = detail.baseLineNum - homeScore + awayScore

      // 원정팀 선택시
      if (detail.betName === '2') {
        finalLine *= -1
      }

      return (
        <>
          {detail.betNameKr} {detail.line && <>({finalLine.toFixed(1) || '-'})</>}
        </>
      )
    }

    return (
      <>
        {detail.betNameKr} {detail.line && <>({detail.lineNum.toFixed(1) || '-'})</>}
      </>
    )
  }

  const getCalculatedHeight = sportId => {
    if (sportId === 6046) {
      // [축구] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 400
    }

    if (sportId === 48242) {
      // [농구] (404 / 800 * width) + 64 (156 - 92 통계 height)
      return 400
    }

    if (sportId === 54094) {
      // [테니스]
      return 400
    }

    if (sportId === 154830) {
      // [배구] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 355
    }

    if (sportId === 131506) {
      // [미식 축구]
      return 400
    }

    if (sportId === 154914) {
      // [야구] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 355
    }

    if (sportId === 35232) {
      // [아이스하키] (404 / 800 * width) + 50 (142 - 92 통계 height )
      return 355
    }
  }

  return (
    <BettingHistoryTableWrap className="sports-container" ref={sportsBettingHistoryWrapRef}>
      <ListWrap>
        <SubMenuItemListWrap>
          <SubMenuItemBox active={subMenuTitle === 'PREMATCH'} onClick={() => setSubMenuTitle('PREMATCH')}>
            프리매치
          </SubMenuItemBox>
          <SubMenuItemBox active={subMenuTitle === 'INPLAY'} onClick={() => setSubMenuTitle('INPLAY')}>
            인플레이
          </SubMenuItemBox>
        </SubMenuItemListWrap>
      </ListWrap>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo} />
        </SportsGamePageLoadingOverlay>
      )}
      {sportsBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          스포츠 배팅내역이 없습니다.
          {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
        </NoContentDiv>
      ) : (
        <>
          {sportsBettingHistoryContent.map((item, index) => (
            <BettingHistoryTable key={item.sportsBettingLogKey}>
              <BettingHistoryTopHeaderTr>
                <MobileBettingHistoryRadio
                  sportsBettingLogKey={item.sportsBettingLogKey}
                  sportsResultStatus={item.sportsResultStatus}
                  checkRadio={checkRadio}
                  setCheckRadio={setCheckRadio}
                />
                {item.allowBettingCancel && (
                  <BettingCancelBox>
                    <BettingCancelButton
                      onClick={() => {
                        onClickBettingHistoryCancelHandler(item)
                      }}
                    >
                      배팅 취소
                    </BettingCancelButton>
                  </BettingCancelBox>
                )}
              </BettingHistoryTopHeaderTr>
              <BettingHistoryTableBody>
                {item.bonusFolderNum !== 0 && (
                  <>
                    <BettingHistoryHeaderTr>
                      <BettingHistoryTh width="25">
                        <span>{convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}</span>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="37">
                        <img
                          style={{ width: '15px', height: '15px' }}
                          src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                          alt=""
                        />
                        <CustomSpan sportMarketType={item.sportMarketType}>
                          {SportMarketTypeV2[item.sportMarketType]} 보너스 배당
                        </CustomSpan>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="38">
                        <span style={{ color: `${HermesMainText}`, fontWeight: '400' }}>보너스 폴더</span>
                      </BettingHistoryTh>
                    </BettingHistoryHeaderTr>
                    <BettingHistoryTableTr>
                      <BettingHistoryContentBox>
                        <ResultBox bettingResult="WINNER">
                          <span>적중</span>
                        </ResultBox>
                        <BettingHistoryContentItemBox
                          width="80"
                          // active={detail.homeOddName === detail.selectedOddName}
                        >
                          <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                            <span style={{ marginRight: '10px' }}>[{item.bonusFolderNum}폴더 이상]</span>
                          </BettingHistoryLongContentItemDetailInfo1>
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="12.5" active>
                          보너스
                          {/* {detail.betNameKr} {detail.priceNum && <>({detail.priceNum || '-'})</>} */}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="7.5">{item.bonusFolderPer}</BettingHistoryContentItemBox>
                        {/* <BettingHistoryContentItemBox width="12.5">
                          <span style={{ fontWeight: '600', color: `${HermesMainText}` }}>-</span>
                        </BettingHistoryContentItemBox> */}
                      </BettingHistoryContentBox>
                    </BettingHistoryTableTr>
                  </>
                )}

                {/* 폴더 수만큼 생겨야 함 */}
                {item.sportsDetailBettingLogList.map(detail => (
                  <>
                    <BettingHistoryHeaderTr>
                      <BettingHistoryTh width="25">
                        <span>{convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}</span>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="37">
                        <span>
                          <img
                            style={{ width: '15px', height: '15px' }}
                            src={`${process.env.PUBLIC_URL}/img/sports/sportsIdType/${detail.sportId}.png`}
                            alt=""
                          />
                          {detail.leagueName || '-'}
                        </span>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="38">
                        <span style={{ color: `${HermesMainText}`, fontWeight: '400' }}>{detail.marketName}</span>
                      </BettingHistoryTh>
                    </BettingHistoryHeaderTr>
                    <BettingHistoryTableTr key={detail.sportsDetailBettingLogKey}>
                      <BettingHistoryContentBox>
                        <ResultBox bettingResult={detail.betSettlementType}>
                          <span>{SportsResultSettlementTypeEnumsV2[detail.betSettlementType]}</span>
                        </ResultBox>
                        <BettingHistoryContentItemBox
                          width="70"
                          // active={detail.homeOddName === detail.selectedOddName}
                        >
                          <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                            {detail?.marketName === '보너스 배당' && (
                              <SportsEventTitleTypeImg
                                src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                                style={{ width: '25px', height: '25px', marginBottom: '-7px' }}
                                alt=""
                              />
                            )}
                            {detail.homeName && (
                              <span style={{ marginRight: '10px' }}>
                                {detail.homeName} VS {detail.awayName}
                              </span>
                            )}

                            {detail.selectedOddNameKr && !detail.marketName.includes('정확한') && (
                              <span style={{ marginRight: '5px' }}> [{detail.selectedOddNameKr}]</span>
                            )}
                            {detail.pointName && <span style={{ marginRight: '5px' }}> [{detail.pointName}]</span>}
                            <span style={{ fontWeight: '600' }}>{detail.homeOddValue}</span>
                          </BettingHistoryLongContentItemDetailInfo1>
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="12.5" active>
                          {getBetBox(detail)}
                        </BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="9.5">{detail.priceNum}</BettingHistoryContentItemBox>
                        <BettingHistoryContentItemBox width="8">
                          {detail?.liveTrackerUrl ? (
                            <BtnLiveTracker
                              onClick={e => {
                                e.preventDefault()

                                onClickLiveTrackerShowHandler(detail.sportsDetailBettingLogKey)
                              }}
                            >
                              <i className="fas fa-eye" aria-hidden="true" />
                            </BtnLiveTracker>
                          ) : (
                            <span>-</span>
                          )}
                        </BettingHistoryContentItemBox>
                        {/* <BettingHistoryContentItemBox width="12.5">
                          <span style={{ fontWeight: '600', color: `${HermesMainText}` }}>
                            {detail.resultScore || '-'}
                          </span>
                        </BettingHistoryContentItemBox> */}
                      </BettingHistoryContentBox>
                    </BettingHistoryTableTr>

                    {detail.isLiveTrackerShow && (
                      <BettingHistoryTableTr>
                        <LiveTrackerIframe
                          src={detail.liveTrackerUrl}
                          scrolling="no"
                          style={{ height: `${getCalculatedHeight(detail.sportId)}px` }}
                        />
                      </BettingHistoryTableTr>
                    )}
                  </>
                ))}
                <BettingHistoryBottomTr>
                  <BettingHistoryBottomContentBox>
                    {' '}
                    <BettingHistoryContentBoxFooterInfo1>
                      <span style={{ color: '#a6a6a6' }}>배팅시간 :</span>{' '}
                      {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                    </BettingHistoryContentBoxFooterInfo1>
                    <BettingHistoryContentBoxFooterInfo2>
                      <span style={{ color: '#a6a6a6' }}>배팅액 : </span>
                      {item.bettingAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo2>
                  </BettingHistoryBottomContentBox>
                  <BettingHistoryBottomContentBox>
                    <BettingHistoryContentBoxFooterInfo1>
                      <span style={{ color: '#a6a6a6' }}>배당 : </span>
                      {(() => {
                        const {
                          realReduceBettingPerForSingleFolder,
                          realReduceBettingPerForCombination,
                          totalOddValue,
                          originTotalOddValue,
                        } = item
                        const reduceBettingValue =
                          realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                        if (reduceBettingValue === null) {
                          return <span>{totalOddValue}</span>
                        }

                        return (
                          <>
                            {Number(reduceBettingValue) < 0 ? (
                              <span>
                                ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                {reduceBettingValue?.toFixed(2)})
                              </span>
                            ) : (
                              <span>
                                ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                {reduceBettingValue?.toFixed(2)})
                              </span>
                            )}
                          </>
                        )
                      })()}
                    </BettingHistoryContentBoxFooterInfo1>
                    <BettingHistoryContentBoxFooterInfo2>
                      <span style={{ color: '#a6a6a6' }}>예상 적중액 : </span>
                      {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo2>
                  </BettingHistoryBottomContentBox>
                  <BettingHistoryBottomContentBox>
                    <BettingHistoryContentBoxFooterInfo1 style={{ color: `${HermesMainText}` }}>
                      <span style={{ color: '#a6a6a6' }}>적중액 : </span>
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo1>
                    <BettingHistoryContentBoxFooterInfo2 bettingResult={item.sportsResultStatus}>
                      <span style={{ color: '#a6a6a6' }}>결과 : </span>
                      {SportsResultStatusEnums[item.sportsResultStatus]}
                    </BettingHistoryContentBoxFooterInfo2>
                  </BettingHistoryBottomContentBox>
                </BettingHistoryBottomTr>
                {/* <BettingHistoryBottomTr style={{ borderTop: 'none' }}>
                  
                </BettingHistoryBottomTr> */}
              </BettingHistoryTableBody>
            </BettingHistoryTable>
          ))}
        </>
      )}
      {sportsBettingHistoryContent.length === 0 ? (
        <></>
      ) : (
        <>
          <ListWrap>
            <CustomDeleteButton
              onClick={() => {
                setCheckRadio(
                  sportsBettingHistoryContent
                    .filter(value => value.sportsResultStatus !== 'ING')
                    .map(value => value.sportsBettingLogKey),
                )
              }}
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton>
          </ListWrap>
          {totalElements > 0 && (
            <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          )}
        </>
      )}
    </BettingHistoryTableWrap>
  )
}

export default MobileSportsBettingHistoryV2

const BtnLiveTracker = styled.span`
  border: 1px solid #000;
  padding: 1px 5px;
  cursor: pointer;

  background: linear-gradient(to bottom, rgb(183 47 47) 1%, rgb(119 14 14) 100%);
  text-shadow: 0 0px 0px rgb(0 0 0 / 80%);
  color: #fff;
`

const LiveTrackerIframe = styled.iframe`
  width: 100%;
  margin: 0 auto;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin-top: 0px;
  padding-bottom: 5px;
  gap: 10px;
  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  background-color: #1b1c1f;
  padding: 10px 10px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 80px;
  height: 33px;
  &:hover {
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const getColor3 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'white'
  }
}

const CustomSpan = styled.span`
  color: ${props => getColor3(props.sportMarketType)};
`
const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-right: 10px;
  gap: 10px;
`

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return 'white'
  }
}

const ResultBox = styled.div`
  position: absolute;
  display: block;
  padding: 0px;
  text-align: center;
  z-index: 150;
  width: 60px !important;
  height: 14px !important;
  line-height: 14px;
  bottom: 1px;
  left: 50%;
  margin-left: -56px;
  margin-bottom: -1px;
  span {
    border-radius: 36px 36px 0px 0px;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: bold;
    background: #fff;
    color: ${props => getColor2(props.bettingResult)};
    border: 1px solid #d5d4d3;
    border-bottom: 0px;
  }
`
const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: black;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
`

const BettingHistoryTh = styled.th`
  border: none !important;
  width: ${props => props.width && `${props.width}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  span {
    padding-left: 2px;
    width: 100%;
    font-weight: 300;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    img {
      width: 15px;
      height: 15px;
      margin-right: 2px;
      margin-bottom: -3px;
    }
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Pretendard', Dotum, Helvetica, 'Apple SD Gothic Neo', Sans-serif;
  font-weight: 600;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo1 = styled.span`
  width: 50%;
  color: ${props => getColor(props.bettingResult)};
  text-align: left;
  margin-left: 10px;
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  width: 50%;
  color: ${props => getColor(props.bettingResult)};
  text-align: left;
`

const BettingHistoryContentBoxFooterInfo3 = styled.span`
  width: 50%;
  color: ${props => getColor(props.bettingResult)};
  text-align: left;
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;

  span {
  }
`

const BettingHistoryBottomContentBox = styled.div`
  padding-left: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 22px;
  span {
  }
`

const BettingHistoryBottomTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const BettingHistoryContentItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-size: 11px;
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: ${HermesMainSelect};
    color: ${HermesMainText};
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 20%;
  position: relative;
  color: ${HermesMainText};
  margin-left: auto;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryLongContentItemDetailInfo1 = styled.div`
  width: 100%;
  display: table-cell;
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 60%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 39%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;

  span {
    font-size: 11px;
  }
`
