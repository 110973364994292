import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Collapse } from 'react-bootstrap'
import parse from 'style-to-object'
import ReactHtmlParser from 'react-html-parser'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNoticeBoardList } from '../../../redux/customCenterSlice'
import CustomPagination from '../../../shared/components/CustomPagination'
import {
  CustomCollapse,
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  CustomerCenterHeaderWrap,
} from '../../../shared/components/MoneyCustomElement'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import { BoardTypeEnums } from '../../../utils/enums/Operation/boardEnums'
import CustomLoading from '../../CustomLoading'
import { HermesMainBorder, HermesMainSelect, HermesMainText, colorTableBodyBackground } from '../../../utils/palette'

const NoticeEventModalContent = () => {
  const [loading, setLoading] = useState(true)
  const [subMenuTitle, setSubMenuTitle] = useState('NOTICE')
  const dispatch = useDispatch()

  /**
   *  게시판 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(50)

  const [boardParams, setBoardParams] = useState({
    boardType: subMenuTitle,
    page,
    size,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      boardType: subMenuTitle,
      page: 0,
      size,
    }))
  }, [subMenuTitle])

  const [content, setContent] = useState([])

  const { totalElement, apiContent } = useSelector(state => {
    const { customCenter } = state

    return {
      totalElement: customCenter.noticeInfo.totalElement,
      apiContent: customCenter.noticeInfo.content,
    }
  })

  useEffect(() => {
    setContent(
      apiContent.map(item => ({
        ...item,
        isVisible: content.find(prevItem => prevItem.boardKey === item.boardKey)?.isVisible || false,
      })),
    )
  }, [apiContent])

  const fetchSearchBoardList = async () => {
    try {
      dispatch(fetchNoticeBoardList(boardParams))
      setLoading(false)
    } catch (error) {
      // console.log('error ', error)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    fetchSearchBoardList()
  }, [boardParams])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = boardKey => {
    setContent(prev => {
      const idx = prev.findIndex(item => item.boardKey === boardKey)

      if (idx !== -1) {
        // 원래의 state를 복사하여 새로운 배열을 만듭니다.
        const newState = [...prev]
        newState[idx] = {
          ...newState[idx],
          isVisible: !newState[idx].isVisible,
        }

        // 새로운 배열을 반환합니다.
        return newState
      }

      // 인덱스를 찾지 못한 경우, 원래의 state를 그대로 반환합니다.
      return prev
    })
  }

  /**
   * 페이징처리
   */
  const webPageWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (webPageWrapRef.current) {
      webPageWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const transformFn = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.width === 'auto' && style?.height === 'auto') {
        return <img key={index} src={node.attribs.src} style={{ width: '100%' }} alt="" />
      }
      return <img key={index} src={node.attribs.src} style={style} alt="" />
    }
    if (node.type === 'tag' && node.name === 'span') {
      let style = {}
      if (node.attribs?.style) {
        style = parse(node.attribs?.style)
      }
      if (style?.color === 'rgb(0, 0, 0)') {
        return (
          <span key={index} style={{ color: 'rgb(255, 255, 255)' }} alt="">
            {node.children[0]?.data}
          </span>
        )
      }
    }
  }

  return (
    <ContentWrap>
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <SubMenuItemListWrap>
            <div style={{ display: 'flex', gap: '10px' }}>
              <SubMenuItemBox active={subMenuTitle === 'NOTICE'} onClick={() => setSubMenuTitle('NOTICE')}>
                공지사항
              </SubMenuItemBox>
              <SubMenuItemBox active={subMenuTitle === 'BETTING_RULE'} onClick={() => setSubMenuTitle('BETTING_RULE')}>
                배팅규정
              </SubMenuItemBox>
            </div>
          </SubMenuItemListWrap>

          <ContentBox style={{ marginTop: '-25px' }}>
            {loading ? (
              <CustomLoading />
            ) : (
              <CustomerCenterContentWrap>
                <CustomerCenterContentTable>
                  <CustomerCenterContentTableHead>
                    <CustomerCenterContentTableHeadTr>
                      <th style={{ width: '10%' }}>번호</th>
                      <th style={{ width: '10%' }}>타입</th>
                      <th style={{ width: '80%' }}>내용</th>
                    </CustomerCenterContentTableHeadTr>
                  </CustomerCenterContentTableHead>
                  <CustomerCenterContentTableBody>
                    {content.length === 0 ? (
                      <NoContentDiv>
                        <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
                        최근 게시물이 없습니다.
                      </NoContentDiv>
                    ) : (
                      <>
                        {content.map(item => (
                          <>
                            <CustomerCenterContentTableBodyTr key={item.boardKey}>
                              <CustomerCenterContentBox>
                                <CustomerCenterContenItemBox
                                  width="10"
                                  onClick={() => onClickRowHandler(item.boardKey)}
                                >
                                  {item.num}
                                </CustomerCenterContenItemBox>
                                <CustomerCenterContenItemBox
                                  width="10"
                                  onClick={() => onClickRowHandler(item.boardKey)}
                                >
                                  {BoardTypeEnums[item.type]}
                                </CustomerCenterContenItemBox>
                                <CustomerCenterContenItemBox
                                  width="80"
                                  onClick={() => onClickRowHandler(item.boardKey)}
                                  style={{ cursor: 'pointer', flexDirection: 'column' }}
                                >
                                  {item.boardTitle}
                                </CustomerCenterContenItemBox>
                              </CustomerCenterContentBox>
                            </CustomerCenterContentTableBodyTr>

                            <CustomCollapse in={item.isVisible}>
                              <CustomerCenterContentTableBodyTr>
                                <CustomerCenterContentDetailBox>
                                  {ReactHtmlParser(item.boardContent, { transform: transformFn })}
                                </CustomerCenterContentDetailBox>
                              </CustomerCenterContentTableBodyTr>
                            </CustomCollapse>
                          </>
                        ))}
                      </>
                    )}
                  </CustomerCenterContentTableBody>
                </CustomerCenterContentTable>
                {/* {totalElement > 0 && (
                  <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
                )} */}
              </CustomerCenterContentWrap>
            )}
          </ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default NoticeEventModalContent

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  img {
    width: 120px;
    height: auto;
  }
`

const CustomerCenterContentTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;

  th {
    padding: 16px 4px;
    background: rgba(9, 9, 10) !important;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomerCenterContentTableHead = styled.thead``

const CustomerCenterContentTableBody = styled.tbody`
  // gap: 10px;
`

const CustomerCenterContentTableHeadTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
`

const CustomerCenterContentTableBodyTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: rgb(22, 23, 25);
  margin-top: 12px;
  border-radius: 6px;

  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
`

const CustomerCenterContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
`

const CustomerCenterContentDetailBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  min-height: 45px;
  padding: 20px;
`

const CustomerCenterContenItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  color: white;
  font-size: 14px;
  font-weight: 700;
  padding: 16px 5px;
`

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;

  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  height: 725px;
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: left;
  height: 80px;
  padding-left: 30px;
  gap: 10px;
`

const SubMenuItemBox = styled.div`
  background-color: #1b1c1f;
  padding: 10px 10px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 100px;
  height: 43px;
  &:hover {
    background: ${HermesMainSelect};
    color: white;
  }

  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    color: white;
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`
