import React, { useState } from 'react'
import { FaSearchPlus, FaStar } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import 'reactjs-crontab/dist/index.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import { setSelectedBonusFolder } from '../../../redux/sportsBettingSlice'
import { setSearchKeywordParam, setSelectedSportId, setSortType } from '../../../redux/sportsOverseasInfoSlice'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'

const SportsOverseasHeader = () => {
  const dispatch = useDispatch()
  const [showAddSportsType, setShowAddSportsType] = useState(false)

  const [searchKeyword, setSearchKeyword] = useState('')

  const onClickSearchKeywordHandler = () => {
    dispatch(setSearchKeywordParam(searchKeyword))
  }

  const onClickSelectedSportHandler = sportId => {
    dispatch(setSelectedSportId(sportId))
  }

  const onClickSportSortTypeHandler = sortType => {
    dispatch(setSortType(sortType))
  }

  const onClickSportBonusFolderHandler = selectedBonusFolder => {
    dispatch(setSelectedBonusFolder(selectedBonusFolder))
  }

  const { selectedSportId, overseasCategory, overseasParams, bonusFolderInfo, selectedBonusFolder } = useSelector(
    state => {
      const { sportsBetting, sportsOverseasInfo } = state

      const sportsBettingInfo = sportsBetting.sportsBettingInfos[sportsBetting.activeSportsMarketType]

      return {
        selectedSportId: sportsOverseasInfo.selectedSportId,
        overseasCategory: sportsOverseasInfo.overseasCategory,
        overseasParams: sportsOverseasInfo.overseasParams,
        bonusFolderInfo: sportsBettingInfo?.bonusFolderInfo || [],
        selectedBonusFolder: sportsBetting.selectedBonusFolder,
      }
    },
  )

  return (
    <HeaderWrap>
      <SportsGameHeaderInnerBox>
        <div style={{ width: '100%', position: 'relative' }}>
          <div style={{ position: 'relative' }}>
            <SportsEventTypeListBox>
              {overseasCategory.slice(0, 6).map((item, index) => (
                <SportsEventTypeItemBox
                  key={`${item.sportId}-${index + 1}`}
                  onClick={() => {
                    onClickSelectedSportHandler(item.sportId)
                  }}
                  active={item.sportId === selectedSportId}
                >
                  <SportsEventTypeIcon>
                    <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-${item.sportId}.png`} alt="" />
                  </SportsEventTypeIcon>
                  <SportsEventTypeTitle>{item.sportName}</SportsEventTypeTitle>
                  <SportsEventTypeCount>{item.sportCount}</SportsEventTypeCount>
                </SportsEventTypeItemBox>
              ))}
            </SportsEventTypeListBox>
            <SportsEventAddListBox
              onClick={() => {
                setShowAddSportsType(true)
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/img/sidebar/etc.png`} alt="" />
            </SportsEventAddListBox>
          </div>
        </div>
      </SportsGameHeaderInnerBox>
      {showAddSportsType && (
        <SportsEventAddListBoxContent>
          <SportsEventAddListBoxClose
            onClick={() => {
              setShowAddSportsType(false)
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`} alt="" />
          </SportsEventAddListBoxClose>
          {overseasCategory.slice(6, 12).map((item, index) => (
            <div style={{ position: 'relative', display: 'block' }}>
              <SportsEventAddListBoxItem
                key={`${item.sportId}-${index + 1}`}
                onClick={() => {
                  onClickSelectedSportHandler(item.sportId)
                  setShowAddSportsType(false)
                }}
                active={item.sportId === selectedSportId}
              >
                <SportsEventAddListBoxItemIcon>
                  <img src={`${process.env.PUBLIC_URL}/img/sidebar/side-${item.sportId}.png`} alt="" />
                </SportsEventAddListBoxItemIcon>
                <SportsEventAddListBoxItemTitle>{item.sportName}</SportsEventAddListBoxItemTitle>
                <SportsEventAddListBoxItemCount>{item.sportCount}</SportsEventAddListBoxItemCount>
              </SportsEventAddListBoxItem>
            </div>
          ))}
        </SportsEventAddListBoxContent>
      )}

      <SportMenuFuncBox>
        <SportsGameMultiFolderWrap>
          <SportsGameMultiFolderHeader>
            <SportsGameMultiFolderHeaderIcon />

            <span style={{ color: '#fff' }}>다폴더 보너스 추가 배당</span>
          </SportsGameMultiFolderHeader>
          <SportsGameMultiFolderContent>
            {bonusFolderInfo.map((folder, index) => (
              <SportsGameMultiFolderContentItem
                key={index}
                isActive={folder.bonusFolderNum === selectedBonusFolder?.bonusFolderNum}
                onClick={() => {
                  onClickSportBonusFolderHandler(folder)
                }}
              >
                <SportsGameMultiFolderImg
                  src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                  style={{ width: '25px' }}
                  alt=""
                />
                <span>{folder.bonusFolderNum}폴더 이상</span>
                <SportsGameMultiFolderContentOdd>{folder.bonusFolderPer}</SportsGameMultiFolderContentOdd>
              </SportsGameMultiFolderContentItem>
            ))}
          </SportsGameMultiFolderContent>
        </SportsGameMultiFolderWrap>

        <div style={{ width: '50%' }}>
          <SportsGameSortingWrap>
            <SportsGameSortingLeftWrap>
              <SportsGameSortingItem
                isActive={overseasParams.sortType === 'POPULAR'}
                onClick={() => onClickSportSortTypeHandler('POPULAR')}
              >
                <span>인기순 정렬</span>
              </SportsGameSortingItem>
              <SportsGameSortingItem
                isActive={overseasParams.sortType !== 'POPULAR'}
                onClick={() => {
                  onClickSportSortTypeHandler('TODAY')
                }}
              >
                <span>시간대 정렬</span>
              </SportsGameSortingItem>
            </SportsGameSortingLeftWrap>
            {overseasParams.sortType !== 'POPULAR' && (
              <SportsGameSortingRightWrap>
                <SportsGameSortingRightItem
                  isActive={overseasParams.sortType === 'TODAY'}
                  onClick={() => onClickSportSortTypeHandler('TODAY')}
                >
                  24시간내 경기
                </SportsGameSortingRightItem>
                <SportsGameSortingRightItem
                  isActive={overseasParams.sortType === 'TOMORROW'}
                  onClick={() => onClickSportSortTypeHandler('TOMORROW')}
                >
                  내일 경기
                </SportsGameSortingRightItem>
              </SportsGameSortingRightWrap>
            )}
          </SportsGameSortingWrap>
          <SportsGameSearchWrap>
            <span>검색 : </span>
            <input
              type="text"
              name="searchTeamName"
              value={searchKeyword}
              placeholder="국가/리그/팀"
              onChange={e => {
                setSearchKeyword(e.target.value)
              }}
            />
            <FaSearchPlus
              onClick={() => {
                onClickSearchKeywordHandler()
              }}
            />
          </SportsGameSearchWrap>
        </div>
      </SportMenuFuncBox>
    </HeaderWrap>
  )
}

export default SportsOverseasHeader

const HeaderWrap = styled.div`
  display: inline-block;
  width: 100%;
  padding: 0;
  margin-top: 48px;
  margin-bottom: 7px;
  box-sizing: border-box;
  position: relative;
  font-family: 'Malgun Gothic', dotum;
`
const SportsGameHeaderInnerBox = styled.div`
  width: 100%;
  overflow: hidden;
  display: block;
`

const SportsEventTypeListBox = styled.div`
  width: calc(100% - 32px);
  float: left;
  display: block;
`

const SportsEventTypeItemBox = styled.div`
  float: left;
  background: #1c1f21;
  border-radius: 16px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  margin-right: 16px;
  min-width: 120px;
  width: calc(16.6% - 16px);
  transition: background-color 0.1s ease-out 0s, color 0.1s ease-out 0s;
  display: block;
  padding-right: 20px;

  // active 일 때,
  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
 `}

  &:hover {
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }
`

const SportsEventTypeIcon = styled.div`
  float: left;
  margin-right: 12px;
  display: block;

  img {
    margin-top: 3px;
    width: 16px;
    height: 16px;
  }
`

const SportsEventTypeTitle = styled.div`
  float: left;
  margin-top: 2px;
  display: block;
`

const SportsEventTypeCount = styled.div`
  float: right;
  margin-top: 2px;
  display: block;
`

const SportsEventAddListBox = styled.div`
  float: right;
  cursor: pointer;

  img {
    cursor: pointer;
    margin-top: 3px;
    border: 0;
  }
`

const SportsEventAddListBoxContent = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 999;
  background: rgb(28, 31, 33);
  padding: 8px;
  border-radius: 6px;
  display: block;
`

const SportsEventAddListBoxClose = styled.div`
  display: inline-block;
  width: 100%;
  padding: 12px;

  img {
    width: 14px;
    height: 14px;
    float: right;
    cursor: pointer;
  }
`

const SportsEventAddListBoxItem = styled.div`
  padding: 12px;
  background: #272b2e;
  border-radius: 6px;
  display: inline-block;
  width: 100%;
  cursor: pointer;

  ${props =>
    props.active &&
    `
    background: ${HermesMainBorder};
    color: #fff; 
    `}

  &:hover {
    background: ${HermesMainBorder};
    color: #fff;
  }
`

const SportsEventAddListBoxItemIcon = styled.div`
  float: left;
  margin-right: 12px;

  img {
    margin-top: 3px;
    width: 16px;
    height: 16px;
  }
`
const SportsEventAddListBoxItemTitle = styled.div`
  color: #fff;
  float: left;
  margin-right: 40px;
  margin-top: 1px;
  cursor: pointer;
`
const SportsEventAddListBoxItemCount = styled.div`
  float: right;
  margin-top: 1px;
`

const SportsMenuSliderBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 5px;
`

const SportsMenuSlider = styled(Slider)`
  position: relative;
  width: 100%;

  .slick-track {
    float: left;
  }

  .slick-slide {
    width: 80px !important;
  }

  .slick-next {
    right: 10px;
    z-index: 300;
  }
  .slick-prev {
    left: 10px;
    z-index: 300;
  }
`

const SportsMenuSliderItem = styled.div`
  width: 100%;
  height: 59px;
  float: left;
  margin-left: 1px;
  cursor: pointer;

  ${props =>
    props.$icon &&
    `
  background: ${
    props.active
      ? `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}-hover.png)`
      : `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}.png)`
  } no-repeat center;  
`}

  &:hover {
    background: ${props => props.$icon && `url(${process.env.PUBLIC_URL}/img/sports/${props.$icon}-hover.png)`}
      no-repeat center;
  }
`

const SportsMenuSliderItemCount = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: ${props => (props.active ? '#f8d346' : '#fff')};
  font-size: 14px;
  float: right;
  padding: 3px 5px 3px 5px;
  margin: 1px;
`

const SportMenuFuncBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`

// 다폴더 보너스 배당
const SportsGameMultiFolderWrap = styled.div`
  width: 50%;
  border: 2px solid #222733;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 8px;
  position: relative;
`

const SportsGameMultiFolderHeader = styled.div`
  font-size: 12px !important;
  float: left;
  display: flex;
  align-items: center;
  gap: 5px;
`

const SportsGameMultiFolderHeaderIcon = styled(FaStar)`
  color: ${HermesMainHover2};
`

const SportsGameMultiFolderContent = styled.div`
  width: 100%;
  height: 50px;
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
`

const SportsGameMultiFolderContentItem = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background: rgba(29, 29, 29, 1);
  border: solid 1px ${HermesMainBorder};
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;

  span {
    margin-left: 10px;
    float: left;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
  }
  div {
    color: ${HermesMainText};
    font-weight: 700;
  }

  ${props =>
    props.isActive &&
    `
    border: solid 2px ${HermesMainText};

    span {
     
    }
    div {
      color: rgba(255, 192, 26, 1);
    }
    `}

  img {
    width 20px;
    height: 20px;
  }
`

const SportsGameMultiFolderImg = styled.img`
  width: 22px;
  height: auto;
`

// 배당
const SportsGameMultiFolderContentOdd = styled.div`
  background: #0c0c0c;
  width: 33px;
  height: 33px;
  color: #ffba00;
  border-radius: 50%;
  float: right;
  font-weight: 400;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

// 정렬
const SportsGameSortingWrap = styled.div`
  width: 100%;
  border-bottom: 2px solid #222733;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 6px 8px;
  position: relative;
  display: flex;
`

const SportsGameSortingLeftWrap = styled.div`
  width: 50%;
  gap: 10px;
  display: flex;
`

const SportsGameSortingRightItem = styled.div`
  color: white;
  min-width: max-content;
  height: 35px;
  font-weight: 400;
  border-radius: 18px;
  padding: 3px 6px;
  display: flex;
  text-align: center;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect}
    color: ${HermesMainText} !important;
    `}
`

const SportsGameSortingRightWrap = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
`

const SportsGameSortingItem = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  background: rgba(29, 29, 29, 1);
  border: none;
  border-radius: 5px;
  padding: 0 15px;
  cursor: pointer;

  span {
    float: left;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
  }

  ${props =>
    props.isActive &&
    `
    border: solid 1px rgba(255, 192, 26, 1);
    `}
`

// 검색
const SportsGameSearchWrap = styled.div`
  width: 100%;
  background: rgba(48, 48, 48, 1);
  border-radius: 3px;
  padding: 6px 8px;
  position: relative;
  display: flex;
  gap: 5px;
  justify-content: right;
  align-items: center;
  font-weight: 400;
  color: #fff;
  font-size: 13px;

  input {
    background: rgb(29, 29, 29);
    height: 30px;
    border: none;
    color: ${HermesMainText};
    font-weight: 700;
    font-size: 13px;
    padding-left: 5px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 1px ${HermesMainBorder};
    }
  }
  svg {
    width: 25px;
    height: 20px;
    color: ${HermesMainText};
    &:hover {
      cursor: pointer;
    }
  }
`
