import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import {
  cancelSportsBettingHistory,
  deleteSportsBettingHistory,
  searchSportsBettingHistory,
} from '../../api/game/sportsGameApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import CustomPagination from '../../shared/components/CustomPagination'
import { ListButton, MobileListButton, MoneyFormModifyButtomInfo } from '../../shared/components/MoneyCustomElement'
import { convertToKstByFormat } from '../../utils/dateTime'
import { SportsResultStatusEnums } from '../../utils/enums/SportsGame/SportsGameEnums'
import { commonReg2 } from '../../utils/validate/commonValidate'
import CustomLoading from '../../containers/CustomLoading'
import MobileBettingHistoryRadio from './MobileBettingHistoryRadio'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../utils/palette'
import { CustomButtonInfos, CustomDeleteButton } from './MobileMiniGameBettingHistory'

const MobileSportsBettingHistory = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [sportsBettingHistoryParams, setSportsBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setSportsBettingHistoryParams(prev => ({
      page,
      size,
    }))
  }, [page, size])

  const [totalElements, setTotalElements] = useState(0)
  const [sportsBettingHistoryContent, setSportsBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchSportsBettingHistory = async params => {
    await searchSportsBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setSportsBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchSportsBettingHistory(sportsBettingHistoryParams)
  }, [sportsBettingHistoryParams])

  /**
   * 페이징처리
   */
  const sportsBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (sportsBettingHistoryWrapRef.current) {
      sportsBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getMarketIconComponent = (marketName, oddName) => {
    switch (true) {
      case marketName.includes('승무패') || marketName.includes('승패'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_1x2.png`}
            alt=""
            style={{ width: '10px', height: '7px' }}
          />
        )
      case marketName.includes('핸디'):
        return (
          // <img src={`${process.env.PUBLIC_URL}/img/sports/icon_h.png`} alt="" style={{ width: '7px', height: '7px' }} />
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_handy.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('언더'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_under.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      case marketName.includes('언더오버') && oddName.includes('오버'):
        return (
          <img
            src={`${process.env.PUBLIC_URL}/img/sports/icon_over.gif`}
            alt=""
            style={{ width: '12px', height: '12px' }}
          />
        )
      default:
    }
  }

  const getOddNameKrComponent = (marketName, oddNameKr, pointName) => {
    switch (true) {
      case oddNameKr === '홀':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr === '짝':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr === '예':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr === '아니오':
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr.includes('/언더'):
        return <span>&nbsp; [{oddNameKr}]</span>
      case oddNameKr.includes('/오버'):
        return <span>&nbsp; [{oddNameKr}]</span>
      case marketName.includes('/양팀모두득점') && oddNameKr.includes('예'):
        return <span>&nbsp; [{oddNameKr}]</span>
      case marketName.includes('/양팀모두득점') && oddNameKr.includes('아니오'):
        return <span>&nbsp;[{oddNameKr}]</span>
      case marketName.includes('언더오버 3-Way') && oddNameKr.includes('언더'):
        return <span>&nbsp;[{pointName}]</span>
      case marketName.includes('언더오버 3-Way') && oddNameKr.includes('오버'):
        return <span>&nbsp;[{pointName}]</span>
      case marketName.includes('핸디캡 3-Way') && oddNameKr.includes('홈'):
        return <span>&nbsp;[{pointName}]</span>
      case marketName.includes('핸디캡 3-Way') && oddNameKr.includes('원정'):
        return <span>&nbsp;[{pointName}]</span>
      default:
    }
  }

  const onClickBettingHistoryCancelHandler = item => {
    // 현재시간
    const currentTime = new Date(moment().tz('Asia/Seoul').format())
    // 취소 가능 시간
    const availableCancelTime = new Date(item.availableCancelTime)

    if (currentTime >= availableCancelTime) {
      alert('해당 배팅의 취소가능 시간이 지나서 배팅 취소가 불가능합니다.')
      return
    }

    // 경기가 시작된 것이 하나라도 있는 지
    const findIdx = item.detailBettingLogs.findIndex(log => new Date(log.startAt) < currentTime)

    if (findIdx !== -1) {
      // 이미 시작된 경기가 존재하는 상황
      alert('이미 시작된 경기가 있어, 배팅 취소가 불가능합니다.')
      return
    }

    setLoading(true)
    setLoadingInfo('배팅 취소 처리중입니다.')

    cancelSportsBettingHistory({
      sportsBettingLogKey: item.sportsBettingLogKey,
      bettingAmount: item.bettingAmount,
    })
      .then(res => {
        alert('해당 배팅건에 대해 취소 처리 되었습니다.')
        fetchSportsBettingHistory(sportsBettingHistoryParams)
        dispatch(fetchMemberInfoAction())
        setLoading(false)
        setLoadingInfo('')
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'SPORTS-1017':
            alert(`${message}`)
            break
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('해당 배팅건에 대해 취소 처리 실패하였습니다.')
        }

        setLoading(false)
        setLoadingInfo('')
      })
  }
  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteSportsBettingHistory({
        sportsBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSportsBettingHistory(sportsBettingHistoryParams)
        })
    }
  }

  return (
    <BettingHistoryTableWrap className="sports-container" ref={sportsBettingHistoryWrapRef}>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo} />
        </SportsGamePageLoadingOverlay>
      )}
      {sportsBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          스포츠 배팅내역이 없습니다.
          {/* <SportsGameEmptyInfoBox></SportsGameEmptyInfoBox> */}
        </NoContentDiv>
      ) : (
        <>
          {sportsBettingHistoryContent.map((item, index) => (
            <BettingHistoryTable key={item.sportsBettingLogKey}>
              <BettingHistoryTopHeaderTr>
                <MobileBettingHistoryRadio
                  sportsBettingLogKey={item.sportsBettingLogKey}
                  sportsResultStatus={item.sportsResultStatus}
                  checkRadio={checkRadio}
                  setCheckRadio={setCheckRadio}
                />
                {new Date(item.availableCancelTime) > new Date(moment().tz('Asia/Seoul').format()) && (
                  <BettingCancelBox>
                    <BettingCancelButton
                      onClick={() => {
                        onClickBettingHistoryCancelHandler(item)
                      }}
                    >
                      배팅 취소
                    </BettingCancelButton>
                  </BettingCancelBox>
                )}
              </BettingHistoryTopHeaderTr>
              <BettingHistoryTableBody>
                {/* 폴더 수만큼 생겨야 함 */}
                {item.detailBettingLogs.map(detail => (
                  <>
                    <BettingHistoryHeaderTr>
                      <BettingHistoryTh width="25">
                        <span>{convertToKstByFormat(detail.startAt, 'MM-DD HH:mm')}</span>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="37">
                        <span>
                          {' '}
                          {detail.sportsType !== 'ETC' && (
                            <img
                              src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${detail.sportsType}.png`}
                              alt=""
                            />
                          )}
                          {detail.leagueName || '-'}
                        </span>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="38">
                        <span style={{ color: `${HermesMainText}`, fontWeight: '400' }}>{detail.marketName}</span>
                      </BettingHistoryTh>
                    </BettingHistoryHeaderTr>
                    {detail.awayOddName ? (
                      <BettingHistoryTableTr key={detail.sportsDetailBettingLogKey}>
                        <BettingHistoryContentBox>
                          <ResultBox bettingResult={detail.sportsResultStatus}>
                            <span>{SportsResultStatusEnums[detail.sportsResultStatus]}</span>
                          </ResultBox>
                          <BettingHistoryContentItemBox
                            width="39"
                            active={detail.homeOddName === detail.selectedOddName}
                          >
                            <BettingHistoryContentItemDetailInfo1 textAlign="left">
                              <span>{detail.homeName}</span>
                              {getOddNameKrComponent(detail.marketName, detail.homeOddNameKr, detail.pointName)}
                            </BettingHistoryContentItemDetailInfo1>
                            <BettingHistoryContentItemDetailInfo2 textAlign="right">
                              {getMarketIconComponent(detail.marketName, detail.homeOddNameKr)}
                              <span style={{ marginLeft: '5px' }}>{detail.homeOddValue}</span>
                            </BettingHistoryContentItemDetailInfo2>
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="10" active={detail.selectedOddName === 'Draw'}>
                            <span style={{ marginBottom: '5px' }}>
                              {(detail.drawOddName && detail.drawOddValue) || detail.pointName || 'VS'}
                            </span>
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox
                            width="39"
                            active={detail.awayOddName === detail.selectedOddName}
                          >
                            <BettingHistoryContentItemDetailInfo2 textAlign="left">
                              <span style={{ marginRight: '5px' }}>{detail.awayOddValue}</span>
                              {getMarketIconComponent(detail.marketName, detail.awayOddNameKr)}
                            </BettingHistoryContentItemDetailInfo2>
                            <BettingHistoryContentItemDetailInfo1 textAlign="right">
                              <span>{detail.awayName}</span>
                              {getOddNameKrComponent(detail.marketName, detail.awayOddNameKr, detail.pointName)}
                            </BettingHistoryContentItemDetailInfo1>
                          </BettingHistoryContentItemBox>
                          <BettingHistoryContentItemBox width="12">
                            {detail.marketName === '보너스 배당' ? (
                              <span style={{ fontWeight: '600', color: `${HermesMainText}` }}>1:0</span>
                            ) : (
                              <span style={{ fontWeight: '600', color: `${HermesMainText}` }}>
                                {detail.resultScore || '-'}
                              </span>
                            )}
                          </BettingHistoryContentItemBox>
                        </BettingHistoryContentBox>
                      </BettingHistoryTableTr>
                    ) : (
                      <BettingHistoryTableTr key={detail.sportsDetailBettingLogKey}>
                        <BettingHistoryContentBox>
                          <ResultBox bettingResult={detail.sportsResultStatus}>
                            <span>{SportsResultStatusEnums[detail.sportsResultStatus]}</span>
                          </ResultBox>
                          <BettingHistoryContentItemBox
                            width="90"
                            active={detail.homeOddName === detail.selectedOddName}
                          >
                            <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                              {detail?.marketName === '보너스 배당' && (
                                <SportsEventTitleTypeImg
                                  src={`${process.env.PUBLIC_URL}/img/sports/bonus-folder.png`}
                                  style={{ width: '25px', height: '25px', marginBottom: '-7px' }}
                                  alt=""
                                />
                              )}
                              {detail.homeName && (
                                <span style={{ marginRight: '10px' }}>
                                  {detail.homeName} VS {detail.awayName}
                                </span>
                              )}

                              {detail.selectedOddNameKr && !detail.marketName.includes('정확한') && (
                                <span style={{ marginRight: '5px' }}> [{detail.selectedOddNameKr}]</span>
                              )}
                              {detail.pointName && <span style={{ marginRight: '5px' }}> [{detail.pointName}]</span>}
                              <span style={{ fontWeight: '600' }}>{detail.homeOddValue}</span>
                            </BettingHistoryLongContentItemDetailInfo1>
                          </BettingHistoryContentItemBox>

                          <BettingHistoryContentItemBox width="12.7">
                            {detail.marketName === '보너스 배당' ? (
                              <span style={{ fontWeight: '600', color: `${HermesMainText}` }}>1:0</span>
                            ) : (
                              <span style={{ fontWeight: '600', color: `${HermesMainText}` }}>
                                {detail.resultScore || '-'}
                              </span>
                            )}
                          </BettingHistoryContentItemBox>
                        </BettingHistoryContentBox>
                      </BettingHistoryTableTr>
                    )}
                  </>
                ))}
                <BettingHistoryBottomTr>
                  <BettingHistoryBottomContentBox>
                    <BettingHistoryContentBoxFooterInfo1>
                      <span style={{ color: '#a6a6a6' }}>배팅시간 :</span>{' '}
                      {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                    </BettingHistoryContentBoxFooterInfo1>
                    <BettingHistoryContentBoxFooterInfo2>
                      <span style={{ color: '#a6a6a6' }}>배팅액 : </span>
                      {item.bettingAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo2>
                    <BettingHistoryContentBoxFooterInfo3>
                      <span style={{ color: '#a6a6a6' }}>배당 : </span>
                      {item.totalOddValue}
                    </BettingHistoryContentBoxFooterInfo3>
                  </BettingHistoryBottomContentBox>
                  <BettingHistoryBottomContentBox>
                    <BettingHistoryContentBoxFooterInfo2>
                      <span style={{ color: '#a6a6a6' }}>예상 적중액 : </span>
                      {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo2>
                    <BettingHistoryContentBoxFooterInfo2 style={{ color: `${HermesMainText}` }}>
                      <span style={{ color: '#a6a6a6' }}>적중액 : </span>
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo2>
                    <BettingHistoryContentBoxFooterInfo3 bettingResult={item.sportsResultStatus}>
                      <span style={{ color: '#a6a6a6' }}>결과 : </span>
                      {SportsResultStatusEnums[item.sportsResultStatus]}
                    </BettingHistoryContentBoxFooterInfo3>
                  </BettingHistoryBottomContentBox>
                </BettingHistoryBottomTr>
                {/* <BettingHistoryBottomTr style={{ borderTop: 'none' }}>
                  
                </BettingHistoryBottomTr> */}
              </BettingHistoryTableBody>
            </BettingHistoryTable>
          ))}
        </>
      )}
      {sportsBettingHistoryContent.length === 0 ? (
        <></>
      ) : (
        <>
          <ListWrap>
            <CustomDeleteButton
              onClick={() => {
                setCheckRadio(
                  sportsBettingHistoryContent
                    .filter(value => value.sportsResultStatus !== 'ING')
                    .map(value => value.sportsBettingLogKey),
                )
              }}
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton>
          </ListWrap>
          {totalElements > 0 && (
            <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          )}
        </>
      )}
    </BettingHistoryTableWrap>
  )
}

export default MobileSportsBettingHistory

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-right: 10px;
  gap: 10px;
`

const ResultBox = styled.div`
  position: absolute;
  display: block;
  padding: 0px;
  text-align: center;
  z-index: 150;
  width: 60px !important;
  height: 14px !important;
  line-height: 14px;
  bottom: 1px;
  left: 50%;
  margin-left: -56px;
  margin-bottom: -1px;
  span {
    border-radius: 36px 36px 0px 0px;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: bold;
    background: #fff;
    color: ${props => getColor(props.bettingResult)};
    border: 1px solid #d5d4d3;
    border-bottom: 0px;
  }
`
const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: black;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
`

const BettingHistoryTh = styled.th`
  border: none !important;
  width: ${props => props.width && `${props.width}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;

  span {
    padding-left: 2px;
    width: 100%;
    font-weight: 300;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    img {
      width: 15px;
      height: 15px;
      margin-right: 2px;
      margin-bottom: -3px;
    }
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Pretendard', Dotum, Helvetica, 'Apple SD Gothic Neo', Sans-serif;
  font-weight: 600;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo1 = styled.span`
  width: 45%;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  width: 45%;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo3 = styled.span`
  width: 24%;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;

  span {
  }
`

const BettingHistoryBottomContentBox = styled.div`
  padding-left: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 22px;
  span {
  }
`

const BettingHistoryBottomTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const BettingHistoryContentItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-size: 11px;
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: ${HermesMainSelect};
    color: ${HermesMainText};
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 20%;
  position: relative;
  color: ${HermesMainText};
  margin-left: auto;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryLongContentItemDetailInfo1 = styled.div`
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 60%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 39%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;

  span {
    font-size: 11px;
  }
`
