import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deleteVirtualGameBettingHistory, searchVirtualGameBettingHistory } from '../../api/game/virtualGameApi'
import CustomLoading from '../../containers/CustomLoading'
import MobileCustomPagination from '../../shared/components/MobileCustomPagination'
import { convertToKstByFormat } from '../../utils/dateTime'
import {
  FixtureBetResult,
  VirtualGameBettingTypeKr,
  VirtualGameLeagueTypeKr,
  VirtualGameResultStatusEnums,
  VirtualGameSportType,
  VirtualResultSettlementTypeEnums,
} from '../../utils/enums/VirtualGame/VirtualGameEnums'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../utils/palette'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MobileBettingHistoryRadio from './MobileBettingHistoryRadio'
import { CustomButtonInfos, CustomDeleteButton } from './MobileMiniGameBettingHistory'
import MobileVirtualGameBettingHistoryRadio from './MobileVirtualGameBettingHistoryRadio'

const MobileVirtualGameBettingHistory = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [virtualGameBettingHistoryParams, setVirtualGameBettingHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setVirtualGameBettingHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [virtualGameSportType, setVirtualGameSportType] = useState(null)

  const onClickVirtualGameTypeHandler = type => {
    setVirtualGameSportType(type)

    setVirtualGameBettingHistoryParams(prev => ({
      ...prev,
      page: 0,
      virtualGameSportType: type || null,
    }))
  }

  const [totalElements, setTotalElements] = useState(0)
  const [virtualGameBettingHistoryContent, setVirtualGameBettingHistoryContent] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingInfo, setLoadingInfo] = useState('')

  const fetchVirtualGameBettingHistory = async params => {
    await searchVirtualGameBettingHistory(params)
      .then(res => {
        setTotalElements(res.data.totalElements)
        setVirtualGameBettingHistoryContent(res.data.content)
        setLoading(false)
      })
      .catch(error => setLoading(false))
  }

  useEffect(() => {
    fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
  }, [virtualGameBettingHistoryParams])

  /**
   * 페이징처리
   */
  const virtualGameBettingHistoryWrapRef = useRef(null)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElements / size))
  }, [totalElements, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (virtualGameBettingHistoryWrapRef.current) {
      virtualGameBettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)

      deleteVirtualGameBettingHistory({
        virtualBettingLogKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchVirtualGameBettingHistory(virtualGameBettingHistoryParams)
        })
    }
  }

  const getFixtureRowInfoCompo = detailItem => {
    switch (detailItem.marketId) {
      case 1:
      case 2:
        // 승무패
        // 승패
        return <>{VirtualGameBettingTypeKr[detailItem.selectedBetType]}</>
      case 3:
        // 핸디
        return (
          <>
            {VirtualGameBettingTypeKr[detailItem.selectedBetType]} {detailItem.handicapPoint}
          </>
        )
      case 4:
        // 언더오버
        return (
          <>
            {VirtualGameBettingTypeKr[detailItem.selectedBetType]} {detailItem.overUnderPoint}
          </>
        )
      case 6:
      case 7:
        // 총 골수
        // 더블찬스
        return <>{VirtualGameBettingTypeKr[detailItem.selectedBetType]}</>
      case 8:
        // 우승 맞추기
        return (
          <>
            [{detailItem.selectedRaceNumber}] {detailItem.selectedRaceName}{' '}
            {detailItem.selectedRaceRider && `, ${detailItem.selectedRaceRider}`}
          </>
        )
      case 9:
        // 홀짝
        return <>{VirtualGameBettingTypeKr[detailItem.selectedBetType]}</>
      default:
        return ''
    }
  }

  const getFixtureResultCompo = detailItem => {
    switch (detailItem.sportType) {
      case 'VIRTUAL_SOCCER':
        return (
          <BettingHistoryTableTr>
            <BettingHistoryContentBox>
              <ResultBox bettingResult={detailItem.betSettlementType}>
                <span>{VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}</span>
              </ResultBox>

              <BettingHistoryContentItemBox width="80">
                <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                  <span>
                    {detailItem.homeName} VS {detailItem.awayName}
                  </span>
                </BettingHistoryLongContentItemDetailInfo1>
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="12.5" active>
                {getFixtureRowInfoCompo(detailItem)}
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="10">{detailItem.selectedBetPrice}</BettingHistoryContentItemBox>
              {detailItem.betSettlementType === 'NOT_SETTLED' ? (
                <BettingHistoryContentItemBox width="10">-</BettingHistoryContentItemBox>
              ) : (
                <BettingHistoryContentItemBox width="10">
                  {detailItem.homeScore} : {detailItem.awayScore}
                </BettingHistoryContentItemBox>
              )}
            </BettingHistoryContentBox>
          </BettingHistoryTableTr>
        )
      case 'VIRTUAL_BASKETBALL':
        return (
          <BettingHistoryTableTr>
            <BettingHistoryContentBox>
              <ResultBox bettingResult={detailItem.betSettlementType}>
                <span>{VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}</span>
              </ResultBox>

              <BettingHistoryContentItemBox width="80">
                <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                  <span>
                    {detailItem.homeName} VS {detailItem.awayName}
                  </span>
                </BettingHistoryLongContentItemDetailInfo1>
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="12.5" active>
                {getFixtureRowInfoCompo(detailItem)}
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="10">{detailItem.selectedBetPrice}</BettingHistoryContentItemBox>
              {detailItem.betSettlementType === 'NOT_SETTLED' ? (
                <BettingHistoryContentItemBox width="10">-</BettingHistoryContentItemBox>
              ) : (
                <>
                  <BettingHistoryContentItemBox width="30">
                    {detailItem.marketId === 2 && <>승패: {FixtureBetResult[detailItem.winDrawLoseResult]}</>}
                    {detailItem.marketId === 3 && <>핸디: {FixtureBetResult[detailItem.handicapResult]}</>}
                    {detailItem.marketId === 4 && <>언더/오버: {FixtureBetResult[detailItem.overUnderResult]}</>}
                  </BettingHistoryContentItemBox>
                </>
              )}
            </BettingHistoryContentBox>
          </BettingHistoryTableTr>
        )
      case 'VIRTUAL_BASEBALL':
        return (
          <BettingHistoryTableTr>
            <BettingHistoryContentBox>
              <ResultBox bettingResult={detailItem.betSettlementType}>
                <span>{VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}</span>
              </ResultBox>

              <BettingHistoryContentItemBox width="80">
                <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                  <span>
                    {detailItem.homeName} VS {detailItem.awayName}
                  </span>
                </BettingHistoryLongContentItemDetailInfo1>
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="12.5" active>
                {getFixtureRowInfoCompo(detailItem)}
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="10">{detailItem.selectedBetPrice}</BettingHistoryContentItemBox>
              {detailItem.betSettlementType === 'NOT_SETTLED' ? (
                <BettingHistoryContentItemBox width="10">-</BettingHistoryContentItemBox>
              ) : (
                <BettingHistoryContentItemBox width="10">
                  {detailItem.homeScore} : {detailItem.awayScore}
                </BettingHistoryContentItemBox>
              )}
            </BettingHistoryContentBox>
          </BettingHistoryTableTr>
        )
      case 'VIRTUAL_GREYHOUNDS':
        return (
          <BettingHistoryTableTr>
            <BettingHistoryContentBox>
              <ResultBox bettingResult={detailItem.betSettlementType}>
                <span>{VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}</span>
              </ResultBox>

              <BettingHistoryContentItemBox width="80">
                <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                  {detailItem.raceFirstName ? (
                    <span>
                      우승: [{detailItem.raceFirstNumber}] {detailItem.raceFirstName}
                    </span>
                  ) : (
                    '-'
                  )}
                </BettingHistoryLongContentItemDetailInfo1>
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="30" active>
                {getFixtureRowInfoCompo(detailItem)}
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="10">{detailItem.selectedBetPrice}</BettingHistoryContentItemBox>
            </BettingHistoryContentBox>
          </BettingHistoryTableTr>
        )
      case 'VIRTUAL_HORSE':
        return (
          <BettingHistoryTableTr>
            <BettingHistoryContentBox>
              <ResultBox bettingResult={detailItem.betSettlementType}>
                <span>{VirtualResultSettlementTypeEnums[detailItem.betSettlementType]}</span>
              </ResultBox>

              <BettingHistoryContentItemBox width="80">
                <BettingHistoryLongContentItemDetailInfo1 textAlign="center" style={{ marginTop: '-8px' }}>
                  {detailItem.raceFirstName ? (
                    <span>
                      우승: [{detailItem.raceFirstNumber}] {detailItem.raceFirstName}
                    </span>
                  ) : (
                    '-'
                  )}
                </BettingHistoryLongContentItemDetailInfo1>
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="30" active>
                {getFixtureRowInfoCompo(detailItem)}
              </BettingHistoryContentItemBox>
              <BettingHistoryContentItemBox width="10">{detailItem.selectedBetPrice}</BettingHistoryContentItemBox>
            </BettingHistoryContentBox>
          </BettingHistoryTableTr>
        )
      default:
        return ''
    }
  }

  return (
    <BettingHistoryTableWrap className="sports-container" ref={virtualGameBettingHistoryWrapRef}>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart info={loadingInfo} />
        </SportsGamePageLoadingOverlay>
      )}

      <ListWrap>
        <SubMenuItemListWrap>
          <SubMenuItemBox
            active={!virtualGameSportType}
            onClick={() => {
              onClickVirtualGameTypeHandler(null)
            }}
          >
            전체
          </SubMenuItemBox>
          {Object.entries(VirtualGameSportType).map(([key, value]) => {
            return (
              <SubMenuItemBox
                active={key === virtualGameSportType}
                onClick={() => {
                  onClickVirtualGameTypeHandler(key)
                }}
              >
                {value}
              </SubMenuItemBox>
            )
          })}
        </SubMenuItemListWrap>
      </ListWrap>

      {virtualGameBettingHistoryContent.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          가상게임 배팅내역이 없습니다.
        </NoContentDiv>
      ) : (
        <>
          {virtualGameBettingHistoryContent.map((item, index) => (
            <BettingHistoryTable key={item.virtualBettingLogKey}>
              <BettingHistoryTopHeaderTr>
                <MobileVirtualGameBettingHistoryRadio
                  virtualBettingLogKey={item.virtualBettingLogKey}
                  virtualResultStatus={item.virtualResultStatus}
                  checkRadio={checkRadio}
                  setCheckRadio={setCheckRadio}
                />
              </BettingHistoryTopHeaderTr>
              <BettingHistoryTableBody>
                {/* 폴더 수만큼 생겨야 함 */}
                {item.virtualDetailBettingLogList.map(detail => (
                  <>
                    <BettingHistoryHeaderTr>
                      <BettingHistoryTh width="25">
                        <span>{convertToKstByFormat(detail.startDate, 'MM-DD HH:mm')}</span>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="37">
                        <span>{VirtualGameLeagueTypeKr[detail.leagueType]}</span>
                      </BettingHistoryTh>
                      <BettingHistoryTh width="38">
                        <span style={{ color: `${HermesMainText}`, fontWeight: '400' }}>{detail.marketName}</span>
                      </BettingHistoryTh>
                    </BettingHistoryHeaderTr>

                    {getFixtureResultCompo(detail)}
                  </>
                ))}
                <BettingHistoryBottomTr>
                  <BettingHistoryBottomContentBox>
                    {' '}
                    <BettingHistoryContentBoxFooterInfo1>
                      <span style={{ color: '#a6a6a6' }}>배팅시간 :</span>{' '}
                      {convertToKstByFormat(item.bettingAt, 'MM-DD HH:mm')}
                    </BettingHistoryContentBoxFooterInfo1>
                    <BettingHistoryContentBoxFooterInfo2>
                      <span style={{ color: '#a6a6a6' }}>배팅액 : </span>
                      {item.bettingAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo2>
                  </BettingHistoryBottomContentBox>

                  <BettingHistoryBottomContentBox>
                    <BettingHistoryContentBoxFooterInfo1>
                      <span style={{ color: '#a6a6a6' }}>배당 : </span>
                      {(() => {
                        const {
                          realReduceBettingPerForSingleFolder,
                          realReduceBettingPerForCombination,
                          totalOddValue,
                          originTotalOddValue,
                        } = item
                        const reduceBettingValue =
                          realReduceBettingPerForSingleFolder || realReduceBettingPerForCombination

                        if (!reduceBettingValue) {
                          return <span>{totalOddValue}</span>
                        }

                        return (
                          <>
                            {Number(reduceBettingValue) < 0 ? (
                              <span>
                                ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                {reduceBettingValue?.toFixed(2)})
                              </span>
                            ) : (
                              <span>
                                ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                {reduceBettingValue?.toFixed(2)})
                              </span>
                            )}
                          </>
                        )
                      })()}
                    </BettingHistoryContentBoxFooterInfo1>
                    <BettingHistoryContentBoxFooterInfo2>
                      <span style={{ color: '#a6a6a6' }}>예상 적중액 : </span>
                      {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo2>
                  </BettingHistoryBottomContentBox>
                  <BettingHistoryBottomContentBox>
                    <BettingHistoryContentBoxFooterInfo1 style={{ color: `${HermesMainText}` }}>
                      <span style={{ color: '#a6a6a6' }}>적중액 : </span>
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')} 원
                    </BettingHistoryContentBoxFooterInfo1>
                    <BettingHistoryContentBoxFooterInfo2 bettingResult={item.virtualResultStatus}>
                      <span style={{ color: '#a6a6a6' }}>결과 : </span>
                      {VirtualGameResultStatusEnums[item.virtualResultStatus]}
                    </BettingHistoryContentBoxFooterInfo2>
                  </BettingHistoryBottomContentBox>
                </BettingHistoryBottomTr>
              </BettingHistoryTableBody>
            </BettingHistoryTable>
          ))}
        </>
      )}
      {virtualGameBettingHistoryContent.length === 0 ? (
        <></>
      ) : (
        <>
          <ListWrap>
            <CustomDeleteButton
              onClick={() => {
                setCheckRadio(
                  virtualGameBettingHistoryContent
                    .filter(value => value.virtualResultStatus !== 'ING')
                    .map(value => value.virtualBettingLogKey),
                )
              }}
              type="button"
            >
              <CustomButtonInfos>전체선택</CustomButtonInfos>
            </CustomDeleteButton>
            <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
              <CustomButtonInfos>삭제하기</CustomButtonInfos>
            </CustomDeleteButton>
          </ListWrap>
          {totalElements > 0 && (
            <MobileCustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          )}
        </>
      )}
    </BettingHistoryTableWrap>
  )
}

export default MobileVirtualGameBettingHistory

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  margin-top: 0px;
  padding-bottom: 5px;
  gap: 10px;

  white-space: nowrap !important;
  overflow: auto;

  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  background-color: #1b1c1f;
  padding: 10px 10px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 150px;
  height: 33px;

  &:hover {
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const getColor3 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'white'
  }
}

const CustomSpan = styled.span`
  color: ${props => getColor3(props.sportMarketType)};
`
const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 12px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 100px;
    height: auto;
  }
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '#82b2ff'
    case bettingResult === 'LOSE':
      return '#ff828e'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const ListWrap = styled.div`
  display: flex;
  text-align: right;
  align-items: right;
  justify-content: right;
  padding-right: 10px;
  gap: 10px;
`

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return 'white'
  }
}

const ResultBox = styled.div`
  position: absolute;
  display: block;
  padding: 0px;
  text-align: center;
  z-index: 150;
  width: 60px !important;
  height: 14px !important;
  line-height: 14px;
  bottom: 1px;
  left: 50%;
  margin-left: -56px;
  margin-bottom: -1px;
  span {
    border-radius: 36px 36px 0px 0px;
    width: 100%;
    height: 100%;
    display: block;
    font-weight: bold;
    background: #fff;
    color: ${props => getColor2(props.bettingResult)};
    border: 1px solid #d5d4d3;
    border-bottom: 0px;
  }
`
const BettingHistoryTopHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  background: black;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
  padding: 0px 5px;
`

const BettingHistoryHeaderTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
  height: 25px;
  align-items: center;
`

const BettingHistoryTh = styled.th`
  border: none !important;
  width: ${props => props.width && `${props.width}%`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;

  span {
    padding-left: 2px;
    width: 100%;
    font-weight: 700;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    img {
      width: 15px;
      height: 15px;
      margin-right: 2px;
      margin-bottom: -3px;
    }
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Pretendard', Dotum, Helvetica, 'Apple SD Gothic Neo', Sans-serif;
  font-weight: 600;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px #eec100;
    cursor: pointer;
  }
  th {
    background: rgba(48, 48, 48, 1);
    padding: 2px 2px;
    border-top: 1px solid #888;
    border-right: 1px solid #888;
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead``

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo1 = styled.span`
  width: 50%;
  color: ${props => getColor(props.bettingResult)};
  text-align: left;
  margin-left: 10px;
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  width: 50%;
  color: ${props => getColor(props.bettingResult)};
  text-align: left;
`

const BettingHistoryContentBoxFooterInfo3 = styled.span`
  width: 50%;
  color: ${props => getColor(props.bettingResult)};
  text-align: left;
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;

  span {
  }
`

const BettingHistoryBottomContentBox = styled.div`
  padding-left: 5px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 22px;
  span {
  }
`

const BettingHistoryBottomTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  border-bottom: solid 1px gray;
  border-top: solid 1px gray;
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px gray;
  border-right: solid 1px gray;
  // border-bottom: solid 1px gray;
  &:last-child {
    border-bottom: solid 1px gray;
  }
`

const BettingHistoryContentItemBox = styled.div`
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  &:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
  }

  span {
    font-size: 11px;
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: ${HermesMainSelect};
    color: ${HermesMainText};
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 20%;
  position: relative;
  color: ${HermesMainText};
  margin-left: auto;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryLongContentItemDetailInfo1 = styled.div`
  width: 100%;
  display: table-cell;
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 60%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 2px 1px 2px;
  float: left;

  span {
    font-weight: 300;
  }
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 39%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;

  span {
    font-size: 11px;
  }
`
