import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { ko } from 'date-fns/esm/locale'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import {
  confirmNickName,
  confirmRecommendCode,
  confirmUserId,
  searchRecommendCode,
  siginUp,
} from '../../api/authManage/authManageApi'
import { searchBankList } from '../../api/bank/bankApi'
import { CustomSelect } from '../../shared/components/CustomSelect'
import Error from '../../shared/components/form/Error'
import { ButtonH40 } from '../../shared/components/GoldButton'
import Portal from '../../shared/components/Portal'
import { getRecommendCode } from '../../utils/enums/authority'
import {
  colorBackground,
  colorErrorText,
  colorFormSelect,
  colorFormSelectActive,
  colorFormSelected,
  colorText,
  colorVeryLightRed,
  colorAccent,
  colorAccentHover,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorWhite,
  scrollbarStyles,
  HermesMainBorder,
  HermesMainText,
} from '../../utils/palette'
import { signUpValidate } from '../../utils/validate/authManage/authManageValidate'
import { convertToKst } from '../../utils/dateTime'

/**
 * @see : https://github.com/masroorejaz/react-simple-captcha
 */
const MobileSignUpPopup = ({ onClose, visible }) => {
  useEffect(() => {
    document.body.style = `overflow: hidden`
    return () => {
      document.body.style = `overflow: auto`
    }
  }, [])

  // 아이디
  const [userId, setUserId] = useState('')
  // 아이디 중복확인
  const [isConfirmUserId, setIsConfirmUserId] = useState(false)
  const [isProcessUserId, setIsProcessUserId] = useState(false)
  const onClickConfirmUserIdHandler = () => {
    if (!userId) {
      alert('아이디를 먼저 입력해주세요.')
      return
    }

    if (isProcessUserId) {
      return
    }

    setIsProcessUserId(true)

    confirmUserId({
      userId,
    })
      .then(res => {
        const { result, message } = res
        setIsConfirmUserId(true)
        setIsProcessUserId(false)
        alert(message)
      })
      .catch(error => {
        const { message } = error.response.data
        alert(message)
        setIsConfirmUserId(false)
        setIsProcessUserId(false)
      })
  }

  const [nickName, setNickName] = useState('')

  const [isConfirmNickName, setIsConfirmNickName] = useState(false)
  const [isProcessNickName, setIsProcessNickName] = useState(false)

  const onClickConfirmNickNameHandler = () => {
    if (!nickName) {
      alert('닉네임을 먼저 입력해주세요.')
      return
    }

    if (isProcessNickName) {
      return
    }

    if (userId === nickName) {
      alert('ID와 동일한 닉네임은 불가합니다.')
      return
    }

    const koreanPattern = /[ㄱ-ㅎㅏ-ㅣ가-힣]/ // 한글 문자가 포함되어 있는지 확인하는 정규표현식
    const koreanConsonantsPattern = /[ㄱ-ㅎ]/ // 한글 자음 패턴
    const koreanVowelsPattern = /[ㅏ-ㅣ]/ // 한글 모음 패턴
    const numberPattern = /^[0-9]+$/ // 숫자로만 이루어진지 확인하는 정규표현식

    if (koreanPattern.test(nickName)) {
      // 닉네임에 한글 문자가 포함된 경우
      if (koreanConsonantsPattern.test(nickName) || koreanVowelsPattern.test(nickName)) {
        // 닉네임이 자음 또는 모음으로만 이루어진 경우
        alert('잘못된 닉네임입니다.')
        return
      }
    } else if (numberPattern.test(nickName)) {
      // 닉네임이 숫자로만 이루어진 경우
      alert('숫자로만 이루어진 닉네임은 불가합니다.')
      return
    }

    setIsProcessNickName(true)

    confirmNickName(nickName)
      .then(res => {
        const { result, message } = res
        setIsConfirmNickName(true)
        setIsProcessNickName(false)
        alert(message)
      })
      .catch(error => {
        const { message } = error.response.data
        alert(message)
        setIsConfirmNickName(false)
        setIsProcessNickName(false)
      })
  }

  const [userBirthDayFirst, setUserBirthDayFirst] = useState('')
  const [userBirthDaySecond, setUserBirthDaySecond] = useState('')

  // 비밀번호
  const [password, setPassword] = useState('')
  // 비밀번호 확인
  const [confirmPassword, setConfirmPassword] = useState('')
  // 이름
  const [memberName, setMemberName] = useState('')
  // 연락처
  const [memberPhoneNum, setMemberPhoneNum] = useState('')

  // 은행
  const [bankNameOptionValue, setBankNameOptionValue] = useState(null)
  const [bankNameOption, setBankNameOption] = useState([])
  const onChangeBankNameHandler = option => {
    setBankNameOptionValue(option)
  }

  const [telecomOptionValue, setTelecomOptionValue] = useState('')
  const [telecomOptions, setTelecomOptions] = useState([])
  const onChangeTelecomHandler = option => {
    setTelecomOptionValue(option)
  }

  // 추천 코드
  const [recommendCode, setRecommendCode] = useState()

  // 추천코드 확인
  const [isConfirmRecommendCode, setIsConfirmRecommendCode] = useState(false)

  useEffect(() => {
    if (!visible) return
    searchBankList().then(res => {
      res.data.bankList.map(item =>
        setBankNameOption(prev => [
          ...prev,
          {
            value: item.bankId,
            label: item.bankName,
          },
        ]),
      )
    })

    searchRecommendCode().then(res => {
      const rec = res.data.recommendCode
      const temp = rec || getRecommendCode()
      if (temp) {
        setIsConfirmRecommendCode(true)
      }
      setRecommendCode(temp)
    })

    setTelecomOptions([
      { value: 'LG', label: 'LG' },
      { value: 'SKT', label: 'SKT' },
      { value: 'KT', label: 'KT' },
      { value: 'LG_PLUS', label: 'LG 알뜰폰' },
      { value: 'SKT_PLUS', label: 'SKT 알뜰폰' },
      { value: 'KT_PLUS', label: 'KT 알뜰폰' },
    ])
  }, [visible])

  // 계좌번호
  const [accountNum, setAccountNum] = useState('')

  const [isProcessRecommendCode, setIsProcessRecommendCode] = useState(false)

  const onClickConfirmRecommendCodeHandler = () => {
    if (!recommendCode) {
      alert('추천코드를 먼저 입력하세요')
      return
    }

    if (isProcessRecommendCode) {
      return
    }

    setIsProcessRecommendCode(true)

    confirmRecommendCode({
      recommendCode,
    })
      .then(res => {
        setIsConfirmRecommendCode(true)
        setIsProcessRecommendCode(false)
        alert('확인 되었습니다.')
      })
      .catch(error => {
        setIsConfirmRecommendCode(false)
        setIsProcessRecommendCode(false)
        alert('유효하지 않는 추천코드입니다.')
      })
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  const [siginUpApiLoading, setSiginUpApiLoading] = useState(false)
  const [loginIp, setLoginIp] = useState('')
  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })
  }, [])
  const onSubmit = e => {
    if (siginUpApiLoading) return

    setSiginUpApiLoading(true)

    const body = {
      signUpIp: loginIp,
      userId,
      nickName,
      password,
      memberName,
      memberPhoneNum,
      bankName: bankNameOptionValue?.label,
      telecom: telecomOptionValue?.value,
      accountNum,
      recommendCode: !recommendCode ? null : recommendCode,
      userBirthDayFirst,
      userBirthDaySecond,
    }
    siginUp(body)
      .then(res => {
        alert('회원가입에 성공했습니다. \n잠시 후 승인전화 확인 부탁드립니다.')
        window.fbq('track', 'CompleteRegistration')
        setSiginUpApiLoading(false)
        close()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data
        if (errorCode === 'SIGN-1004') {
          alert(`${message}\n잠시후 다시 시도해주세요.`)
          window.location.replace(window.location.href)
        } else if (errorCode === 'SIGN-1010') {
          alert(`${message}`)
          window.location.reload()
        } else {
          alert('회원가입을 실패했습니다. \n잠시후 다시 시도해주세요.')
          window.location.replace(window.location.href)
        }
        setSiginUpApiLoading(false)
      })
  }

  return (
    <Portal elementId="signup-modal">
      <SignUpModalOverlay visible={visible} />
      <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
            <SignUpModalCloseButton
              onClick={close}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                userId,
                nickName,
                password,
                confirmPassword,
                memberName,
                memberPhoneNum,
                bankName: bankNameOptionValue,
                telecom: telecomOptionValue?.value,
                accountNum,
                recommendCode,
                isConfirmRecommendCode,
                isConfirmUserId,
                isConfirmNickName,
                userBirthDayFirst,
                userBirthDaySecond,
              }}
              validate={signUpValidate}
            >
              {({ handleSubmit, form: { reset } }) => (
                <SignUpModalFormWrap onSubmit={handleSubmit}>
                  <SignUpModalFormGroup style={{ paddingTop: '10px' }}>
                    <SignUpModalFormLabel>아이디</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="userId">
                        {({ input, meta }) => (
                          <>
                            <SignUpModalFormInputWrap>
                              <SignUpModalFormInput
                                {...input}
                                type="text"
                                value={userId}
                                readOnly={isConfirmUserId}
                                placeholder="아이디"
                                autoComplete="off"
                                onChange={e => {
                                  const { value } = e.target
                                  const onlyEnglish = value.replace(/[^A-Za-z0-9]/gi, '')
                                  if (onlyEnglish.length > 15) {
                                    return
                                  }
                                  setUserId(onlyEnglish)
                                }}
                              />
                              <SignUpModalFormIdConfirmButton
                                type="button"
                                disabled={isConfirmUserId}
                                onClick={() => {
                                  onClickConfirmUserIdHandler()
                                }}
                              >
                                <SignUpModalFormIdConfirmButtonInfo>중복 확인</SignUpModalFormIdConfirmButtonInfo>
                              </SignUpModalFormIdConfirmButton>
                            </SignUpModalFormInputWrap>
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                      <SignUpModalFormDescription>* 영문+숫자 15글자 미만</SignUpModalFormDescription>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>닉네임</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="nickName">
                        {({ input, meta }) => (
                          <>
                            <SignUpModalFormInputWrap>
                              <SignUpModalFormInput
                                {...input}
                                type="text"
                                value={nickName}
                                placeholder="닉네임"
                                onChange={e => {
                                  const { value } = e.target
                                  if (value.length > 15) {
                                    return
                                  }
                                  setNickName(value)
                                }}
                                autoComplete="off"
                              />
                              <SignUpModalFormIdConfirmButton
                                type="button"
                                disabled={isConfirmNickName}
                                onClick={() => {
                                  onClickConfirmNickNameHandler()
                                }}
                              >
                                <SignUpModalFormIdConfirmButtonInfo>중복 확인</SignUpModalFormIdConfirmButtonInfo>
                              </SignUpModalFormIdConfirmButton>
                            </SignUpModalFormInputWrap>
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                      <SignUpModalFormDescription>* 15글자 미만으로 가능합니다.</SignUpModalFormDescription>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>암호</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="password">
                        {({ input, meta }) => (
                          <>
                            <SignUpModalFormInputWrap>
                              <SignUpModalFormInput
                                {...input}
                                type="password"
                                inputMode="latin"
                                value={password}
                                autoComplete="new-password"
                                onChange={e => {
                                  setPassword(e.target.value)
                                }}
                                placeholder="비밀번호를 입력하세요"
                              />
                            </SignUpModalFormInputWrap>
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>암호 확인</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="confirmPassword">
                        {({ input, meta }) => (
                          <>
                            <SignUpModalFormInputWrap>
                              <SignUpModalFormInput
                                {...input}
                                type="password"
                                inputMode="latin"
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={e => {
                                  setConfirmPassword(e.target.value)
                                }}
                                placeholder="비밀번호를 입력하세요"
                              />
                            </SignUpModalFormInputWrap>
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>이름</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="memberName">
                        {({ input, meta }) => (
                          <>
                            <SignUpModalFormInputWrap>
                              <SignUpModalFormInput
                                {...input}
                                type="text"
                                value={memberName}
                                autoComplete="off"
                                onChange={e => {
                                  setMemberName(e.target.value)
                                }}
                                placeholder="이름을 입력하세요"
                              />
                            </SignUpModalFormInputWrap>
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                      <SignUpModalFormDescription>
                        * 은행 예금주명으로 기입하여 주시기 바랍니다
                      </SignUpModalFormDescription>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>생년월일</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Field name="userBirthDayFirst">
                          {({ input, meta }) => (
                            <div
                              style={{ width: '70%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                            >
                              <SignUpModalFormInputWrap>
                                <SignUpModalFormInput
                                  {...input}
                                  type="text"
                                  value={userBirthDayFirst}
                                  autoComplete="off"
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    if (onlyNumber.length > 6) {
                                      return
                                    }
                                    setUserBirthDayFirst(onlyNumber)
                                  }}
                                  placeholder="주민번호 앞 6자리"
                                />
                              </SignUpModalFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                            </div>
                          )}
                        </Field>
                        <span style={{ display: 'block', margin: '0 10px', fontSize: '20px' }}> - </span>
                        <Field name="userBirthDaySecond">
                          {({ input, meta }) => (
                            <div style={{ width: '25%', display: 'flex', flexDirection: 'column' }}>
                              <SignUpModalFormInputWrap>
                                <SignUpModalFormInput
                                  {...input}
                                  type="text"
                                  value={userBirthDaySecond}
                                  autoComplete="off"
                                  placeholder="뒤 1자리"
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    if (onlyNumber.length > 1) {
                                      return
                                    }
                                    setUserBirthDaySecond(onlyNumber)
                                  }}
                                />
                              </SignUpModalFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                            </div>
                          )}
                        </Field>
                      </div>

                      <SignUpModalFormDescription>
                        * 주민번호 앞 6자리, 뒤 첫번째 숫자만 입력하세요
                      </SignUpModalFormDescription>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>전화번호</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Field name="telecom">
                          {({ input, meta }) => (
                            <div
                              style={{ width: '45%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                            >
                              <SignUpModalFormInputWrap>
                                <BankSelect
                                  {...input}
                                  value={telecomOptionValue}
                                  onChange={option => {
                                    onChangeTelecomHandler(option)
                                  }}
                                  options={telecomOptions}
                                  placeholder="통신사"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                />
                              </SignUpModalFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                            </div>
                          )}
                        </Field>
                        <span style={{ display: 'block', margin: '0 10px', fontSize: '20px' }}> - </span>
                        <Field name="memberPhoneNum">
                          {({ input, meta }) => (
                            <div style={{ width: '55%', display: 'flex', flexDirection: 'column' }}>
                              <SignUpModalFormInputWrap>
                                <SignUpModalFormInput
                                  {...input}
                                  type="text"
                                  value={memberPhoneNum}
                                  autoComplete="off"
                                  onChange={e => {
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setMemberPhoneNum(onlyNumber)
                                  }}
                                  placeholder="전화번호를 입력하세요"
                                />
                              </SignUpModalFormInputWrap>
                              {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                            </div>
                          )}
                        </Field>
                      </div>

                      <SignUpModalFormDescription>* 띄어쓰기와 - 없이 숫자만 입력하세요</SignUpModalFormDescription>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>은행명</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="bankName">
                        {({ input, meta }) => (
                          <>
                            <BankSelect
                              {...input}
                              value={bankNameOptionValue}
                              onChange={option => {
                                // input.onChange(option)
                                onChangeBankNameHandler(option)
                              }}
                              options={bankNameOption}
                              placeholder="은행을 선택하세요"
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable
                            />
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>계좌번호</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="accountNum">
                        {({ input, meta }) => (
                          <>
                            <SignUpModalFormInputWrap>
                              <SignUpModalFormInput
                                {...input}
                                type="text"
                                value={accountNum}
                                autoComplete="off"
                                onChange={e => {
                                  const { value } = e.target
                                  const onlyNumber = value.replace(/[^0-9]/g, '')
                                  setAccountNum(onlyNumber)
                                }}
                                placeholder="계좌번호를 입력하세요"
                              />
                            </SignUpModalFormInputWrap>
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                      <SignUpModalFormDescription>* 띄어쓰기와 - 없이 숫자만 입력하세요</SignUpModalFormDescription>
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormGroup>
                    <SignUpModalFormLabel>추천코드</SignUpModalFormLabel>
                    <SignUpModalFormField>
                      <Field name="recommendCode">
                        {({ input, meta }) => (
                          <>
                            <SignUpModalFormInputWrap>
                              <SignUpModalFormInput
                                {...input}
                                type="text"
                                readOnly={isConfirmRecommendCode}
                                autoComplete="off"
                                value={recommendCode}
                                onChange={e => {
                                  setRecommendCode(e.target.value)
                                }}
                                placeholder="추천코드를 입력하세요"
                              />
                              <SignUpModalFormIdConfirmButton
                                type="button"
                                style={{
                                  minWidth: '80px',
                                }}
                                disabled={isConfirmRecommendCode}
                                onClick={() => {
                                  onClickConfirmRecommendCodeHandler()
                                }}
                              >
                                <SignUpModalFormIdConfirmButtonInfo>추천코드 확인</SignUpModalFormIdConfirmButtonInfo>
                              </SignUpModalFormIdConfirmButton>
                            </SignUpModalFormInputWrap>
                            {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                          </>
                        )}
                      </Field>
                      {/* <SignUpModalFormDescription>* 추천코드가 없으시면 비워두셔도 됩니다.</SignUpModalFormDescription> */}
                    </SignUpModalFormField>
                  </SignUpModalFormGroup>
                  <SignUpModalFormButtom type="submit">
                    <SignUpModalFormButtomInfo>회원가입</SignUpModalFormButtomInfo>
                  </SignUpModalFormButtom>
                </SignUpModalFormWrap>
              )}
            </Form>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default MobileSignUpPopup

const MoneyExchangeFormGroupIcon = styled.div`
  padding: 6px;
  min-width: max-content;
  height: 100%;
  background: #585858;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
`

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden !important;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  width: 100%;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #000000;

  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
`

const SignUpModalHeader = styled.div`
  position: fixed;
  background: #16171a !important;
  top: 0;
  // border 양옆 1px 생각해서
  width: calc(100% - 2px);
  background-color: black;

  height: 60px;
  // border-top: 1px solid #edae07;
  // border-bottom: 1px solid #7b808c;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 980;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: auto;
  height: 35px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  width: 100%;
  display: flex;
  background: #16171a !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  position: absolute;
  top: 60px;
  padding-bottom: 5px;
`

// sign up form
const SignUpModalFormWrap = styled.form`
  display: flex;
  flex-direction: column;
  // gap: 1px;
  // gap: 33px;
  padding: 0 5px;

  width: 100%;
  max-width: 800px;
`

// sign up form group
const SignUpModalFormGroup = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 10px;
  gap: 5px;
`

// sign up form label
const SignUpModalFormLabel = styled.div`
  min-width: 90px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #fff;
  display: inline-block;
  text-align: center;
`

// sign up form field
const SignUpModalFormField = styled.div`
  width: 70%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  // border: solid 1px blue;
`

// sign up form input wrap
const SignUpModalFormInputWrap = styled.div`
  width: 100%;
  border: 1px solid #4a4a4a;
  position: relative;
  display: flex;
  height: 40px;
`

// sign up form input
const SignUpModalFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;
`

// sign up form description
const SignUpModalFormDescription = styled.span`
  font-style: normal;
  font-weight: 500;
  padding-top: 2px;
  font-size: 11px;
  line-height: 13px;

  color: #d3d3d3;
`

// 버튼 안에 텍스트
const SignUpModalFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  text-align: center;

  color: #000;
`

// sign up from button
const SignUpModalFormButtom = styled.button`
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  font-size: 12px;
  outline: none;

  border: 2px solid ${HermesMainBorder};
  max-width: 400px;

  position: relative;
  left: 50%;
  transform: translateX(-50%);

  height: 50px !important;
`

// 버튼 안에 텍스트
const SignUpModalFormIdConfirmButtonInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;

  color: #000;
`

// Sign up form id confirm button
const SignUpModalFormIdConfirmButton = styled.button`
  position: relative;
  width: 120px;
  height: 40px;
  background: ${HermesMainBorder};
  border-radius: 0px !important;
  font-size: 12px;
  outline: none;
  border: 2px solid ${HermesMainBorder};
`

// Select
export const BankSelect = styled(CustomSelect)`
  font-size: 12px;

  .react-select__control {
    height: 28px;
    border: 1px solid #4a4a4a;
    background-color: transparent;

    &.react-select__control--is-focused,
    &:hover {
      border: solid 2px;
      border-color: white !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: #d3d3d3;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: #d3d3d3 !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    background: rgba(0, 0, 0, 0.7) !important;
    overflow: auto !important;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: ${HermesMainText};
  }

  .react-select__single-value {
    color: #d3d3d3;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    overflow: hidden;
    font-size: 12px;
    color: ${HermesMainText};
    font-weight: 600;
    background: rgba(0, 0, 0, 1) !important;
    border: 1px solid #4a4a4a;
  }

  .react-select__option {
    border-bottom: 1px solid #5e5c5c;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: #5e5c5c !important;
  }
`

const DatePickerWrap = styled.div`
  border-radius: 4px;
  color: #646777;
  outline: none;
  font-size: 12px;
  line-height: 26px;
  transition: border 0.3s;
  background: transparent;
  border: 1px solid #4a4a4a;
  z-index: 101;

  input {
    width: 100%;
    padding: 0px 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    color: rgb(211, 211, 211);
    border: transparent;
    background: transparent;
    outline: none;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: transparent;
    border: 2px solid lightgrey;
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    border-left: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month {
    margin: 0;
    background-color: lightgrey;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: black !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__month-dropdown-container {
    .react-datepicker__month-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__month-dropdown {
    border: 2px solid lightgrey;
    color: black;
  }

  .react-datepicker__year-dropdown {
    border: 2px solid lightgrey;
    color: black;

    .react-datepicker__navigation {
      top: 4px;
    }
    .react-datepicker__navigation--years-upcoming {
      display: none;
    }

    .react-datepicker__navigation--years-previous {
      display: none;
    }
  }

  .react-datepicker__year-dropdown-container {
    .react-datepicker__year-read-view--down-arrow {
      top: 6px;
    }
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`
