import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { socket } from '../../../utils/socket'
import { SocketIOEventName } from '../../../utils/enums/WebSocket/SocketIOEventName'
import CustomLoading from '../../../containers/CustomLoading'
import MobileSportsDomesticHeader from './MobileSportsDomesticHeader'
import MobileSportsDomesticContent from './MobileSportsDomesticContent'
import MobileScrollButton from '../../MobileLayout/MobileScrollButton'
import MobileSportsBettingCartSidebar from '../../MobileLayout/MobileSportsBettingCartSidebar'

const MobileSportsDomesticPage = () => {
  const { domesticLoading, bettingCartLoading } = useSelector(state => {
    const { sportsDomesticInfo, sportsBetting } = state

    return {
      domesticLoading: sportsDomesticInfo.domesticLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })

  return (
    <>
      <SportsPageWrap>
        {(domesticLoading || bettingCartLoading) && (
          <SportsPageLoadingOverlay>
            <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
          </SportsPageLoadingOverlay>
        )}
        <SportsContentWrap>
          <MobileSportsDomesticHeader />
          <MobileSportsDomesticContent />
        </SportsContentWrap>
      </SportsPageWrap>

      <MobileSportsBettingCartSidebar />
      <MobileScrollButton />
    </>
  )
}

export default MobileSportsDomesticPage

const SportsPageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsPageWrap = styled.div`
  width: 100%;
  background: #161719;
  min-height: calc(100vh - 105px);

  padding-bottom: 10px;
  position: relative;
`

const SportsContentWrap = styled.div`
  width: 100%;
`
