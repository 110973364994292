import { MobileTopWrap } from '@/shared/components/MoneyCustomElement'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroupDescription,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { minusDayToKst } from '@/utils/dateTime'
import { commonReg2 } from '@/utils/validate/commonValidate'
import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMemberPointDeposit, searchMemberPointExchange } from '../../api/memberHistory/memberHistoryApi'
import { createMemberPointExchange } from '../../api/memberManage/memberManageApi'
import CustomLoading from '../../containers/CustomLoading'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import { onClickTitleHandler } from '../../redux/modalSlice'
import {
  ContentInfo,
  ContentRowSecondWrap,
  ContentRowSmall,
  ContentRowThirdWrap,
  ContentRowWrap,
  ContentWrap,
  CustomButtonInfo,
  CustomerCenterFormGroup,
  MoneyButton,
  SignUpModalCloseButton,
  SignUpModalContentWrap,
  SignUpModalHeader,
  SignUpModalHeaderLogo,
  SignUpModalInner,
  SignUpModalOverlay,
  SignUpModalWrapper,
  TableBodyTwoWrap,
} from '../../shared/components/MobileMoneyCustomElement'
import Portal from '../../shared/components/Portal'
import {
  HermesMainBorder,
  HermesMainText,
  colorMainText,
  colorMoneyExchangeBottomBorder,
  colorMoneyExchangeSvg,
  colorWalletSvg,
} from '../../utils/palette'
import MobilePointDepositListData from '../mobileMyPage/mobilePointDepositHistory/MobilePointDepositListData'
import './MobilePointExchange.css'
import MobilePointExchangeWeekData from './MobilePointExchangeWeekData'

const MobilePointExchangeForm = ({ visible, onClose }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const [writeLoading, setWriteLoading] = useState(false)
  const dispatch = useDispatch()
  /**
    회원 콤프액 조회
  */
  const { holdingMoney, pointAmount, memberTotalGameMoney } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      pointAmount: memberInfo.memberHoldingPoint,
      memberTotalGameMoney: memberInfo.memberTotalGameMoney,
    }
  })

  /**
    회원 최근 일주일 롤링 전환내역 조회
*/
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [depositPage, setDepositPage] = useState(0)
  const [depositSize, setDepositSize] = useState(10)

  const [pointWeekHistoryParams, setPointWeekHistoryParams] = useState({
    page,
    size,
    startDate: minusDayToKst(7),
  })

  const [pointDepositHistoryParams, setPointDepositHistoryParams] = useState({
    page: depositPage,
    size: depositSize,
  })

  useEffect(() => {
    setPointDepositHistoryParams(prev => ({
      ...prev,
      page: depositPage,
    }))
  }, [depositPage])

  useEffect(() => {
    setPointWeekHistoryParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  // 포인트 입금 내역
  const [depositTotalElement, setDepositTotalElement] = useState(0)
  const [depositContent, setDepositContent] = useState([])

  const fetchSearchMemberPointDeposit = async () => {
    setPageLoading(true)
    await searchMemberPointDeposit(pointDepositHistoryParams)
      .then(response => {
        setDepositTotalElement(response.data.totalElement)
        setDepositContent(response.data.content)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberPointDeposit()
  }, [pointDepositHistoryParams])

  const [pointDepositListData, setPointDepositListData] = useState(MobilePointDepositListData(depositContent))

  useEffect(() => {
    setPointDepositListData(MobilePointDepositListData(depositContent))
  }, [depositContent])

  const [depositRows, setDepositRowsData] = useState(pointDepositListData.tableRowsData)

  useEffect(() => {
    setDepositRowsData(pointDepositListData.tableRowsData)
  }, [pointDepositListData])

  // 포인트 전환 내역
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberPointExchange = async () => {
    setPageLoading(true)
    await searchMemberPointExchange(pointWeekHistoryParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberPointExchange()
  }, [pointWeekHistoryParams])

  useEffect(() => {
    fetchSearchMemberPointDeposit()
    fetchSearchMemberPointExchange()
  }, [])

  const [pointExchangeListData, setPointExchangeListData] = useState(MobilePointExchangeWeekData(content))

  useEffect(() => {
    setPointExchangeListData(MobilePointExchangeWeekData(content))
  }, [content])

  const [rows, setData] = useState(pointExchangeListData.tableRowsData)

  useEffect(() => {
    setData(pointExchangeListData.tableRowsData)
  }, [pointExchangeListData])

  const tableConfig2 = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: depositPage,
    // size
    _pageSize: depositSize,
    // total element
    totalElement: depositTotalElement,
    // setPageIndex
    _setPageIndex: setDepositPage,
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  const onSubmit = e => {
    if (writeLoading) return
    if (pointAmount === 0) return
    setWriteLoading(true)
    createMemberPointExchange()
      .then(res => {
        alert('포인트 전환이 완료되었습니다.')
        setWriteLoading(false)
        fetchSearchMemberPointDeposit()
        fetchSearchMemberPointExchange()
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('포인트 전환이 실패했습니다. 다시 시도해주세요.')
        }
        setWriteLoading(false)
      })
  }

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }
  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <Portal elementId="signup-modal">
      <SignUpModalOverlay visible={visible} />
      <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible}>
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
            <SignUpModalCloseButton
              onClick={close}
              src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
              alt=""
            />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <MobileTopWrap>
              {pageLoading ? (
                <CustomLoading />
              ) : (
                <>
                  <ContentWrap>
                    <ContentRowWrap>
                      <FaChevronLeft
                        onClick={e => {
                          dispatch(onClickTitleHandler('MYPAGE'))
                        }}
                      />
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>스포츠 캐쉬</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {holdingMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                      <ContentRowSmall>
                        <div>
                          <span style={{ color: '#b2b2b2' }}>카지노 캐쉬</span>
                          <span style={{ color: `${HermesMainText}`, fontWeight: '500' }}>
                            {memberTotalGameMoney?.toString().replace(commonReg2, ',')} 원
                          </span>
                        </div>
                      </ContentRowSmall>
                    </ContentRowWrap>
                    <ContentRowThirdWrap>
                      <CustomContentRowBig selectedIndex={selectedIndex}>
                        <div onClick={() => setSelectedIndex(0)}>포인트 전환</div>
                        <div onClick={() => setSelectedIndex(1)}>포인트 전환내역</div>
                        <div onClick={() => setSelectedIndex(2)}>포인트 입금내역</div>
                      </CustomContentRowBig>
                    </ContentRowThirdWrap>
                    <ContentInfo>
                      {selectedIndex === 0 && (
                        <ContentRowSecondWrap>
                          <CustomerCenterFormGroup>
                            <div className="box_wrap01" style={{ marginRight: '5px' }}>
                              <div className="box_box01">
                                <div className="box_font01">보유 포인트</div>
                                <div className="box_font02">{pointAmount?.toString().replace(commonReg2, ',')}</div>
                              </div>
                            </div>
                          </CustomerCenterFormGroup>
                          <MoneyButton variant="primary" onClick={onSubmit}>
                            <CustomButtonInfo>전환하기</CustomButtonInfo>
                          </MoneyButton>
                        </ContentRowSecondWrap>
                      )}
                      {selectedIndex === 1 && (
                        <MoneyDepositBodyTwoWrap>
                          <ReactTableBase
                            columns={pointExchangeListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyDepositBodyTwoWrap>
                      )}
                      {selectedIndex === 2 && (
                        <MoneyDepositBodyTwoWrap>
                          <ReactTableBase
                            columns={pointDepositListData.tableHeaderData}
                            data={depositRows}
                            tableConfig={tableConfig2}
                          />
                        </MoneyDepositBodyTwoWrap>
                      )}
                    </ContentInfo>
                  </ContentWrap>
                </>
              )}
            </MobileTopWrap>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default MobilePointExchangeForm

export const CustomContentRowBig = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    flex: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    height: 62px;
    font-size: 14px;
    color: #fff;
    font-weight: 700 !important;
    width: 110px;
    &:not(:last-child) {
      margin-right: 10px;
    }

    ${({ selectedIndex }) =>
      selectedIndex !== null &&
      `
      &:nth-child(${selectedIndex + 1}) {
        border-bottom: solid 1px ${HermesMainBorder};
      }
    `}
  }
`

// sign up form label
const CompFormLabel = styled.div`
  width: max-content;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

export const CompFormField = styled.div`
  width: 400px;
  display: flex;
`

const CompExchangeWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
  top: 30px;

  svg {
    width: 40px;
    height: 40px;
    color: ${colorWalletSvg};
    margin: 0 5px;
  }
`

const CompExchangeHeaderWrap = styled.div`
  height: 100px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  // border: 1px solid red;
  border-bottom: 1px solid #edae07;
`
const CompExchangeHeaderTwoWrap = styled.div`
  height: 50px;

  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const CompExchangeBodyOneWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2px !important;
  padding: 10px 20px;
  // border: 1px solid red;
  // border-bottom: 1px solid #edae07;
`

const CompExchangeBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  gap: 10px;
`

const CompExchangeBodyThirdWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  padding: 10px 2px;

  gap: 10px;
`

const CompExchangeHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #edae07;
`

const CompExchangeHeaderThirdInfo = styled.span`
  font-style: normal;
  font-size: 12px;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #edae07;
`

export const CompExchangeCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
  color: ${colorMainText};
`

export const CompExchangeInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CompCustomFormInputWrap = styled(FormInputWrap)`
  width: 45%;
  text-align: left;
`
const CompFormGroupIconWrap = styled(FormGroupIcon)`
  width: max-content;
  font-size: 12px;
`

const CompCustomFormGroupIcon = styled(FormGroupIcon)`
  width: 26%;
  font-size: 12px;
`

export const CompFormContainer = styled(FormContainer)`
  ${props =>
    props.horizontal &&
    `

    ${FormGroupField} {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 120px;
    }

    @media screen and (min-width: 480px) {

      ${FormGroupLabel} {
        width: 120px;
      }

      ${FormGroupField} {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;

      }

      ${FormGroupDescription}, ${FormButtonToolbar} {
        margin-left: 0px;
      }
    }
  `}
`

export const CompIoIosArrowBack = styled(IoIosArrowBack)`
  color: ${colorMoneyExchangeSvg} !important;
`

const CompExchangeInfo = styled.div`
  float: left;
  width: 100%;
  border-bottom: solid 2px ${colorMoneyExchangeBottomBorder};
  margin: 0px 0px 7px 0px;
  font-size: 12px;
  font-weight: 700;
  color: ${colorMainText};
`

// endregion

const MoneyDepositBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 0px;

  gap: 10px;

  .react-table__column-header {
    font-size: 12px;
    font-weight: 700;
    padding: 16px 0;
  }

  thead th {
    padding: 0;
  }

  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 14px 0;
    font-size: 12px;
    font-weight: 700;
  }
`
