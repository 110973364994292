import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import CustomAlert from '../../../containers/CustomAlert'
import { onClickMobileSignInHandlerInSideBar, onShowModalHandler } from '../../../redux/modalSlice'
import { ButtonH40 } from '../../../shared/components/GoldButton'
import isLogin from '../../../utils/enums/authority'
import { HermesMainText } from '../../../utils/palette'
import MobileSignUpPopup from '../../MobileLayout/MobileSignUp'
import MobileAuthorityAlertPopup from '../../../containers/Popup/Error/MobileAuthorityAlertPopup'
import { searchHoldemGameUrl } from '../../../api/game/gameApi'
import { setSelectedSportId as setOverseasSelectedSportId } from '../../../redux/sportsOverseasInfoSlice'
import { setSelectedSportId as setDomesticSelectedSportId } from '../../../redux/sportsDomesticInfoSlice'
import { setSelectedSportId as setSpecialSelectedSportId } from '../../../redux/sportsSpecialInfoSlice'
import { setSelectedSportId as setLiveSelectedSportId } from '../../../redux/sportsLiveInfoSlice'

const MobileMainGame = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    noteRedisSize,
    oneToOneRedisSize,
    realTimeDepositList,
    realTimeWithdrawalList,
    domesticSportsUsing,
    domesticSportsName,
    overseasSportsUsing,
    overseasSportsName,
    specialSportsUsing,
    specialSportsName,
    liveSportsUsing,
    liveSportsName,
    revolutionHoldemInfo,
    wildHoldemInfo,
  } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      realTimeDepositList: memberInfo.realTimeDepositList,
      realTimeWithdrawalList: memberInfo.realTimeWithdrawalList,
      domesticSportsUsing: memberInfo.domesticSportsUsing,
      domesticSportsName: memberInfo.domesticSportsName,
      overseasSportsUsing: memberInfo.overseasSportsUsing,
      overseasSportsName: memberInfo.overseasSportsName,
      specialSportsUsing: memberInfo.specialSportsUsing,
      specialSportsName: memberInfo.specialSportsName,
      liveSportsUsing: memberInfo.liveSportsUsing,
      liveSportsName: memberInfo.liveSportsName,
      revolutionHoldemInfo: memberInfo.revolutionHoldemInfo,
      wildHoldemInfo: memberInfo.wildHoldemInfo,
    }
  })

  const { modalTitle, sideMenuModalShow } = useSelector(state => {
    const { modalInfo } = state

    return {
      modalTitle: modalInfo.modalTitle,
      sideMenuModalShow: modalInfo.modalShow,
    }
  })

  const { virtualSportList } = useSelector(state => {
    const { virtualGameInfo } = state

    return {
      virtualSportList: virtualGameInfo.virtualSportList.toSorted((a, b) => a.sportOrderNum - b.sportOrderNum),
    }
  })

  // 로그인 모달
  const [signInModalShow, setSignInModalShow] = useState(false)
  const onCloseSignInModal = () => {
    setSignInModalShow(false)
  }

  // 회원 가입 모달
  const [signUpModalShow, setSignUpModalShow] = useState(false)
  const onShowSignUpModal = () => {
    setSignUpModalShow(true)
  }
  const onCloseSignUpModal = () => {
    setSignUpModalShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMovePageHandler = path => {
    if (!isLogin()) {
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(path)
  }

  const onClickVirtualMenuHandler = () => {
    if (!isLogin()) {
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    const { sportType } = virtualSportList[0] ?? ''

    switch (sportType) {
      case 'VIRTUAL_SOCCER':
        return navigate('/mobile/virtual/soccer')
      case 'VIRTUAL_BASKETBALL':
        return navigate('/mobile/virtual/basketball')
      case 'VIRTUAL_BASEBALL':
        return navigate('/mobile/virtual/baseball')
      case 'VIRTUAL_GREYHOUNDS':
        return navigate('/mobile/virtual/greyhounds')
      case 'VIRTUAL_HORSE':
        return navigate('/mobile/virtual/horse')
      default:
        return navigate('/')
    }
  }

  const [gameLoading, setGameLoading] = useState(false)
  const holdemGameStartHandler = game => {
    if (!isLogin()) {
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }
    if (gameLoading) return

    setGameLoading(true)
    searchHoldemGameUrl()
      .then(res => {
        const { gameUrl } = res.data

        window.open(
          `${gameUrl}`,
          '',
          `height=${window.screen.height}, width=${window.screen.width}, location=no, status=no, scrollbars=yes`,
        )
        setGameLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('잠시후 다시 시도해주세요.')
        }
        setGameLoading(false)
        // alert('잠시후 다시 시도해주세요.')
        // setAlertPopupShow(true)
      })
  }

  const onClickOpenModalHandler = title => {
    if (!isLogin()) {
      dispatch(onClickMobileSignInHandlerInSideBar(true))
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    dispatch(
      onShowModalHandler({
        isModalShow: true,
        title,
      }),
    )
  }

  useEffect(() => {
    if (sideMenuModalShow) {
      // 현재 스크롤 위치를 저장합니다.
      const { scrollY } = window
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
    } else {
      // 저장된 스크롤 위치를 복원합니다.
      const savedScrollY = parseInt(document.body.style.top || '0', 10)
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, savedScrollY * -1)
    }
  }, [sideMenuModalShow])
  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {signUpModalShow && <MobileSignUpPopup visible={signUpModalShow} onClose={onCloseSignUpModal} />}
      <MobileMainGameWrap>
        <MobileMainRowWrap>
          {domesticSportsUsing && (
            <MobileMainButton
              onClick={e => {
                dispatch(setDomesticSelectedSportId(-1))
                onClickMovePageHandler('/mobile/sports/domestic')
              }}
            >
              <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-sports.png`} alt="" />{' '}
              <MobileMainText> {domesticSportsName} </MobileMainText>
            </MobileMainButton>
          )}
          {overseasSportsUsing && (
            <MobileMainButton
              onClick={e => {
                dispatch(setOverseasSelectedSportId(-1))
                onClickMovePageHandler('/mobile/sports/overseas')
              }}
            >
              <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-sports.png`} alt="" />{' '}
              <MobileMainText> {overseasSportsName} </MobileMainText>{' '}
            </MobileMainButton>
          )}
          {specialSportsUsing && (
            <MobileMainButton
              onClick={e => {
                dispatch(setSpecialSelectedSportId(-1))
                onClickMovePageHandler('/mobile/sports/special')
              }}
            >
              <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-sports-special.png`} alt="" />{' '}
              <MobileMainText> {specialSportsName} </MobileMainText>{' '}
            </MobileMainButton>
          )}
          {liveSportsUsing && (
            <MobileMainButton
              onClick={e => {
                dispatch(setLiveSelectedSportId(-1))
                onClickMovePageHandler('/mobile/sports/live')
              }}
            >
              <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-live-sports.png`} alt="" />{' '}
              <MobileMainText> {liveSportsName} </MobileMainText>{' '}
            </MobileMainButton>
          )}
        </MobileMainRowWrap>
        <MobileMainRowWrap>
          <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/live-casino')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-live.png`} alt="" />{' '}
            <MobileMainText> 라이브 카지노 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/slot-casino')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-slot.png`} alt="" />{' '}
            <MobileMainText> 슬롯 카지노 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/mini-game')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-mini.png`} alt="" />{' '}
            <MobileMainText> 미니게임 </MobileMainText>{' '}
          </MobileMainButton>
        </MobileMainRowWrap>
        <MobileMainRowWrap>
          <MobileMainButton
            onClick={e => {
              onClickVirtualMenuHandler()
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-virtual.png`} alt="" />{' '}
            <MobileMainText> 가상게임 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/holdem/revolution')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-holdem.png`} alt="" />{' '}
            <MobileMainText> {revolutionHoldemInfo.title} </MobileMainText>{' '}
          </MobileMainButton>

          <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/holdem/wild-games')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-holdem.png`} alt="" />{' '}
            <MobileMainText> {wildHoldemInfo.title} </MobileMainText>{' '}
          </MobileMainButton>
        </MobileMainRowWrap>
        <MobileMainRowWrap>
          <MobileMainButton
            onClick={e => {
              onClickOpenModalHandler('BETTING')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-betting-history.png`} alt="" />{' '}
            <MobileMainText> 배팅내역 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/notice')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-notice.png`} alt="" />{' '}
            <MobileMainText> 공지/규정 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickOpenModalHandler('ONETOONE')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-onetoone.png`} alt="" />{' '}
            <MobileMainText> 1:1 문의 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickOpenModalHandler('NOTE')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-note.png`} alt="" />{' '}
            <MobileMainText> 쪽지함 </MobileMainText>{' '}
          </MobileMainButton>

          {/* <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/friendlist')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-friend.png`} alt="" />{' '}
            <MobileMainText> 지인추천 </MobileMainText>{' '}
          </MobileMainButton> */}
        </MobileMainRowWrap>
        <MobileMainRowWrap>
          <MobileMainButton
            onClick={e => {
              onClickMovePageHandler('/mobile/event')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-event.png`} alt="" />{' '}
            <MobileMainText> 이벤트 </MobileMainText>{' '}
          </MobileMainButton>
          {/* <MobileMainButton
            onClick={e => {
              onClickOpenModalHandler('COUPONUSE')
            }}
          >
            <MobileMainImg src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-coupon.png`} alt="" />{' '}
            <MobileMainText> 쿠폰사용 </MobileMainText>{' '}
          </MobileMainButton> */}
          <MobileMainButton
            onClick={e => {
              onClickOpenModalHandler('MONEYDEPOSIT')
            }}
          >
            <MobileMainImg
              style={{ width: '27px', height: '27px' }}
              src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-deposit.png`}
              alt=""
            />{' '}
            <MobileMainText> 입금 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickOpenModalHandler('MONEYWITHDRAWAL')
            }}
          >
            <MobileMainImg
              style={{ width: '27px', height: '27px' }}
              src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-withdrawal.png`}
              alt=""
            />{' '}
            <MobileMainText> 출금 </MobileMainText>{' '}
          </MobileMainButton>
          <MobileMainButton
            onClick={e => {
              onClickOpenModalHandler('POINTEXCHANGE')
            }}
          >
            <MobileMainImg
              style={{ width: '27px', height: '27px' }}
              src={`${process.env.PUBLIC_URL}/img/main/main-icon/main-money-exchange.png`}
              alt=""
            />{' '}
            <MobileMainText> 포인트 전환 </MobileMainText>{' '}
          </MobileMainButton>
        </MobileMainRowWrap>
      </MobileMainGameWrap>
      <MobileMainImgWrap>
        <div
          onClick={() => {
            window.open('https://타이슨.com')
          }}
          style={{ width: '100%', height: 'auto' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/main/forever2.png`}
            alt=""
            style={{ width: '300px', height: 'auto' }}
          />
        </div>
        <div
          onClick={() => {
            window.open('https://t.me/tysonsol')
          }}
          style={{ width: '100%', height: 'auto' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/main/forever1.png`}
            alt=""
            style={{ width: '300px', height: 'auto' }}
          />
        </div>
      </MobileMainImgWrap>
    </>
  )
}

export default MobileMainGame

const MobileMainImgWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
`

const MobileMainRowWrap = styled.div`
  width: 100%;
  height: 94px;
  display: flex;
  flex: 20%;
  gap: 5px;
`

const MobileMainButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  // justify-content: center;
  gap: 10px;
  padding: 10px 0 5px 0;
  background: #1b1b1c;
  border-radius: 6px;
  border: none;
  cursor: pointer;
`
const MobileMainImg = styled.img`
  width: 24px;
  height: 28px;
  color: ${HermesMainText};
`

const MobileMainText = styled.div`
  width: 100%;
  height: 40px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  word-break: keep-all;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MobileMainGameWrap = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

// 라이브
const MobileMainGameLiveWrap = styled.div`
  box-sizing: border-box;
  border: solid 1px #edae07;
  position: relative;

  width: 100%;
  height: 140px;
  // background: url(${process.env.PUBLIC_URL}/img/game/main-live-casino.png) no-repeat center;

  // background-size: contain;
`

const MobileMainGameLiveImg = styled.img`
  width: 100%;
  height: 100%;
`

// 라이브 텍스트
const MobileMainGameLiveInfo = styled.span`
  position: absolute;
  right: 15px;
  top: 40px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #ffffff;
`

// 라이브 버튼 글씨
const MobileMainGameLiveStartButtonInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #000000;
`

// 라이브 버튼
const MobileMainGameLiveStartButton = styled(ButtonH40)`
  position: absolute;
  width: 90px;
  height: 30px;
  right: 13px;
  top: 73px;

  // 호버
  &:hover {
    ${MobileMainGameLiveStartButtonInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MobileMainGameLiveStartButtonInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MobileMainGameLiveStartButtonInfo} {
      color: #ffffff4d;
    }
  }
`

// 슬롯
const MobileMainGameSlotWrap = styled.div`
  box-sizing: border-box;
  border: solid 1px #edae07;
  position: relative;
  width: 100%;
  height: 140px;

  //   background: url(${process.env.PUBLIC_URL}/img/game/main-slot-casino.png) no-repeat center;
  //   background-size: contain;
`

const MobileMainGameSlotImg = styled.img`
  width: 100%;
  height: 100%;
`

// 슬롯 텍스트
const MobileMainGameSlotInfo = styled.span`
  position: absolute;
  right: 20px;
  top: 40px;

  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: right;

  color: #ffffff;
`

// 슬롯 버튼 글씨
const MobileMainGameSlotStartButtonInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #000000;
`

// 슬롯 버튼
const MobileMainGameSlotStartButton = styled(ButtonH40)`
  position: absolute;
  width: 90px;
  height: 30px;
  right: 13px;
  top: 73px;

  // 호버
  &:hover {
    ${MobileMainGameSlotStartButtonInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${MobileMainGameSlotStartButtonInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${MobileMainGameSlotStartButtonInfo} {
      color: #ffffff4d;
    }
  }
`
