import moment from 'moment'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { searchMinimumAmount } from '../../../api/memberManage/memberManageApi'
import { CustomSelect } from '../../../shared/components/CustomSelect'
import { ButtonH40 } from '../../../shared/components/GoldButton'
import Portal from '../../../shared/components/Portal'
import { colorFormSelect } from '../../../utils/palette'

const InspectionPopup = () => {
  // 점검 시작일
  const [startDate, setStartDate] = useState('')

  // 점검 종료일
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    searchMinimumAmount()
      .then(res => {
        setStartDate(res.data.inspectionStartDate)
        setEndDate(res.data.inspectionEndDate)
      })
      .catch(error => {})
  }, [])

  return (
    <Portal elementId="signup-modal">
      <SignUpModalOverlay />
      <SignUpModalWrapper tabIndex="-1">
        <SignUpModalInner tabIndex="0">
          <SignUpModalHeader>
            <SignUpModalHeaderLogo src={`${process.env.PUBLIC_URL}/img/main/Logo.png`} alt="" />
          </SignUpModalHeader>
          <SignUpModalContentWrap>
            <AlertPopupContentInfo1>현재 점검중입니다.</AlertPopupContentInfo1>
            <br />
            <AlertPopupContentInfo1>
              점검 일정 : {moment(new Date(startDate)).format('MM월 DD일 HH:mm')} ~{' '}
              {moment(new Date(endDate)).format('MM월 DD일 HH:mm')}
            </AlertPopupContentInfo1>
          </SignUpModalContentWrap>
        </SignUpModalInner>
      </SignUpModalWrapper>
    </Portal>
  )
}

export default InspectionPopup

const SignUpModalWrapper = styled.div`
  background-color: rgba(0, 0, 0, 1);
  box-sizing: border-box;
  display: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 999;
`

// 이용 권한이 없습니다.
const AlertPopupContentInfo1 = styled.div`
  font-style: normal;
  font-weight: 600;
  line-height: 25px;

  /* 엘로우폰트 */

  color: #ffc01a;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #000000;

  width: 500px;
  height: 200px;
  top: 300px;
  margin: 0 auto;

  border: 1px solid #edae07;
`

const SignUpModalHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid #edae07;

  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  padding: 30px 40px;
  //   height: calc(100% - 60px);
  text-align: center;
`

// sign up form
const SignUpModalFormWrap = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

// sign up form group
const SignUpModalFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

// sign up form label
const SignUpModalFormLabel = styled.div`
  width: 120px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  display: inline-block;
  text-align: right;
`

// sign up form field
const SignUpModalFormField = styled.div`
  width: 200px;
`

// sign up form input wrap
const SignUpModalFormInputWrap = styled.div`
  border: 1px solid #4a4a4a;
  position: relative;
`

// sign up form input
const SignUpModalFormInput = styled.input`
  width: 100%;
  padding: 0 10px;

  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  color: #d3d3d3;

  border: transparent;
  background: transparent;

  outline: none;
`

// sign up form description
const SignUpModalFormDescription = styled.span`
  font-style: normal;
  font-weight: 400;
  line-height: 17px;

  color: #d3d3d3;
`

// 버튼 안에 텍스트
const SignUpModalFormButtomInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-align: center;

  color: #000000;
`

// sign up from button
const SignUpModalFormButtom = styled(ButtonH40)`
  width: 100%;
  height: 40px;
  border-radius: 0;

  // 호버
  &:hover {
    ${SignUpModalFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${SignUpModalFormButtomInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${SignUpModalFormButtomInfo} {
      color: #ffffff4d;
    }
  }
`

// 버튼 안에 텍스트
const SignUpModalFormIdConfirmButtonInfo = styled.span`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;

  color: #000000;
`

// Sign up form id confirm button
const SignUpModalFormIdConfirmButton = styled(ButtonH40)`
  width: 110px;
  height: 29px;
  border-radius: 0;

  // 호버
  &:hover {
    ${SignUpModalFormIdConfirmButtonInfo} {
      color: #ffc01a;
    }
  }

  // 클릭
  &:focus,
  &:active,
  &active:focus {
    ${SignUpModalFormIdConfirmButtonInfo} {
      color: #ffc01a;
    }
  }

  // 비활성화
  &:disabled {
    ${SignUpModalFormIdConfirmButtonInfo} {
      color: #ffffff4d;
    }
  }
`

// Select
export const BankSelect = styled(CustomSelect)`
  .react-select__control {
    height: 28px;
    border: 1px solid #4a4a4a;
    background-color: transparent;

    &.react-select__control--is-focused,
    &:hover {
      border: solid 2px;
      border-color: white !important;
      box-shadow: none;
      background: transparent;
      .react-select__placeholder {
        color: #d3d3d3;
      }
    }
  }

  .react-select__input {
    height: 28px;
    color: #d3d3d3 !important;
    &.react-select__control--is-focused {
      color: red !important;
    }
  }

  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    color: #d3d3d3;
  }

  .react-select__single-value {
    color: #d3d3d3;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    overflow: hidden;
    background: ${colorFormSelect};
    border: 1px solid #4a4a4a;
  }
`
