import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { searchGameMoney } from '../../api/memberManage/memberManageApi'
import {
  CustomerCenterContentWrap,
  CustomerCenterHeaderInfo,
  CustomerCenterHeaderWrap,
} from '../../shared/components/MoneyCustomElement'
import ReactTableBase from '../../shared/components/table/ReactTableBase'
import CustomLoading from '../CustomLoading'
import GameMoneyListData from './GameMoneyListData'

const GameMoneyPage = () => {
  const [params, setParams] = useState({
    edgeKey: '',
    userId: '',
  })

  const [totalGameMoney, setTotalGameMoney] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchGameMoney(params)
      .then(res => {
        setContent(res.data.gameMoney)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [params])

  const [gameMoneyListData, setGameMoneyListData] = useState(GameMoneyListData(content))

  useEffect(() => {
    setGameMoneyListData(GameMoneyListData(content))
  }, [content])

  const [rows, setData] = useState(gameMoneyListData.tableRowsData)
  useEffect(() => {
    setData(gameMoneyListData.tableRowsData)
  }, [gameMoneyListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: false,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <GameMoneyPageWrap>
      <CustomerCenterHeaderWrap>
        <CustomerCenterHeaderInfo>게임머니 조회</CustomerCenterHeaderInfo>
      </CustomerCenterHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <CustomerCenterContentWrap>
          <ReactTableBase columns={gameMoneyListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </CustomerCenterContentWrap>
      )}
    </GameMoneyPageWrap>
  )
}

export default GameMoneyPage

const GameMoneyPageWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;

  position: relative;
  top: 30px;
`
