// 피해 유형

export const MiniGameBettingTypeEnums = {
  ING: '진행',
  WIN: '당첨',
  LOSE: '낙첨',
  CANCEL: '취소',
}

export const MiniGameBettingTypeEnumsSelectOptions = () => {
  return Object.entries(MiniGameBettingTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MiniGameVendor = {
  NTRY: '엔트리',
  BOSCORE: '보스코어',
  NAMED: '네임드',
  SUREMAN: '슈어맨',
  GO_PICK: '고픽',
  CROWN: '크라운',
  MGM: 'MGM',
  LOTUS: '로투스',
}

export const MiniGameTypeEnums = {
  NTRY_POWER_BALL: '엔트리 파워볼',
  NTRY_EOS_POWER_BALL_1M: '엔트리 EOS파워볼 1분',
  NTRY_EOS_POWER_BALL_2M: '엔트리 EOS파워볼 2분',
  NTRY_EOS_POWER_BALL_3M: '엔트리 EOS파워볼 3분',
  NTRY_EOS_POWER_BALL_4M: '엔트리 EOS파워볼 4분',
  NTRY_EOS_POWER_BALL_5M: '엔트리 EOS파워볼 5분',
  NTRY_SPEED_KENO: '엔트리 스피드 키노',
  NTRY_KENO_LADDER: '엔트리 키노 사다리',
  NTRY_POWER_LADDER: '엔트리 파워 사다리',
  NTRY_EOS_SPEED_KENO: '엔트리 EOS 스피드 키노',
  NTRY_SEVEN_BALL_1MIN: '엔트리 세븐볼 1분',
  NTRY_SEVEN_BALL_3MIN: '엔트리 세븐볼 3분',
  NTRY_SEVEN_BALL_5MIN: '엔트리 세븐볼 5분',
  TOKEN_GAME_POWER_BALL: '토큰 파워볼',
  TOKEN_GAME_ROULETTE: '토큰 룰렛',
  TOKEN_GAME_HILO: '토큰 하이로우',
  TOKEN_GAME_HILO_5S: '토큰 하이로우 5초',
  TOKEN_GAME_BACCARAT: '토큰 바카라',
  TOKEN_GAME_HORSE: '토큰 가상경주',
  TOKEN_GAME_FW: '토큰 포츈휠',
  TOKEN_GAME_SNAIL3: '토큰 달팽이 레이싱 3줄',
  TOKEN_GAME_SNAIL4: '토큰 달팽이 레이싱 4줄',
  BOSCORE_LADDER_1MIN: '보스코어 사다리 1분',
  BOSCORE_LADDER_2MIN: '보스코어 사다리 2분',
  BOSCORE_LADDER_3MIN: '보스코어 사다리 3분',
  NAMED_POWER_BALL_3MIN: '네임드 파워볼 3분',
  NAMED_POWER_BALL_5MIN: '네임드 파워볼 5분',
  NAMED_POWER_LADDER_3MIN: '네임드 파워사다리 3분',
  NAMED_POWER_LADDER_5MIN: '네임드 파워사다리 5분',
  NAMED_RED_POWER_BALL: '네임드 레드 파워볼',
  NAMED_RED_POWER_LADDER: '네임드 레드 파워사다리',
  NAMED_RUNNING_BALL_SPEED6: '네임드 런닝볼 스피드6',
  NAMED_RUNNING_BALL_SPEED4: '네임드 런닝볼 스피드4',
  NAMED_RUNNING_BALL_MAZE2: '네임드 런닝볼 메이즈2',
  SUREMAN_POWER_BALL_1MIN: '슈어맨 파워볼 1분',
  SUREMAN_POWER_BALL_2MIN: '슈어맨 파워볼 2분',
  SUREMAN_POWER_BALL_3MIN: '슈어맨 파워볼 3분',
  SUREMAN_LADDER_1MIN: '슈어맨 사다리 1분',
  SUREMAN_LADDER_2MIN: '슈어맨 사다리 2분',
  SUREMAN_LADDER_3MIN: '슈어맨 사다리 3분',
  SUREMAN_RACING_1MIN: '슈어맨 레이싱 1분',
  SUREMAN_RACING_2MIN: '슈어맨 레이싱 2분',
  SUREMAN_RACING_3MIN: '슈어맨 레이싱 3분',
  SUREMAN_ROULETTE_1MIN: '슈어맨 룰렛 1분',
  SUREMAN_ROULETTE_2MIN: '슈어맨 룰렛 2분',
  SUREMAN_ROULETTE_3MIN: '슈어맨 룰렛 3분',
  PBG_POWER_BALL: 'PBG 파워볼',
  NEXT_BACCARAT: '넥스트 바카라',
  NEXT_RING_1MIN: '넥스트 링 1분',
  NEXT_LADDER: '넥스트 사다리',
  NEXT_POWER_BALL: '넥스트 파워볼',
  SPOLUV_LADDER_1MIN: '스포럽 사다리 1분',
  SPOLUV_LADDER_3MIN: '스포럽 사다리 3분',
  SPOLUV_ROULETTE_1MIN: '스포럽 룰렛 1분',
  SPLIT_CASINO: '스플릿 카지노',
  GO_PICK_EVO_POWER_BALL_1: '고픽 EVO 파워볼 1턴',
  GO_PICK_EVO_POWER_BALL_2: '고픽 EVO 파워볼 2턴',
  GO_PICK_EVO_POWER_BALL_3: '고픽 EVO 파워볼 3턴',
  GO_PICK_EVO_POWER_BALL_4: '고픽 EVO 파워볼 4턴',
  GO_PICK_EVO_POWER_BALL_5: '고픽 EVO 파워볼 5턴',
  GO_PICK_EVO_POWER_LADDER_1: '고픽 EVO 파워 사다리 1분',
  GO_PICK_EVO_POWER_LADDER_2: '고픽 EVO 파워 사다리 2분',
  GO_PICK_EVO_POWER_LADDER_3: '고픽 EVO 파워 사다리 3분',
  GO_PICK_EVO_POWER_LADDER_4: '고픽 EVO 파워 사다리 4분',
  GO_PICK_EVO_POWER_LADDER_5: '고픽 EVO 파워 사다리 5분',
  GO_PICK_EVO_DRAGON_TIGER: '고픽 EVO 드래곤타이거',
  GO_PICK_EVO_BASEBALL_STUDIO: '고픽 EVO 베이스볼스튜디오',
  GO_PICK_NUGURI: '고픽 너구리',
  GO_PICK_SOCCER: '고픽 축구',
  GO_PICK_BASEBALL: '고픽 야구',
  GO_PICK_SQUIDGAME: '고픽 오징어게임',
  PLAY_SCORE_POWER_BALL_1MIN: '플레이스코어 파워볼 1분',
  PLAY_SCORE_POWER_BALL_2MIN: '플레이스코어 파워볼 2분',
  PLAY_SCORE_LADDER_1MIN: '플레이스코어 사다리 1분',
  PLAY_SCORE_LADDER_2MIN: '플레이스코어 사다리 2분',
  CROWN_SUTDA: '크라운 섯다',
  CROWN_ODD_EVEN: '크라운 홀짝',
  CROWN_BACCARAT: '크라운 바카라',
  CROWN_NINE_BALL: '크라운 나인볼',
  LOTUS_SICBO: '로투스 식보',
  LOTUS_ODD_EVEN: '로투스 홀짝',
  LOTUS_BACCARAT: '로투스 바카라',
  MGM_GO_STOP: 'MGM 고스톱',
  MGM_ODD_EVEN: 'MGM 홀짝',
  MGM_BACCARAT_1: 'MGM 바카라1',
  MGM_BACCARAT_2: 'MGM 바카라2',
  MGM_BACCARAT_3: 'MGM 바카라3',
}

export const PowerBallTypeEnums = {
  POWER_BALL_ODD: '파워볼 - 홀',
  POWER_BALL_EVEN: '파워볼 - 짝',
  POWER_BALL_UNDER: '파워볼 - 언더(4.5)',
  POWER_BALL_OVER: '파워볼 - 오버(4.5)',
  NORMAL_BALL_ODD: '일반볼 - 홀',
  NORMAL_BALL_EVEN: '일반볼 - 짝',
  NORMAL_BALL_UNDER: '일반볼 - 언더(72.5)',
  NORMAL_BALL_OVER: '일반볼 - 오버(72.5)',
  POWER_BALL_ODD_UNDER: '파워볼 - 홀+언더',
  POWER_BALL_ODD_OVER: '파워볼 - 홀+오버',
  POWER_BALL_EVEN_UNDER: '파워볼 - 짝+언더',
  POWER_BALL_EVEN_OVER: '파워볼 - 짝+오버',
  NORMAL_BALL_ODD_UNDER: '일반볼 - 홀+언더',
  NORMAL_BALL_ODD_OVER: '일반볼 - 홀+오버',
  NORMAL_BALL_EVEN_UNDER: '일반볼 - 짝+언더',
  NORMAL_BALL_EVEN_OVER: '일반볼 - 짝+오버',
  NORMAL_BALL_ODD_LARGE: '일반볼 - 홀+대',
  NORMAL_BALL_ODD_MEDIUM: '일반볼 - 홀+중',
  NORMAL_BALL_ODD_SMALL: '일반볼 - 홀+소',
  NORMAL_BALL_EVEN_LARGE: '일반볼 - 짝+대',
  NORMAL_BALL_EVEN_MEDIUM: '일반볼 - 짝+중',
  NORMAL_BALL_EVEN_SMALL: '일반볼 - 짝+소',
  NORMAL_BALL_LARGE: '일반볼 - 대(81~130)',
  NORMAL_BALL_MEDIUM: '일반볼 - 중(65~80)',
  NORMAL_BALL_SMALL: '일반볼 - 소(15~64)',
  POWER_BALL_ODD_NORMAL_BALL_ODD: '파워볼 홀 + 일반볼 홀',
  POWER_BALL_ODD_NORMAL_BALL_EVEN: '파워볼 홀 + 일반볼 짝',
  POWER_BALL_EVEN_NORMAL_BALL_ODD: '파워볼 짝 + 일반볼 홀',
  POWER_BALL_EVEN_NORMAL_BALL_EVEN: '파워볼 짝 + 일반볼 짝',
  POWER_BALL_NUM_0: '파워볼 - 0',
  POWER_BALL_NUM_1: '파워볼 - 1',
  POWER_BALL_NUM_2: '파워볼 - 2',
  POWER_BALL_NUM_3: '파워볼 - 3',
  POWER_BALL_NUM_4: '파워볼 - 4',
  POWER_BALL_NUM_5: '파워볼 - 5',
  POWER_BALL_NUM_6: '파워볼 - 6',
  POWER_BALL_NUM_7: '파워볼 - 7',
  POWER_BALL_NUM_8: '파워볼 - 8',
  POWER_BALL_NUM_9: '파워볼 - 9',
  LADDER_LEFT: '좌',
  LADDER_RIGHT: '우',
  LADDER_ODD: '홀',
  LADDER_EVEN: '짝',
  LADDER_THREE: '3줄',
  LADDER_FOUR: '4줄',
  LADDER_LEFT_THREE_EVEN: '좌-3-짝',
  LADDER_LEFT_FOUR_ODD: '좌-4-홀',
  LADDER_RIGHT_THREE_ODD: '우-3-홀',
  LADDER_RIGHT_FOUR_EVEN: '우-4-짝',
  KENO_ODD: '홀',
  KENO_EVEN: '짝',
  KENO_UNDER: '언더',
  KENO_OVER: '오버',
  KENO_ODD_UNDER: '홀 + 언더',
  KENO_ODD_OVER: '홀 + 오버',
  KENO_EVEN_UNDER: '짝 + 언더',
  KENO_EVEN_OVER: '짝 + 오버',
  NTRY_SEVENBALL_HIGH: '하이',
  NTRY_SEVENBALL_LOW: '로우',
  NTRY_SEVENBALL_BLUE: '파',
  NTRY_SEVENBALL_RED: '빨',
  NTRY_SEVENBALL_ODD: '홀',
  NTRY_SEVENBALL_EVEN: '짝',
  NTRY_SEVENBALL_HIGH_BLUE: '하이 + 파',
  NTRY_SEVENBALL_HIGH_RED: '하이 + 빨',
  NTRY_SEVENBALL_LOW_BLUE: '로우 + 파',
  NTRY_SEVENBALL_LOW_RED: '로우 + 빨',
  NTRY_SEVENBALL_HIGH_ODD: '하이 + 홀',
  NTRY_SEVENBALL_HIGH_EVEN: '하이 + 짝',
  NTRY_SEVENBALL_LOW_ODD: '로우 + 홀',
  NTRY_SEVENBALL_LOW_EVEN: '로우 + 짝',
  NTRY_SEVENBALL_BLUE_ODD: '파 + 홀',
  NTRY_SEVENBALL_BLUE_EVEN: '파 + 짝',
  NTRY_SEVENBALL_RED_ODD: '빨 + 홀',
  NTRY_SEVENBALL_RED_EVEN: '빨 + 짝',
  NTRY_SEVENBALL_HIGH_BLUE_ODD: '하이 + 파 + 홀',
  NTRY_SEVENBALL_HIGH_BLUE_EVEN: '하이 + 파 + 짝',
  NTRY_SEVENBALL_HIGH_RED_ODD: '하이 + 빨 + 홀',
  NTRY_SEVENBALL_HIGH_RED_EVEN: '하이 + 빨 + 짝',
  NTRY_SEVENBALL_LOW_BLUE_ODD: '로우 + 파 + 홀',
  NTRY_SEVENBALL_LOW_BLUE_EVEN: '로우 + 파 + 짝',
  NTRY_SEVENBALL_LOW_RED_ODD: '로우 + 빨 + 홀',
  NTRY_SEVENBALL_LOW_RED_EVEN: '로우 + 빨 + 짝',
  RUNNING_BALL_FIRST_BALL_ODD: '1등볼 홀',
  RUNNING_BALL_FIRST_BALL_EVEN: '1등볼 짝',
  RUNNING_BALL_FIRST_BALL_UNDER: '1등볼 언더',
  RUNNING_BALL_FIRST_BALL_OVER: '1등볼 오버',
  RUNNING_BALL_FIRST_BALL_NUM_1: '1등볼 1',
  RUNNING_BALL_FIRST_BALL_NUM_2: '1등볼 2',
  RUNNING_BALL_FIRST_BALL_NUM_3: '1등볼 3',
  RUNNING_BALL_FIRST_BALL_NUM_4: '1등볼 4',
  RUNNING_BALL_FIRST_BALL_NUM_5: '1등볼 5',
  RUNNING_BALL_FIRST_BALL_NUM_6: '1등볼 6',
  RUNNING_BALL_NUM123_ODD: '123등 홀',
  RUNNING_BALL_NUM123_EVEN: '123등 짝',
  RUNNING_BALL_NUM123_UNDER: '123등 언더',
  RUNNING_BALL_NUM123_OVER: '123등 오버',
  SUREMAN_ROULETTE_ODD: '벌(홀)',
  SUREMAN_ROULETTE_EVEN: '꿀통(짝)',
  SUREMAN_ROULETTE_NUM_1: '1등 1',
  SUREMAN_ROULETTE_NUM_2: '1등 2',
  SUREMAN_ROULETTE_NUM_3: '1등 3',
  SUREMAN_ROULETTE_NUM_4: '1등 4',
  SUREMAN_ROULETTE_ODD_NUM_1: '벌(홀) + 1',
  SUREMAN_ROULETTE_ODD_NUM_2: '벌(홀) + 2',
  SUREMAN_ROULETTE_ODD_NUM_3: '벌(홀) + 3',
  SUREMAN_ROULETTE_ODD_NUM_4: '벌(홀) + 4',
  SUREMAN_ROULETTE_EVEN_NUM_1: '꿀통(짝) + 1',
  SUREMAN_ROULETTE_EVEN_NUM_2: '꿀통(짝) + 2',
  SUREMAN_ROULETTE_EVEN_NUM_3: '꿀통(짝) + 3',
  SUREMAN_ROULETTE_EVEN_NUM_4: '꿀통(짝) + 4',
  SUREMAN_RACING_ODD: '홀',
  SUREMAN_RACING_EVEN: '짝',
  SUREMAN_RACING_UNDER: '언더',
  SUREMAN_RACING_OVER: '오버',
  SUREMAN_RACING_NUM_1: '1등 1',
  SUREMAN_RACING_NUM_2: '1등 2',
  SUREMAN_RACING_NUM_3: '1등 3',
  SUREMAN_RACING_NUM_4: '1등 4',
  GOPICK_SOCCER_GOAL: '골',
  GOPICK_SOCCER_NO_GOAL: '노골',
  GOPICK_SOCCER_KICKER_LEFT: '키커 좌',
  GOPICK_SOCCER_KICKER_RIGHT: '키커 우',
  GOPICK_SOCCER_KEEPER_LEFT: '키퍼 좌',
  GOPICK_SOCCER_KEEPER_RIGHT: '키퍼 우',
  GOPICK_BASEBALL_STRIKE: '스트라이크',
  GOPICK_BASEBALL_HOMERUN: '홈런',
  GOPICK_BASEBALL_STRIKE_LEFT: '스트라이크 좌',
  GOPICK_BASEBALL_STRIKE_CENTER: '스트라이크 중앙',
  GOPICK_BASEBALL_STRIKE_RIGHT: '스트라이크 우',
  GOPICK_BASEBALL_HOMERUN_LEFT: '홈런 좌',
  GOPICK_BASEBALL_HOMERUN_CENTER: '홈런 중앙',
  GOPICK_BASEBALL_HOMERUN_RIGHT: '홈런 우',
  GOPICK_SQUID_LEFT: '좌',
  GOPICK_SQUID_RIGHT: '우',
  CROWN_SUTDA_KOREA: '한국',
  CROWN_SUTDA_JAPAN: '일본',

  CROWN_BACCARAT_PLAYER: '플레이어 승',
  CROWN_BACCARAT_BANKER: '뱅커 승',
  CROWN_BACCARAT_TIE: '타이',
  CROWN_BACCARAT_PLAYER_COLOR_RED: '플레이어 빨강',
  CROWN_BACCARAT_PLAYER_COLOR_BLACK: '플레이어 검정',
  CROWN_BACCARAT_BANKER_COLOR_RED: '뱅커 빨강',
  CROWN_BACCARAT_BANKER_COLOR_BLACK: '뱅커 검정',
  CROWN_BACCARAT_CENTER_COLOR_RED: '공통볼 빨강',
  CROWN_BACCARAT_CENTER_COLOR_BLACK: '공통볼 검정',

  CROWN_ODD_EVEN_HIDDEN_UNDER: '히든볼 언더',
  CROWN_ODD_EVEN_HIDDEN_OVER: '히든볼 오버',
  CROWN_ODD_EVEN_HIDDEN_COLOR_RED: '히든볼 빨강',
  CROWN_ODD_EVEN_HIDDEN_COLOR_BLUE: '히든볼 블루',
  CROWN_ODD_EVEN_SUM_ODD: '카드합-홀',
  CROWN_ODD_EVEN_SUM_EVEN: '카드합-짝',
  CROWN_NINE_BALL_LEFT: '왼쪽',
  CROWN_NINE_BALL_TIE: '타이',
  CROWN_NINE_BALL_RIGHT: '오른쪽',
  CROWN_NINE_BALL_RED: '빨강',
  CROWN_NINE_BALL_BLUE: '블루',
  CROWN_NINE_BALL_ODD: '홀',
  CROWN_NINE_BALL_EVEN: '짝',
  LOTUS_SUM_ODD_EVEN_ODD: '카드합-홀',
  LOTUS_SUM_ODD_EVEN_EVEN: '카드합-짝',
  LOTUS_HIDDEN_UNDER_OVER_UNDER: '히든 언더',
  LOTUS_HIDDEN_UNDER_OVER_OVER: '히든 오버',
  LOTUS_HIDDEN_COLOR_BLACK: '히든 블랙',
  LOTUS_HIDDEN_COLOR_RED: '히든 레드',
  LOTUS_HIDDEN_SHAPE_SPADE: '히든 스페이드',
  LOTUS_HIDDEN_SHAPE_DIAMOND: '히든 다이아몬드',
  LOTUS_HIDDEN_SHAPE_HEART: '히든 하트',
  LOTUS_HIDDEN_SHAPE_CLOVER: '히든 클로버',
  LOTUS_BACCARAT_WINNER_PLAYER: '플레이어 승',
  LOTUS_BACCARAT_WINNER_BANKER: '뱅커 승',
  LOTUS_BACCARAT_WINNER_TIE: '타이',
  LOTUS_BACCARAT_PAIR_PLAYER: '플레이어 페어',
  LOTUS_BACCARAT_PAIR_BANK: '뱅커 페어',
  LOTUS_SICBO_ODD: '홀',
  LOTUS_SICBO_EVEN: '짝',
  LOTUS_SICBO_SMALL: '스몰',
  LOTUS_SICBO_BIG: '빅',
  MGM_SUM_ODD_EVEN_ODD: '카드합-홀',
  MGM_SUM_ODD_EVEN_EVEN: '카드합-짝',
  MGM_HIDDEN_UNDER_OVER_UNDER: '히든 언더',
  MGM_HIDDEN_UNDER_OVER_OVER: '히든 오버',
  MGM_HIDDEN_COLOR_BLACK: '히든 블랙',
  MGM_HIDDEN_COLOR_RED: '히든 레드',
  MGM_HIDDEN_SHAPE_SPADE: '히든 스페이드',
  MGM_HIDDEN_SHAPE_DIAMOND: '히든 다이아몬드',
  MGM_HIDDEN_SHAPE_HEART: '히든 하트',
  MGM_HIDDEN_SHAPE_CLOVER: '히든 클로버',
  MGM_BACCARAT_WINNER_PLAYER: '플레이어 승',
  MGM_BACCARAT_WINNER_BANKER: '뱅커 승',
  MGM_BACCARAT_WINNER_TIE: '타이',
  MGM_BACCARAT_PAIR_PLAYER: '플레이어 페어',
  MGM_BACCARAT_PAIR_BANKER: '뱅커 페어',
  MGM_GOSTOP_GO: 'GO',
  MGM_GOSTOP_STOP: 'STOP',
  MGM_GOSTOP_TIE: 'TIE',
  MGM_GOSTOP_GO_ODD: 'GO-홀',
  MGM_GOSTOP_GO_EVEN: 'GO-짝',
  MGM_GOSTOP_GO_UNDER: 'GO-언더',
  MGM_GOSTOP_GO_OVER: 'GO-오버',
  MGM_GOSTOP_STOP_ODD: 'STOP-홀',
  MGM_GOSTOP_STOP_EVEN: 'STOP-짝',
  MGM_GOSTOP_STOP_UNDER: 'STOP-언더',
  MGM_GOSTOP_STOP_OVER: 'STOP-오버',

  /**
   * v1 버전 - 삭제된 애들
   * */
  NORMAL_BALL_ODD_MAJOR: '일반볼 - 홀+대',
  NORMAL_BALL_ODD_MIDDLE: '일반볼 - 홀+중',
  NORMAL_BALL_ODD_MINOR: '일반볼 - 홀+소',
  NORMAL_BALL_EVEN_MAJOR: '일반볼 - 짝+대',
  NORMAL_BALL_EVEN_MIDDLE: '일반볼 - 짝+중',
  NORMAL_BALL_EVEN_MINOR: '일반볼 - 짝+소',
  NORMAL_BALL_MAJOR: '일반볼 - 대(81~130)',
  NORMAL_BALL_MIDDLE: '일반볼 - 중(65~80)',
  NORMAL_BALL_MINOR: '일반볼 - 소(15~64)',
  SPEEDKENO_LEFT: '홀',
  SPEEDKENO_RIGHT: '짝',
  SPEEDKENO_UNDER: '언더',
  SPEEDKENO_OVER: '오버',
}
