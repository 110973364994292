import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import NoteAlert from '../../containers/NoteAlert'
import OneToOneAlert from '../../containers/OneToOneAlert'
import {
  createUserLogAction,
  initHomeInfoAction,
  initPopupInfoAction,
  initSportsInfoAction,
  initVirtualInfoAction,
  reAuthenticationAction,
  setNoteMessageInfo,
} from '../../redux/memberInfoSlice'
import { onClickTitleHandler } from '../../redux/modalSlice'
import useAudio from '../../utils/audioHook'
import isLogin from '../../utils/enums/authority'
import { decodeAccessToken, deleteAccessToken } from '../../utils/token'
import MobileFooter from './MobileFooter'
import MobileHeader from './MobileHeader'

const MobileLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [pathName, setPathName] = useState('')

  const [alarmSrc, setAlarmSrc] = useState(`${process.env.PUBLIC_URL}/audio/noteAudio.mp3`)

  const [play, pause, paused, audioRef] = useAudio(alarmSrc, {
    // playbackRate: 2,
  })

  const { noteRedisSize, noteMessageInfo, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
      noteMessageInfo: memberInfo.noteMessageInfo,
    }
  })

  const { modalTitle } = useSelector(state => {
    const { modalInfo } = state

    return {
      modalTitle: modalInfo.modalTitle,
    }
  })

  useEffect(() => {
    dispatch(initHomeInfoAction())
    dispatch(initPopupInfoAction())
    dispatch(initSportsInfoAction())
    dispatch(initVirtualInfoAction())

    if (isLogin()) {
      dispatch(reAuthenticationAction({ newWindow: false }))
    }
  }, [])

  useEffect(() => {
    setPathName(location.pathname)

    if (isLogin()) {
      const userTokenInfo = decodeAccessToken()

      dispatch(
        createUserLogAction({
          userId: userTokenInfo.userId,
          userName: userTokenInfo.userName,
          position: location.pathname,
          devicePixelRatio: window.devicePixelRatio,
          userAgent: window.navigator.userAgent,
          resolution: `${window.screen.width}X${window.screen.height}`,
          windowSize: `${window.innerWidth}X${window.innerHeight}`,
          platform: window.navigator?.platform || '',
        }),
      )
    }
  }, [location])

  // 쪽지 모달이 켜져 있으면 false
  const [isValidPath, setIsValidPath] = useState(false)

  useEffect(() => {
    if (modalTitle === 'NOTE') {
      setIsValidPath(false)
    } else if (modalTitle === 'ONETOONE') {
      setIsValidPath(false)
    } else {
      setIsValidPath(true)

      if (noteRedisSize > 0 && paused()) {
        play()
      }

      if (oneToOneRedisSize > 0 && paused()) {
        play()
      }
    }
  }, [oneToOneRedisSize, noteRedisSize, modalTitle, paused, play])

  const onClickCloseHandler = () => {
    dispatch(setNoteMessageInfo(''))
    dispatch(onClickTitleHandler('NOTE'))
  }

  return (
    <ContainerWrap>
      {isValidPath && noteRedisSize > 0 && <NoteAlert noteSize={noteRedisSize} />}
      {isValidPath && noteRedisSize === 0 && oneToOneRedisSize > 0 && <OneToOneAlert noteSize={oneToOneRedisSize} />}
      <MobileHeader />
      <ContainerSecondWrap>
        <MobileContainer>
          <Outlet />
        </MobileContainer>
        <MobileFooter />
      </ContainerSecondWrap>
      {/* <MobileScrollButton /> */}
      {/* <LiveChat /> */}
    </ContainerWrap>
  )
}

export default MobileLayout

const ContainerSecondWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 10px;
`

const ContainerWrap = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`

const MobileContainer = styled.div`
  padding-top: 70px;
  margin-bottom: -10px;
  overflow: auto;
  // margin: 0 auto;
`
