import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CustomLoading from '../../../containers/CustomLoading'
import MobileSportsOverseasHeader from './MobileSportsOverseasHeader'
import MobileSportsOverseasContent from './MobileSportsOverseasContent'
import MobileScrollButton from '../../MobileLayout/MobileScrollButton'
import MobileSportsBettingCartSidebar from '../../MobileLayout/MobileSportsBettingCartSidebar'

const MoblieSportsOverseasPage = () => {
  const { overseasLoading, bettingCartLoading } = useSelector(state => {
    const { sportsOverseasInfo, sportsBetting } = state

    return {
      overseasLoading: sportsOverseasInfo.overseasLoading,
      bettingCartLoading: sportsBetting.bettingCartLoading,
    }
  })

  return (
    <>
      <SportsPageWrap>
        {(overseasLoading || bettingCartLoading) && (
          <SportsPageLoadingOverlay>
            <CustomLoading isGameStart info={bettingCartLoading ? '배팅 처리중입니다.' : ''} />
          </SportsPageLoadingOverlay>
        )}
        <SportsContentWrap>
          <MobileSportsOverseasHeader />
          <MobileSportsOverseasContent />
        </SportsContentWrap>
      </SportsPageWrap>

      <MobileSportsBettingCartSidebar />
      <MobileScrollButton />
    </>
  )
}

export default MoblieSportsOverseasPage

const SportsPageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const SportsPageWrap = styled.div`
  width: 100%;
  background: #161719;
  min-height: calc(100vh - 105px);

  padding-bottom: 10px;
  position: relative;
`

const SportsContentWrap = styled.div`
  width: 100%;
`
