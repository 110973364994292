import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { onClickTitleHandler } from '../../../redux/modalSlice'
import Portal from '../../../shared/components/Portal'
import { HermesMainBorder } from '../../../utils/palette'
import AttendanceModalContent from './AttendanceModalContent'
import BettingHistoryModalContent from './BettingHistoryModalContent'
import CouponModalContent from './CouponModalContent'
import DepositModalContent from './DepositModalContent'
import DepositModalContentV2 from './DepositModalContentV2'
import FriendHistoryModalContent from './FriendHistoryModalContent'
import MoneyExchangeModalContent from './MoneyExchangeModalContent'
import NoteModalContent from './NoteModalContent'
import NoticeModalContent from './NoticeEventModalContent'
import OneToOneModalContent from './OneToOneModalContent'
import PointExchangeModalContent from './PointExchangeModalContent'
import WithdrawalModalContent from './WithdrawalModalContent'
import CustomAlert from '../../CustomAlert'

/**
 * @see : https://github.com/masroorejaz/react-simple-captcha
 */
const SideMenuModal = ({ onClose, visible }) => {
  const dispatch = useDispatch()

  const { modalTitle, noteRedisSize, oneToOneRedisSize } = useSelector(state => {
    const { modalInfo, memberInfo } = state

    return {
      modalTitle: modalInfo.modalTitle,
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const onMaskClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e)
    }
  }

  const close = e => {
    if (onClose) {
      onClose(e)
    }
  }

  const getModalContentComponent = () => {
    switch (modalTitle) {
      case 'DEPOSIT':
        return <DepositModalContentV2 />
      case 'WITHDRAWAL':
        return <WithdrawalModalContent />
      case 'MONEY_EXCHANGE':
        return <MoneyExchangeModalContent />
      case 'COUPON':
        return <CouponModalContent />
      case 'POINT_EXCHANGE':
        return <PointExchangeModalContent />
      case 'NOTE':
        return <NoteModalContent />
      case 'ONETOONE':
        return <OneToOneModalContent />
      case 'NOTICE_EVENT':
        return <NoticeModalContent />
      case 'BETTING_HISTORY':
        return <BettingHistoryModalContent />
      case 'FRIEND':
        return <FriendHistoryModalContent />
      // case 'ATTENDANCE':
      //   return <AttendanceModalContent />
      default:
        return <></>
    }
  }
  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)
  const onClickMenuHandler = title => {
    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }
    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }
    dispatch(onClickTitleHandler(title))
  }

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      <Portal elementId="signup-modal">
        <SignUpModalOverlay visible={visible} />
        <SignUpModalWrapper onClick={onMaskClick} tabIndex="-1" visible={visible} className="sidebar-container">
          <SignUpModalInner tabIndex="0">
            <SignUpModalHeader>
              <SignUpModalCloseButton
                onClick={close}
                src={`${process.env.PUBLIC_URL}/img/popup/popup-close.png`}
                alt=""
              />
            </SignUpModalHeader>
            <SignUpModalContentWrap>
              <MenuItemListWrap>
                <MenuItemBox
                  active={modalTitle === 'DEPOSIT'}
                  onClick={() => {
                    onClickMenuHandler('DEPOSIT')
                  }}
                >
                  입금
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'WITHDRAWAL'}
                  onClick={() => {
                    onClickMenuHandler('WITHDRAWAL')
                  }}
                >
                  출금
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'MONEY_EXCHANGE'}
                  onClick={() => {
                    onClickMenuHandler('MONEY_EXCHANGE')
                  }}
                >
                  머니이동
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'COUPON'}
                  onClick={() => {
                    onClickMenuHandler('COUPON')
                  }}
                >
                  쿠폰사용
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'POINT_EXCHANGE'}
                  onClick={() => {
                    onClickMenuHandler('POINT_EXCHANGE')
                  }}
                >
                  포인트 전환
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'NOTE'}
                  onClick={() => {
                    onClickMenuHandler('NOTE')
                  }}
                >
                  쪽지
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'ONETOONE'}
                  onClick={() => {
                    onClickMenuHandler('ONETOONE')
                  }}
                >
                  1:1 문의
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'NOTICE_EVENT'}
                  onClick={() => {
                    onClickMenuHandler('NOTICE_EVENT')
                  }}
                >
                  공지&규정
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'BETTING_HISTORY'}
                  onClick={() => {
                    onClickMenuHandler('BETTING_HISTORY')
                  }}
                >
                  배팅내역
                </MenuItemBox>
                <MenuItemBox
                  active={modalTitle === 'FRIEND'}
                  onClick={() => {
                    onClickMenuHandler('FRIEND')
                  }}
                >
                  지인추천
                </MenuItemBox>
                {/* <MenuItemBox
                  active={modalTitle === 'ATTENDANCE'}
                  onClick={() => {
                    onClickMenuHandler('ATTENDANCE')
                  }}
                >
                  출석현황
                </MenuItemBox> */}
              </MenuItemListWrap>
              {getModalContentComponent()}
            </SignUpModalContentWrap>
          </SignUpModalInner>
        </SignUpModalWrapper>
      </Portal>
    </>
  )
}

export default SideMenuModal

const SignUpModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9990;
  overflow: auto;
  outline: 0;
`
const SignUpModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9990;
`

const SignUpModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #000000;
  width: 1200px;
  margin: 0 auto;
  // min-height: 250px;
  margin-top: 10vh;
  color: #606266;
  word-break: break-all;

  height: calc(100vh - 150px);
`

const SignUpModalHeader = styled.div`
  height: 60px;
  background: #16171a !important;
  display: flex;
  justify-content: center;
  align-items: center;
`

// 로고
const SignUpModalHeaderLogo = styled.img`
  width: 200px;
  height: 50px;
`

// 닫기 아이콘
const SignUpModalCloseButton = styled.img`
  width: 30px;
  height: 30px;

  position: absolute;
  right: 16px;

  &:hover {
    cursor: pointer;
  }
`

// modal body content
const SignUpModalContentWrap = styled.div`
  color: #606266;
  word-break: break-all;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #16171a !important;
`

const MenuItemListWrap = styled.div`
  background: rgb(16, 16, 18);
`

const MenuItemBox = styled.div`
  float: left;
  padding: 18px 0;
  text-align: center;
  color: #fff;
  border-bottom: 2px solid #292929;
  cursor: pointer;
  width: 10%;
  font-size: 14px;
  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  // active 일때
  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
  `}
`
