import React from 'react'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MemberDepositStatusEnums } from '@/utils/enums/MyPage/myPageEnums'
import { convertToKstDateTime } from '@/utils/dateTime'
import DepositDeleteRadio from '../../MoneyDeposit/DepositDeleteRadio'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyDepositListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '삭제',
      accessor: 'deleteRadio',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <DepositDeleteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '회원 ID',
      accessor: 'memberId',
      width: 65,
    },
    {
      Header: '신청액',
      accessor: 'applyMoney',
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.applyMoney?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '지급액',
      accessor: 'paymentMoney',
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.status === 'APPROVE' ? original.paymentMoney?.toString().replace(commonReg2, ',') : '-'
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberDepositStatusEnums[original.status]
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyDepositListData
