import { BorderedBottomTabs, NavItem, NavLink, TabPaneInnerWrap, TabsWrap } from '@/shared/components/Tabs'
import Tooltip from '@/shared/components/Tooltip'
import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import NtryTabs from './Ntry/NtryTabs'
import { HermesMainText } from '../../utils/palette'
import TokenGameTabs from './TokenGame/TokenGameTabs'

const MiniGameTabs = ({ activeKey }) => {
  const [vendorKey, setVendorKey] = useState('1')
  const navigate = useNavigate()
  return (
    <>
      <CustomBottomTabs>
        <Tab.Container defaultActiveKey={activeKey}>
          <TabsWrap>
            <Nav className="nav-tabs">
              <MiniGameNavItem>
                <MiniGameNavLink
                  eventKey="1"
                  onClick={() => {
                    navigate('/minigame')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/ntry.png`} alt="" />
                  엔트리
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink
                  eventKey="2"
                  onClick={() => {
                    navigate('/minigame/boscore/stat1m')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/boscore.png`} alt="" />
                  보스코어
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink
                  eventKey="3"
                  onClick={() => {
                    navigate('/minigame/named/n/powerball3m')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/named.png`} alt="" />
                  네임드
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink
                  eventKey="4"
                  onClick={() => {
                    navigate('/minigame/sureman/roulette1m')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/sureman.png`} alt="" />
                  슈어맨
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink
                  eventKey="5"
                  onClick={() => {
                    navigate('/minigame/gopick/raccoon')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/gopick.png`} alt="" />
                  고픽
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink
                  eventKey="6"
                  onClick={() => {
                    navigate('/minigame/crown/sutda')
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/img/main/crown.png`} alt="" />
                  크라운
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink
                  eventKey="7"
                  onClick={() => {
                    navigate('/minigame/mgm/go-stop')
                  }}
                >
                  <img
                    style={{ width: '32px', height: 'auto' }}
                    src={`${process.env.PUBLIC_URL}/img/main/mgm.png`}
                    alt=""
                  />
                  MGM
                </MiniGameNavLink>
              </MiniGameNavItem>
              <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink
                  eventKey="8"
                  onClick={() => {
                    navigate('/minigame/lotus/odd-even')
                  }}
                >
                  <img
                    style={{ width: '28px', height: 'auto' }}
                    src={`${process.env.PUBLIC_URL}/img/main/lotus.png`}
                    alt=""
                  />
                  로투스
                </MiniGameNavLink>
              </MiniGameNavItem>
              {/* <MiniGameNavItem>
                <MiniGameNavLink eventKey="2">
                  <img src={`${process.env.PUBLIC_URL}/img/main/ntry.png`} alt="" />
                  토큰게임
                </MiniGameNavLink>
              </MiniGameNavItem> */}
              {/* <MiniGameNavItem>
                <MiniGameNavLink>
                  <img src={`${process.env.PUBLIC_URL}/img/main/named.png`} alt="" />
                  <Tooltip text="준비중입니다." placement="top">
                    <span>네임드</span>
                  </Tooltip>
                </MiniGameNavLink>
              </MiniGameNavItem> */}

              {/* <MiniGameNavItem style={{ border: 'none' }}>
                <MiniGameNavLink>
                  <img src={`${process.env.PUBLIC_URL}/img/main/sureman.png`} alt="" />
                  <Tooltip text="준비중입니다." placement="top">
                    <span>슈어맨</span>
                  </Tooltip>
                </MiniGameNavLink>
              </MiniGameNavItem> */}
            </Nav>
          </TabsWrap>
        </Tab.Container>
      </CustomBottomTabs>
    </>
  )
}

export default MiniGameTabs

const CustomBottomTabs = styled(BorderedBottomTabs)`
  border-bottom: none !important;
  border-top: none !important;
`

const MiniGameNavLink = styled(NavLink)`
  height: 50px;
  color: #fff !important;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  &.active,
  &.active:focus,
  &.active:hover {
    color: ${HermesMainText} !important;
    background: rgb(33, 35, 38) !important;
    font-weight: 700;
    border-bottom: none !important;
  }
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  img {
    margin-right: 5px;
    width: 32px;
    height: 28px;
  }
`

const MiniGameNavItem = styled(NavItem)`
  width: max-content;
  text-align: center;
  background: rgb(22, 23, 26);
`

const MoneyDepositWrap = styled.div`
  background: rgb(22, 23, 26);
  // border: 1px solid #525252;
  border-radius: 10px;
  min-height: 744px;
  padding-bottom: 10px;
  margin-top: 32px;
  width: 1277px;
`
