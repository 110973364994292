import React from 'react'
import { Collapse } from 'react-bootstrap'
import styled from 'styled-components'

// 스타일링 적용
const StyledCollapseContainer = styled.div`
  &.collapsing {
    will-change: height;
    transition-timing-function: ease-in-out; /* 애니메이션이 시작과 끝에서 부드럽게 동작하도록 설정 */
    transition-duration: 0.5s; /* 애니메이션 시간을 0.5초로 연장 */
    transition-property: height, visibility;
  }

  &.collapse.show {
    height: auto;
    transition: height 0.35s ease-in-out; /* 애니메이션 시간을 0.5초로 설정 */
  }

  &.collapse {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease-in-out; /* 애니메이션 시간을 0.5초로 설정 */
  }
`

const CustomCollapse2 = ({ children, in: inProp }) => {
  return (
    <Collapse in={inProp}>
      <StyledCollapseContainer className={`collapse ${inProp ? 'show' : ''}`}>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              className: `${child.props.className || ''} custom-class`,
            })
          }
          return child
        })}
      </StyledCollapseContainer>
    </Collapse>
  )
}

export default CustomCollapse2
