export const OnetoOneValidate = values => {
  const errors = {}

  if (!values.questionTitle) {
    errors.questionTitle = '필수 입력 항목입니다.'
  }

  if (!values.questionContent) {
    errors.questionContent = '필수 입력 항목입니다.'
  }

  // if (!values.questionContent.getCurrentContent().hasText()) {
  //   errors.questionContent = '필수 입력 항목입니다.'
  // }

  return errors
}
