import React, { useState } from 'react'
import PropTypes from 'prop-types'

const MobileHoldemBettingLogRadio = ({ holdemBettingLogKey, bettingResult, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        if (bettingResult === 'ING') {
          alert('진행중 건은 삭제하실 수 없습니다.')
          break
        }
        setCheckRadio(prev => [...prev, holdemBettingLogKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== holdemBettingLogKey))
        break
      default:
        break
    }
  }
  return (
    <div>
      <input
        style={{ width: '100%' }}
        checked={bettingResult === 'ING' ? false : checkRadio.find(e => e === holdemBettingLogKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default MobileHoldemBettingLogRadio

MobileHoldemBettingLogRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  holdemBettingLogKey: PropTypes.string,
  bettingResult: PropTypes.string,
}

MobileHoldemBettingLogRadio.defaultProps = {
  holdemBettingLogKey: null,
  bettingResult: null,
  checkRadio: [],
}
