import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { deleteMemberWildGamesBettingLog, searchMemberWildGamesBettingLog } from '../../../api/game/holdemApi'
import { CustomButtonInfos } from '../../../mobile/mobileBettingHistory/MobileMiniGameBettingHistory'
import { CustomDeleteButton } from '../../../mobile/mobileCasinoGame/MobileLiveCasino'
import CustomPagination from '../../../shared/components/CustomPagination'
import { convertToKstByFormat } from '../../../utils/dateTime'
import { HoldemBettingTypeEnums } from '../../../utils/enums/Holdem/HoldemEnums'
import { HermesMainBorder, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import CustomLoading from '../../CustomLoading'
import WildGamesBettingLogRadio from './WildGamesBettingLogRadio'

const WildGamesBettingLog = ({ menuType }) => {
  const [loading, setLoading] = useState(false)

  const bettingHistoryWrapRef = useRef(null)

  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    page: 0,
    size: 10,
  })

  const [totalElement, setTotalElement] = useState(0)
  const [bettingLogList, setBettingLogList] = useState([])

  const fetchWildGamesBettingLog = async () => {
    setLoading(true)

    await searchMemberWildGamesBettingLog(params)
      .then(res => {
        setTotalElement(res.data.totalElements)
        setBettingLogList(res.data.bettingLogList)
      })
      .catch(error => {
        // console.log('dd')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (menuType !== 'BETTING_LOG') return
    fetchWildGamesBettingLog()
  }, [menuType, params])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 10))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))

    if (bettingHistoryWrapRef.current) {
      bettingHistoryWrapRef.current.scrollTop = 0
    }

    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const [checkRadio, setCheckRadio] = useState([])

  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (loading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 내역을 선택해주세요.')
      return
    }
    if (window.confirm('정말 삭제하시겠습니까?')) {
      setLoading(true)
      deleteMemberWildGamesBettingLog({
        bettingLogKeyList: checkRadio,
      })
        .then(res => {
          alert('삭제가 완료 되었습니다.')
        })
        .catch(error => {
          alert('삭제가 실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
          setCheckRadio([])
          fetchWildGamesBettingLog()
        })
    }
  }

  return (
    <>
      {loading && (
        <SportsGamePageLoadingOverlay>
          <CustomLoading isGameStart />
        </SportsGamePageLoadingOverlay>
      )}
      {bettingLogList.length === 0 ? (
        <NoContentDiv>
          <img src={`${process.env.PUBLIC_URL}/img/main/logo3.png`} alt="" />
          와일드 게임즈 배팅내역이 없습니다.
        </NoContentDiv>
      ) : (
        <>
          {bettingLogList.map(item => {
            return (
              <BettingHistoryTable key={item.bettingLogKey}>
                <BettingHistoryTableHead>
                  <BettingHistoryTableTr>
                    <th style={{ width: '5%' }}>-</th>
                    <th style={{ width: '10%' }}>게임명</th>
                    <th style={{ width: '10%' }}>라운드</th>
                    <th style={{ width: '8%' }}>배팅일시</th>
                    <th style={{ width: '7%' }}>배팅 금액</th>
                    <th style={{ width: '7%' }}>당첨 금액</th>
                    <th style={{ width: '7%' }}>잭팟 금액</th>
                    <th style={{ width: '7%' }}>기타 금액</th>
                    <th style={{ width: '7%' }}>변동 금액</th>
                    <th style={{ width: '8%' }}>시작 금액</th>
                    <th style={{ width: '8%' }}>종료 금액</th>
                    <th style={{ width: '8%' }}>잔고</th>
                    <th style={{ width: '8%' }}>배팅결과</th>
                  </BettingHistoryTableTr>
                </BettingHistoryTableHead>
                <BettingHistoryTableBody>
                  <BettingHistoryTableTr>
                    <BettingHistoryContentBox>
                      <BettingHistoryContentItemBox width="5">
                        <WildGamesBettingLogRadio
                          bettingLogKey={item.bettingLogKey}
                          bettingResult={item.bettingResult}
                          checkRadio={checkRadio}
                          setCheckRadio={setCheckRadio}
                        />
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="10">{item.gameName}</BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="10">{item.roundId}</BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="8">
                        {convertToKstByFormat(item.transactionDate, 'MM-DD HH:mm')}
                      </BettingHistoryContentItemBox>

                      <BettingHistoryContentItemBox width="7">
                        {item.bet?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="7">
                        {item.win?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="7">
                        {item.jackpot?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="7">
                        {item.other?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>

                      <BettingHistoryContentItemBox width="7" style={{ color: `${HermesMainText}`, fontWeight: '600' }}>
                        {item.amount?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>

                      <BettingHistoryContentItemBox width="8">
                        {item.startAmount?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="8">
                        {item.endAmount?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>
                      <BettingHistoryContentItemBox width="8" style={{ color: `${HermesMainText}`, fontWeight: '600' }}>
                        {item.balance?.toString().replace(commonReg2, ',')}
                      </BettingHistoryContentItemBox>

                      <BettingHistoryContentItemBox width="8">
                        {
                          {
                            ING: (
                              <button
                                type="button"
                                style={{
                                  width: '60px',
                                  height: '20px',
                                  background: 'green',
                                  color: 'white',
                                  fontWeight: '900',
                                  border: 'none',
                                }}
                              >
                                {HoldemBettingTypeEnums[item.bettingResult]}
                              </button>
                            ),

                            WIN: (
                              <button
                                type="button"
                                style={{
                                  width: '60px',
                                  height: '20px',
                                  background: 'blue',
                                  color: 'white',
                                  fontWeight: '900',
                                  border: 'none',
                                }}
                              >
                                {HoldemBettingTypeEnums[item.bettingResult]}
                              </button>
                            ),
                            LOSE: (
                              <button
                                type="button"
                                style={{
                                  width: '60px',
                                  height: '20px',
                                  background: 'red',
                                  color: 'white',
                                  fontWeight: '900',
                                  border: 'none',
                                }}
                              >
                                {HoldemBettingTypeEnums[item.bettingResult]}
                              </button>
                            ),
                          }[item.bettingResult]
                        }
                      </BettingHistoryContentItemBox>
                    </BettingHistoryContentBox>
                  </BettingHistoryTableTr>
                </BettingHistoryTableBody>
              </BettingHistoryTable>
            )
          })}
        </>
      )}

      {totalElement > 0 && (
        <ListWrap>
          <CustomDeleteButton
            onClick={() => {
              setCheckRadio(
                bettingLogList.filter(value => value.bettingResult !== 'ING').map(value => value.bettingLogKey),
              )
            }}
            type="button"
          >
            <CustomButtonInfos>전체선택</CustomButtonInfos>
          </CustomDeleteButton>
          <CustomDeleteButton onClick={() => onDeleteHandler()} variant="primary" type="button">
            <CustomButtonInfos>삭제하기</CustomButtonInfos>
          </CustomDeleteButton>
        </ListWrap>
      )}

      {totalElement > 0 && <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />}
    </>
  )
}

export default WildGamesBettingLog

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  height: 55px;
  // padding-left: 10px;
  gap: 10px;
  // border: solid 2px red;
`

const SubMenuItemBox = styled.div`
  background-color: #1b1c1f;
  padding: 10px 10px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 24px;
  width: 140px;
  height: 43px;
  &:hover {
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.active &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const NoContentDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  gap: 5px;
  margin-top: 30px;
  img {
    width: 120px;
    height: auto;
  }
`

// 내역이 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SportsEventTitleTypeImg = styled.img`
  width: 22px;
  height: auto;
`

const ListWrap = styled.div`
  display: flex;
  gap: 10px;
`

const SportsGamePageLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
`

const BettingHistoryTableWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-top: -20px;
`

const BettingHistoryTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    border: solid 1px ${HermesMainBorder};
    cursor: pointer;
  }
  th {
    height: 35px;
    padding: 4px 4px;
    border-top: 1px solid #525252;
    background: rgba(48, 48, 48, 1);
    &:last-child {
      border-right: 0;
    }

    white-space: nowrap;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BettingHistoryTableHead = styled.thead`
  th {
    background: none;
  }
`

const BettingHistoryTableBody = styled.tbody`
  //   gap: 10px;
`

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'ING':
      return 'green'
    default:
      return 'white'
  }
}

const getColor2 = settlementType => {
  switch (true) {
    case settlementType === 'WINNER':
      return 'blue'
    case settlementType === 'LOSER':
      return 'red'
    case settlementType === 'REFUND':
      return 'grey'
    case settlementType === 'NOT_SETTLED':
      return 'green'
    case settlementType === 'CANCELLED':
      return 'black'
    default:
      return 'white'
  }
}

const getFonwWeight = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return '900'
    case bettingResult === 'LOSE':
      return '900'
    case bettingResult === 'DRAW':
      return '900'
    default:
      return '300'
  }
}

const BettingHistoryContentBoxFooterInfo = styled.span`
  font-weight: 600;
  color: ${props => getColor2(props.bettingResult)};
`

const BettingHistoryContentBoxFooterInfo2 = styled.span`
  font-weight: 600;
  color: ${props => getColor(props.bettingResult)};
`

const BettingHistoryContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 30px;

  div {
    font-weight: 500;
  }
`

const BettingHistoryTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-left: solid 1px #525252;
  border-right: solid 1px #525252;
  border-bottom: solid 1px #525252;
  // &:hover {
  //   border: none;
  //   cursor: pointer;
  // }
  font-weight: 700;
`

const getColor3 = virtualGameSportType => {
  switch (true) {
    case virtualGameSportType === 'VIRTUAL_SOCCER':
      return '#f6a033'
    case virtualGameSportType === 'VIRTUAL_BASKETBALL':
      return '#f44c32'
    case virtualGameSportType === 'VIRTUAL_BASEBALL':
      return '#8f47fc'
    case virtualGameSportType === 'VIRTUAL_HORSE':
      return '#36bd29'
    case virtualGameSportType === 'VIRTUAL_GREYHOUNDS':
      return '#24c1f0'
    default:
      return '#fff'
  }
}

const BettingHistoryContentItemBox = styled.div`
  //   width: 5%;
  width: ${props => props.width && `${props.width}%`};
  height: 100%;
  background: rgba(48, 48, 48, 0.6);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: ${props => getColor3(props.virtualGameSportType)};
  // &:not(:first-child) {
  //   border-right: 1px solid rgba(255, 255, 255, 0.4);
  // }

  &:not(:last-child) {
    border-right: 1px solid #525252;
  }

  span {
    font-weight: 600;
  }

  ${props =>
    props.active &&
    `
    background-color: ${HermesMainSelect};
    color: ${HermesMainText};
  `}
  img {
    width: 15px;
    height: 15px;
    margin-right: 3px;
  }
`

const BettingCancelBox = styled.div`
  width: 100%;
`

const BettingCancelButton = styled.button`
  width: 10%;
  position: relative;
  color: red;
  margin-top: 2px;
  margin-left: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${HermesMainBorder};
  padding: 2px;
  background: #262626;
`

const BettingHistoryContentItemDetailInfo1 = styled.div`
  width: 70%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  padding: 0 5px;
  float: left;
  font-weight: 600;
`

const BettingHistoryContentItemDetailInfo2 = styled.div`
  width: 29%;
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => props.textAlign && `${props.textAlign}`};
  cursor: pointer;
  padding: 0 5px;
  font-weight: 600;
`
