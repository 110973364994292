import axios from '../base/axios'

// 레볼 홀덤 배팅내역 조회
export async function searchMemberRevolutionBettingLog(params) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/revolution/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 레볼 홀덤 배팅내역 삭제
export async function deleteMemberRevolutionBettingLog(body) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/revolution/betting-log',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 레볼 홀덤 배팅 세부내역 조회
export async function searchMemberRevolutionDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/revolution/betting-detail-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 레볼 홀덤 배팅 세부내역 삭제
export async function deleteMemberRevolutionDetailBettingLog(body) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/revolution/betting-detail-log',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 레볼 홀덤 바이인/아웃 내역 조회
export async function searchMemberRevolutionBuyInOutLog(params) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/revolution/buyinout-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 레볼 홀덤 바이인/아웃 내역 삭제
export async function deleteMemberRevolutionBuyInOutLog(body) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/revolution/buyinout-log',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 와일드게임즈 게임 리스트 조회
export async function searchWildGamesGameList(params) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/wild-games/game-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 와일드게임즈 배팅내역 조회
export async function searchMemberWildGamesBettingLog(params) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/wild-games/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 와일드게임즈 배팅내역 삭제
export async function deleteMemberWildGamesBettingLog(body) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/wild-games/betting-log',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 와일드게임즈 배팅 세부내역 조회
export async function searchMemberWildGamesDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/wild-games/betting-detail-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 와일드게임즈 배팅 세부내역 삭제
export async function deleteMemberWildGamesDetailBettingLog(body) {
  const response = await axios({
    url: '/tyson/member/v1/holdem/wild-games/betting-detail-log',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}
