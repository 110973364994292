import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { HermesMainBorder, HermesMainHover, HermesMainHover2 } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { minusDayToKst } from '../../../utils/dateTime'
import { searchMemberPointDeposit, searchMemberPointExchange } from '../../../api/memberHistory/memberHistoryApi'
import PointExchangeData from '../../PointExchange/PointExchangeData'
import { createMemberPointExchange } from '../../../api/memberManage/memberManageApi'
import { fetchMemberInfoAction } from '../../../redux/memberInfoSlice'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import PointDepositListData from '../../MyPage/PointDepositHistory/PointDepositListData'
import { MoneyFormModifyButtomInfo } from '../../../shared/components/MoneyCustomElement'
import CustomLoading from '../../CustomLoading'

const PointExchangeModalContent = () => {
  // POINT , POINT_EXCHANGE_HISTORY, POINT_DEPOSIT_HISTORY
  const [subMenuTitle, setSubMenuTitle] = useState('POINT')
  const dispatch = useDispatch()

  const { holdingMoney, totalGameMoney, pointAmount, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      pointAmount: memberInfo.memberHoldingPoint,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [pointWeekHistoryParams, setPointWeekHistoryParams] = useState({
    pointType: 'ROLLING',
    page,
    size,
    startDate: minusDayToKst(7),
  })

  useEffect(() => {
    setPointWeekHistoryParams(prev => ({
      ...prev,
      page,
    }))
  }, [page])

  const [pageLoading, setPageLoading] = useState(true)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMemberPointExchange = async () => {
    setPageLoading(true)
    await searchMemberPointExchange(pointWeekHistoryParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
      })
  }

  useEffect(() => {
    if (subMenuTitle !== 'POINT_EXCHANGE_HISTORY') return

    fetchSearchMemberPointExchange()
  }, [subMenuTitle])

  const [pointExchangeListData, setPointExchangeListData] = useState(PointExchangeData(content))

  useEffect(() => {
    setPointExchangeListData(PointExchangeData(content))
  }, [content, subMenuTitle])

  const [rows, setData] = useState(pointExchangeListData.tableRowsData)

  useEffect(() => {
    setData(pointExchangeListData.tableRowsData)
  }, [pointExchangeListData, subMenuTitle])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  /**
    마이페이지 - 포인트 입금내역 조회
   */
  const [pointDepositPage, setPointDepositPage] = useState(0)
  const [pointDepositSize, setPointDepositSize] = useState(10)

  const [params, setParams] = useState({
    page: pointDepositPage,
    size: pointDepositSize,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: pointDepositPage,
      size: pointDepositSize,
    }))
  }, [pointDepositPage, pointDepositSize])

  const [pointDepositTotalElement, setPointDepositTotalElement] = useState(0)
  const [pointDepositContent, setPointDepositContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (subMenuTitle !== 'POINT_DEPOSIT_HISTORY') return
    setLoading(true)
    searchMemberPointDeposit(params)
      .then(res => {
        setPointDepositTotalElement(res.data.totalElement)
        setPointDepositContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [params, subMenuTitle])

  const [pointDepositListData, setPointDepositListData] = useState(PointDepositListData(pointDepositContent))

  useEffect(() => {
    setPointDepositListData(PointDepositListData(pointDepositContent))
  }, [pointDepositContent, subMenuTitle])

  const [pointDepositRows, setPointDepositData] = useState(pointDepositListData.tableRowsData)
  useEffect(() => {
    setPointDepositData(pointDepositListData.tableRowsData)
  }, [pointDepositListData, subMenuTitle])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const pointDepositTableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: pointDepositPage,
    // size
    _pageSize: pointDepositSize,
    // total element
    totalElement: pointDepositTotalElement,
    // setPageIndex
    _setPageIndex: setPointDepositPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const [compExchangeLoading, setCompExchangeLoading] = useState(false)
  const onSubmit = () => {
    if (compExchangeLoading) return
    if (pointAmount === 0) return
    setCompExchangeLoading(true)
    createMemberPointExchange()
      .then(res => {
        setCompExchangeLoading(false)
        alert('포인트 전환이 완료되었습니다.')
        fetchSearchMemberPointExchange()
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('포인트 전환이 실패했습니다. 다시 시도해주세요.')
        }
        setCompExchangeLoading(false)
      })
  }

  return (
    <ContentWrap>
      <ContentInnerScrollWrap>
        <ContentInnerWrap className="sidebar-container">
          <SubMenuItemListWrap>
            <div>
              <SubMenuItemBox active={subMenuTitle === 'POINT'} onClick={() => setSubMenuTitle('POINT')}>
                포인트 전환
              </SubMenuItemBox>
              <SubMenuItemBox
                active={subMenuTitle === 'POINT_EXCHANGE_HISTORY'}
                onClick={() => setSubMenuTitle('POINT_EXCHANGE_HISTORY')}
              >
                포인트 전환내역
              </SubMenuItemBox>
              <SubMenuItemBox
                active={subMenuTitle === 'POINT_DEPOSIT_HISTORY'}
                onClick={() => setSubMenuTitle('POINT_DEPOSIT_HISTORY')}
              >
                포인트 입금내역
              </SubMenuItemBox>
            </div>
          </SubMenuItemListWrap>

          <ContentBox>
            {
              {
                POINT: (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '70%' }}>
                      <MoneyDepositInfoWrap>
                        <MoneyDepositInfoLeftWrap>
                          <MoneyDepositInfoLeftBox>
                            <MoneyDepositInfoLeftTextBox>
                              <div>스포츠캐쉬</div>
                              <MoneyDepositInfoLeftText>
                                {holdingMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoLeftText>
                            </MoneyDepositInfoLeftTextBox>
                          </MoneyDepositInfoLeftBox>
                        </MoneyDepositInfoLeftWrap>
                        <MoneyDepositInfoRightWrap>
                          <MoneyDepositInfoRightBox>
                            <MoneyDepositInfoRightTextBox>
                              <div>카지노캐쉬</div>
                              <MoneyDepositInfoRightText>
                                {totalGameMoney?.toString().replace(commonReg2, ',')} 원
                              </MoneyDepositInfoRightText>
                            </MoneyDepositInfoRightTextBox>
                          </MoneyDepositInfoRightBox>
                        </MoneyDepositInfoRightWrap>
                      </MoneyDepositInfoWrap>
                      <CompExchangeBodyOneWrap>
                        <RollingPointWrap>
                          <RollingPointBox>
                            <RollingPointText1>보유 포인트</RollingPointText1>
                            <RollingPointText2>{pointAmount?.toString().replace(commonReg2, ',')}</RollingPointText2>
                          </RollingPointBox>
                        </RollingPointWrap>
                      </CompExchangeBodyOneWrap>
                      <CompExchangeBodyTwoWrap>
                        <MoneyButton variant="primary" onClick={onSubmit}>
                          <CustomMoneyFormModifyButtomInfo>전환하기</CustomMoneyFormModifyButtomInfo>
                        </MoneyButton>
                      </CompExchangeBodyTwoWrap>
                    </div>
                  </div>
                ),
                POINT_EXCHANGE_HISTORY: (
                  <>
                    {pageLoading ? (
                      <CustomLoading />
                    ) : (
                      <>
                        <MoneyWithdrawalHeaderTwoWrap>
                          <MoneyWithdrawalHeaderThirdInfoBox>
                            <MoneyWithdrawalHeaderThirdInfo>포인트 전환내역</MoneyWithdrawalHeaderThirdInfo>
                          </MoneyWithdrawalHeaderThirdInfoBox>
                        </MoneyWithdrawalHeaderTwoWrap>
                        <MoneyWithdrawalBodyTwoWrap>
                          <ReactTableBase
                            columns={pointExchangeListData.tableHeaderData}
                            data={rows}
                            tableConfig={tableConfig}
                          />
                        </MoneyWithdrawalBodyTwoWrap>
                      </>
                    )}
                  </>
                ),
                POINT_DEPOSIT_HISTORY: (
                  <>
                    {loading ? (
                      <CustomLoading />
                    ) : (
                      <>
                        <MoneyWithdrawalHeaderTwoWrap>
                          <MoneyWithdrawalHeaderThirdInfoBox>
                            <MoneyWithdrawalHeaderThirdInfo>포인트 입금내역</MoneyWithdrawalHeaderThirdInfo>
                          </MoneyWithdrawalHeaderThirdInfoBox>
                        </MoneyWithdrawalHeaderTwoWrap>
                        <MoneyWithdrawalBodyTwoWrap>
                          <ReactTableBase
                            columns={pointDepositListData.tableHeaderData}
                            data={pointDepositRows}
                            tableConfig={pointDepositTableConfig}
                          />
                        </MoneyWithdrawalBodyTwoWrap>
                      </>
                    )}
                  </>
                ),
              }[subMenuTitle]
            }
          </ContentBox>
        </ContentInnerWrap>
      </ContentInnerScrollWrap>
    </ContentWrap>
  )
}

export default PointExchangeModalContent

const ContentWrap = styled.div`
  color: #fff;
  border-radius: 0px;
  background: rgb(16, 16, 18);
  border: none;
  box-shadow: none;
  font-family: 'Malgun Gothic', dotum;
  display: block;
  position: relative;
`

// 스크롤 적용하기 위함
const ContentInnerScrollWrap = styled.div`
  // height: 550px;
  height: calc(100vh - 270px);
  overflow: hidden;
  position: relative;
  display: block;
  color: #fff;
`

const ContentInnerWrap = styled.div`
  overflow: auto;
  height: 100%;
  display: block;
`

const SubMenuItemListWrap = styled.div`
  border-bottom: 1px solid rgb(41, 41, 41);
  display: flex;
  align-items: center;
  justify-content: center;
`

const SubMenuItemBox = styled.div`
  padding: 16px 50px;
  float: left;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-bottom: 1px solid ${HermesMainBorder};
  }

  ${props =>
    props.active &&
    `
    border-bottom: 1px solid ${HermesMainBorder};
    `}
`

const ContentBox = styled.div`
  width: 100%;
  padding: 24px;
  display: block;
`

const MoneyDepositInfoWrap = styled.div`
  font-weight: 700;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  box-sizing: border-box;
  display: flex;
  font-family: 'Malgun Gothic', dotum;
`

const MoneyDepositInfoLeftWrap = styled.div`
  background: rgb(18, 18, 20);
  width: calc(50% - 8px);
  float: left;
  display: block;
`
const MoneyDepositInfoLeftBox = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
`

const MoneyDepositInfoLeftTextBox = styled.div`
  float: left;
  padding-left: 24px;
  font-weight: 700;
`
const MoneyDepositInfoLeftText = styled.div`
  color: ${HermesMainHover2};
  font-size: 14px;
`

const MoneyDepositInfoRightWrap = styled.div`
  background: rgb(18, 18, 20);
  width: calc(50% - 8px);
  float: right;
  display: block;
`
const MoneyDepositInfoRightBox = styled.div`
  padding: 16px 24px;
  border-radius: 6px;
`

const MoneyDepositInfoRightTextBox = styled.div`
  float: right;
  text-align: right;
  padding-right: 24px;
`
const MoneyDepositInfoRightText = styled.div`
  color: ${HermesMainHover2};
  font-size: 14px;
`

const MoneyWithdrawalHeaderTwoWrap = styled.div`
  padding: 8px 8px 8px 16px;
  border-radius: 6px;
  background: #171719;
  font-weight: 900;
  display: flex;
`

const MoneyWithdrawalBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 20px 0;

  gap: 10px;

  .react-table__column-header {
    font-size: 14px;
    font-weight: 700;
    padding: 10px 0;
  }

  tbody tr td {
    font-family: 'Malgun Gothic', dotum;
    padding: 16px 5px;
    font-size: 14px;
    font-weight: 700;
  }
`
const MoneyWithdrawalHeaderThirdInfoBox = styled.div`
  float: left;
  padding-top: 10px;
  height: 40px;
  display: block;
`

const MoneyWithdrawalHeaderThirdInfo = styled.span`
  color: #fff;
  font-size: 14px;
`

const CompExchangeBodyOneWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 2px !important;
  padding: 10px 20px;
`

const CompExchangeBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  gap: 10px;
`

const CustomMoneyFormModifyButtomInfo = styled(MoneyFormModifyButtomInfo)`
  cursor: pointer;
  color: #000 !important;
  font-weight: 700;
  font-size: 14px;
`

const MoneyButton = styled.button`
  width: 100%;
  margin-top: 30px;
  height: 45px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;
  color: #fff;
  font-weight: 300;
  border: 2px solid ${HermesMainBorder};
`

const RollingPointWrap = styled.div`
  border: 2px solid ${HermesMainBorder};
  padding: 4px;
  float: left;
  width: 100%;
`

const RollingPointBox = styled.div`
  background: rgb(18, 18, 20);
  float: left;
  width: 100%;
  padding: 10px 0 10px 0;
  text-align: center;
`

const RollingPointText1 = styled.div`
  float: left;
  width: 100%;
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  padding: 0 0 0 0;
  letter-spacing: -1px;
`

const RollingPointText2 = styled.div`
  float: left;
  width: 100%;
  line-height: 20px;
  font-size: 14px;
  padding: 0 0 3px 0;
  color: ${HermesMainHover2};
  font-weight: 900;
`
