import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import customCenterSlice from './customCenterSlice'
import memberInfoSlice from './memberInfoSlice'
import miniGameBettingSliceReducer from './miniGameBettingSlice'
import modalSlice from './modalSlice'
import rootSaga from './sagas/rootSaga'
import sportsBettingSlice from './sportsBettingSlice'
import sportsDomesticInfoSlice from './sportsDomesticInfoSlice'
import sportsGameBettingSlice from './sportsGameBettingSlice'
import sportsLiveInfoSlice from './sportsLiveInfoSlice'
import sportsOverseasInfoSlice from './sportsOverseasInfoSlice'
import sportsSpecialInfoSlice from './sportsSpecialInfoSlice'
import virtualGameInfoSlice from './virtualGameInfoSlice'
import virtualGameBettingSlice from './virtualGameBettingSlice'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: {
    miniGameBetting: miniGameBettingSliceReducer,
    memberInfo: memberInfoSlice,
    customCenter: customCenterSlice,
    sportsGameBetting: sportsGameBettingSlice,
    modalInfo: modalSlice,
    sportsDomesticInfo: sportsDomesticInfoSlice,
    sportsOverseasInfo: sportsOverseasInfoSlice,
    sportsSpecialInfo: sportsSpecialInfoSlice,
    sportsLiveInfo: sportsLiveInfoSlice,
    sportsBetting: sportsBettingSlice,
    virtualGameInfo: virtualGameInfoSlice,
    virtualGameBetting: virtualGameBettingSlice,
  },
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
})

// 루트 사가 실행
sagaMiddleware.run(rootSaga)

export default store
