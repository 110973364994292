import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import { fetchClientInspectionPolling } from '../api/poling/pollingApi'
import InspectionPopup from '../containers/Popup/Inspection/InspectionPopup'
import NormalPopup from '../containers/Popup/Normal/NormalPopup'
import store from '../redux/store'
import usePollingHook from '../utils/pollingHook'
import MobileRouter from './MobileRouter'
import Router from './Router'
import GlobalStyles from './globalStyles'
import CustomLoading from '../containers/CustomLoading'
import CustomAlert from '../containers/CustomAlert'
import { searchMinimumAmount } from '../api/memberManage/memberManageApi'
import { socket } from '../utils/socket'

const App = () => {
  useEffect(() => {
    document.oncontextmenu = function () {
      return false
    }
  }, [])
  // 웹소켓으로 계속 받아서 바꿔야 함
  const isInspectionPopupShow = false

  const [edgeSize, setEdgeSize] = useState(0)

  const [pollingFlag, setPollingFlag] = useState(false)
  // 점검 시작일
  const [startDate, setStartDate] = useState('')

  // 점검 종료일
  const [endDate, setEndDate] = useState('')
  usePollingHook(
    async () => {
      await fetchClientInspectionPolling().then(res => {
        setEdgeSize(res.data.edgeSize)
        setStartDate(res.data.inspectionStartDate)
        setEndDate(res.data.inspectionEndDate)
      })
    },
    [],
    {},
  )

  // 웹소켓 연결되었을 때
  const onSocketIOConnect = () => {
    console.log('connect')
  }

  // 웹소켓 연결이 끊어졌을 때
  const onSocketIODisconnect = () => {
    console.log('disconnect')
  }

  // 웹소켓 연결 코드
  useEffect(() => {
    socket.connect()

    socket.on('connect', onSocketIOConnect)
    socket.on('disconnect', onSocketIODisconnect)

    return () => {
      socket.off('connect', onSocketIOConnect)
      socket.off('disconnect', onSocketIODisconnect)

      socket.disconnect()
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <GlobalStyles />
          {edgeSize !== 0 ? (
            <CustomAlert
              info={`점검 일정 : ${moment(new Date(startDate)).format('MM월 DD일 HH:mm')} ~
              ${moment(new Date(endDate)).format('MM월 DD일 HH:mm')}`}
              isInspection
            />
          ) : (
            <NormalPopup />
          )}
          <BrowserView>
            <Router />
          </BrowserView>
          <MobileView>
            <MobileRouter />
          </MobileView>
        </Provider>
      </BrowserRouter>
    </>
  )
}

export default App
