import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import CustomAlert from '../../../containers/CustomAlert'
import isLogin from '../../../utils/enums/authority'
import MobileSignInPopup from '../../MobileLayout/MobileSignIn'
import { HermesMainHover2, HermesMainText } from '../../../utils/palette'

const MobileMainCarousel = () => {
  // mobile 용 으로 가져와야 함
  const [mobileMainCarousel, setMobileMainCarousel] = useState([{ num: '1' }, { num: '2' }])

  const navigate = useNavigate()

  const { noteRedisSize, oneToOneRedisSize } = useSelector(state => {
    const { memberInfo } = state

    return {
      noteRedisSize: memberInfo.noteRedisSize,
      oneToOneRedisSize: memberInfo.oneToOneRedisSize,
    }
  })

  const [authorityAlertShow, setAuthorityAlertShow] = useState(false)

  const onCloseAuthorityAlert = () => {
    setAuthorityAlertShow(false)
  }

  const [noteAlertShow, setNoteAlertShow] = useState(false)
  const [oneToOneAlertShow, setOneToOneAlertShow] = useState(false)

  const onClickMenuHandler = link => {
    if (!isLogin()) {
      setAuthorityAlertShow(true)
      return
    }

    if (noteRedisSize > 0) {
      setNoteAlertShow(true)
      return
    }

    if (oneToOneRedisSize > 0) {
      setOneToOneAlertShow(true)
      return
    }

    navigate(link)
  }

  const [showControls, setShowControls] = useState(false) // 컨트롤 보이기/숨기기 상태

  useEffect(() => {
    // 캐러셀이 처음에 로드되었을 때 컨트롤을 숨김
    setShowControls(false)
  }, [])

  return (
    <>
      {noteAlertShow && (
        <CustomAlert
          info="읽지 않은 중요한 쪽지가 있어 이동이 불가합니다."
          onClickCloseHandler={() => {
            setNoteAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {oneToOneAlertShow && (
        <CustomAlert
          info="문의 답변건을 먼저 확인해주세요."
          onClickCloseHandler={() => {
            setOneToOneAlertShow(false)
          }}
          checkIsRequired
        />
      )}
      {authorityAlertShow && <MobileSignInPopup visible={authorityAlertShow} onClose={onCloseAuthorityAlert} />}
      <MobileMainCarouselWrap>
        <MobileCarousel
          fade
          indicators={false} // 페이지 표시 점 숨기기
          controls={showControls} // 컨트롤 보이기/숨기기 상태에 따라 설정
          onMouseEnter={() => setShowControls(true)} // 마우스 진입 시 컨트롤 보이기
          onMouseLeave={() => setShowControls(false)} // 마우스 이탈 시 컨트롤 숨기기
        >
          {mobileMainCarousel.map((value, index) => (
            <MobileCarouselItem key={value.num}>
              <MobileCarouselImg src={`${process.env.PUBLIC_URL}/img/main/mobile-banner0${index + 1}.png`} alt="" />
              <MainPageCarouselBtnBox>
                <MainPageCarouselBtn
                  onClick={() => {
                    onClickMenuHandler('/mobile/event')
                  }}
                >
                  자세히
                </MainPageCarouselBtn>
              </MainPageCarouselBtnBox>
            </MobileCarouselItem>
          ))}
        </MobileCarousel>
      </MobileMainCarouselWrap>
    </>
  )
}

export default MobileMainCarousel

const MainPageCarouselBtnBox = styled.div`
  position: absolute;
  left: -10px;
  bottom: 10px;
  z-index: 99;
`

const MainPageCarouselBtn = styled.div`
  padding: 3px 20px;
  display: inline-block;
  margin: 16px 0 0 35px;
  color: #fff;
  cursor: pointer;
  border: 1px solid ${HermesMainText};
  border-radius: 16px;
  font-size: 13px;
  &:hover {
    background-color: ${HermesMainText};
  }
`

const MobileMainCarouselWrap = styled.div`
  width: 100%;
  margin-top: 5px;
`

// const MobileCarouselButton = styled.button`
//   width: 100%;
//   font-size: 12px;
//   color: #fff;
//   background: none;
//   border: 1px solid ${HermesMainBorder};
//   border-radius: 16px;
//   position: absolute;
//   bottom: 10px;
// `
const MobileCarousel = styled(Carousel)`
  width: 100%;
  height: auto;
  position: relative; /* MainCarousel 내부에서 절대 위치 지정을 위해 필요합니다. */
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='orange' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    opacity: 1;
    position: absolute;
  }
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='orange' width='20' height='20' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    opacity: 1;
    position: absolute;
  }
`

const MobileCarouselItem = styled(Carousel.Item)``

const MobileCarouselImg = styled.img`
  width: 100%;
  height: auto;
`
