import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { searchFriendList } from '../../../api/memberHistory/memberHistoryApi'
import { WebPageWrap } from '../../../shared/components/form/newForm'
import CustomLoading from '../../CustomLoading'
import { MyPageContentWrap, MyPageHeaderInfo, MyPageHeaderWrap } from '../MyPageElement'
import FriendListData from './FriendListData'

const FriendList = () => {
  /**
   지인 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [friendListparams, setFriendListparamsParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setFriendListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    searchFriendList(friendListparams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [friendListparams])

  const [friendListData, setFriendListData] = useState(FriendListData(content))
  useEffect(() => {
    setFriendListData(FriendListData(content))
  }, [content])

  const [rows, setData] = useState(friendListData.tableRowsData)
  useEffect(() => {
    setData(friendListData.tableRowsData)
  }, [friendListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <WebPageWrap>
      <MyPageHeaderWrap>
        <MyPageHeaderInfo>지인내역</MyPageHeaderInfo>
      </MyPageHeaderWrap>
      {loading ? (
        <CustomLoading />
      ) : (
        <MyPageContentWrap>
          <ReactTableBase columns={friendListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </MyPageContentWrap>
      )}
    </WebPageWrap>
  )
}

export default FriendList
