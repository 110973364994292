import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainText } from '../../../utils/palette'
// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const MobileLotusTabs = ({ activeKey }) => {
  const navigate = useNavigate()

  return (
    <CustomBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <MiniGameNavItemFirst>
              <MiniGameNavLink
                eventKey="1"
                onClick={() => {
                  navigate('/mobile/mini-game/lotus/odd-even')
                }}
              >
                <span>홀짝</span>
              </MiniGameNavLink>
            </MiniGameNavItemFirst>
            <MiniGameNavItemFirst>
              <MiniGameNavLink
                eventKey="2"
                onClick={() => {
                  navigate('/mobile/mini-game/lotus/baccarat')
                }}
              >
                <span>바카라</span>
              </MiniGameNavLink>
            </MiniGameNavItemFirst>
            <MiniGameNavItemFirst>
              <MiniGameNavLink
                eventKey="3"
                onClick={() => {
                  navigate('/mobile/mini-game/lotus/sicbo')
                }}
              >
                <span>식보</span>
              </MiniGameNavLink>
            </MiniGameNavItemFirst>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </CustomBottomTabs>
  )
}
export default MobileLotusTabs
const CustomBottomTabs = styled(BorderedBottomTabs)`
  border: none !important;
  background: rgb(33, 35, 38) !important;
  margin-top: -8px;
`
const MiniGameNavLink = styled(NavLink)`
  font-weight: 500;
  &.active,
  &.active:focus,
  &.active:hover {
    background: rgb(44, 52, 59) !important;
    color: ${HermesMainText} !important;
    font-weight: 600;
  }
  font-size: 12px;
  padding: 10px 3px;
  span {
    white-space: normal;
    word-break: keep-all;
    display: block;
  }
`

const MiniGameNavItemFirst = styled(NavItem)`
  width: 25%;
  text-align: center;
`

const MiniGameNavItemSecond = styled(NavItem)`
  width: 20%;
  text-align: center;
`

const MiniGameNavItemThird = styled(NavItem)`
  width: 50%;
  text-align: center;
  border-right: solid 1px #575757;
`

const NtryGameWrap = styled.div`
  height: 30px;
  position: relative;
  width: 100%;
`

export const TableBettingInfo = styled.div`
  width: 30%;
  position: left;
  color: white;
  font-size: 12px;
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`

const CustomerCenterWrap = styled.div`
  background: #000000;
  height: 100%;
  min-height: 800px;
  position: relative;
`

// content Wrap
const MiniGameContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const MiniGameBettingTableWrap = styled.div`
  margin-top: 30px;
  border: solid 2px red;
  width: 100%;
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 10px;
`

const MiniGameBettingSortWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 530px;
  overflow: hidden !important;

  // 1450 이상
  @media screen and (min-width: 1450px) {
    width: calc(100% - 560px);
  }
  background-color: #996148;
`

const MiniGameBettingSortHeaderWrap = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 5px solid rgb(244, 225, 192);
  height: 55px;
  padding: 7px 0px;
  align-items: center;
  width: 100%;

  gap: 10px;
`

const MiniGameBettingSortTitleWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 7px 0px;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const MiniGameBettingSortCartWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 525px;
  padding: 0px;
  align-items: center;
  width: 100%;
  gap: 7px;
  margin-top: -5px;
`

const MiniGameBettingSortMixCartWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 525px;
  padding: 0px;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: -5px;
`

const MiniGameBettingSortCartGroupWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 120px;
  padding-bottom: 2px;
  border-bottom: 2px solid #4a4a4a;
  align-items: center;
  width: 97%;
  flex-direction: column;
`

const MiniGameBettingSortCartGroup = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

const MiniGameBettingSortCartTitle = styled.span`
  display: flex;
  justify-content: center;
  height: 27px;
  margin-bottom: 3px;
  align-items: center;
  width: 100%;
  background-color: #674836;
  color: #e9d6b5;
  border: 1px solid #4b3528;
`

const MiniGameBettingSortCartMedium = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 80px;
  width: 100px;
  align-items: center;
  border: 1px solid #684935;
  background-color: #e2c65b;
  position: relative;
`

const MiniGameBettingSortCartMixMedium = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 70px;
  width: 100px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer;
  background-color: #e2c65b;
  position: relative;
  flex-direction: column;
  img {
    margin-top: 10px;
  }
  gap: 5px;
  &:hover {
    img {
      width: 65%;
    }
    span {
      font-size: 12px;
    }
  }
`

const MiniGameBettingSortCartMixMediumSmall = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  color: #4b3422;
  font-weight: 600;
  height: 50px;
  width: 100px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer;
  background-color: #e2c65b;
  position: relative;
  font-size: 12px;
  flex-direction: column;
  img {
    margin-top: 15px;
  }
  gap: 2px;
  &:hover {
    img {
      width: 45%;
    }
    span {
      font-size: 12px;
    }
  }
`

const MiniGameBettingSortCartSmall = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  height:45px;
  width: 45px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
  position: relative;
`

const MiniGameBettingSortCartBig = styled.div`
  background-color: #e2c65b;
  display: flex;
  justify-content: center;
  height:80px;
  width: 120px;
  align-items: center;
  border: 1px solid #684935;
  cursor: pointer
  background-color: #e2c65b;
  position: relative;
  img {
    position: absolute;
    left:0;
    bottom:0;
  }
`

const MiniGameBettingPer = styled.div`
  justify-content: center;
  background-color: #4a3427;
  height: 15px;
  font-size: 12px;
  position: absolute;
  top: 0;
  text-align: center;
  align-items: center;
  width: 100%;
  color: white;
`

const MiniGameBettingCartContentBlack = styled.div`
  margin-top: 14px;
  cursor: pointer;
  border: 3px solid #c0c0c0;
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #5c5c5c 40%, #6c6c6c 60%, #7c7c7c 100%);

  min-width: 30px;
  max-width: 30px;
  font-size: 12px;
  height: 30px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  &:hover {
    color: white;
    max-width: 35px;
    height: 35px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentRed = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 3px;
  border: 3px solid #e02727;
  background: linear-gradient(to bottom, #ec2929 0%, #c30113 40%, #a5051a 60%, #ff0000 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentBlue = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  border: 3px solid #0069ec;
  background: linear-gradient(to bottom, #0069ec 0%, #074798 40%, #023a80 60%, #0d63ce 100%);
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  display: flex;
  cursor: pointer;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentSize = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  min-width: 50px;
  max-width: 50px;
  height: 40px;
  background: transparent;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: solid 1px #674836;
  width: 100%;
  color: #4b3422;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #674836;
  }
`

const MiniGameBettingCartContentOver = styled.div`
  margin-top: 10px;
  margin-bottom: 3px;
  border: solid 2px red;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: red;
  display: flex;
  cursor: pointer;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingCartContentUnder = styled.div`
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 3px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: blue;
  display: flex;
  position: relative;
  border: solid 2px blue;
  &:hover {
    color: white;
    max-width: 50px;
    height: 50px;
    font-size: 12px;
  }
`

const MiniGameBettingPerSecond = styled.div`
  justify-content: center;
  height: 15px;
  font-size: 12px;
  align-items: center;
  width: 100%;
  color: black;
  position: absolute;
  bottom: 0;
  text-align: center;
`

const MiniGameBettingSortTitle = styled.span`
  display: flex;
  background-color: #674836;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 2px solid #674836;
  color: ${props => (props.selected ? 'yellow' : 'white')};
  font-size: 12px;
  height: 50px;
  align-items: center;
  width: 48%;
  cursor: pointer;
  border: ${props => (props.selected ? '2px solid yellow' : 'none')};
`

const MiniGameBettingSortHeaderInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
`

const MiniGameBettingSortHeaderLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height 40px;
  color: #f9e5c0;
  background-color: #4a3427;
`
const MiniGameBettingSortHeaderText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height 40px;
  width: 60%;
  color: white;
  background-color: #674836;
`

const MiniGameIframe = styled.iframe`
  width: 100%;
  height: 640px;
  width: 830px;
`

const MiniGameIframeWrap = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: -280px;
  width: 840px;
  overflow: hidden !important;
`
