import Error from '@/shared/components/form/Error'
import { FormInputWrap } from '@/shared/components/form/FormElements'
import { OnetoOneValidate } from '@/utils/validate/customCenter/oneToOneValidate'
import React, { useEffect, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import { createOnetoOne } from '../../../api/customCenter/customCenterApi'
import {
  CustomCenterFormBottomWrap,
  CustomCenterFormInput,
  CustomCenterTextArea,
  ListButton,
  MobileCustomCenterFormField,
  MobileCustomerCenterFormGroup,
  MobileCustomerCenterFormLabel,
  MobileCustomerCenterFormWrap,
  MoneyFormModifyButtomInfo,
} from '../../../shared/components/MoneyCustomElement'
import { decodeAccessToken } from '../../../utils/token'
import { HermesMainBorder } from '../../../utils/palette'
import CustomLoading from '../../../containers/CustomLoading'
// import './PartnerOnetoOne.css'

const MobileOnetoOneWrite = ({ setWriteOnetoOneShow, fetchSearchOnetoOneList }) => {
  const [questionTitle, setQuestionTitle] = useState('')
  const onChangeQuestionTitle = e => {
    setQuestionTitle(e.target.value)
  }
  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  // 문의 내용
  // const [questionContent, setQuestionContent] = useState(EditorState.createEmpty())
  const [questionContent, setQuestionContent] = useState('')
  const onChangeQuestionContent = e => {
    setQuestionContent(e.target.value)
  }
  // const onChangeQuestionContentHandler = editorState => {
  //   setQuestionContent(editorState)
  // }
  const onSubmit = e => {
    if (apiFlag) return
    setApiFlag(true)
    // TODO userID에 로그인한사람 ID박아주기
    const body = {
      questionTitle,
      questionContent,
    }
    createOnetoOne(body)
      .then(res => {
        alert('1:1문의가 접수되었습니다.')
        setWriteOnetoOneShow(false)
        fetchSearchOnetoOneList()
        setApiFlag(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'CUS-1015':
            alert(message)
            break
          default:
            alert('1:1문의 접수가 실패했습니다. 다시 시도해주세요.')
        }
        setApiFlag(false)
      })
  }

  return (
    <>
      {apiFlag && <CustomLoading />}
      <Form
        onSubmit={onSubmit}
        initialValues={{
          userId: decodeAccessToken().userId,
          questionTitle,
          questionContent,
        }}
        validate={OnetoOneValidate}
      >
        {({ handleSubmit, form: { reset } }) => (
          <MobileCustomerCenterFormWrap horizontal onSubmit={handleSubmit}>
            <MobileCustomerCenterFormGroup>
              <MobileCustomerCenterFormLabel>문의 제목</MobileCustomerCenterFormLabel>
              <MobileCustomCenterFormField>
                <Field name="questionTitle">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <CustomCenterFormInput
                        {...input}
                        style={{ background: '#C2C3C4' }}
                        type="text"
                        value={questionTitle}
                        onChange={e => {
                          onChangeQuestionTitle(e)
                        }}
                        placeholder="제목을 작성하세요"
                      />
                      {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                    </FormInputWrap>
                  )}
                </Field>
              </MobileCustomCenterFormField>
            </MobileCustomerCenterFormGroup>
            <MobileCustomerCenterFormGroup>
              <MobileCustomerCenterFormLabel>문의 내용</MobileCustomerCenterFormLabel>
              <MobileCustomCenterFormField>
                <Field name="questionContent">
                  {({ input, meta }) => (
                    <FormInputWrap>
                      <CustomCenterTextArea
                        {...input}
                        type="text"
                        placeholder={`해당 영역에 문의내용을 입력해주세요 \n\n매너 있는 채팅을 부탁드리며 '욕설 및 협박' 등의 내용을 기재 시 회원자격이 박탈됩니다`}
                        value={questionContent}
                        onChange={e => {
                          onChangeQuestionContent(e)
                        }}
                      />
                      {meta.touched && meta.error && <Error error={meta.error} isMobile />}
                    </FormInputWrap>
                  )}
                </Field>
              </MobileCustomCenterFormField>
            </MobileCustomerCenterFormGroup>
            <CustomCenterFormBottomWrap>
              <QuestionButton
                onClick={() => {
                  setWriteOnetoOneShow(false)
                }}
                variant="primary"
                type="button"
              >
                <MoneyFormModifyButtomInfo>목록</MoneyFormModifyButtomInfo>
              </QuestionButton>
              <BackButton variant="primary" type="submit">
                <MoneyFormModifyButtomInfo>문의완료</MoneyFormModifyButtomInfo>
              </BackButton>
            </CustomCenterFormBottomWrap>
          </MobileCustomerCenterFormWrap>
        )}
      </Form>
    </>
  )
}

export default MobileOnetoOneWrite

// endregion

const QuestionButton = styled.button`
  width: 100px;
  height: 38px;
  background: none;
  border-radius: 6px !important;
  outline: none;

  border: 2px solid ${HermesMainBorder};
`

const BackButton = styled.button`
  width: 100px;
  height: 38px;
  background: ${HermesMainBorder};
  border-radius: 6px !important;
  outline: none;

  border: 2px solid ${HermesMainBorder};
`
