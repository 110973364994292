import React, { Fragment } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineArrowForwardIos } from 'react-icons/md'
import styled from 'styled-components'
import { MoblieDomesticBetBox } from '../../../shared/components/MobileSportDomesticBetBox'
import { HermesMainBorder, HermesMainHover2, HermesMainSelect, HermesMainText } from '../../../utils/palette'
import MobileSportsLiveContentMarket from './MobileSportsLiveContentMarket'

function formatTime(seconds) {
  if (seconds === '-1' || !seconds) return '00:00'

  const minutes = Math.floor(seconds / 60) // 전체 분을 계산
  const remainingSeconds = seconds % 60 // 남은 초를 계산

  // 두 자리 수를 유지하기 위해 문자열 포맷팅
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}

const SportEventInProgressFixtureItem = React.memo(
  ({ fixtureKey, fixtureData, onClickSportFixtureMarketAdditionalOptionHandler, onClickAddBettingCartHandler }) => {
    return (
      <Fragment key={`FixtureItem-${fixtureKey}-${fixtureData.fixtureId}`}>
        <InProgressFixtureItemWrap
          active={fixtureData.additionalOption}
          onClick={() => {
            onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
          }}
        >
          <InProgressFixtureItemHeaderBox>
            <InProgressFixtureItemHeaderPeriodBox>
              <InProgressFixtureItemHeaderPeriodTextBox>
                <div style={{ float: 'left' }}> 시간: {formatTime(fixtureData?.liveScore?.time)}</div>
                <div style={{ float: 'left' }}>
                  &nbsp;상태:{' '}
                  <span style={{ color: `${HermesMainHover2}` }}>{fixtureData?.liveScore?.currentPeriodKr}</span>
                </div>
              </InProgressFixtureItemHeaderPeriodTextBox>
            </InProgressFixtureItemHeaderPeriodBox>

            <InProgressFixtureItemHeaderTopOptionBox>
              <InProgressFixtureItemHeaderTopOption
                active={fixtureData.additionalOption}
                onClick={() => {
                  // onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData)
                }}
              >
                <span>추가 배팅옵션</span>
              </InProgressFixtureItemHeaderTopOption>
            </InProgressFixtureItemHeaderTopOptionBox>
          </InProgressFixtureItemHeaderBox>
          <InProgressFixtureItemBox>
            <InProgressFixtureItemInnerBox>
              <InProgressFixtureItemInnerHomeBox>
                <InProgressFixtureItemInnerHomeTeamBox>
                  {fixtureData.fixture.homeImageUrl ? (
                    <img src={fixtureData.fixture.homeImageUrl} alt="" />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
                  )}
                  <div>{fixtureData.fixture.homeName}</div>
                </InProgressFixtureItemInnerHomeTeamBox>
                <InProgressFixtureItemInnerScoreBox style={{ float: 'right' }}>
                  {fixtureData.liveScore?.homeScore || 0}
                </InProgressFixtureItemInnerScoreBox>
              </InProgressFixtureItemInnerHomeBox>
              <InProgressFixtureItemInnerVSBox>VS</InProgressFixtureItemInnerVSBox>
              <InProgressFixtureItemInnerAwayBox>
                <InProgressFixtureItemInnerScoreBox style={{ float: 'left' }}>
                  {fixtureData.liveScore?.awayScore || 0}
                </InProgressFixtureItemInnerScoreBox>
                <InProgressFixtureItemInnerAwayTeamBox>
                  {fixtureData.fixture.awayImageUrl ? (
                    <img src={fixtureData.fixture.awayImageUrl} alt="" />
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/img/sports/common/default.png`} alt="" />
                  )}
                  <div>{fixtureData.fixture.awayName}</div>
                </InProgressFixtureItemInnerAwayTeamBox>
              </InProgressFixtureItemInnerAwayBox>
            </InProgressFixtureItemInnerBox>

            {fixtureData.mainMarkets ? (
              <>
                {fixtureData.mainMarkets.map(mainMarket => {
                  if (!mainMarket.usedMarket || !mainMarket.marketVisible) return null

                  const filterBetLines = mainMarket.betLines.filter(betLine => {
                    // 모든 bets가 'SUSPENDED'인 경우를 체크합니다.
                    // const allSuspended = betLine.bets.every(bet => bet.betStatus === 'SUSPENDED')

                    // if (allSuspended) return false

                    // 각 betLine의 bets 배열을 검사하여 모든 조건을 충족하는지 확인합니다.
                    return betLine.bets.every(bet => {
                      // showStatus가 true여야 하고, betStatus가 'OPEN' 또는 'SUSPEND'여야 합니다.
                      return bet.showStatus && (bet.betStatus === 'OPEN' || bet.betStatus === 'SUSPENDED')
                    })
                  })

                  if (filterBetLines.length === 0) return null

                  const customMarket = {
                    ...mainMarket,
                    betLines: filterBetLines,
                  }

                  return (
                    <>
                      <MoblieDomesticBetBox
                        fixtureInfo={{
                          fixtureId: fixtureData.fixtureId,
                          ...fixtureData.fixture,
                        }}
                        marketInfo={customMarket}
                        onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                      />
                    </>
                  )
                })}
              </>
            ) : (
              <FixtureMarketBetLineBox
                onClick={() => {
                  onClickSportFixtureMarketAdditionalOptionHandler(fixtureKey, fixtureData.fixtureId)
                }}
              >
                <FixtureMarketBetBoxWrap isActive={fixtureData.additionalOption}>
                  <FixtureMarketBetBox>
                    <FixtureMarketBetBoxName type="home-box">{fixtureData.fixture.homeName}</FixtureMarketBetBoxName>
                  </FixtureMarketBetBox>
                  <SportsEventContentItemPoint>VS</SportsEventContentItemPoint>
                  <FixtureMarketBetBox>
                    <FixtureMarketBetBoxName type="away-box">{fixtureData.fixture.awayName}</FixtureMarketBetBoxName>
                  </FixtureMarketBetBox>
                </FixtureMarketBetBoxWrap>
              </FixtureMarketBetLineBox>
            )}
          </InProgressFixtureItemBox>
        </InProgressFixtureItemWrap>

        {fixtureData.additionalOption && (
          <MobileSportsLiveContentMarket
            selectedFixtureInfo={{
              eventKey: fixtureKey,
              fixtureId: fixtureData.fixtureId,
            }}
          />
        )}
      </Fragment>
    )
  },
)

const InProgressFixtureItemWrap = styled.div`
  display: block;
  background: rgb(29, 30, 33);
  margin-top: 20px;
  margin-bottom: 3px;
  position: relative;
  cursor: pointer !important;

  &:hover {
    cursor: pointer;
    border: 1px solid ${HermesMainHover2};
  }

  ${props =>
    props.active &&
    `
    cursor: pointer;
    border: 1px solid ${HermesMainHover2};
    `}
`
const InProgressFixtureItemHeaderBox = styled.div`
  border-radius: 3px;
  width: 100%;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 5;
  text-align: left;
  color: #000;
  padding: 0;
  font-size: 11px;
`

const InProgressFixtureItemHeaderPeriodBox = styled.div`
  display: block;
  margin-left: 1px;
  padding: 2px 12px;
  font-size: 12px;
  float: left;
  color: #fff;
  border: 1px solid #222733;
  border-radius: 3px;
  background: #34383b;
  text-align: center;
`

const InProgressFixtureItemHeaderPeriodTextBox = styled.div`
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
`

const InProgressFixtureItemHeaderTopOptionBox = styled.div`
  margin-right: 10px;
  padding: 0;
  float: right;
  border: 1px solid #222733;
  border-radius: 3px;
  background: #34383b;
  text-align: center;
  color: #fff;
`

const InProgressFixtureItemHeaderTopOption = styled.div`
  border-radius: 3px;
  color: ${HermesMainText};
  float: right;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;

  span {
    width: 100%;
    display: block;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  ${props =>
    props.active &&
    `
    cursor: pointer;
    background: ${HermesMainSelect};
    color: #fff;
    `}
`

const InProgressFixtureItemBox = styled.div`
  min-height: 92px;
  padding: 2px;
  padding-top: 10px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const InProgressFixtureItemInnerBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 52px;
  font-size: 20px;
  text-align: center;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 9px;

  margin-bottom: 2px;
`
const InProgressFixtureItemInnerHomeBox = styled.div`
  width: 40%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  overflow: hidden;
  float: left;
`

const InProgressFixtureItemInnerHomeTeamBox = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 600;

  img {
    width: auto;
    height: 25px;
    margin-bottom: 2px;
  }
`

const InProgressFixtureItemInnerVSBox = styled.div`
  width: 20%;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  overflow: hidden;
  float: left;
`

const InProgressFixtureItemInnerAwayBox = styled.div`
  width: 40%;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  overflow: hidden;
  float: left;
`

const InProgressFixtureItemInnerAwayTeamBox = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 600;

  img {
    width: auto;
    height: 25px;
    margin-bottom: 2px;
  }
`

const InProgressFixtureItemInnerScoreBox = styled.div`
  display: block;
  margin-left: 20px;
  padding: 0;
  height: 40px;
  width: 40px;
  border: 1px solid ${HermesMainHover2};
  line-height: 35px;
`

const FixtureMarketBetBoxWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const FixtureMarketBetBox = styled.div`
  width: ${props => props.wd || 'calc(50% - 39px);'};
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  ${props =>
    !props.empty &&
    `
        &:hover {
            cursor: pointer;
            background: ${HermesMainSelect};
            color: ${HermesMainText};
        }  
    `}

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const FixtureMarketBetBoxPrice = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'home-box'
      ? `
      padding-right: 10px;
      img {
        margin-right: 5px;
      }
    `
      : `
      padding-left: 10px;
      img {
        margin-left: 5px;
      }
    `}
`

const FixtureMarketBetLineBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${props => props.fd || 'column'};
`

const FixtureMarketBetBoxName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'home-box'
      ? `
      justify-content: start;
      padding-left: 10px;
      `
      : `
      justify-content: end;
      padding-right: 10px;
      `}
`
const SportsEventContentItemPoint = styled.div`
  width: 100px;
  min-height: 36px;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-color: #424242 #181818 #232324 #2c3339 !important;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  background: rgba(48, 48, 48, 0.6);

  font-weight: 600;

  &:hover {
    cursor: pointer;
    background: ${HermesMainSelect};
    color: ${HermesMainText};
  }

  ${props =>
    props.isActive &&
    `
    background: ${HermesMainSelect};
    color: ${HermesMainText};
    `}
`

const SportsEventContentItemOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: 100%;
  height: 100%; // 각 요소의 높이를 고려한 적절한 높이 설정
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid ${HermesMainBorder};
`

const SportsEventContentItemLockIcon = styled(FaLock)`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${HermesMainBorder};
`

export const MobileSportEventInProgressFixtures = React.memo(
  ({
    eventKey,
    event,
    onClickFixtureCollapseHandler,
    onClickSportFixtureMarketAdditionalOptionHandler,
    onClickAddBettingCartHandler,
  }) => {
    return (
      <>
        <div>
          <div style={{ position: 'relative' }}>
            <SportFixtureLeagueTitleWrap onClick={() => onClickFixtureCollapseHandler(eventKey)}>
              <SportFixtureLeagueTitleBox>
                <SportsEventTitleTypeImg
                  src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${event.sportId}.png`}
                  alt=""
                />
                {event.locationImageUrl && <SportsEventTitleCountryImg src={event.locationImageUrl} alt="" />}
                <SportsEventTitleCountryInfo>{event.locationName}</SportsEventTitleCountryInfo>
                <SportsEventTitleArrowRightIcon />
                {event.leagueImageUrl && <SportsEventTitleLeagueImg src={event.leagueImageUrl} alt="" />}
                <SportsEventTitleLeagueInfo>{event.leagueName}</SportsEventTitleLeagueInfo>
                {/* <SportsEventTitleLeagueCount>{event.fixtures.length}경기</SportsEventTitleLeagueCount> */}
                {event.fixtureLeagueCollapse ? <SportsEventTitleArrowUpIcon /> : <SportsEventTitleArrowDownIcon />}
              </SportFixtureLeagueTitleBox>
            </SportFixtureLeagueTitleWrap>
          </div>
        </div>
        <SportsEventWrap key={eventKey}>
          {event.fixtureLeagueCollapse && (
            <SportsEventContentBox>
              {event.fixtures.map(fixtureData => {
                return (
                  <SportEventInProgressFixtureItem
                    key={`${eventKey}-${fixtureData.fixtureId}`}
                    fixtureKey={eventKey}
                    fixtureData={fixtureData}
                    onClickSportFixtureMarketAdditionalOptionHandler={onClickSportFixtureMarketAdditionalOptionHandler}
                    onClickAddBettingCartHandler={onClickAddBettingCartHandler}
                  />
                )
              })}
            </SportsEventContentBox>
          )}
        </SportsEventWrap>
      </>
    )
  },
)

const SportFixtureLeagueTitleWrap = styled.div`
  margin-top: 20px;
  padding: 8px 16px;
  cursor: pointer;
  background: rgba(48, 48, 48, 1);
  height: 36px;
  color: #fff;
  border-radius: 6px 6px 0 0;
`

const SportFixtureLeagueTitleBox = styled.div`
  font-weight: 700;
  font-size: 14px;
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
`

const SportsContentWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`

const SportsEventListWrap = styled.div`
  width: 100%;
  height: calc(100vh - 167px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
`

// 경기가 없을때 띄우는 창
const SportsGameEmptyInfoBox = styled.div`
  width: 100%;
  color: white;
  font-weight: 400;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const SportsInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

const SportsNotInProgressWrap = styled.div`
  width: 100%;
  display: block;
`

// 진행예정경기 문구
const SportsNotInProgressTextBox = styled.div`
  display: block;
  padding: 20px;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  color: #fff;
  margin-top: 20px;
  background: rgb(29, 30, 33);
`

// 페이징
const PaginationBox = styled.div`
  width: 100%;
`

const SportsEventWrap = styled.div`
  width: 100%;
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
`

const SportsEventTitleBox = styled.div`
  padding: 0 10px;
  height: 36px;
  width: 100%;
  color: white;
  background: rgba(48, 48, 48, 1);
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SportsEventTitleTypeImg = styled.img`
  width: auto;
  height: 20px;
`
const SportsEventTitleCountryImg = styled.img`
  width: auto;
  height: 20px;
  margin-left: 5px;
`

const SportsEventTitleCountryInfo = styled.span`
  min-width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;
`

const SportsEventTitleArrowRightIcon = styled(MdOutlineArrowForwardIos)`
  color: ${HermesMainHover2};
  width: 17px;
  height: auto;
  margin-left: 5px;
`

const SportsEventTitleLeagueImg = styled.img`
  width: auto;
  height: 20px;
  margin-left: 5px;
`

const SportsEventTitleLeagueInfo = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  display: inline-block;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SportsEventTitleLeagueCount = styled.span`
  width: max-content;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  margin-right: 10px;
  display: inline-block;
`

const SportsEventTitleArrowDownIcon = styled(MdKeyboardArrowDown)`
  color: ${HermesMainHover2};
  width: auto;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
`

const SportsEventTitleArrowUpIcon = styled(MdKeyboardArrowUp)`
  color: ${HermesMainHover2};
  width: auto;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
`

const SportsEventContentBox = styled.div`
  width: 100%;
  min-height: 50px;
  color: white;
  padding: 7px;
  display: flex;
  flex-direction: column;
  border: 2px solid #3a3a3a;

  background: rgb(20, 21, 23);
  border-radius: 0px 0px 6px 6px;
  gap: 2px;
`

const SportsEventContentTopWrap = styled.div`
  width: 100%;
  min-height: 1px;
  color: white;
  margin-bottom: 1px;

  &:not(:first-child) {
    margin-top: 5px;
  }
`

const SportsEventContentTopDate = styled.div`
  border-radius: 3px;
  color: #fff;
  float: left;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    color: ${HermesMainText};
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
  }
`

const SportsEventContentTopOption = styled.div`
  border-radius: 3px;
  color: #fff;
  float: right;
  padding: 4px 12px;
  background: ${HermesMainSelect};

  span {
    width: 100%;
    color: ${HermesMainText};
    display: block;
    text-align: center;
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
  }
`
