import {
  CustomCenterFormBottomWrap,
  MoneyApplyButton,
  MoneyCustomFormInputWrap,
  MoneyInfo,
  MoneyInfoContent,
  MoneyInfoGameMoney,
} from '@/shared/components/MoneyCustomElement'
import Error from '@/shared/components/form/Error'
import { FormGroupIcon } from '@/shared/components/form/FormElements'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { commonReg2 } from '@/utils/validate/commonValidate'
import { MoneyWithdrawalValidate } from '@/utils/validate/moneyFormValidate'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { searchMemberMoneyWithdrawal } from '../../api/memberHistory/memberHistoryApi'
import { createUserMoneyWithdrawal, searchMinimumAmount } from '../../api/memberManage/memberManageApi'
import { fetchMemberInfoAction } from '../../redux/memberInfoSlice'
import {
  CustomMoneyFormGroupField,
  MoneyFormButtomInfo,
  MoneyFormButton,
  MoneyFormField,
  MoneyFormGroup,
  MoneyFormInput,
  MoneyFormInputWrap,
  MoneyFormLabel,
  MoneyFormModifyButtomInfo,
  MoneyFormModifyButton,
  MoneyFormWrap,
  WebPageWrap,
} from '../../shared/components/form/newForm'
import { colorMainText } from '../../utils/palette'
import CustomLoading from '../CustomLoading'
import MoneyWithdrawalListData from '../MyPage/MoneyWithdrawalHistory/MoneyWithdrawalListData'

const MoneyWithdrawalForm = () => {
  const [myInfoLoading, setMyInfoLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  /**
   * 회원 머니출금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [historyParams, setHistoryParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  // const { totalElement, content } = useSearchMemberMoneyWithdrawal(params)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  useEffect(() => {
    setLoading(true)
    searchMemberMoneyWithdrawal(historyParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }, [historyParams])

  const fetchSearchMemberMoneyWithdrawal = async () => {
    setLoading(true)
    await searchMemberMoneyWithdrawal(historyParams)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const [moneyWithdrawalListData, setMoneyWithdrawalListData] = useState(MoneyWithdrawalListData(content))

  useEffect(() => {
    setMoneyWithdrawalListData(MoneyWithdrawalListData(content))
  }, [content])

  const [rows, setData] = useState(moneyWithdrawalListData.tableRowsData)
  useEffect(() => {
    setData(moneyWithdrawalListData.tableRowsData)
  }, [moneyWithdrawalListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 최소 출금액
  const [minimumWithdrawal, setMinimumWithdrawal] = useState('0')

  const { holdingMoney, totalGameMoney, userId, userName, bankName, accountNum } = useSelector(state => {
    const { memberInfo } = state

    return {
      holdingMoney: memberInfo.memberHoldingMoney,
      totalGameMoney: memberInfo.memberTotalGameMoney,
      userId: memberInfo.userId,
      userName: memberInfo.userName,
      bankName: memberInfo.bankName,
      accountNum: memberInfo.accountNum,
    }
  })

  useEffect(() => {
    setMyInfoLoading(true)

    searchMinimumAmount()
      .then(res => {
        setMinimumWithdrawal(res.data.minimumWithdrawal)
        setMyInfoLoading(false)
      })
      .catch(error => {
        setMyInfoLoading(false)
      })
  }, [])

  // 출금 금액
  const [applyMoney, setApplyMoney] = useState('0')

  const onClickMoneyHandler = name => {
    setApplyMoney(prev => (Number(prev.replaceAll(',', '')) + Number(name)).toString().replace(commonReg2, ','))
  }

  const onClearClickHandler = () => {
    setApplyMoney('0')
  }

  // api 여러번 호출 막기
  const [apiFlag, setApiFlag] = useState(false)

  const onSubmit = e => {
    if (apiFlag) return
    const checkMoney = applyMoney?.toString().replaceAll(',', '')
    if (Number(checkMoney) <= 0) {
      alert('금액을 다시 확인해주세요.')
      return
    }

    if (Number(checkMoney) > 0 && checkMoney?.substr(checkMoney.length - 4, 4) !== '0000') {
      alert('10,000원단위로 입력이 가능합니다.')
      return
    }
    setApiFlag(true)

    const body = {
      applyMoney: checkMoney,
    }

    createUserMoneyWithdrawal(body)
      .then(res => {
        alert('출금신청이 완료되었습니다.')
        setApiFlag(false)
        fetchSearchMemberMoneyWithdrawal()
        dispatch(fetchMemberInfoAction())
      })
      .catch(error => {
        const { errorCode } = error.response.data

        switch (errorCode) {
          case 'MONEY-1007':
            alert('현재 처리중인 입금 신청이 존재합니다.\n입금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1008':
            alert('현재 처리중인 출금 신청이 존재합니다.\n출금내역에서 상태 확인 후 다시 시도해주세요.')
            break
          case 'MONEY-1009':
            alert('현재 머니이동을 처리중입니다.\n잠시 후 다시 시도해주세요.')
            break
          default:
            alert('출금신청이 실패했습니다. 다시 시도해주세요.')
        }
        setApiFlag(false)
        fetchSearchMemberMoneyWithdrawal()
      })
  }

  return (
    <WebPageWrap>
      <MoneyWithdrawalHeaderWrap>
        <MoneyWithdrawalHeaderInfo>출금신청</MoneyWithdrawalHeaderInfo>
        <MoneyWithdrawalCardSubhead>
          * 출금시 등록한 계좌정보로 출금이 진행되며, 재확인 부탁드립니다.
        </MoneyWithdrawalCardSubhead>
        <MoneyWithdrawalCardSubhead>
          * 지갑머니 기준으로 출금가능 하며, 최소 {minimumWithdrawal}원이상 출금이 가능합니다.
        </MoneyWithdrawalCardSubhead>
        <MoneyWithdrawalCardSubhead>
          * 온라인은행 계좌(토스.퍼블리카.핀크.페이코.카카오페이.신세계아이앤씨)로 입금시에는 처리불가합니다.
        </MoneyWithdrawalCardSubhead>
      </MoneyWithdrawalHeaderWrap>
      {myInfoLoading || loading ? (
        <CustomLoading />
      ) : (
        <MoneyWithdrawalBodyWrap>
          <MoneyWithdrawalInfoWrap>
            <MoneyInfo>
              <MoneyInfoGameMoney style={{ width: '50px', height: '50px' }} />
              <MoneyInfoContent>지갑머니: {holdingMoney?.toString().replace(commonReg2, ',')}</MoneyInfoContent>
            </MoneyInfo>
            <MoneyInfo>
              <img
                style={{ width: '50px', height: '50px' }}
                src={`${process.env.PUBLIC_URL}/img/header/slot-casino-icon.png`}
                alt=""
              />
              <MoneyInfoContent>카지노 머니: {totalGameMoney?.toString().replace(commonReg2, ',')}</MoneyInfoContent>
            </MoneyInfo>
          </MoneyWithdrawalInfoWrap>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              memberId: userId,
              memberName: userName,
              bankName,
              accountHolder: accountNum,
              applyMoney,
              // TODO 지갑머니 < 출금머니가 클때 validate 체크할려고 넣은 변수
              holdingMoney,
              minimumWithdrawal,
            }}
            validate={MoneyWithdrawalValidate}
          >
            {({ handleSubmit, form: { reset } }) => (
              <MoneyFormWrap horizontal onSubmit={handleSubmit}>
                <MoneyFormGroup>
                  <MoneyFormLabel>아이디</MoneyFormLabel>

                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="memberId">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup>
                  <MoneyFormLabel>예금주명</MoneyFormLabel>

                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="memberName">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup>
                  <MoneyFormLabel>은행</MoneyFormLabel>

                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="bankName">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup>
                  <MoneyFormLabel>출금계좌</MoneyFormLabel>

                  <MoneyFormField>
                    <MoneyFormInputWrap>
                      <Field name="accountHolder">
                        {({ input, meta }) => <MoneyFormInput {...input} type="text" disabled />}
                      </Field>
                    </MoneyFormInputWrap>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup style={{ borderBottom: 'none' }}>
                  <MoneyFormLabel>신청금액</MoneyFormLabel>
                  <MoneyFormField>
                    <MoneyCustomFormInputWrap>
                      <Field name="applyMoney">
                        {({ input, meta }) => (
                          <MoneyFormInputWrap>
                            <MoneyFormInput
                              {...input}
                              type="text"
                              value={input.value}
                              onChange={e => {
                                input.onChange(e)
                                setApplyMoney(e.target.value)
                              }}
                            />
                            {meta.error && <Error error={meta.error} />}
                          </MoneyFormInputWrap>
                        )}
                      </Field>
                    </MoneyCustomFormInputWrap>

                    <FormGroupIcon>원</FormGroupIcon>
                  </MoneyFormField>
                </MoneyFormGroup>
                <MoneyFormGroup style={{ justifyContent: 'center' }}>
                  <CustomMoneyFormGroupField>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="10000"
                      onClick={e => {
                        onClickMoneyHandler('10000')
                      }}
                    >
                      <MoneyFormButtomInfo>1만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="50000"
                      onClick={e => {
                        onClickMoneyHandler('50000')
                      }}
                    >
                      <MoneyFormButtomInfo>5만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="100000"
                      onClick={e => {
                        onClickMoneyHandler('100000')
                      }}
                    >
                      <MoneyFormButtomInfo>10만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="500000"
                      onClick={e => {
                        onClickMoneyHandler('500000')
                      }}
                    >
                      <MoneyFormButtomInfo>50만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="1000000"
                      onClick={e => {
                        onClickMoneyHandler('1000000')
                      }}
                    >
                      <MoneyFormButtomInfo>100만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      name="5000000"
                      onClick={e => {
                        onClickMoneyHandler('5000000')
                      }}
                    >
                      <MoneyFormButtomInfo>500만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormButton
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      onClick={e => {
                        onClickMoneyHandler('10000000')
                      }}
                    >
                      <MoneyFormButtomInfo>1000만원</MoneyFormButtomInfo>
                    </MoneyFormButton>
                    <MoneyFormModifyButton
                      $active
                      size="sm"
                      type="button"
                      variant="outline-secondary"
                      onClick={() => {
                        onClearClickHandler()
                      }}
                    >
                      <MoneyFormModifyButtomInfo>정정</MoneyFormModifyButtomInfo>
                    </MoneyFormModifyButton>{' '}
                  </CustomMoneyFormGroupField>
                </MoneyFormGroup>

                <CustomCenterFormBottomWrap>
                  <MoneyApplyButton variant="primary" type="submit">
                    <MoneyFormModifyButtomInfo>출금신청</MoneyFormModifyButtomInfo>
                  </MoneyApplyButton>
                </CustomCenterFormBottomWrap>
              </MoneyFormWrap>
            )}
          </Form>
          <MoneyWithdrawalHeaderTwoWrap style={{ marginTop: '-20px' }}>
            <MoneyWithdrawalHeaderThirdInfo>&lt;&nbsp;출금 내역&nbsp;&gt;</MoneyWithdrawalHeaderThirdInfo>
          </MoneyWithdrawalHeaderTwoWrap>
          <MoneyWithdrawalBodyTwoWrap>
            <ReactTableBase columns={moneyWithdrawalListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
          </MoneyWithdrawalBodyTwoWrap>
        </MoneyWithdrawalBodyWrap>
      )}
    </WebPageWrap>
  )
}

export default MoneyWithdrawalForm

// endregion

const MoneyWithdrawalHeaderThirdInfo = styled.span`
  font-style: normal;
  line-height: 23px;
  margin-bottom: 10px;
  /* 엘로우폰트 */

  color: #ffc01a;
`

const MoneyWithdrawalHeaderTwoWrap = styled.div`
  height: 50px;
  width: 100%;
  padding: 20px 0 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px !important;

  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalBodyTwoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

const MoneyWithdrawalWrap = styled.div`
  background: #000000;
  border: 1px solid #4a4a4a;

  height: 100%;
  min-height: 550px;
  margin-top: 10px;
`

const MoneyWithdrawalHeaderWrap = styled.div`
  border-top: 1px solid #edae07;
  padding: 20px 10px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px !important;
  border-bottom: 1px solid #edae07;
`

const MoneyWithdrawalHeaderInfo = styled.span`
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 5px;
  /* 엘로우폰트 */

  color: #ffc01a;
`
export const MoneyWithdrawalCardSubhead = styled.p`
  text-transform: none;
  line-height: 14px;
  opacity: 0.7;
  color: ${colorMainText};
`

const MoneyWithdrawalBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  // justify-content: center;

  padding: 10px 20px;

  gap: 10px;
`

export const MoneyWithdrawalInfoWrap = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
