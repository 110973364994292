import { BorderedBottomTabs, NavItem, NavLink, TabPaneInnerWrap, TabsWrap } from '@/shared/components/Tabs'
import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { HermesMainText } from '../../../utils/palette'

// import PartnerManageMentTabs from '../../PartnerManageMentTabs'

const SuremanTabs = ({ activeKey }) => {
  const navigate = useNavigate()

  return (
    <CustomBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="1"
                onClick={() => {
                  navigate('/minigame/sureman/roulette1m')
                }}
              >
                슈어룰렛 1분
              </MiniGameNavLink>
            </MiniGameNavItem>
            {/* <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="2"
                onClick={() => {
                  navigate('/minigame/sureman/roulette2m')
                }}
              >
                슈어룰렛 2분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="3"
                onClick={() => {
                  navigate('/minigame/sureman/roulette3m')
                }}
              >
                슈어룰렛 3분
              </MiniGameNavLink>
            </MiniGameNavItem> */}
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="4"
                onClick={() => {
                  navigate('/minigame/sureman/racing1m')
                }}
              >
                슈어레이싱 1분
              </MiniGameNavLink>
            </MiniGameNavItem>
            {/* <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="5"
                onClick={() => {
                  navigate('/minigame/sureman/racing2m')
                }}
              >
                슈어레이싱 2분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="6"
                onClick={() => {
                  navigate('/minigame/sureman/racing3m')
                }}
              >
                슈어레이싱 3분
              </MiniGameNavLink>
            </MiniGameNavItem> */}

            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="7"
                onClick={() => {
                  navigate('/minigame/sureman/ladder1m')
                }}
              >
                슈어사다리 1분
              </MiniGameNavLink>
            </MiniGameNavItem>
            {/* <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="8"
                onClick={() => {
                  navigate('/minigame/sureman/ladder2m')
                }}
              >
                슈어사다리 2분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="9"
                onClick={() => {
                  navigate('/minigame/sureman/ladder3m')
                }}
              >
                슈어사다리 3분
              </MiniGameNavLink>
            </MiniGameNavItem> */}
            {/* <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="10"
                onClick={() => {
                  navigate('/minigame/sureman/powerball1m')
                }}
              >
                슈어파워볼 1분
              </MiniGameNavLink>
            </MiniGameNavItem>

            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="11"
                onClick={() => {
                  navigate('/minigame/sureman/powerball2m')
                }}
              >
                슈어파워볼 2분
              </MiniGameNavLink>
            </MiniGameNavItem>
            <MiniGameNavItem>
              <MiniGameNavLink
                eventKey="12"
                onClick={() => {
                  navigate('/minigame/sureman/powerball3m')
                }}
              >
                슈어파워볼 3분
              </MiniGameNavLink>
            </MiniGameNavItem> */}
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </CustomBottomTabs>
  )
}
export default SuremanTabs

const CustomBottomTabs = styled(BorderedBottomTabs)`
  border-top: none !important;
  background: rgb(33, 35, 38) !important;
`

const MiniGameNavLink = styled(NavLink)`
  &.active,
  &.active:focus,
  &.active:hover {
    // background-color: #636260 !important;
    color: ${HermesMainText} !important;
    font-weight: 600;
  }
`

const MiniGameNavItem = styled(NavItem)`
  width: 11.11%;
  text-align: center;
  // border: solid 1px red;
`

const NtryGameWrap = styled.div`
  height: 30px;
  position: relative;
  width: 100%;
`

export const TableBettingInfo = styled.div`
  width: 30%;
  position: left;
  color: white;
`

export const BettingFormGroup = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  gap: 10px;

  border-bottom: 1px solid #4a4a4a;
`
